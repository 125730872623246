import React, { useEffect, useState } from 'react';
import { useGetList, useInput } from 'react-admin';
import { InputTitle } from '../../StyledComponents/InputTitle';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
import { COLORS } from '../../../assets/constants/theme';
import { Checkbox, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { useRequiredSchema } from '../../../Hooks/useRequiredSchema';

const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const InputTags = ({ title, source, reference, subtitle, validate = [] }) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const { isRequiredSchema } = useRequiredSchema();
  const { id, field, fieldState } = useInput({ source, validate: [...isRequiredSchema(source), ...validate] });
  const [tags, setTags] = useState([]);
  const { data, error, loading } = useGetList(
    `${reference}`,
    {
      pagination: {
        perPage: 100,
        page: 1,
      },
      limit: 100,
    },
    {
      onSuccess: ({ data }) => {
        setTags(data.map((d) => d.text));
        if (field.value === '') field.onChange([]);
      },
    }
  );
  return (
    <div>
      {title && <InputTitle required={!!isRequiredSchema(source)}>{title}</InputTitle>}
      {subtitle && <InputSubtitle>{subtitle}</InputSubtitle>}
      <Autocomplete
        isOptionEqualToValue={(e, v) => {
          return e === v;
        }}
        multiple
        limitTags={1}
        options={tags}
        disableCloseOnSelect
        disableClearable
        ChipProps={{
          onDelete: null,
          sx: { fontWeight: 600 },
        }}
        getOptionLabel={(option) => {
          if (option !== undefined) return option;
        }}
        filterOptions={(options, params) => {
          if (field.value === '') {
            field.onChange([]);
          }
          const filtered = filter(options, params);
          const { inputValue } = params;
          console.log(inputValue, 'filterOptionsinputValue');
          const isExisting =
            options && options?.some((option) => inputValue === option);
          if (inputValue !== '') {
            if (!isExisting) {
              filtered.push(`+Add ${inputValue}`);
            } else {
              filtered.push(inputValue);
            }
          }
          return filtered;
        }}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={option}>
              {!option.startsWith('+Add ') && (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  sx={{
                    padding: 0,
                    marginRight: 1,
                    color: palette?.primary,
                    '&.Mui-checked': {
                      color: palette?.primary,
                    },
                  }}
                  checked={selected}
                />
              )}
              <span
                style={{
                  color: field.value.includes(option)
                    ? palette?.primary
                    : 'black',
                  fontWeight: 600,
                }}
              >
                {option}
              </span>
            </li>
          );
        }}
        onChange={(event, newValue) => {
          if (newValue) {
            const excludeUndefined = newValue?.filter(
              (tag) => tag !== undefined
            );
            const filteredNewValue = excludeUndefined?.map((tag) =>
              tag?.startsWith('+Add ') ? tag.split('+Add ')[1] : tag
            );
            const uniqueTags = [...new Set([...tags, ...filteredNewValue])];
            if (tags.length > 0) {
              field.onChange(filteredNewValue);
              setTags(uniqueTags);
            } else {
              if (filteredNewValue && filteredNewValue?.length > 0) {
                field.onChange(filteredNewValue);
                setTags(uniqueTags);
              }
            }
          }
        }}
        value={field.value}
        sx={{
          width: 250,
          '& .MuiFilledInput-root:before': {
            border: 'none',
          },
          '& .MuiFilledInput-root': {
            paddingX: 1,
            paddingY: 0.7,
          },
          '& .MuiFilledInput-root:after': {
            border: 'none',
          },
          '& .MuiAutocomplete-inputRoot': {
            borderRadius: '10px',
            backgroundColor: 'transparent',
            // border: '1px solid grey',
            '& .MuiChip-root': {
              backgroundColor: palette?.primary,
              color: 'white',
            },
            '& .MuiChip-deleteIcon': {
              color: 'white',
            },
          },
          '& .MuiAutocomplete-tag': {
            backgroundColor: '#7C7C7C',
            borderRadius: '10px',
            padding: '5px',
            color: 'white',
          },
          '& .MuiOutlinedInput-root':{
            padding:'2px',
            height:'auto'
          }
        }}
        renderInput={(params) => <TextField {...params} label="" />}
      />
    </div>
  );
};

export default InputTags;
