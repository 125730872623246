import React, { useEffect, useState } from 'react';
// Hooks & Utils
import { dynamicColor } from '../../helpers/dynamicColor';
// Components
import { Card, IconButton, Button } from '../Generics/index';
import styles from './ImportDataWizar.module.css';
// Material & Icons
import { Alert, Modal } from '@mui/material';
import { useSelector } from 'react-redux';
import { BiEdit, BiCheck, BiImport, BiExport, BiX } from 'react-icons/bi';
import { useResourceContext } from 'react-admin';
import { LuFileSpreadsheet } from 'react-icons/lu';

const importBtStyle = {
  width: '130px',
  fontWeight: '600',
  display: 'flex',
  height: '44px',
  alignItems: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  justifyContent: 'center',
  textTransform: 'none',
  gap: '10px',
  color: '#8A8A8A',
  fontSize: '15px',
};
const cardStyle = {
  height: '650px',
  width: '720px',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: '10px',
  backgroundColor: '#fff',
  padding: '10px 0px',
};
const btStepStyle = {
  borderRadius: '50px',
  height: '50px',
  width: '20%',
};
const errorBtStyle = {
  backgroundColor: '#B12121',
  color: '#fff',
  textTransform: 'none',
  fontWeight: 400,
  fontSize: '16px',
  borderRadius: '5px',
  padding: '10px 25px',
  width: '200px',
};
const ImportDataWizard = () => {
  const [showCard, setShowCard] = useState(false);
  const [step, setStep] = useState(1);
  const [templateUpload, setTemplateUpload] = useState(false);
  const [formResults, setFormResults] = useState(undefined);
  const palette = useSelector((state) => state?.palette?.palette);
  const resource = useResourceContext();
  const openCard = () => {
    setShowCard(!showCard);
  };
  const closeCard = () => {
    setShowCard(false);
    setStep(1);
  };
  const ContentDataWizard = () => {
    switch (step) {
    case 1:
      return <ContentDataWizard1 palette={palette} resource={resource} />;
    case 2:
      return <ContentDataWizard2 />;
    case 3:
      return (
        <ContentDataWizard3
          palette={palette}
          setTemplateUpload={setTemplateUpload}
          setFormResults={setFormResults}
        />
      );
    case 4:
      return (
        <ContentDataWizard4 resource={resource} formResults={formResults} />
      );
    default:
      return null;
    }
  };
  const actionStep = (action) => {
    if (step === 4) {
      closeCard();
    }
    if (action === 'next' && step < 4) {
      setStep(step + 1);
    }
    if (action === 'back' && step > 1) {
      setStep(step - 1);
    }
  };
  const textNextBtn = () => {
    switch (step) {
    case 1:
      return 'Next';
    case 2:
      return 'Next';
    case 3:
      return 'Import';
    case 4:
      return 'End';
    default:
      return 'Next';
    }
  };
  useEffect(() => {
    setTemplateUpload(false);
  }, [step]);
  useEffect(() => {
    setFormResults(undefined);
  }, []);
  return (
    <div>
      <Button style={importBtStyle} onClick={openCard}>
        <ImportSvg />
        Import
      </Button>
      {showCard && (
        <Modal open={showCard} onClose={closeCard}>
          <Card style={cardStyle}>
            <div className={styles.closeContainer}>
              <IconButton
                style={{ height: 'auto', width: 'auto', fontSize: '20px' }}
                onClick={closeCard}
              >
                X
              </IconButton>
            </div>
            <div className={styles.subContainer}>
              <StepsDataWizard
                step={step}
                setStep={setStep}
                palette={palette}
                formResults={formResults}
              />
              <div className={styles.content}>
                <div className={styles.informationContainer}>
                  {ContentDataWizard()}
                </div>
                <div className={styles.btnContainer}>
                  {step > 1 && step < 4 && (
                    <Button
                      onClick={() => actionStep('back')}
                      style={{
                        fontWeight: 700,
                        textTransform: 'none',
                        fontSize: '16px',
                        width: '90px',
                      }}
                    >
                      Back
                    </Button>
                  )}
                  <Button
                    onClick={() => actionStep('next')}
                    style={{
                      backgroundColor: palette?.primary,
                      color: '#fff',
                      width: '90px',
                      borderRadius: '5px',
                      fontWeight: 700,
                      textTransform: 'none',
                      fontSize: '16px',
                    }}
                    disabled={step === 3 && !templateUpload ? true : false}
                  >
                    {textNextBtn()}
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </Modal>
      )}
    </div>
  );
};
const StepsDataWizard = ({ step, setStep, palette, formResults }) => {
  const changeStep = (value) => {
    setStep(value);
  };
  const getIcon = (stepIndex) => {
    if (step > stepIndex) {
      return <BiCheck size={25} color="#fff" />;
    }
    switch (stepIndex) {
    case 1:
      return <BiImport size={25} color="#fff" />;
    case 2:
      return <BiEdit size={25} color="#fff" />;
    case 3:
      return <BiExport size={25} color="#fff" />;
    default:
      return null;
    }
  };

  const getBackgroundColor = (stepIndex) => {
    if (step > stepIndex) {
      return '#4caf50';
    }
    return step === stepIndex ? palette?.primary : '#ccc';
  };
  return (
    <div className={styles.containerSteps}>
      {/* STEP 1 */}
      <div className={`${styles.stepContainer} ${styles.stepContainer1}`}>
        <div className={styles.iconAndDivider}>
          <IconButton
            style={{
              ...btStepStyle,
              backgroundColor: getBackgroundColor(1),
            }}
            onClick={() => changeStep(1)}
          >
            {getIcon(1)}
          </IconButton>
          <div
            className={`${styles.divider} ${step >= 2 ? styles.activeDivider : ''}`}
          ></div>
        </div>
        <div className={styles.textStep}>
          <p className={styles.titleStep}>STEP 1</p>
          <p className={styles.descriptionStep}>Download Template</p>
        </div>
      </div>

      {/* STEP 2 */}
      <div className={`${styles.stepContainer} ${styles.stepContainer2}`}>
        <div className={styles.iconAndDivider}>
          <IconButton
            style={{
              ...btStepStyle,
              backgroundColor: getBackgroundColor(2),
            }}
            onClick={() => changeStep(2)}
          >
            {getIcon(2)}
          </IconButton>
          <div
            className={`${styles.divider} ${step >= 3 ? styles.activeDivider : ''}`}
          ></div>
        </div>
        <div className={styles.textStep}>
          <p className={styles.titleStep}>STEP 2</p>
          <p className={styles.descriptionStep}>Fill Template</p>
        </div>
      </div>

      {/* STEP 3 */}
      <div className={`${styles.stepContainer} ${styles.stepContainer3}`}>
        <div className={styles.iconAndDivider}>
          <IconButton
            style={{
              ...btStepStyle,
              width: '40%',
              backgroundColor:
                step > 3
                  ? formResults === 'error'
                    ? '#B12121'
                    : getBackgroundColor(3)
                  : getBackgroundColor(3),
            }}
            onClick={() => changeStep(3)}
          >
            {step > 3 ? (
              formResults === 'error' ? (
                <BiX size={25} color="#fff" />
              ) : (
                getIcon(3)
              )
            ) : (
              getIcon(3)
            )}
          </IconButton>
          <div className={styles.lastDivider}></div>
        </div>
        <div className={styles.textStep}>
          <p className={styles.titleStep}>STEP 3</p>
          <p className={styles.descriptionStep}>Upload Template</p>
        </div>
      </div>
    </div>
  );
};
const ContentDataWizard1 = ({ palette, resource }) => {
  const downloadTemplate = () => {
    console.log('downloadTemplate');
  };
  const dynamicBg = dynamicColor(palette?.primary, 0.2).rgba;

  return (
    <>
      <p className={styles.titleContent}>Download the Template</p>
      <p className={styles.descriptionContent}>
        An XLS template will be downloaded with the fields you{' '}
        <strong>{resource}</strong> selected for your the file will contain info
        over each field, any new column will be ignored.
      </p>
      <div className={styles.checkBoxContainer}>
        <input type="checkbox" />
        <p>Download with existing data</p>
      </div>
      <Button
        onClick={downloadTemplate}
        style={{
          backgroundColor: dynamicBg,
          color: palette?.primary,
          fontWeight: 400,
          textTransform: 'none',
          fontSize: '16px',
          borderRadius: '5px',
          padding: '10px 25px',
        }}
      >
        Download template
      </Button>
    </>
  );
};
const ContentDataWizard2 = () => {
  return (
    <>
      <p className={styles.titleContent}>Fill the Template</p>
      <p className={styles.descriptionContent}>
        Fill the template with all the necessary information.
      </p>
      <p className={styles.subtitleContent}>DO NOT</p>
      <ul>
        <li>
          <p>Add columns</p>
        </li>
        <li>
          <p>Change the disposition of the sheets</p>
        </li>
      </ul>
      <Alert
        severity="warning"
        sx={{
          backgroundColor: '#FFF8B7',
          borderRadius: '10px',
          border: '1px solid #BF9500',
          fontSize: '15px',
          marginTop: '2em',
        }}
      >
        Please respect the rules for data import. Any doubt or additional
        requests you can contact us to rocio@muvinai.com
      </Alert>
    </>
  );
};
const ContentDataWizard3 = ({ palette, setTemplateUpload, setFormResults }) => {
  const [progress, setProgress] = useState(0);
  const [fileSize, setFileSize] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState(5);
  const [fileName, setFileName] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (isUploading) {
      const totalTime = 5000;
      const startTime = Date.now();

      const intervalId = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const newProgress = (elapsedTime / totalTime) * 100;
        setProgress(newProgress.toFixed(2));
        setTimeRemaining(
          Math.max(0, Math.ceil((totalTime - elapsedTime) / 1000)),
        );
        const errorChance = Math.random();
        if (errorChance < 0.01) {
          clearInterval(intervalId);
          setIsUploading(false);
          setFormResults('error');
          setTemplateUpload(true);
          return;
        }
        if (elapsedTime >= totalTime) {
          clearInterval(intervalId);
          setIsUploading(false);
          setFormResults('success');
          setTemplateUpload(true);
        }
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, [isUploading]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    setFileName(file.name);
    setFileSize(file.size);
    setProgress(0);
    setTimeRemaining(5);
    setIsUploading(true);
    setFormResults(undefined);
    // const formData = new FormData();
    // formData.append('file', file);

    // const xhr = new XMLHttpRequest();

    // xhr.upload.addEventListener('progress', (event) => {
    //   if (event.lengthComputable) {
    //     const percentComplete = (event.loaded / event.total) * 100;
    //     setProgress(percentComplete);

    //     const elapsedTime = (Date.now() - startTime) / 1000;
    //     const speed = event.loaded / 1024 / elapsedTime;
    //     const remainingBytes = event.total - event.loaded;
    //     const remainingTime = remainingBytes / (speed * 1024);
    //     setTimeRemaining(Math.max(0, Math.round(remainingTime)));
    //   }
    // });

    // xhr.upload.addEventListener('load', () => {
    //   setProgress(100);
    //   setTimeRemaining(0);
    // });

    // xhr.open('POST', '/');
    // xhr.send(formData);

    // const startTime = Date.now();
  };
  return (
    <>
      <p className={styles.titleContent}>Upload the Template</p>
      <p className={styles.descriptionContent}>
        Once your tempalte is filled you need to upload it.
      </p>
      <div className={styles.uploadTemplate}>
        <input
          type="file"
          id="fileInput"
          className={styles.uploadTemplate}
          onChange={handleFileChange}
        />
        <label htmlFor="fileInput" className={styles.customButton}>
          <BiExport size={50} color="#515151" />
          <span>
            Upload as a <strong>.csv</strong> file
          </span>
        </label>
      </div>
      {fileSize && (
        <div className={styles.uploadTemplateData}>
          <div className={styles.uploadTemplateDataText}>
            <LuFileSpreadsheet color="#07923E" size={40} />
            <div className={styles.uploadInfo}>
              {fileName && <span className={styles.fileName}>{fileName}</span>}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                  alignItems: 'center',
                }}
              >
                {fileSize && (
                  <span className={styles.fileData}>
                    {(fileSize / 1024).toFixed(2)} KB
                  </span>
                )}
                <span>-</span>
                {timeRemaining !== null && (
                  <span className={styles.fileData}>
                    {timeRemaining} seconds left
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.uploadTemplateDataProgress}>
            <div
              className={styles.uploadTemplateProgressFill}
              style={{
                backgroundColor: palette?.primary,
                width: `${progress}%`,
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};
const ContentDataWizard4 = ({ resource, formResults }) => {
  return (
    <>
      <p className={styles.titleContent}>Results</p>
      {formResults === 'success' ? (
        <div className={styles.successInfo}>
          <IconButton
            style={{
              ...btStepStyle,
              width: '100px',
              height: '100px',
              cursor: 'default',
              backgroundColor: '#07923E',
            }}
          >
            <BiCheck color="#fff" size={100} />
          </IconButton>
          <p>
            Your data was uploaded successfuly!!! You can now view it in your{' '}
            {resource} table.
          </p>
        </div>
      ) : (
        <div className={styles.errorInfo}>
          <Alert severity="error">
            There was an error when uploading your data. The following file
            contains all the rows that were not uploaded with a detail. Reupload
            the file with the corrections to import the data.
          </Alert>
          <Button style={errorBtStyle}>Download error logs</Button>
        </div>
      )}
    </>
  );
};
const ImportSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 20H6C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19V5C5 4.73478 5.10536 4.48043 5.29289 4.29289C5.48043 4.10536 5.73478 4 6 4H11V7C11 7.79565 11.3161 8.55871 11.8787 9.12132C12.4413 9.68393 13.2044 10 14 10H17V12C17 12.2652 17.1054 12.5196 17.2929 12.7071C17.4804 12.8946 17.7348 13 18 13C18.2652 13 18.5196 12.8946 18.7071 12.7071C18.8946 12.5196 19 12.2652 19 12V8.94C18.9896 8.84813 18.9695 8.75763 18.94 8.67V8.58C18.8897 8.4754 18.8258 8.37789 18.75 8.29L12.75 2.29C12.6621 2.21419 12.5646 2.15031 12.46 2.1C12.4302 2.09576 12.3998 2.09576 12.37 2.1L12.06 2H6C5.20435 2 4.44129 2.31607 3.87868 2.87868C3.31607 3.44129 3 4.20435 3 5V19C3 19.7956 3.31607 20.5587 3.87868 21.1213C4.44129 21.6839 5.20435 22 6 22H11C11.2652 22 11.5196 21.8946 11.7071 21.7071C11.8946 21.5196 12 21.2652 12 21C12 20.7348 11.8946 20.4804 11.7071 20.2929C11.5196 20.1054 11.2652 20 11 20ZM13 5.41L15.59 8H14C13.7348 8 13.4804 7.89464 13.2929 7.70711C13.1054 7.51957 13 7.26522 13 7V5.41ZM19 15H13.41L14.71 13.71C14.8983 13.5217 15.0041 13.2663 15.0041 13C15.0041 12.7337 14.8983 12.4783 14.71 12.29C14.5217 12.1017 14.2663 11.9959 14 11.9959C13.7337 11.9959 13.4783 12.1017 13.29 12.29L10.29 15.29C10.2017 15.3872 10.1306 15.4988 10.08 15.62C9.97998 15.8635 9.97998 16.1365 10.08 16.38C10.1258 16.5036 10.1974 16.6161 10.29 16.71L13.29 19.71C13.383 19.8037 13.4936 19.8781 13.6154 19.9289C13.7373 19.9797 13.868 20.0058 14 20.0058C14.132 20.0058 14.2627 19.9797 14.3846 19.9289C14.5064 19.8781 14.617 19.8037 14.71 19.71C14.8037 19.617 14.8781 19.5064 14.9289 19.3846C14.9797 19.2627 15.0058 19.132 15.0058 19C15.0058 18.868 14.9797 18.7373 14.9289 18.6154C14.8781 18.4936 14.8037 18.383 14.71 18.29L13.41 17H19C19.2652 17 19.5196 16.8946 19.7071 16.7071C19.8946 16.5196 20 16.2652 20 16C20 15.7348 19.8946 15.4804 19.7071 15.2929C19.5196 15.1054 19.2652 15 19 15Z"
        fill="#8A8A8A"
      />
    </svg>
  );
};
export default ImportDataWizard;
