import { useSelector } from 'react-redux';
import { AiTwotoneDelete } from 'react-icons/ai';

export const ImageShow = ({ src, sx, selected, onDelete, index, item }) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const handleOnClickDelete = () => {
    onDelete && onDelete(index);
  };
  return (
    <div style={{position: 'relative',width:item?.size === 0.5 ?'50%':'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      
      <button 
        onClick={handleOnClickDelete}
        style={{      
          width: '35px',
          height: '35px',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundColor: 'rgba(255,255,255, 0.2)',
          borderRadius: '50%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 5,
          cursor: 'pointer',
          outline: 'none'
        }}>

        <AiTwotoneDelete
          size="25px"
        />
      </button>
      <p style={{position:'absolute',margin: 5, fontSize:'18px',color:item?.font_color ? item?.font_color : 'white', textAlign:'center', textOverflow:'ellipsis', overflow:'hidden',width:'100%', fontWeight:600}}>{item?.title}</p>
      { 
        src ? (
          <img src={src} alt="asd" style={{ cursor: 'pointer', outline: selected ? `3px solid ${palette?.primary}` : '1px solid #d8d8d8', borderRadius: '5px', width: '100%', height: '100px', ...sx}} />
        ) : (
          <div style={{ cursor: 'pointer', outline: selected ? `3px solid ${palette?.primary}` : '1px solid #d8d8d8', borderRadius: '5px', width: '100%', height: '100px', backgroundColor: item?.background_color, ...sx}} />
        )}
    </div>
  );
};