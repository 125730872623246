import React, { useRef, useState } from 'react';
import { Box, Typography, AppBar, Popover } from '@mui/material';
import logo from '../../assets/images/Rave_logo_2.png';
import { FaUserCircle } from 'react-icons/fa';
import { getToken } from '../../helpers/getToken';
import { useLogout, useSidebarState } from 'react-admin';
import { TbLogout } from 'react-icons/tb';
import { Button } from '../Generics/index';

export const AppbarCustom = (props) => {
  const tokenUser = getToken()?.name;
  const tokenEmail = getToken()?.email;
  const [openPopover, setOpenPopover] = useState(false);
  const buttonRef = useRef(null);
  return (
    <AppBar
      elevation={0}
      {...props}
      sx={{
        height: '55px',
        backgroundColor: 'rgb(250 250 250)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '0.8px solid #cdcdcd',
      }}
    >
      <img
        src={logo}
        alt="Rave Logo"
        style={{
          alignSelf: 'center',
          width: 'auto',
          marginLeft: '20px',
          height: '25px',
          objectFit: 'contain',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 15,
          paddingRight: '20px',
        }}
      >
        <Button
          referencia={buttonRef}
          onClick={() => setOpenPopover(true)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            borderRadius: '40px',
            border: '1px solid #FFFFFF',
            backgroundColor: '#FFFFFF',
            minWidth: '150px',
            padding: '5px 10px',
          }}
          hoverStyle={{ backgroundColor: '#e5e5e5' }}
        >
          <FaUserCircle size={18} />
          <Typography
            fontSize={25}
            sx={{
              color: '#000000',
              fontWeight: '600',
              borderRadius: '12px',
              fontSize: '14px',
              lineHeight: '1.75',
              letterSpacing: '0.01em',
              textTransform: 'none',
              marginLeft: '8px',
            }}
          >
            {tokenUser ? tokenUser : tokenEmail}
          </Typography>
        </Button>
      </div>
      <Popover
        open={openPopover}
        anchorEl={buttonRef.current}
        onClose={() => setOpenPopover(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: { sx: { borderRadius: '10px', marginTop: '10px' } },
        }}
      >
        <LogOutButton />
      </Popover>
    </AppBar>
  );
};
export const LogOutButton = () => {
  const logout = useLogout();
  const handleClick = () => logout();
  const sidebarIsOpen = useSidebarState()[0];
  return (
    <Box
      sx={{
        padding: '8px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        borderRadius: '10px',
        width: '100%',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      }}
      onClick={handleClick}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <TbLogout color="#B12121" size="25px" />
        <Typography
          sx={{
            color: '#000000',
            fontWeight: '600',
            borderRadius: '12px',
            fontSize: '16px',
            lineHeight: '1.75',
            letterSpacing: '0.01em',
          }}
        >
          Log out
        </Typography>
      </Box>
    </Box>
  );
};
export const MyAppBar = (props) => {
  return null;
};
