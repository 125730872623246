import React, { useState } from 'react';
import ListCustom from '../../Components/ListCustom/ListCustom';
import {
  TextField,
  FunctionField,
  useGetList,
} from 'react-admin';
import { AdminActions } from '../TalentForm/ContactInformation';
import { COLORS } from '../../assets/constants/theme';
import RolActions from '../../Components/ListActions/RolActions';
import { ListActions } from '../../Components/ListActions/ListActions';

const AdminsList = () => {
  const [open, setOpen] = useState(false);
  const paramsList = {
    listParams: {
      hasCreate: true,
      actions: <ListActions search={true} csv={true} from={'admins'} placeholder={'Search by name and email...'}/>,
      queryOptions: {
        meta: { field: 'last_modified'},
      }
    },
    datagridParams: {
      bulkActionButtons: false,
      rowClick: 'show',
    },
  };
  const {data, loading} = useGetList('admins/roles',{pagination:{page:1,perPage:9999}});

  return (
    <div >
      <ListCustom {...paramsList}
        datagridParams={{
          sx: {
            padding:'0px 20px',
            zIndex:100,
            '& .MuiTypography-root':{
              color:'#000000',
              fontWeight:400
            },
            '& .column-question': {
              width: '50%',
              textAlign: 'left',
            },
            '& .column-category': {
              width: '50%',
              textAlign: 'right',
            },
            '& .column-function': {
              textAlign: 'right',
            }
          },
          ...paramsList.datagridParams,
        }}
      >
        <TextField source="name" label='Member'/>
        <TextField source="email" />
        <FunctionField source="role" render={(record, index) => <RolActions record={record} options={data}/>}/>
        <FunctionField label="" source='function' render={(record, index) => <AdminActions setOpen={setOpen} record={record}/> } />
      </ListCustom>
    </div>
  );
};

export default AdminsList;
