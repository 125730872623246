import React, { useContext, useEffect, useState } from 'react';
import {
  Menu,
  MenuItemLink,
  useGetList,
  useResourceDefinitions,
  useSidebarState,
} from 'react-admin';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';
import { COLORS } from '../../assets/constants/theme';
import { getToken } from '../../helpers/getToken';
import { userAccessControl } from '../../helpers/permissions';
import { apiUrl, calendarContext } from '../../dataProvider';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { storeInfo, storeShemas } from '../../Store/Reducers/infoReducer';
import { setPalette } from '../../Store/Reducers/paletteReducer';
import {
  SectionParent,
  TalentManagerForm,
  TitleSection,
} from './Components/Buttons';
import logoDefault from '../../assets/images/squareLogoDefault.png';
import { useParams } from 'react-router-dom';
import JoyRideComponent from '../JoyComponent/JoyRide';
import { IconButton } from '../Generics';

export const fetchData = async (_callback) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_PATH}/statistics`,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    });
    _callback(
      response.data.talents_status.pending,
      response.data.events_status.pending,
    );
  } catch (error) {
    console.error('Error fetching data:', error);
    // Manejar el error según tus necesidades
  }
};

const MenuCustom = (props) => {
  const festivalInfo = useSelector((state) => state?.data?.info);
  const palette = useSelector((state) => state?.palette?.palette);
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(
    (name) => resourcesDefinitions[name],
  );
  const sidebarOpen = useSidebarState();
  const token = getToken();
  const paramss = useParams();
  const urlparams = paramss['*']?.split('/')[0];

  const handleChange = () => sidebarOpen[1](window.innerWidth > 1540);
  useEffect(() => {
    handleChange();
    window.addEventListener('resize', handleChange);
    return () => window.removeEventListener('resize', handleChange);
  }, []);
  const dispatch = useDispatch();
  // FEstival start_enddates
  const { setPendingSubmissions } = useContext(calendarContext);

  const params = {
    limit: 9999,
    offset: 0,
    ascending: true,
    filter: {},
  };

  const { refetch } = useGetList('festivals', params, {
    onSuccess: ({ data }) => {
      dispatch(storeInfo(data[0]));
      if (data[0]?.palette !== null) {
        dispatch(setPalette(data[0]?.palette));
      }
    },
    enabled: false,
  });

  const refetchSchemas = async () => {
    try {
      const response = await axios({
        url: `${apiUrl}/schemas`,
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'content-range',
          Authorization: 'Bearer ' + localStorage.getItem('auth'),
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
      });
      if (response.data) {
        response.data?.results?.map((item, index) => {
          dispatch(storeShemas({ value: item, key: item.resource }));
        });
      }
    } catch (error) {
      throw new Error(error);
    }
  };
  useEffect(() => {
    refetch();
    refetchSchemas();
  }, []);

  useEffect(() => {
    fetchData(setPendingSubmissions);
  }, []);

  const handleResourcePermisisons = (e) => {
    let resourceExceptions = ['resources'];
    let resourcePermissions = getToken()?.role !== 'talent_manager';
    if (resourceExceptions.includes(e.name)) {
      return true;
    } else {
      return resourcePermissions;
    }
  };
  const openAndClose = () => {
    if (sidebarOpen[0]) {
      sidebarOpen[1](false);
    } else {
      sidebarOpen[1](true);
    }
  };

  return (
    <>
      <Menu>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor: '#808080',
            paddingY: '15px',
            gap: sidebarOpen[0] ? 0 : 0,
            height: '100%',
          }}
        >
          {sidebarOpen[0] ? (
            <div
              style={{
                width: '100%',
                paddingLeft: '25px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <img
                src={
                  festivalInfo?.logo?.square?.original
                    ? festivalInfo?.logo?.square?.original
                    : logoDefault
                }
                alt="logo"
                style={{ width: '25%' }}
              />
              <div style={{ width: '75%', height: '100%' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                  {festivalInfo?.name ? festivalInfo?.name : 'RaveUp'}
                </Typography>
              </div>
            </div>
          ) : null}
          <IconButton
            size="small"
            onClick={() => openAndClose()}
            icon={
              sidebarOpen[0] ? (
                <MdKeyboardArrowLeft size={30} />
              ) : (
                <MdKeyboardArrowRight size={24} />
              )
            }
          />
        </Box>
        <Box
          id="sidebarBox"
          key="menu-custom-box"
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            '& .MuiMenuItem-root .MuiListItemIcon-root': {
              minWidth: '30px',
            },
            '& .RaMenuItemLink-root .RaMenuItemLink-icon': {
              minWidth: '30px',
            },
            '& .MuiListItemIcon-root': {
              minWidth: '30px',
            },
          }}
        >
          <Box
            key="menu-custom-box-2"
            sx={{
              height: '100%',
              alignItems: 'space-between',
            }}
          >
            {userAccessControl(getToken()?.role, 'read', ['talentsForm']) && (
              <TalentManagerForm talent_id={token?.talent_id} />
            )}
            {resources.map((resource, index) => {
              if (
                handleResourcePermisisons(resource) &&
                resource.options?.isTitle
              ) {
                return (
                  <TitleSection
                    key={`title-${resource.name}-${index}`}
                    resource={resource}
                    resources={resources}
                  />
                );
              } else if (
                handleResourcePermisisons(resource) &&
                resource.options?.isMenuParent
              ) {
                return (
                  <SectionParent
                    key={`parent-${resource.name}-${index}`}
                    resource={resource}
                    resources={resources}
                  />
                );
              } else {
                if (
                  handleResourcePermisisons(resource) &&
                  resource.hasList &&
                  !resource.options?.menuParent
                ) {
                  const firstIndex = index === 0 ? true : false;
                  return (
                    <>
                      {!firstIndex ? (
                        <MenuItemLink
                          id={resource.name}
                          key={`link-${resource.name}-${index}`}
                          to={
                            resource.options.customPath
                              ? `/${resource.options.customPath}`
                              : `/${resource.name}`
                          }
                          primaryText={
                            (resource.options && resource.options.label) ||
                            resource.name
                          }
                          leftIcon={
                            resource.icon ? (
                              <resource.icon
                                color={
                                  resource.name === urlparams
                                    ? '#ffffff'
                                    : '#000000'
                                }
                              />
                            ) : null
                          }
                          sidebarIsOpen={sidebarOpen[0]}
                          sx={{
                            color: '#000000',
                            padding: '6px 16px',
                            margin: sidebarOpen[0] ? '0px' : '3px 4px',
                            fontWeight: '600',
                            lineHeight: '1.75',
                            letterSpacing: '0.01em',
                            '&.MuiListItemIcon-root': {
                              minWidth: '20px',
                            },
                            '&.RaMenuItemLink-active': {
                              backgroundColor: palette?.primary,
                              color: '#ffffff',
                              '& .MuiListItemIcon-root': {
                                color: COLORS.light.primary.main,
                              },
                            },
                          }}
                        />
                      ) : null}
                      {/* PARA EL DASHBOARD */}
                      {firstIndex && (
                        <Box
                          sx={{
                            height: sidebarOpen[0] ? '80px' : 'auto',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid',
                            borderColor: '#808080',
                          }}
                        >
                          <MenuItemLink
                            key={`dashboard-${resource.name}-${index}`}
                            to={
                              resource.options.customPath
                                ? `/${resource.options.customPath}`
                                : `/${resource.name}`
                            }
                            primaryText={
                              (resource.options && resource.options.label) ||
                              resource.name
                            }
                            leftIcon={
                              resource.icon ? (
                                <resource.icon
                                  color={
                                    resource.name === urlparams
                                      ? '#ffffff'
                                      : '#000000'
                                  }
                                />
                              ) : null
                            }
                            sidebarIsOpen={sidebarOpen[0]}
                            sx={{
                              height: '40px',
                              padding: sidebarOpen[0] ? '6px 29px' : '0px 20px',
                              margin: sidebarOpen[0] ? '0 0px' : '0px',
                              color: '#000000',
                              fontWeight: '600',
                              width: '100%',
                              '&.RaMenuItemLink-active': {
                                backgroundColor: palette?.primary,
                                color: '#ffffff',
                                '& .MuiListItemIcon-root': {
                                  color: COLORS.light.primary.main,
                                },
                              },
                            }}
                          />
                        </Box>
                      )}
                    </>
                  );
                }
              }
            })}
          </Box>
          {sidebarOpen[0] && token?.role !== 'talent_manager' ? (
            <JoyRideComponent palette={palette} sidebarOpen={sidebarOpen} />
          ) : null}
        </Box>
      </Menu>
    </>
  );
};

export default MenuCustom;
