import { Edit } from 'react-admin';
import { FormInfo } from './FormInfo';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';
import { useMemo, useState } from 'react';
import { FormSchema } from './FormSchema';
import { FormPalette } from './FormPalette';


export const EditInfo = () => {
  const [tab, setTab] = useState('info');
  const tabs = [
    {
      title: 'General Settings',
      redirectUrl: 'info',
    },
    {
      title: 'Palette & Typography',
      redirectUrl: 'palette',
    },
    {
      title: 'Schemas',
      redirectUrl: 'schema',
    },
  ];

  const Forms = useMemo(() => {

    switch(true) {
    case tab === 'info':
      return <FormInfo />;
    case tab === 'schema':
      return <FormSchema />;
    case tab === 'palette':
      return <FormPalette />;
    }

  },[tab]);

  return (
    <EditFormCustom tabs={tabs} handleForm={setTab} defaultTab={'info'} redirect='list' mutationMode={'pessimistic'}>
      {
        Forms
      }
    </EditFormCustom>
  );
};