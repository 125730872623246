import { Box,  Modal } from '@mui/material';
import { useState } from 'react';
import { ShowProvider } from '../../../Components/ShowComponents/ShowProvider';
import { useRecordContext } from 'react-admin';
import { EventsCard } from '../../Talents/Form/TalentsShowComponents/EventosCard';
import {Button} from '../../../Components/Generics/index';

export const ShowEventsSubmissionModal = (props) => {
  const { body } = props;
  const [open, setOpen] = useState(false);
  const record = useRecordContext();
  
  if(record?.submission?.status === 'approved') {
    return (
      <>
        <Button
          onClick={() => setOpen(true)}
          style={{
            alignSelf: 'flex-end',
            justifySelf: 'flex-end'
          }}
        >
          View Last Submission
        </Button>
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              width: '70vw',
              padding: '20px',
              height: 'auto',
              // maxHeight: '90vh',
              overflowX: 'hidden',
              overflowY: 'scroll',
              borderRadius: '12px',
              backgroundColor: 'white',
              '&::-webkit-scrollbar': {
                width: '12px',
                backgroundColor: 'white',
              },
            }}
          >
            <ShowProvider body={record}>
              <EventsCard sx={{marginBottom: '0px'}} showButtons={false} />
            </ShowProvider>
          </Box>
        </Modal>
      </>
    );
  } else {
    return(
      <div></div>
    );
  }
};