import { Box, Typography,Switch } from '@mui/material';
import { TooltipIcon } from './TooltipIcon';
const asteriskStyle = {
  color: 'red', 
  marginLeft: '4px', 
};
export const InputTitle = ({ tooltip, textTransform = 'uppercase', sx, value, children,required}) => (
  <Box sx={{display: 'flex', flexDirection : 'row', alignItems: 'center'}}>
    <Typography
      sx={{
        fontSize:'16px',
        fontWeight: '600',
        textTransform: textTransform,
        color: '#000',
        ...sx
      }}
    >{value ?? children}
      {required &&<span style={asteriskStyle}>*</span>}
    </Typography> 
    {tooltip && <TooltipIcon message={tooltip} />}
  </Box>
);
