import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { Datagrid, List, useListContext, usePermissions, useResourceContext,useStore } from 'react-admin';
import { COLORS } from '../../assets/constants/theme';
import { ListTabs } from '../../Resources/Talents/Form/TalentsListTabs/ListTabs';
import { EmptyComponent } from '../EmptyComponent.jsx/EmptyComponent';

const ListCustom = (props) => {
  const {
    sx, 
    children,
    listParams,
    datagridParams,
    defaultTab, 
    tabs, 
    setFilter, 
    filter,
    pagination
  } = props;
  const resource = useResourceContext();
  const {permissions} = usePermissions();
  return (
    <List
      filter={filter}
      {...listParams}
      perPage={25}
      component={ListComponent}
      empty={false}
      pagination={pagination}
    >
      {
        permissions !== 'talent_manager' && 
      tabs && 
      <ListTabs defaultTab={defaultTab} setFilter={setFilter} tabs={tabs} />
      }
      <DatagridCustom filter={filter} sx={sx} datagridParams={datagridParams} >
        {children}
      </DatagridCustom>
    </List>
  );
};

const ListComponent = ({ children }) => {
  return (
    <Box>
      {children}
    </Box>
  );
};

export const DatagridCustom = ({filter, sx, datagridParams, children }) => {
  const [selectedTalent] = useStore('selectedTalent', {selected:false, talent:''});
  const { data, setFilters } = useListContext();
  const resource = useResourceContext();
  const postRowSx = (record, index) => ({
    backgroundColor: record.id === selectedTalent?.talent ? 'rgba(237, 237, 237, 0.87)' : null,
  });
  useEffect(() => {
    setFilters({...filter});
  },[]);

  const EmptyDataGridHeader = () => {
    return (
      <>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {children?.map((item, index) => (
                  <TableCell sx={{textTransform: 'uppercase'}}>{item.props.source}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>No {resource} found, you can start by creating the first one.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  };
  return (
    <Datagrid
      rowStyle={postRowSx}
      size="medium"
      {...datagridParams}
      sx={{
        '& .MuiTable-root': {
          border: '1px solid #E8E8E8',
          borderRadius:'10px 10px 0px 0px',
        },
        '& .MuiTableCell-body': {
          borderBottom: '1px solid #E8E8E8',
        },
        '& .MuiTableCell-head': {
          backgroundColor: '#F5F5F5',
        },
        '& .MuiTableSortLabel-root': {
          fontWeight: 600,
          fontSize:'14px',
          lineHeight:'19px',
          color:'#7C7C7C',
          textTransform:'uppercase',
        },
        ...datagridParams.sx
      }}
      row
      empty={<EmptyDataGridHeader />}
    >
      {children}
    </Datagrid>
  );
};

export default ListCustom;