import { InputGalleryAws } from '../../../../Components/RedesignComponents/Inputs';
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';

export const CarouselForm = () => {
  const data = [
    {
      inputs: [
        {
          field: <InputGalleryAws urlInput={true} picture limit={5} source={'home.carousel.items'} draggable={true}/>,
          breakpoints: { xs: 12}
        }
      ],
    },
  ];

  return {
    data: data
  };
};
