import React, { useEffect, useState } from 'react';
import { ReferenceArrayInput, useGetList } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Hooks & Utils
import { DEEP_LINKING_OPTIONS } from '../../../assets/constants/types';
import { strCapitalize } from '../../../helpers/strCapitilize';
// Components
import { InputTitle } from '../../StyledComponents/InputTitle';
import InputAutocomplete from '../../InputsForm/InputAutocomplete';
// Material & Icons
import { ButtonGroup, Select, MenuItem, Skeleton } from '@mui/material';
import { Button } from '../../Generics/index';

const btStyle = {
  textTransform: 'uppercase',
  fontWeight: 500,
  fontSize: '0.875rem',
};
export const InputTabSingleSelector = ({
  setState,
  state,
  approvedFields,
  textTransform,
  title,
  watchField,
  optionText = 'text',
  optionValue = 'text',
  source,
  setLinking,
  setValueUrl,
  resource,
  options,
  ...rest
}) => {
  const [choices, setChoices] = useState();
  const { data } = useGetList(
    `${resource}`,
    {
      pagination: {
        perPage: 100,
        page: 1,
      },
      filter: {},
      limit: 100,
    },
    {
      onSuccess: ({ data }) => {
        if (options) {
          setChoices(options);
        } else {
          setChoices(data);
        }
      },
    },
  );
  const palette = useSelector((state) => state?.palette?.palette);
  const { watch, setValue, getValues } = useFormContext();
  const valores = getValues();
  const pickUrl = getValues()?.url;
  const destructUrl = (url) => {
    const defaultOption = DEEP_LINKING_OPTIONS[0].text;
    let option = defaultOption;
    let id = '-';

    if (url && url?.startsWith('Raveapp://')) {
      const parts = url?.split('/');
      if (parts?.length === 4) {
        option = parts[2];
        id = parts[3];
      } else {
        option = parts[2];
      }
    }
    setLinking(option === 'faqs' ? 'FAQs' : strCapitalize(option));
    setValueUrl(id);
  };
  const [buttonPicked, setButtonPick] = useState(undefined);
  const paramss = useParams();
  const urlparams = paramss['*']?.split('/')[0];
  const fromTalentId = urlparams?.split('=')[1];
  const handlerSetValue = (value) => {
    setButtonPick(value);
    if (setState) {
      setState(value);
      setValue(source, value === 'Internal' ? true : false);
    } else {
      setValue(source, value);
    }
  };
  useEffect(() => {
    if (setState) {
      destructUrl(pickUrl);
    }
  }, []);
  useEffect(() => {
    if (source === 'role') {
      setButtonPick(valores?.role);
    } else {
      setButtonPick(valores[source]);
    }
  }, []);
  useEffect(() => {
    if (fromTalentId) {
      setValue('role', 'talent_manager');
      setButtonPick('talent_manager');
    }
  }, []);
  return (
    <>
      {title && (
        <InputTitle
          textTransform={textTransform}
          sx={{
            display: !watchField
              ? 'block'
              : watch(watchField) === 'true' ||
                  approvedFields?.includes(watch(watchField))
                ? 'block'
                : 'none',
          }}
        >
          {title}
        </InputTitle>
      )}

      <ButtonGroup
        variant="outlined"
        sx={{
          marginTop: '20px',
          '& .MuiButtonGroup-grouped': {
            border: '1px solid grey',
          },
          border: '1px solid grey',
        }}
        aria-label="outlined primary button group"
      >
        {choices?.map((choice, index) =>
          source !== 'internal_deeplink' ? (
            <Button
              style={{
                color: buttonPicked === choice.text ? 'white' : 'black',
                backgroundColor:
                  buttonPicked === choice.text ? palette?.primary : '#fff',
                border: '1px solid transparent',
                borderRadius:
                  index === 0
                    ? '4px 0px 0px 4px'
                    : choices?.length - 1 === index
                      ? '0px 4px 4px 0px'
                      : '0px 0px 0px 0px',
                borderRight: choices?.length - 1 !== index && '1px solid black',
                ...btStyle,
              }}
              hoverStyle={{ backgroundColor: palette?.primary, color: 'white' }}
              key={choice.id}
              onClick={() => handlerSetValue(choice.text)}
              disabled={valores[source] === choice.id}
            >
              {choice[optionText]}
            </Button>
          ) : (
            <Button
              style={{
                color: state === choice.text ? 'white' : 'black',
                backgroundColor:
                  state === choice.text ? palette?.primary : '#fff',
                border: '1px solid transparent',
                borderRadius: '0px 0px 0px 0px',
                ...btStyle,
              }}
              hoverStyle={{
                backgroundColor: palette?.primary,
                color: 'white',
                border: '1px solid black',
              }}
              key={choice.id}
              onClick={() => handlerSetValue(choice.text)}
              {...rest}
            >
              {choice[optionText]}
            </Button>
          ),
        )}
      </ButtonGroup>
    </>
  );
};

export const InputDropdownSelector = ({
  watchField = 'category',
  resource,
  label,
  title,
  width,
  withChoices,
  source,
}) => {
  const { watch, setValue, getValues } = useFormContext();
  const [size, setSize] = useState();
  const [loading, setLoading] = useState(true);
  const valores = getValues();
  const handlerSetValue = (value) => {
    let valor = value.target.value;
    setSize(valor);
    setValue(source, valor);
  };

  useEffect(() => {
    const fetchData = async () => {
      const valores = getValues(source);
      setSize(valores);
      setLoading(false);
    };

    fetchData();
  }, [getValues]);
  if (withChoices) {
    if (loading) {
      return (
        <Skeleton
          variant="rectangular"
          width={width}
          height={40}
          sx={{ borderRadius: '10px' }}
        />
      );
    }

    return (
      <div style={{ width: '100%' }}>
        {title && (
          <InputTitle
            value={title}
            sx={{ marginBottom: width ? '7px' : '20px' }}
          />
        )}
        <Select
          size="small"
          value={size || ''}
          onChange={handlerSetValue}
          sx={{ width: '100%', borderRadius: '10px' }}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={0.5}>0.5</MenuItem>
        </Select>
      </div>
    );
  } else
    return (
      <ReferenceArrayInput
        source="tags"
        reference={`${resource}/tags`}
        filter={{ category: encodeURIComponent(watch(watchField)) }}
      >
        <InputAutocomplete
          width={width}
          title={title}
          disabled={
            watchField && (watch(watchField) === '' || !watch(watchField))
          }
          isArray
          label={label}
          optionText={'text'}
          optionValue={'text'}
          sx={{ width: '150px' }}
          limitTags={2}
        />
      </ReferenceArrayInput>
    );
};
