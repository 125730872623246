import React, { useState } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { MdOutlineNewReleases, MdArrowForward } from 'react-icons/md';
import { stepsJoy } from './steps';
import { dynamicColor } from '../../helpers/dynamicColor';
import styles from './JoyRide.module.css';
import { IconButton } from '../Generics';

const JoyRideComponent = (props) => {
  const [state, setState] = useState({
    run: false,
    steps: stepsJoy,
  });
  const { palette, sidebarOpen } = props;
  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setState((prevState) => ({
        ...prevState,
        run: false,
      }));
    }
  };
  const handleClick = () => {
    const sidebarScroll = document.getElementsByClassName('RaSidebar-fixed')[0];
    if (sidebarScroll) {
      sidebarScroll.scrollTop = 0;
    }
    setState((prevState) => ({
      ...prevState,
      run: true,
    }));
  };

  return (
    <>
      <div
        className={styles.container}
        style={{
          padding: sidebarOpen?.[0] ? '6px 0px 6px 16px' : '0 0 0 10px',
          backgroundColor: dynamicColor(palette?.primary, 0.2)?.rgba,
          margin: sidebarOpen?.[0] ? '10px 12px 20px 12px' : '3px',
          color: palette?.primary,
        }}
      >
        <div
          className={styles.subContainer}
        >
          <div
            className={styles.titleContainer}
          >
            <div
              className={styles.iconContainer}
            >
              <MdOutlineNewReleases size={18} />
            </div>
            <p className={styles.title}>
              New Here?
            </p>
          </div>
          <div className={styles.textContainer}>
            <p className={styles.text}>
              Learn how to use the plaform by clicking here and doing the tour.
            </p>
            <IconButton
              style={{
                alignSelf: 'flex-end',
                color: palette?.primary,
              }}
              onClick={handleClick} 
              data-testid='joyrideAction'
              icon={<MdArrowForward size={18} />}
            />
          </div>
        </div>
      </div>
      <Joyride
        continuous
        styles={{
          options: {
            primaryColor: palette?.primary,
          },
        }}
        callback={handleJoyrideCallback}
        run={state?.run}
        steps={state?.steps}
        scrollToFirstStep
        showSkipButton
        showProgress
        disableScrolling
        spotlightPadding={0}
      />
    </>
  );
};

export default JoyRideComponent;
