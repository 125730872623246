import { Box, IconButton } from '@mui/material';
import { memo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { hexToRgb } from '../../../Components/InputsForm/InputColorPicker';
import EditModal from './EditModal';
import EditIcon from '@mui/icons-material/Edit';

const EditModalButton = (props) => {
  const { sx } = props;
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const palette = useSelector((state) =>
    hexToRgb(state.palette.palette.primary),
  );

  const handleOnClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  return (
    <Box
      id="edit_modal_component"
      sx={{
        position: 'absolute',
        top: 35,
        right: 25,
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        ...sx,
      }}
    >
      <IconButton
        ref={ref}
        onClick={handleOnClick}
        sx={{
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          outline: '2px solid black',
          cursor: 'pointer',
          zIndex: 5,
          opacity: 1,
          overflow: 'visible',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#fff',
          '&:hover': {
            backgroundColor: '#fff',
            boxShadow: `rgba(${palette.r}, ${palette.g}, ${palette.b}, 0.3) 0px 0px 30px 6px`,
          },
        }}
      >
        <EditIcon sx={{ width: '15px', color: 'black' }} />
      </IconButton>

      {open && (
        <EditModal parentRef={ref} open={open} setOpen={setOpen} {...props} />
      )}
    </Box>
  );
};

export default memo(EditModalButton);
