import CreateActivities from './Form/CreateActivities';
import EditActivities from './Form/EditActivities';
import ActivitiesList from './ActivitiesList';
import { MdHiking } from 'react-icons/md';

export default {
  list: ActivitiesList,
  create: CreateActivities,
  edit: EditActivities,
  icon: MdHiking
};