import { Box, Popover } from '@mui/material';
import React, { useMemo, Fragment, useRef, useState, useEffect } from 'react';
import { NewFilter } from './FilterComponents';
import { useListContext, useResourceContext } from 'react-admin';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button } from '../Generics';
import styles from './FilterList.module.css';
const statusValues = ['Approved', 'Pending', 'Rejected', 'Not Submitted'];
const statusNotifications = ['Sent', 'Canceled', 'Scheduled'];
const completeValues = ['Complete', 'Incomplete'];
const visiblityValues = ['Visible', 'Hidden'];
const scheduleValues = ['Scheduled', 'Not Scheduled'];

const FilterList = ({
  from,
  permission,
  submissionList,
  showFilters,
}) => {
  const { filterValues, setFilters } = useListContext();
  const [showFilter, setShowFilter] = useState([]);
  const handleFilterStatus = (e, v) => {
    if (from === 'notifications') {
      setFilters({
        ...filterValues,
        status: v?.map((item) => item.toLowerCase()),
      });
    } else {
      setFilters({
        ...filterValues,
        status: v?.map((item) =>
          item === 'Not Submitted'
            ? encodeURIComponent('not_submitted')
            : encodeURIComponent(item.toLowerCase()),
        ),
      });
    }
  };
  const handlerDaysFilter = (e, v) => {
    if (v.length >= 1) {
      const selectedDates = v.map((date) =>
        dayjs(date).startOf('day').format('YYYY-MM-DD'),
      );
      setFilters({
        ...filterValues,
        dates: selectedDates,
      });
    } else {
      setFilters({
        ...filterValues,
        dates: null,
      });
    }
  };
  const handlerCompletedFilter = (e, v) => {
    const target = v[0];
    if (target === 'Complete') {
      return setFilters({ ...filterValues, completed: true });
    } else if (target === 'Incomplete') {
      return setFilters({ ...filterValues, completed: false });
    } else {
      return setFilters({ ...filterValues, completed: null });
    }
  };
  const handlerVisibilityFilter = (e, v) => {
    const target = v[0];
    if (target === 'Visible') {
      return setFilters({ ...filterValues, draft: false });
    } else if (target === 'Hidden') {
      return setFilters({ ...filterValues, draft: true });
    } else {
      return setFilters({ ...filterValues, draft: null });
    }
  };
  const handlerScheduledFilter = (e, v) => {
    const target = v[0];
    if (target === 'Scheduled') {
      return setFilters({ ...filterValues, scheduled: true });
    } else if (target === 'Not Scheduled') {
      return setFilters({ ...filterValues, scheduled: false });
    } else {
      return setFilters({ ...filterValues, scheduled: null });
    }
  };
  const handleChangeGeo = (e, v) => {
    const target = v[0];
    if (target === 'Yes') {
      return setFilters({ ...filterValues, geo_tagged: true });
    } else if (target === 'No') {
      return setFilters({ ...filterValues, geo_tagged: false });
    } else {
      return setFilters({ ...filterValues, geo_tagged: null });
    }
  };
  const handleRoleFilter = (e, v) => {
    const target = v[0];
    setFilters({ ...filterValues, role: target?.text});
  };
  const schema = useSelector((state) => state?.data?.schemas);
  const daysArr = useSelector((state) => state?.data?.info?.days);
  const schemaF = () => {
    if (schema && from in schema) {
      const base = schema[from]?.base || [];
      const additional = schema[from]?.additional || [];
      return [...base, ...additional];
    } else {
      return [];
    }
  };
  const finalSchema = schemaF();
  const filters = [
    {
      title: 'tags',
      element: (
        <NewFilter
          label={'Tags'}
          from={from}
          fetchUrl={`${from}/tags`}
          setShowFilter={setShowFilter}
          showFilter={showFilter}
        />
      ),
    },
  ];
  const schemaResults = useMemo(() => {
    if (!finalSchema || finalSchema?.length === 0) return [];
    return filters.filter((item) => finalSchema.includes(item.title));
  }, [schema, filters]);
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 2,
        justifyContent: 'flex-start',
        height: showFilters ? 'auto' : '0px',
        padding:showFilters ? '5px 20px' : '0px',
        flexWrap: 'wrap',
        visibility: showFilters ? 'visible' : 'hidden',
      }}
    >
      {showFilter?.map((nameFilter) => {
        switch (nameFilter) {
        case 'Category':
          return (
            <div key={nameFilter}>
              {from !== 'notifications' && from !== 'admins' && (
                <NewFilter
                  label={'Category'}
                  fetchUrl={`${from}/categories`}
                  from={from}
                  setShowFilter={setShowFilter}
                  showFilter={showFilter}
                  deleteSlug={'category'}
                />
              )}
            </div>
          );
        case 'Completed':
          return (
            <div key={nameFilter}>
              {!submissionList && permission !== 'talent_manager' && (
                <>
                  {from === 'talents' || from === 'events' ? (
                    <NewFilter
                      options={completeValues}
                      noObj={true}
                      label={'Completed'}
                      _callback={handlerCompletedFilter}
                      setShowFilter={setShowFilter}
                      showFilter={showFilter}
                      singleSelect={true}
                      deleteSlug={'completed'}
                    />
                  ) : null}
                </>
              )}
            </div>
          );
        case 'Last Submission Status':
          return (
            <div key={nameFilter}>
              {!submissionList && permission !== 'talent_manager' && (
                <>
                  {from === 'talents' || from === 'events' ? (
                    <NewFilter
                      options={statusValues}
                      noObj={true}
                      label={'Last submission status'}
                      _callback={handleFilterStatus}
                      setShowFilter={setShowFilter}
                      showFilter={showFilter}
                      deleteSlug={'status'}
                    />
                  ) : null}
                </>
              )}
            </div>
          );
        case 'Visibility':
          return (
            <div key={nameFilter}>
              {permission !== 'talent_manager' &&
                  from === 'talents' &&
                  !submissionList && (
                <NewFilter
                  options={visiblityValues}
                  noObj={true}
                  label={'Visibility'}
                  _callback={handlerVisibilityFilter}
                  setShowFilter={setShowFilter}
                  showFilter={showFilter}
                  singleSelect={true}
                  deleteSlug={'draft'}
                />
              )}
            </div>
          );
        case 'Scheduled':
          return (
            <div key={nameFilter}>
              {permission !== 'talent_manager' &&
                  from === 'events' &&
                  !submissionList && (
                <NewFilter
                  options={scheduleValues}
                  noObj={true}
                  label={'Scheduled'}
                  _callback={handlerScheduledFilter}
                  setShowFilter={setShowFilter}
                  showFilter={showFilter}
                  singleSelect={true}
                  deleteSlug={'scheduled'}
                />
              )}
            </div>
          );
        case 'Days':
          return (
            <div key={nameFilter}>
              {permission !== 'talent_manager' &&
                  from === 'events' &&
                  !submissionList && (
                <NewFilter
                  _callback={handlerDaysFilter}
                  options={daysArr}
                  noObj={true}
                  label={'Days'}
                  from={from}
                  setShowFilter={setShowFilter}
                  showFilter={showFilter}
                  deleteSlug={'dates'}
                />
              )}
            </div>
          );
        case 'GeoTagged':
          return (
            <>
              {from === 'locations' && (
                <NewFilter
                  _callback={handleChangeGeo}
                  options={['Yes', 'No']}
                  noObj={true}
                  label={'GeoTagged'}
                  from={from}
                  setShowFilter={setShowFilter}
                  showFilter={showFilter}
                  singleSelect={true}
                  deleteSlug={'geo_tagged'}
                />
              )}
            </>
          );
        case 'Role':
          return (
            <>
              {from === 'admins' && (
                <NewFilter
                  _callback={handleRoleFilter}
                  label={'Role'}
                  from={from}
                  fetchUrl={'admins/roles'}
                  setShowFilter={setShowFilter}
                  showFilter={showFilter}
                  deleteSlug={'role'}
                  singleSelect={true}
                />
              )}
            </>
          );
        case 'Status':
          return (
            <div key={nameFilter}>
              {from === 'notifications' && (
                <NewFilter
                  options={statusNotifications}
                  noObj={true}
                  label={'Status'}
                  _callback={handleFilterStatus}
                  setShowFilter={setShowFilter}
                  showFilter={showFilter}
                  deleteSlug={'status'}
                />
              )}
            </div>
          );
        default:
          return null;
        }
      })}
      {schemaResults.map((item, index) => {
        if (showFilter?.includes(item?.title)) {
          return <Fragment key={index}>{item.element}</Fragment>;
        } else {
          return;
        }
      })}
      <AddFilters
        setShowFilter={setShowFilter}
        showFilter={showFilter}
        schemaResults={schemaResults}
      />
    </Box>
  );
};
export const AddFilters = ({ setShowFilter, showFilter, schemaResults }) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const [open, setOpen] = useState(false);
  const refBt = useRef(null);
  const resource = useResourceContext();

  // Mapeo de resultados de esquema a filtros
  const filterDes = useMemo(() => {
    return schemaResults?.map((item) => ({
      name: item?.title,
      resource: [resource],
    })) || [];
  }, [schemaResults, resource]);

  // Base de filtros incluyendo los filtros mapeados
  const filterBase = useMemo(() => [
    {
      name: 'Category',
      resource: [
        'talents',
        'events',
        'locations',
        'faqs',
        'experiences',
        'articles',
      ],
    },
    { name: 'Last Submission Status', resource: ['talents', 'events'] },
    { name: 'Completed', resource: ['talents', 'events'] },
    { name: 'Visibility', resource: ['talents'] },
    { name: 'Scheduled', resource: ['events'] },
    { name: 'Days', resource: ['events'] },
    { name: 'Role', resource: ['admins'] },
    { name: 'GeoTagged', resource: ['locations'] },
    { name: 'Status', resource: ['notifications'] },
    { name: 'Submission Status', resource: ['submissions'] },
    ...filterDes,
  ], [filterDes]);

  // Filtros aplicables para el recurso actual
  const applicableFilters = useMemo(() => {
    return filterBase.filter((filter) => filter.resource.includes(resource));
  }, [filterBase, resource]);

  const handlerShowFilter = (name) => {
    if (showFilter?.includes(name)) {
      let updatedFilters = showFilter?.filter((filter) => filter !== name);
      setShowFilter(updatedFilters);
      return;
    }
    let updatedFilters = [...showFilter, name];
    setShowFilter(updatedFilters);
  };
  // Seteo el primer filtro aplicable en el estado 
  useEffect(() => {
    if (applicableFilters.length > 0) {
      setShowFilter([applicableFilters[0].name]);
    }
  }, []);
  console.log('render filters')
  return (
    <>
      <Button
        referencia={refBt}
        style={{ backgroundColor: palette?.primary, color: '#ffff' }}
        onClick={() => setOpen(!open)}
      >
        +
      </Button>
      <Popover
        anchorEl={refBt.current}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div
          style={{
            margin: 0,
            padding: '10px 0px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {applicableFilters.map((filter, index) => (
            <div
              className={`${styles.liAddFilter} ${
                showFilter.includes(filter.name) ? styles.active : ''
              }`}
              key={index}
            >
              <span
                className={styles.addFilterSpan}
                onClick={() => handlerShowFilter(filter.name)}
              >
                {filter.name}
              </span>
            </div>
          ))}
          {/* {filterBase?.map((filter, index) => {
            if (filter?.resource?.includes(resource) === false) return null;
            return (
              <div
                className={`${styles.liAddFilter} ${showFilter?.includes(filter.name) ? styles.active : ''}`}
                key={index}
              >
                <span
                  className={styles.addFilterSpan}
                  onClick={() => handlerShowFilter(filter.name)}
                >
                  {filter.name}
                </span>
              </div>
            );
          })} */}
        </div>
      </Popover>
    </>
  );
};
export default FilterList;
