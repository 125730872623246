import React, { useState } from 'react';
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { useLogin, useRedirect } from 'react-admin';
import {useMediaQuery} from '@mui/material';
import { imagesBackground } from '../../assets/constants/imagesBackground';
import useEmailAuth from '../../Hooks/useEmailAuth';
import RaveLogo from '../../assets/images/login/raveLogin.png';
import Rave1 from '../../assets/images/login/rave1.png';
import Rave2 from '../../assets/images/login/rave2.png';
import Rave3 from '../../assets/images/login/rave3.png';
import Rave4 from '../../assets/images/login/rave4.png';
import Rave5 from '../../assets/images/login/rave5.png';
import Rave6 from '../../assets/images/login/rave6.png';
import { Button } from '../../Components/Generics';
const invalidMessage = {
  data: {
    msg: 'This link was already used before, please try another one.',
    status: 'error',
  },
  res: { ok: true },
};

const statusMessage = {
  201: {
    data: {
      msg: 'A verification email has been sent to your email address.',
      status: 'success',
    },
    res: { ok: true },
  },
  404: {
    data: { msg: 'Email address not found.', status: 'error' },
    res: { ok: true },
  },
};

const LoginPageCustom = (props) => {
  const monitor = useMediaQuery('(min-width:1600px)');
  const notebook = useMediaQuery('(max-width:1500px)');
  const [toggleButton, setToggleButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(null);
  const emailAuth = useEmailAuth();
  const redirect = useRedirect();
  const isAuthLink = window.location.href.includes('token_url');
  
  const login = useLogin();
  const authWithToken = () => {
    const hash = window.location.hash;
    const tokenUrl = hash?.split('?')[1]?.split('=')[1];

    if (tokenUrl == null) {
      return;
    }
    login(tokenUrl)
      .then((res) => {
        
        // Redirect en caso de sectionParent como primer resource.
        setLoading(false);
        if (['superadmin', 'admin', 'staff'].includes(res.role)) {
          return redirect('/talents');
        } else {
          return redirect('/dashboardtm');
        }
      })
      .catch((e) => {

        setShow(invalidMessage);
        setLoading(false);
      });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    setToggleButton(true);
    const data = new FormData(event.currentTarget);
    const r = await emailAuth(data.get('email'));

    setShow(statusMessage[r.res?.status]);
    setToggleButton(false);
  };

  React.useEffect(() => {
    if (isAuthLink) {
      setLoading(true);
      authWithToken();
    }
    // else {
    //   localStorage.getItem('auth') && redirect('/socios');
    // }
  }, [isAuthLink]);

  const randomImg =
    imagesBackground[Math.floor(Math.random() * imagesBackground.length)];
 
  return (
    <Box>
      <Grid
        container
        component="main"
        sx={{ height: '100vh' }}
        display={loading && 'none'}
      >
        <Grid item xs={12} sm={8} md={5} square sx={{ width: '50%' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            {/* {!noShow &&
              <Box sx={{ position: 'absolute', width: '100vw', height: '100vh', backgroundColor: '#fff' }}>
              </Box>
            } */}
            {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}> */}
            {/* <LockOutlinedIcon /> */}
            {/* </Avatar> */}
            {/* <Typography component="h1" variant="h2">
              {festivalTitle
                ? festivalTitle + " Backoffice"
                : "Raveapp Backoffice"}
            </Typography> */}
            <Box
              component="form"
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
                width: '300px',
                gap: '25px',
              }}
            >
              <img
                src={RaveLogo}
                style={{
                  width: '220px',
                  height: '60px',
                }}
              />
              <p
                style={{
                  margin: '0px',
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '20px',
                }}
              >
                Please enter your email:
              </p>
              <TextField
                disabled={toggleButton}
                margin="normal"
                variant="outlined"
                required
                fullWidth
                id="email"
                label=""
                name="email"
                autoComplete="email"
                autoFocus
                inputProps={{
                  style: {
                    height: '30px',
                  },
                }}
                sx={{ margin: 0 }}
              />
              {show ? (
                <Alert
                  variant="filled"
                  severity={show.res.ok ? show.data.status : 'error'}
                >
                  {show.res.ok ? show.data.msg : 'Email address not found'}
                </Alert>
              ) : null}
              <Button
                disabled={toggleButton}
                type="submit"
                style={{
                  height: '55px',
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  borderRadius: '10px',
                  backgroundColor: '#8502C2',
                  color: '#ffffff',
                  width:'100%'
                }}
              >
                {toggleButton ? (
                  <CircularProgress size={24} />
                ) : (
                  <Typography
                    style={{
                      fontSize: '19px',
                      fontWeight: '600',
                      textAlign: 'center',
                      width: '100%',
                      textTransform: 'none',
                    }}
                  >
                    Sign In
                  </Typography>
                )}
              </Button>
              <div
                style={{
                  dispay: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <Typography
                  sx={{
                    color: 'black',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '19px',
                  }}
                >
                  Forgot password? contact
                </Typography>
                <Typography
                  sx={{
                    color: 'black',
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '19px',
                  }}
                >
                  help@raveup.com
                </Typography>
              </div>
              <Grid container justifyContent="flex-end"></Grid>
            </Box>
          </Box>
        </Grid>
        {Rave1 && Rave2 && Rave3 && Rave4 && Rave5 && Rave6 && <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            width: '50%',
            maxHeight: '100vh',
            alignContent: 'center',
          }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              paddingY: '1em',
              paddingX:notebook ? '5em' : '10em',
              width: '100%',
              gap: 3,
            }}
          >
            <img
              src={Rave1}
              style={{ width: '100%', height: '350px', borderRadius: '20px' }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                height: '300px',
                gap: '5%',
                width: '100%',
              }}
            >
              <img src={Rave2} style={{ width: '30%', borderRadius: '20px' }} />
              <img
                src={Rave3}
                style={{
                  width: '30%',
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
              />
              <img
                src={Rave4}
                style={{
                  width: '30%',
                  borderRadius: '20px',
                  objectFit: 'cover',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: '250px',
                width: '100%',
                gap: '10%',
              }}
            >
              <img src={Rave5} style={{ width: '60%', borderRadius: '20px' }} />
              <img src={Rave6} style={{ width: '30%', borderRadius: '20px' }} />
            </div>
          </Box>
        </Grid>}
      </Grid>
    </Box>
  );
};

export default LoginPageCustom;
