import React, { useEffect, useState } from 'react';
import { Checkbox, Popover, FormGroup, FormControlLabel, Tooltip} from '@mui/material';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp  } from "react-icons/md";
import { useSelector } from 'react-redux';
import { useRedirect, useUpdate } from 'react-admin';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import {Button} from '../Generics/index';

const containerStyle={
  display:'flex',
  flexDirection:'column',
  backgroundColor:'#EEEEEE',
  height:'44px',
  width:'150px',
  padding:1,
  borderRadius:'10px'
};
const insidePopoverStyle={
  display: 'flex', 
  flexDirection: 'column',
  alignItems:'flex-start', 
  backgroundColor: '#ECECEC', 
  borderRadius: '10px',
  padding:'0px 5px'
};
const formatRoleName = (role) => {
  switch (role) {
  case 'admin':
    return 'Admin';
  case 'talent_manager':
    return 'Talent Manager';
  case 'staff':
    return 'Staff';
  case 'superadmin':
    return 'Super Admin';
  default:
    return role;
  }
};
const RolActions = (props) => {
  const {record, options } = props;
  const redirect = useRedirect();
  const palette = useSelector(state => state?.palette?.palette);
  const [recordRole, setRecordRole] = useState(record);
  const [openPopover, setOpenPopover] = useState(false);
  const ref = React.useRef(null);
  const customToast = useCustomToasty();
  const handleOpenPopover = (e) =>{
    e.stopPropagation();
    setOpenPopover(!openPopover);
  };
  const handlerRedirect = (e)=>{
    e.stopPropagation();
    redirect('/editRoles');
  };
  const handlerCheck = async (event) => {
    event.stopPropagation();
    const role = event.target.name;
    if(role === undefined || role === null) return;
    let updatedRecordRole = { ...recordRole };
    if (Array.isArray(recordRole.role)) {
      if (recordRole.role.includes(role)) {
        updatedRecordRole.role = recordRole.role.filter(item => item !== role);
      } else {
        updatedRecordRole.role = [...recordRole.role, role];
      }
    } else{
      if(updatedRecordRole.role === role){
        return;
      }else{
        updatedRecordRole.role = role;
      }
    }
    await update('admins', { id: recordRole.id, data: updatedRecordRole, previousData: recordRole }, {});
    setRecordRole(updatedRecordRole);
    
  };
  const isDisabled = (rol)=>{
    if(Array.isArray(recordRole?.role)){
      if(recordRole.role.includes(rol)){
        return false;
      }else{
        return true;
      }
    }else{
      if(rol !== 'talent_manager'){
        return false;
      }else{
        if(recordRole.role  !== 'talent_manager'){
          return true;
        }
        if(record.role === 'talent_manager'){
          return false;
        }
      }
    }
  };
  const [update] = useUpdate(undefined,undefined, {
    onError: (err) => {
      customToast('error', err);
    },
    onSuccess: (data) => {
      return customToast('success', 'Updated succesfully');
    }
  });
  useEffect(() => {
    setRecordRole(record);
  }, [record]);
  return (
    <div style={containerStyle} key={record.id}>
      <div style={{display:'flex',flexDirection:'row',height:'100%',width:'100%',alignItems:'center',justifyContent:'center',gap:5}} ref={ref} onClick={handleOpenPopover}>
        {Array.isArray(record?.role) &&  record?.role?.length > 1 ? 
          <p>
            Multiple Roles
          </p>
          :
          <p>
            {formatRoleName(record.role)}
          </p>
        }
        {openPopover ?<MdOutlineKeyboardArrowUp size={22} onClick={handleOpenPopover} sx={{color:'#515151'}}/> :<MdOutlineKeyboardArrowDown size={22} onClick={handleOpenPopover} sx={{color:'#515151'}} />}
      </div>
      <Popover
        open={openPopover}
        anchorEl={ref.current}
        onClose={handleOpenPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '12em',
          },
          '& .MuiPopover-paper': {
            borderRadius: '10px',
          },
        }}
      >
        <div style={insidePopoverStyle}>
          {options?.map(rol=>(
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start',gap:5}}>
              <FormGroup>
                {isDisabled(rol.text) ? 
                  <Tooltip title={'You can not change to talent manager from the user\'s table'} placement='top'>
                    <FormControlLabel 
                      onClick={handlerCheck}
                      control={
                        <Checkbox 
                          disabled={true} 
                          checked={Array.isArray(recordRole.role) ? recordRole.role.includes(rol.text) : recordRole?.role === rol.text} 
                          onChange={e=>e.stopPropagation()} sx={{
                            '&.Mui-disabled': {
                              pointerEvents:'visible',
                            },
                            '&.Mui-checked': {
                              color: palette?.primary,
                            }}}/> 
                      } 
                      label={'Talent Manager'}
                      name={rol.text}
                    />
                  </Tooltip>
                  : 
                  <FormControlLabel 
                    onClick={handlerCheck}
                    control={
                      <Checkbox 
                        disabled={false} 
                        checked={Array.isArray(recordRole.role) ? recordRole.role.includes(rol.text) : recordRole?.role === rol.text} 
                        onChange={e=>e.stopPropagation()} 
                        sx={{
                          '&.Mui-checked': {
                            color: palette?.primary,
                          }}}/>  } 
                    label={formatRoleName(rol.text)} 
                    name={rol.text} />
                }
              </FormGroup>
            </div>
          ))}
          {/* <Button style={{ color:palette?.primary, fontSize:'14px', fontWeigh:'600', textTransform:'none'}} onClick={handlerRedirect}>Edit Roles</Button> */}
        </div>
      </Popover>
    </div>
  );
};

export default RolActions;