import axios from 'axios';
import { useQuery } from 'react-query';
import { apiUrl } from '../dataProvider';

export const useCheckField = (resource, field, value) => {
  const encodeValue = encodeURIComponent(value);
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Expose-Headers': 'content-range',
    'Authorization': 'Bearer ' + localStorage.getItem('auth'), 
    'x-api-key': process.env.REACT_APP_API_KEY, 
  };

  const checkQuery = useQuery(
    [`${resource}_${field}`, value], 
    () => axios({
      url: `${apiUrl}/${resource}?filter={"${[field]}":"${[encodeValue]}"}`, 
      method: 'GET', 
      headers: headers,
    }),
    {
      onSuccess: ({data}) =>{
        if(data.results?.length > 0){
          return true;
        } else {
          return false;

        }
      },
      onError: ({err}) => {return err;},
      enabled: false
    }
  );
  return checkQuery;
};