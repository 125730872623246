import React from 'react';
import ListCustom from '../../Components/ListCustom/ListCustom';
import { TextField,FunctionField } from 'react-admin';
import { Chip } from '@mui/material';
import { COLORS } from '../../assets/constants/theme';
import { ListActions } from '../../Components/ListActions/ListActions';
const FaqsList = () => {

  const paramsList = {
    listParams: {
      hasCreate: true,
      actions: <ListActions from={'faqs'} search={true} placeholder={'Search by question and answer'}/>,
      queryOptions: {
        meta: { field: 'last_modified'},
      }
    },
    datagridParams: {
      bulkActionButtons: false,
      rowClick: 'edit'
    }
  };

  return (
    <div >
      <ListCustom {...paramsList} datagridParams={{
        sx: {
          padding:'0px 20px',
          zIndex:100,
          '& .MuiTypography-root':{
            color:'#000000',
            fontWeight:400
          },
          '& .column-question': {
            width: '80%',
          },
          '& .column-category': {
            width: '20%',
            textAlign: 'center',
          },
        },
        ...paramsList.datagridParams,
      }}>
        <TextField source="question" />
        <FunctionField source="category" label='Category' render={(record)=> record?.category ? <Chip sx={{width:'auto'}} label={record?.category} /> : null }/>
      </ListCustom>
    </div>
  );
};

export default FaqsList;