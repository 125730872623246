import { required } from 'react-admin';
import InputDate from '../../../../Components/InputsForm/InputDate';
import InputText from '../../../../Components/InputsForm/InputText';
import { InputAddDates } from '../../../../Components/RedesignComponents/Inputs/InputAddDates';
import { InputImage } from '../../../../Components/InputsForm/InputImage';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';
import { InputSubtitle } from '../../../../Components/StyledComponents/InputSubtitle';
import RaveAppImage from '../../../../assets/images/RaveApp.png';

export function generalInformation () {
  return  {
    title:'Festival Information',
    inputs:[
      {
        field: <InputText
          title="Festival Name"
          subtitle="Enter the festival name you will use across our apps"
          source="name" 
          fullWidth
          required
          label=""
          validate={[required('The name is required.')]}
        />,
        breakpoints: { xs: 12},
      },
      {
        field: <InputAddDates 
          title="Festival Dates"
          subtitle={<span >Enter the dates of your festival. Bear in mind if your festival happens in different periods (say for example weekends) you should <span style={{fontWeight:'bold',color:'black'}}>add</span> a new period</span>}
          source="days" />,
        breakpoints: {xs: 12}
      },
      {
        field: <>
          <InputTitle>Logos</InputTitle>
        </>,
        breakpoints: {xs: 12}
      },
      {
        field: <InputImage subtitle="HORIZONTAL" source="logo.horizontal" />,
        breakpoints: {xs: 6}
      },
      {
        field: <InputImage subtitle="SQUARE" source="logo.square" />,
        breakpoints: {xs: 6}
      },
      {
        field: <InputImage  title="Default Image" source="logo.default" />,
        breakpoints: {xs: 6}
      },
    ],
  };

}