import { Box, Button, Typography } from '@mui/material';
import { OrderRankButtons } from './OrderRankButtons';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';

export const InputAddSets = (props) => {
  const {
    addSets,
    title = 'sets',
    sets,
    sx
  } = props;

  const orderProps = {
    choices: sets,
    ...props
  };
 
  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '40px',
        maxWidth: '250px',
        
        ...sx,
      }}>
      <InputTitle>{title}</InputTitle>
      <OrderRankButtons {...orderProps} />
      {
        addSets && (
          <Button 
            sx={{
              fontSize: '.9em',
              fontWeight: '600',
              minWidth: '200px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            onClick={addSets}
          >+ Add</Button>
        )
      }
    </Box>
  );
};
