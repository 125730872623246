import { HiUsers } from "react-icons/hi";
import AdminsList from './AdminsList';
import EditAdmins from './Form/EditAdmins';
import CreateAdmins from './Form/CreateAdmins';


export default {
  list: AdminsList,
  edit: EditAdmins,
  create: CreateAdmins,
  icon: HiUsers,
};