import CreateTalents from './Form/CreateTalents';
import EditTalents from './Form/EditTalents';
import ShowTalents from './Form/ShowTalents';
import TalentsList from './TalentsList';

export default {
  list: TalentsList,
  edit: EditTalents,
  create: CreateTalents,
  show: ShowTalents,
  // icon: TbUsers
};