import React, { useMemo, useState } from 'react';
// Components
import { CountdownForm } from './Sections/Countdown/CountdownForm';
import { CarouselForm } from './Sections/Carousel/CarouselForm';
import { AnnouncementForm } from './Sections/Announcement/AnnouncementForm';
import { QuickLinksForm } from './Sections/QuickLinks/QuickLinksForm';
import { BannerForm } from './Sections/Banner/BannerForm';
import { FeaturedForm } from './Sections/Featured/FeaturedForm';
import { HighlightedExternalContent } from './Sections/HighlightedExternalContent/HighlightedExternalContent';
// Materials & Icons
import { Grid } from '@mui/material';
import { FormComponents } from './FormComponents';
import { useGetList } from 'react-admin';
import { Spinner } from '../../Components/Spinner';
import { InputAddSets } from '../Interests/Form/Components/InputAddSets';

const tabs = [
  {
    key: 'carousel',
    title: 'Carousel',
    subtitle:
      'Select multiple images to highlight in your homepage, you can add links to them to redirect users to external content. You can drag and order the images as you wish',
  },
  {
    key: 'announcement_bar',
    title: 'Announcement bar',
    subtitle:
      'This component is useful to inform users about upcoming events or important issues they should be noticed about',
  },
  {
    key: 'quicklinks',
    title: 'Quick Links',
    subtitle:
      'Quicklinks are useful for important information that needs to be redirected externally. You can add up to 6 quicklinks.',
  },
  {
    key: 'banner',
    title: 'Banner',
    subtitle:
      'Have important content highlighted. With a banner you can further call user attention. Banners can have diferent sizes and can have solid backgrounds or images.',
  },
  {
    key: 'countdown',
    title: 'Countdown',
    subtitle:
      'Generate anticipation with a custom made countdown, select the colors and link it to external content. ',
  },
  {
    key: 'featured_categories',
    title: 'Featured Content',
    subtitle:
      'Show your content in your homepage. Highlight it and select the most featured content. You can either feature a category or feature specific articles',
  },
  {
    key: 'highlighted_external',
    title: 'External Links',
    subtitle:
      'External links are usefull for you to show specific highlights that you want the user to see and visit. You can add a max of 6 external links.',
  },
];

export const HomeComponents = () => {
  const [selectedForm, setSelectedForm] = useState(0);

  const { data: info, isFetching } = useGetList('app_config');

  const formInputs = useMemo(() => {
    switch (!!info) {
    case tabs[selectedForm].key === 'carousel':
      return CarouselForm();
    case tabs[selectedForm].key === 'announcement_bar':
      return AnnouncementForm();
    case tabs[selectedForm].key === 'quicklinks':
      return QuickLinksForm();
    case tabs[selectedForm].key === 'banner':
      return BannerForm();
    case tabs[selectedForm].key === 'countdown':
      return CountdownForm();
    case tabs[selectedForm].key === 'featured_categories':
      return FeaturedForm();
    case tabs[selectedForm].key === 'highlighted_external':
      return HighlightedExternalContent();
    }
  }, [info, selectedForm]);

  const props = useMemo(() => {
    return {
      selectedSet: selectedForm,
      section: tabs[selectedForm],
      info: info?.[0],
      sets: tabs,
      resourceProp: 'app_config',
      onClick: setSelectedForm,
      title: 'Home Components',
      draggable: false,
      ...formInputs,
    };
  }, [info, selectedForm]);

  return (
    <>
      <Grid container direction={'row'}>
        <Grid item xs={3} align="center">
          <InputAddSets {...props} />
        </Grid>
        <Grid item xs={9}>
          {!isFetching ? <FormComponents {...props} xs={12} /> : <Spinner />}
        </Grid>
      </Grid>
    </>
  );
};
