
import { useQuery } from 'react-query';
import { useDataProvider } from 'react-admin';
import { useCallback, useState } from 'react';
import { drop, moveElement, onDragOver } from './functions';
import { DraggableSet } from './DraggableSets';

export const OrderRankButtons = (props) => {
  const {
    choices, 
    selectedSet, 
    resourceProp,
    info,
    setSets, 
    onReorder, 
    orderSx,
    draggable = true,
  } = props;
  const [isDragging, setIsDragging] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(null);
  
    
  const rowProps = {
    setIsDragging,
    isDragging,
    selectedSet,
    setIsDraggingOver,
    isDraggingOver,
    ...props
  };

  const handleSetArr = (data) => {
    
    if(draggable){
      const { selected, dropped } = data;
   
      let number = parseInt(selected);
      if((dropped + 1 > choices.length ) || (dropped === selected)) return choices;
      let secondNumber = number <= dropped ? dropped : dropped + 1;
      const reorder = moveElement(choices, number, secondNumber);
      onReorder && onReorder();
      return setSets(reorder);
    
    }
  };

  const handleDrag = (e) => {
    if(draggable) {
      onDragOver(e, setIsDraggingOver);
    }
  };
  
  return (
    <div 
      id="dnd-sets-dropzone"
      style={{
        display: 'flex', 
        flexDirection: 'column',
        width: '100%',
        ...orderSx
      }}
      onDragOver={handleDrag} 
      onDrop={(e) => handleSetArr(drop(e, setIsDragging))} 
    > 
      {
        choices?.map((item, index) => (
          <DraggableSet key={`draggable-set-${index}`} {...rowProps} index={index} item={item} />
        ))
      }
    </div>
  );
};
  