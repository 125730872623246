export const imagesBackground = [
  // {
  //   image: require('../images/loginbackground_3.jpeg')
  // },
  {
    image: require('../images/loginbackground_7.jpg')
  },
];



