import { Box, Button, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useGetOne, useGetPermissions, usePermissions, useRecordContext, useRedirect, useResourceContext } from 'react-admin';
import { BsArrowLeftCircle  } from 'react-icons/bs';
import { BackArrow, styledArrow, styledShowTemplate } from './CssProperties';
import { mainColor } from '../../Theme';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { DraftButton } from './AsideActions/DraftButton';
import { AsideActions } from './AsideActions/AsideActions';
import React from 'react';
import { MdArrowBack } from 'react-icons/md';

const styledHeader = {
  fontSize: '25px',
  fontWeight: '600',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  color: mainColor,
  marginBottom: '10px'
};

export const slotProps = {
  tooltip: {
    sx: {
      fontSize: '1em',
      padding: '10px'
    }
  }
};

const ShowTemplate = ({from,setSwitchBody, switchBody, Submitter, recordTitle, children, AsideActions}) => {
  const redirect = useRedirect();
  const goBack = () => {
    if(from === 'events') redirect('/event_submissions');
    if(from === 'talents') redirect('/talent_submissions');
  };
  const record = useRecordContext();
  const { permissions } = usePermissions();

  return(
    <div style={{display:'flex',flexDirection:'column',border:'none',height:'auto',justifyContent:'space-between'}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 5,
          padding: '20px 16px',
          justifyContent: 'space-between',
          // height: '6.5%',
          gap: 1,
        }}
      >
        <div style={{display:'flex', flexDirection: 'row', alignItems: 'center'}}>
          <button
            onClick={() => goBack()}
            style={{
              display: 'flex',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              alignItems: 'center',
            }}
          >
            <MdArrowBack size={30} />
          </button>
          <Typography fontSize={'32px'} fontWeight={600}>
          Review - {record?.name}
          </Typography>
        </div>
        {Submitter && <Submitter sx={{margin: '10px 20px 0px 20px', border: 'none', boxShadow: 'none', width: 'auto', backgroundColor: '#fff'}} /> }
      </Box>
      <Box sx={{width: '100%',height:'auto',paddingX: '2em' }}>
        {AsideActions && React.cloneElement(AsideActions)}
      </Box>
      <Box sx={{flexDirection: 'column',marginTop:'3em'}}>
        {/* <Typography sx={{...styledHeader}}>{record?.talents?.map(talent => talent.name).join(', ')} - {record?.[recordTitle]}</Typography> */}
        {children}
      </Box>
    </div>
  );
};

export default ShowTemplate;