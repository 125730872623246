import useMediaQuery from '@mui/material/useMediaQuery';
import { useSidebarState } from 'react-admin';

// FUNCION que retorna el width (en PX) correspondiente para los 3 distintos tamanios de pantalla.
export const responsiveFilter = (searchbar) => {
  const isTabletVertical = useMediaQuery('(min-width: 760px)');
  const isTabletHorizontal = useMediaQuery('(min-width: 960px)');
  const isNetbook = useMediaQuery('(min-width: 1200px)');
  const isNotebook = useMediaQuery('(min-width: 1400px)');
  const isLargeScreen = useMediaQuery('(min-width: 1650px)');
  const sideBarOpen = useSidebarState()[0];
  // RESPONSIVE en caso del SEARCHBAR
  if (searchbar) {
    if (isLargeScreen) {
      return '350px';
    }  else if (isNetbook) {
      return '350px';
    }else if (isTabletHorizontal) {
      return sideBarOpen ? '200px' : '350px';
    }else if (isTabletVertical) {
      return sideBarOpen ? '140px' : '160px';
    }
  }
  // RESPONSIVE en caso de FILTROS
  if (isLargeScreen) {
    return sideBarOpen ? '260px' : '300px';
  } else if (isNotebook) {
    return sideBarOpen ? '220px' : '250px';
  } else if (isNetbook) {
    
    return sideBarOpen ? '210px' : '250px';
  } else if (isTabletHorizontal) {
   
    return sideBarOpen ? '140px' : '170px';
  }else if (isTabletVertical) {
    
    return sideBarOpen ? '100px' : '120px';
  }
};
