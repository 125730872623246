import React from 'react';
import FormVenues from './FormVenues';
import { Create } from 'react-admin';
import { transform } from '../transform/transform';
import { CreateCustomComponent } from '../../../Components/CreateCustomComponent/CreateCustomComponent';

const CreateVenues = () => {
  return (
    <CreateCustomComponent mode="onChange" transform={transform} redirect='list' >
      <FormVenues from={'venues'} isEdit={false}/>
    </CreateCustomComponent>
  );
};

export default CreateVenues;