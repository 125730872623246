import EventsCreate from './Form/CreateEvents';
import EventsEdit from './Form/EditEvents';
import EventsList from './EventsList';
import EventsShow from './EventsShow';

export default {
  list: EventsList,
  edit: EventsEdit,
  create: EventsCreate,
  show: EventsShow,
};