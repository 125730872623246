export const VISIBILITY_TYPES = [
  {
    id: 0,
    text: 'Guest'
  },
  {
    id: 1,
    text: 'Staff'
  },
  {
    id: 2,
    text: 'Talent'
  }
];