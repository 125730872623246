import React, { useEffect } from 'react';
import { usePermissions, useRedirect } from 'react-admin';
import { transform } from './Transforms';
import { ProfileForm } from '../../TalentForm/ProfileForm';
import { CreateCustomComponent } from '../../../Components/CreateCustomComponent/CreateCustomComponent';

const CreateTalents = () => {
  const { permissions } = usePermissions();
  const redirect = useRedirect();
  useEffect(() => {
    if (permissions === 'talent_manager') return redirect('list', 'talents');
  },[permissions]);
  return (
    <CreateCustomComponent transform={transform} redirect='list' mutationMode={'pessimistic'}>
      <ProfileForm from={'create'}/>
    </CreateCustomComponent>
  );
};

export default CreateTalents;