import { keyframes } from '@emotion/react';

export const easeOutLeft = keyframes` 
0%  { transform: translateX(0px); opacity: 1},
100% { transform: translateX(-100px); opacity: 0}
  `;

export const easeInRight = keyframes` 
  0%  { transform: translateX(100px); opacity: 0},
  100% { transform: translateX(0px); opacity: 1}
  `;

export const easeInLeft = keyframes` 
0%  { transform: translateX(-100px); opacity: 0},
100% { transform: translateX(0px); opacity: 1}
  `;

export const easeOutRight = keyframes` 
  0%  { transform: translateX(0px); opacity: 1},
  100% { transform: translateX(100px); opacity: 0}
  `;

export const opacityIn = keyframes`
  0%  { opacity: 0},
  100% {  opacity: 1}
  `;