import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useCustomToasty } from '../../../Hooks/useCustomToasty';
import { FormControlLabel, FormGroup, Switch, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { switchStyleIos } from '../../StyledComponents/Inputs';
import { InputAddTextInputs } from '../../../Resources/Talents/Form/TalentsListComponents/InputAddTextInputs';
import { TimeLineRow } from './TimeLineRow';
import {
  addTime,
  allDay,
  createObj,
  dayValues,
  handleCloseDay,
  handleDayError,
  initialCheckOpen,
} from './functions';
import { useRecordContext } from 'react-admin';
import { useSelector } from 'react-redux';
import { Button } from '../../Generics/index';

const styledDates = {
  fontSize: '.9em',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#D9D9D9',
  textTransform: 'uppercase',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  width: '70px',
  height: '70px',
};

function InputAddTimelines(props) {
  const {
    dayPicked,
    value,
    findDays,
    handleChange,
    days,
    setDayPicked,
    setAddHours,
    source,
  } = props;
  const palette = useSelector((state) => state?.palette?.palette);
  const [values, setValues] = useState(createObj(dayValues(value, dayPicked)));
  const [open24, setOpen24] = useState(
    values ? allDay(values, dayPicked) : false,
  );
  const [closedDay, setClosedDay] = useState(
    values ? !initialCheckOpen(values, dayPicked) : [],
  );
  const customToasty = useCustomToasty();
  const disableOpen = useMemo(() => {
    return initialCheckOpen(values, dayPicked);
  }, [values, dayPicked]);

  const handleAdd = useCallback(() => {
    return addTime(values, dayPicked, customToasty);
  }, [values, dayPicked]);

  useEffect(() => {
    setValues(createObj(dayValues(value, dayPicked), dayPicked));
  }, [value, dayPicked]);

  const INPUT_PROPS = {
    removeAside: true,
    addBelow: true,
    noPadding: true,
    addAction: handleAdd,
    direction: 'row',
    values: values,
    setValues: handleChange,
    initialNumber: findDays?.length,
    element: <TimeLineRow {...props} open24={open24} />,
  };

  const record = useRecordContext();
  const MemoTimeLineRow = useMemo(() => {
    return memo(InputAddTextInputs);
  }, [open24]);

  const defaultDayValues = record?.[source]?.filter((item) =>
    dayjs(item.open).isSame(dayPicked, 'day'),
  ) ?? [
    {
      open: dayjs(dayPicked).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      close: dayjs(dayPicked).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    },
  ];
  const handleCloseCallback = useCallback(
    (e) => {
      if (!e.target.checked) {
        setValues(createObj(defaultDayValues));
      }
      setClosedDay(e.target.checked);
      handleCloseDay(
        e.target.checked,
        value,
        dayPicked,
        setAddHours,
        defaultDayValues,
      );
    },
    [dayPicked],
  );

  const handleSetOpen = useCallback(
    (e) => {
      let boolean = e.target.checked;
      setOpen24(boolean);
    },
    [dayPicked],
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: 30,
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {days?.map((item, index) => (
          <Button
            key={index}
            onClick={() => setDayPicked(item)}
            style={{
              ...styledDates,
              backgroundColor:
                dayPicked === item ? palette?.primary : '#D9D9D9',
              color: dayPicked === item ? 'white' : 'black',
              border: handleDayError(props.error, item)
                ? '3px solid red'
                : null,
            }}
          >
            <Typography fontSize={'12px'} sx={{ textTransform: 'none' }}>
              {dayjs(item).format('MMMM')}
            </Typography>
            <Typography fontSize={'18px'} sx={{ textTransform: 'none' }}>
              {dayjs(item).format('D')}
            </Typography>
          </Button>
        ))}
      </div>
      <FormGroup
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginLeft: '10px',
          gap: 20,
          width: '100%',
          marginBottom: '20px',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                disabled={disableOpen?.length > 1 || closedDay}
                checked={open24}
                onChange={handleSetOpen}
                sx={switchStyleIos}
              />
            }
          />
          <Typography>Open 24 hours</Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                disabled={open24}
                checked={closedDay}
                onChange={handleCloseCallback}
                sx={switchStyleIos}
              />
            }
          />
          <Typography>Closed</Typography>
        </div>
      </FormGroup>
      {!closedDay && <MemoTimeLineRow {...INPUT_PROPS} />}
    </div>
  );
}

export default memo(InputAddTimelines);

// InputAddTimelines.prototype = {

//   dayPicked: PropTypes.string.isRequired,
//   value: PropTypes.object.isRequired,
//   findDays: PropTypes.func.isRequired,
//   days: PropTypes.array.isRequired,
//   setDayPicked: PropTypes.func.isRequired,
//   handleChange: PropTypes.func.isRequired,
//   setAddHours: PropTypes.func,
//   source: PropTypes.string.isRequired

// };
