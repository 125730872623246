import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import EditModalButton from './EditModalButton';
import RaveLogoWelcome from '../../../assets/images/squareLogoDefault.png';
import Notification from '../../../assets/images/notification.png';
import Person from '../../../assets/images/persona.png';
import Schedule from '../../../assets/images/mapa.png';
import Calendar from '../../../assets/images/calendar.png';
import { InputImage } from '../../../Components/InputsForm/InputImage';
import PropType from 'prop-types';
import { useFormContext } from 'react-hook-form';

const DEFAULT_IMAGES = [RaveLogoWelcome, Notification, Schedule, Calendar, Person];

export const IconImage = (props) => {
  const { iconSize = '240px', source } = props;
  const { watch, getValues } = useFormContext();
  const onboarding = watch('onboarding');
  
  return (
    <Box
      key="onboard_provider_icon"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: iconSize,
          height: 'auto',
          zIndex: 1
        }}>
        {
          onboarding?.[source]?.image?.original ? (
            <img src={onboarding?.[source]?.image?.original} style={{width: iconSize, height: 'auto'}} />
          ) : (
            <img src={onboarding?.[source]?.image?.original} style={{width: iconSize, height: 'auto'}} />

          )
        }
        <EditModalButton placement="right" EditInputs={EditInputs} {...props} />
      </Box>
    </Box>
  );
};


const EditInputs = ({ source }) => (
  <Box sx={{
    display: 'flex',
    flexDirection: 'column'
  }}>
    <InputImage title="Icon Image" source={`onboarding.${source}.image`} />
  </Box>
);