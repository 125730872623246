import { Box } from '@mui/material';
import { SmallCard, SmallCardBody, SmallCardHeader, SmallCardIcon, SmallCardTitle } from './CssProperties';
import { ImGithub } from 'react-icons/im';


const SmallCardComponents = ({cards, children, ...rest}) => {

  return(
    <Box style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '20px', width: '90%'}}>
      {
        cards.map(item => (
          <SmallCard>
            <SmallCardHeader>
              <SmallCardTitle>{item.key}</SmallCardTitle>
              <SmallCardIcon>
                {item.icon ?? <ImGithub fontSize="25px" />}
              </SmallCardIcon>
            </SmallCardHeader>
            <SmallCardBody>
              {item.value}
            </SmallCardBody>
          </SmallCard>
        ))  
      }
    </Box>
  );
};

export default SmallCardComponents;