import { useInput } from 'react-admin';
import { useEffect, useRef, useState } from 'react';
import UploadModal from '../ModalCrop/UploadModal';
import useUploadAws from '../../Hooks/useUploadAws';
import { Box, FormHelperText, FormLabel, IconButton } from '@mui/material';
import { BiTrashAlt, BiImageAdd, BiEdit, BiCrop } from 'react-icons/bi';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { InputTitle } from '../StyledComponents/InputTitle';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
import { useSelector } from 'react-redux';
import { useRequiredSchema } from '../../Hooks/useRequiredSchema';
export const InputImage = ({
  title,
  subtitle,
  noImage,
  label,
  source,
  validate = [],
  inputLabelSx,
  maxSize,
  defaultImage,
  modalOn,
  tooltip,
  onChange,
  value,
  action = 'original',
  sxImage,
  sxContainer,
}) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const fileInputRef = useRef(null);

  const { isRequiredSchema } = useRequiredSchema();
  const { id, field, fieldState } = useInput({
    source,
    validate: [...isRequiredSchema(source), ...validate],
    defaultImage: defaultImage,
  });

  const imageFieldSource =
    field?.value[action]?.length > 0
      ? { key: field.name, location: field.value[action] }
      : defaultImage
        ? defaultImage
        : null;
  const [hover, setHover] = useState(false);
  const [image, setImage] = useState(imageFieldSource);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { uploadAWS } = useUploadAws();

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const customToasty = useCustomToasty();

  const onDelete = () => {
    setImage(null);
    if (action === 'icon') {
      field.onChange({
        ...field.value,
        icon: null,
      });
    } else if (action !== 'icon') {
      field.onChange({
        original: null,
      });
    }
    setIsModalOpen(false);
    onChange && onChange(null);
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const onEdit = () => {
    setIsModalOpen(true);
  };

  const handleClick = async (e) => {
    let value = e.target.files[0];
    const fileUpload = await uploadAWS(e);
    if (maxSize) {
      if (value?.size > maxSize) {
        return customToasty('error', 'The image needs to be smaller than 4mb.');
      }
    }
    if (onChange && fileUpload.success) {
      field.onChange({ ...value, original: fileUpload.location });
      onChange({ ...value, original: fileUpload.location });
      return;
    } else if (fileUpload.success && !onChange) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          // Access the image dimensions here
          const width = img.width;
          const height = img.height;
          console.log('Image Dimensions:', { width, height });
          // Set the state or perform other actions based on the dimensions
          setImage({ ...fileUpload, dimensions: { width, height } });
          field.onChange({
            ...field.value,
            [action]: fileUpload.location,
            y_center: null,
          });
          if (height > width) {
            setIsModalOpen(true);
          }
        };
      };
      reader.readAsDataURL(value);
    }
  };
  useEffect(() => {
    if (value) {
      field.onChange(value);
      if (Object.keys(value).includes('original')) {
        setImage({ key: 'image', location: value.original });
      } else {
        setImage(null);
      }
    }
  }, [value]);
  return (
    <>
      {title && <InputTitle tooltip={tooltip ?? ''}>{title}</InputTitle>}
      {subtitle && <InputSubtitle>{subtitle}</InputSubtitle>}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          marginBottom: 3,
          width: '100%',
          ...sxContainer,
        }}
      >
        {modalOn && (
          <UploadModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            image={image}
            onCancel={onCancel}
            setIsModalOpen={setIsModalOpen}
            fieldOnChange={field?.onChange}
            fileUpload={image}
          />
        )}
        {!image?.location && (
          <>
            <Box
              sx={{
                height: action === 'icon' ? '55px' : '140px',
                width: action === 'icon' ? '55px' : '180px',
                borderRadius: '10px',
                border: '1px solid #AEADAD',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'relative',
                ...sxImage,
              }}
            >
              <IconButton
                onClick={handleButtonClick}
                style={{
                  color: fieldState.invalid && 'red',
                  borderColor: fieldState.invalid && 'red',
                  backgroundColor: palette?.input_background,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '10px',
                  border: '1px solid #AEADAD',
                  height: action === 'icon' ? '55px' : '140px',
                  width: action === 'icon' ? '55px' : '180px',
                  ...sxImage,
                }}
              >
                <BiImageAdd size={30} color="grey" />
                <input
                  ref={fileInputRef}
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleClick}
                />
              </IconButton>
              <FormLabel
                sx={{
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  fontSize: '.7em',
                  maxWidth: '100px',
                  ...inputLabelSx,
                }}
              >
                {label}
              </FormLabel>
            </Box>
            {fieldState.invalid && (
              <FormHelperText sx={{ color: 'red' }}>
                The Image is required
              </FormHelperText>
            )}
          </>
        )}
        {image?.location && !noImage && (
          <Box
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{
              height: action === 'icon' ? '55px' : '140px',
              width: action === 'icon' ? '55px' : '180px',
              borderRadius: '10px',
              border: '1px solid #AEADAD',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              position: 'relative',
              ...sxImage,
            }}
          >
            <img
              src={image?.location}
              alt={image?.key}
              loading="lazy"
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '10px',
                objectFit: 'contain',
              }}
            />
            {action !== 'icon' ? (
              <>
                {hover && (
                  <ActionButtonsImg
                    onDelete={onDelete}
                    onCrop={onEdit}
                    onEdit={handleButtonClick}
                    fileInputRef={fileInputRef}
                    handleClick={handleClick}
                    palette={palette}
                    modalOn={modalOn}
                  />
                )}
              </>
            ) : (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  padding: '10px',
                  display: 'flex',
                  textAlign: 'center',
                  margin: '10px',
                  alignItems: 'center',
                  width: '40px',
                  height: '40px',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  borderRadius: '50%',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                    color: '#fff',
                  },
                }}
                onClick={onDelete}
              >
                <BiTrashAlt size={'20px'} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

const ActionButtonsImg = ({
  onDelete,
  onCrop,
  onEdit,
  fileInputRef,
  handleClick,
  palette,
  modalOn,
}) => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 5,
        display: 'flex',
        flexDirection: 'row',
        width: 'auto',
        alignItems: 'center',
        textAlign: 'center',
        border: '1px solid #AEADAD',
        borderRadius: '5px',
        backgroundColor: palette?.background,
      }}
    >
      <IconButton onClick={onEdit}>
        <BiEdit size={18} />
        <input
          ref={fileInputRef}
          hidden
          accept="image/*"
          type="file"
          onChange={handleClick}
        />
      </IconButton>
      {modalOn && (
        <IconButton onClick={onCrop}>
          <BiCrop size={18} />
        </IconButton>
      )}
      <IconButton onClick={onDelete}>
        <BiTrashAlt size={18} />
      </IconButton>
    </div>
  );
};
