import { Box, TextField, Tooltip,Chip } from '@mui/material';
import { slotProps } from '../../../../Components/ShowComponents/ShowTemplate';
import { useMemo } from 'react';
export const FieldTypo = ({
  switchLabel, 
  longMsg,
  value, 
  sx, 
  urlSx, 
  url, 
  icon, 
  field, 
  switchBody,
  maxWidth= '300px',
  type
  
}) => {
  const handleShorten = (str) => {
    return str?.length > 15 ? str?.slice(0, 15) + '...' : str ? str : '';
  };
  
  const updatedValue = useMemo(() => {
    if(value) return value;
    if(!value) return '';
  }, [switchBody]);
  const labelRequired = useMemo(() => {
    if(switchBody) {
      return field?.replace(/_/g, ' ');
    } else {
      return`${field?.replace(/_/g, ' ')} `;
    }
  },[switchBody]);

  const styles = theme => ({
    button: {
      '&:disabled': {
        backgroundColor: theme.palette.primary || 'red'
      }
    }
  });

  return(
    <Box style={{ display: 'flex', flexDirection: 'column',width: '100%', maxWidth: maxWidth, ...sx}}>
      {/* En caso de que sea PILL renderiza cada TAGS como CHIP  */}
      {type === 'pill' && Array.isArray(value) && value?.length > 1 ?(
        <div style={{display:'flex',flexDirection:'row',width:'100%',height:'auto',gap:5,flexWrap:'wrap'}}>
          {updatedValue?.map((item, index) => (
            <Chip
              key={index}
              size='small'
              sx={{
                width: 'auto',
                padding: '10px',
                fontSize: '16px',
                fontWeight: '400',
                color: switchLabel ?'white': 'grey',
                backgroundColor:switchLabel ? '#3395ff' : 'rgba(0,0,0, 0.1)',
              }}
              label={url ? handleShorten(item) : item}
              classes={{ disabled: styles}}
            />
          ))
          }
        </div> )
        // En caso de que sea PILL y un unico valor (Tambien se incluye category estrictamente ya que devuelve como un string) >
        :type === 'pill' && value !== null && value?.length === 1 || field === 'category' ? 
          <Chip
            size='small'
            sx={{
              width: 'auto',
              padding: '10px',
              fontSize: '16px',
              fontWeight: '400',
              color: switchLabel ?'white': 'grey',
              backgroundColor:switchLabel ? '#3395ff' : 'rgba(0,0,0, 0.1)',
            }}
            label={url ? handleShorten(updatedValue) : updatedValue}
            classes={{ disabled: styles}}
          />
          :null}
      {/*NO PILL RENDER > */}
      {type !== 'pill' && (  
        <TextField  
          disabled
          multiline={Boolean(longMsg)}
          classes={{ disabled: styles}}
          minRows={longMsg ? 1 : null}
          maxRows={longMsg ? 6 : null}
          sx={{
            width: 'auto',
            marginRight: '10px',
            '& .MuiInputBase-input.Mui-disabled': {
              fontWeight: '600',
              WebkitTextFillColor: switchLabel ? '#3395ff' : 'rgba(0,0,0, 0.3)',
            },
            ...sx
          }}
          InputProps={{
            sx: {
              width: 'auto'
            },
            disableUnderline: true,
            startAdornment: (icon && url) ? (
              <Tooltip slotProps={slotProps} placement={'top'} title={url}>
                <div>
                  <a target={'_blank'} style={{cursor: 'pointer', ...urlSx,marginRight:'5px'}} href={url} rel="noreferrer">{icon}</a>
                </div>
              </Tooltip>
            ) : icon
          }} 

          InputLabelProps={{
            shrink:true, 
            style: {
              textTransform: 'uppercase',
              fontSize: '1.1em',
              fontWeight: '600'
            }}} 
          variant="standard"
          // label={labelRequired} 
          value={url ? handleShorten(updatedValue) : updatedValue}
        />)
      }
    </Box>
  );
};