import { ReferenceArrayInput, required } from 'react-admin';
import InputAutocomplete from './InputAutocomplete';
import { EVENTS_TOOLTIPS } from '../../Resources/TalentForm/Tooltips';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { AiOutlineWarning } from 'react-icons/ai';


export const MessageInput = (props) => {
  const { 
    text,
    noIcon
  } = props;

  const styleWarning = {
    'background-color': 'rgba(255, 212, 62, 0.15)',
    'border-radius': '12px',
    'width': '100%',
    'margin-bottom': '20px',
    'padding': '15px',
    'display': 'flex',
    'flex-direction': 'row',
    'justify-content': 'space-between',
    'align-items': 'center'
  };
      
  const styledType = {
    'color': '#EBB803',
    'margin-left': !noIcon ? '30px' : 0,
    'font-size': '12px', 
    'font-weight': '700', 
    'width': '100%'
  };
      
  return(
    <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', width: '100%'}}>
      <Box style={{...styleWarning}}>
        {!noIcon && <AiOutlineWarning color="#EBB803" size={30} />}
        <Typography style={{...styledType}}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
};