import React from 'react';
import { TextField,FunctionField,ImageField, ReferenceField } from 'react-admin';
// Hooks & Utils
import { COLORS } from '../../assets/constants/theme';
// Components
import ListCustom from '../../Components/ListCustom/ListCustom';
// Material & Icons
import { Chip } from '@mui/material';
import { ListActions } from '../../Components/ListActions/ListActions';

const ActivitiesList = () => {
  const paramsList = {
    listParams: {
      hasCreate: true,
      actions: <ListActions from={'experiences'} search={true} placeholder={'Search by name and description...'}/>,
      queryOptions: {
        meta: { field: 'last_modified' },
      },
    },
    datagridParams: {
      bulkActionButtons: false,
      rowClick: 'edit',
    }
    
  };
  return (
    <div>
      <ListCustom {...paramsList} datagridParams={{
        sx: {
          paddingX:3,
          '& .MuiTypography-root':{
            color:'#000000',
            fontWeight:400
          },
          '& .column-imagen': {
            width: '10%',
            textAlign:'center'
          },
          '& .column-title': {
            width: '30%'
          },
          '& .column-location': {
            width: '30%'
          },
          '& .column-type': {
            width: '30%',
            textAlign: 'center'
          }
        },
        ...paramsList.datagridParams,
      }}>
        <FunctionField  source="imagen" label='Picture' render={(record) => <ImageField
          source="picture.original"
          label='Picture'
          sx={{ '& img': { borderRadius: 200, maxWidth: 50, maxHeight: 45, objectFit: 'cover' } }}
        />}/>
       
        <TextField source="name" />
        <FunctionField source="category" label='Category' render={(record)=> record?.category ? <Chip sx={{width:'auto'}} label={record?.category} /> : null }/>
        <ReferenceField source="location_id" reference="locations">
          <TextField source="name" />
        </ReferenceField>
      </ListCustom>
    </div>
  );
};

export default ActivitiesList;