import React from 'react';
import { List,Datagrid,FunctionField,useStore ,useRedirect,usePermissions } from 'react-admin';
import { useSelector } from 'react-redux';
// Hooks & Utils
import { COLORS } from '../../../assets/constants/theme';
import { useParams } from 'react-router-dom';
// Components
import { InputSubtitle } from '../../../Components/StyledComponents/InputSubtitle';
import { Indicator } from '../../../Components/RedesignComponents/Indicator';
import {Button} from '../../../Components/Generics';
import ActionButtons from '../../../Components/StyledComponents/ActionButtons';
// Material & Icons
import { Typography, Alert } from '@mui/material';
import { IoMdCloseCircle, IoIosCheckmarkCircle } from 'react-icons/io';

const TabsEventList = () => {
  const [selectedTalent] = useStore('selectedTalent', 'selectedTalent', {
    selected: false,
    talent: '',
  });
  const palette = useSelector((state) => state?.palette?.palette);
  const redirect = useRedirect();
  const params = useParams();
  const { permissions: permis } = usePermissions();
  const talent_id = params['*']?.split('/')[1] ?? params.id;
  const newTalent_id = selectedTalent?.talent;
  const handleRedirect = () => {
    redirect(`/events/create/talentId=${newTalent_id}`);
  };
  const handlerViewTable = (record) => {
    redirect(`/events/${record?.id}/talentId=${newTalent_id}`);
  };
  const eventFilters = {
    talent_id: permis !== 'talent_manager' ? [talent_id] : [newTalent_id],
  };
  return (
    <div style={{marginTop:'3em'}}>
      {permis === 'talent_manager' &&
      <div
        style={{
          marginBottom: '20px',
          position: 'relative',
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Typography
            variant="h5"
            gutterBottom
            fontWeight={400}
            alignSelf={'flex-start'}
          >
            📅 Events
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight={400}
              sx={{ pb: 2, width: '70%' }}
              alignSelf={'flex-start'}
              gutterBottom
            >
              This section allows you to edit multiple events
              of your talent, please provide a
              detailed description of each event. Bear in mind
              this will help guests discover and explore your
              act through the festival app.
            </Typography>
          </div>
          <Alert severity="warning">
            <Typography>
              Complete all sections, especially tags and
              descriptions, to enhance visibility and
              attendance.
            </Typography>
          </Alert>
        </div>
      </div>
      }
      <List hasCreate={true} actions={<EventsInTalentActions redirect={handleRedirect}/>} pagination={false} resource='events' filter={eventFilters} 
        empty={ 
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingY: '100px',
              gap: '20px',
              width:'100%'
            }}
          >
            <InputSubtitle>
              {permis !== 'talent_manager'? 'This talent has no events assigned yet, click here to create one': 'This talent has no events assigned yet.'}
              <br />
            </InputSubtitle>
            {permis !== 'talent_manager'&& 
              <Button
                style={{
                  backgroundColor: palette?.primary,
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: palette?.primary,
                    color: '#fff',
                  },
                }}
                onClick={handleRedirect}
              >
              Create Event
              </Button>}
          </div>
        }>
        <Datagrid
          sx={{
            padding:'0px 20px',
            '& .column-picture.original': {
              width: '15%'
            },
            '& .MuiTypography-root':{
              color:'#000000',
            },
            '& .MuiTableSortLabel-root':{
              color:COLORS.table.headerSorteable,
              fontWeight:400
            },
            '& .column-name': {
              width: '25%',
            },
            '& .column-completed': {
              width: '25%'
            },
            '& .column-status': {
              width: '25%'
            },
            '& .column-start_date': {
              width: '25%'
            } ,
            '& .MuiTableCell-body': {
              borderBottom:'0px'
            },
          }}
          bulkActionButtons={false}
          rowClick={(id,resource,record)=>handlerViewTable(record)}
        >
          <FunctionField source="name" render={(record) => <Typography fontSize={'0.875rem'} fontWeight={'600'}>{record?.name}</Typography>}/>
          <FunctionField label="Complete?" source="completed" render={(record) => 
            <Typography
              sx={{
                fontSize: '.9em',
                fontWeight: '600',
                color: record?.completed ? '#009b00' : '#ffc990',
                textTransform: 'uppercase',
              }}
            >
              {record?.completed ? <IoIosCheckmarkCircle  color='#21B141' size={26}/> : <IoMdCloseCircle color='grey' size={26}/>}
            </Typography> 
          } />
          <FunctionField label="Last submission status" source="status" 
            render={(record) => record?.submission?.status === 'draft'  || record?.submission === null  ? <Indicator data={{status:'not_submitted'}}/> : (<Indicator data={record?.submission} />)}/>
          <FunctionField label="Scheduled" source="start_date" 
            render={(record) => record?.start_date !== null && record?.end_date !== null && record?.location_id !== null  ? 
              <Typography fontSize={'0.875rem'}>Scheduled</Typography>: <Typography fontSize={'0.875rem'}>Unscheduled</Typography>}/>
        </Datagrid>
      </List>
    </div>
  );
};
export const EventsInTalentActions = ({redirect}) =>  {
  const palette = useSelector((state)=>state?.palette?.palette);
  const permission = usePermissions();
  return  (
    <div style={{height:'50px'}}>
      {permission?.permissions !== 'talent_manager' && <ActionButtons sx={{width: '100px', textTransform: 'capitalize',height:'100%'}} noIcon onClick={() => redirect()} label={'+ Add'} palette={palette} />}
    </div>
  );
};
export default TabsEventList;