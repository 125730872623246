import { Box, TextField } from '@mui/material';
import InputDate from '../../InputsForm/InputDate';
import { useInput, useRecordContext } from 'react-admin';
import { InputAddTextInputs } from '../../../Resources/Talents/Form/TalentsListComponents/InputAddTextInputs';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
import { InputTitle } from '../../StyledComponents/InputTitle';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export const createObj = (arr, dayPicked, index = 0) => {
  if(arr) {
    return arr?.map((item, index) => {
      return {key: index, value: item};
    });
  } else {
    return [{ key: index, value: dayjs().format('YYYY-MM-DD')}];
  }
};

export const formatObj = (arr) => {
  return arr?.map(item => item.value);
};

export const InputAddDates = (props) => {
  const {
    source,
    title,
    subtitle
  } = props;

  const {
    field
  } = useInput({
    source,
    defaultValue: [null]
  });
  const [values, setValues] = useState(createObj(field?.value));
  const handleChange = (e) => {
    setValues(e);
    field.onChange(formatObj(e));
  };

  return (
    <>
      {title && <InputTitle>{title}</InputTitle>}
      {subtitle && <InputSubtitle>{subtitle}</InputSubtitle>}
      <InputAddTextInputs 
        initialValue=''
        noPadding={true}
        addText={'+ Add Date'}
        addBelow={true}
        outSideBox={true}
        direction='row'
        boxDisplay="flex"
        boxDirection="row"
        width="100%"
        boxSx={{
          flexWrap: 'wrap',
          maxHeight:'150px',
          overflowY: 'auto',
          marginTop:'10px'
        }}
        sx={{
          width: '45%'
        }}
        element={
          <DateComponent />
        }
        addSx={{
          fontSize:'16px'
        }}
        initialNumber={field?.value?.length}
        limit={5}
        values={values}
        setValues={handleChange}
        label=""
        scroll={true}
      />
    </>
  );
};


const DateComponent = (props) => {

  const {newValues, componentKey, index, handleChange} = props;
  const [value, setvalue] = useState(newValues[index]?.value ?? null);

  useEffect(() => {
    let date = dayjs().format('YYYY-MM-DD');
    if(value !== date) {
      let valueUpdated = false;
      const updatedValues = newValues?.map((item) => {
        if (item.key === index) {
          valueUpdated = true;
          return { ...item, value: value };
        }
        return item;
      });
      if (!valueUpdated) {
        updatedValues.push({ key: index, value: value });
      }
      handleChange(updatedValues);
      return() => {
        valueUpdated = false;
      };
    }
  },[value]);

  return (
    <TextField 
      fullWidth 
      variant="outlined"
      type="date"
      label="" 
      value={value}
      onChange={(e) => setvalue(e.target.value)}
      InputProps={{
        sx: {
          width: '100%',
          borderRadius: '10px',
          backgroundColor: '#f0f0f0',
          '& .MuiInputBase-input':{
            backgroundColor: 'white'
          }
        }}} 
      InputLabelProps={{shrink: true}} 
    />
  );
};