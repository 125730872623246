import React, { useState } from 'react';
import { regex, required, ReferenceInput, SelectInput, ReferenceArrayInput } from 'react-admin';
// Hooks & Utils
import { EventsNameComponent } from '../../Resources/TalentForm/CustomComponents/EventsNameComponent';
import { EVENTS_TOOLTIPS } from '../../Resources/TalentForm/Tooltips';
import { ShowEventsSubmissionModal } from '../../Resources/Events/Form/ShowEventsSubmissionModal';
// Components
import {InputCategoryPills, InputGalleryAws} from '../../Components/RedesignComponents/Inputs/index';
import { InputDurationMinutes } from '../../Components/InputsForm/InputDuration';
import InputDate from '../../Components/InputsForm/InputDate';
import InputButtonAws from '../../Components/InputsForm/InputButtonAws';
import { InputTitle } from '../../Components/StyledComponents/InputTitle';
import { InputSubtitle } from '../../Components/StyledComponents/InputSubtitle';
import InputText from '../../Components/InputsForm/InputText';
import { InputPhoneNumber } from '../../Components/InputsForm/InputPhoneNumber';
import InputAutocomplete from '../../Components/InputsForm/InputAutocomplete';
import SchedulingEvent from '../../Components/RedesignComponents/SchedulingEvent';
import InputTags from '../../Components/RedesignComponents/Inputs/InputTags.jsx';
// Material & Icons
import { FormControl,Typography,Box,Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import { InputImage } from '../../Components/InputsForm/InputImage.jsx';
import { useRequiredSchema } from '../../Hooks/useRequiredSchema.js';
import { breakpoints } from '../../Theme/index.js';
import { linkRegex } from '../../Resources/TalentForm/regex.js';


// Data para CONTACT INFORMATION modal
export const getDataForCreate = (manager, emailRegex) => [
  {
    inputs: [
      {
        field:
        <div style={{width:'100%'}}>
          <InputTitle sx={{fontSize:'2em',textTransform:'none'}}>Invite new talent manager</InputTitle>
        </div>,
      },
      {
        field: (
          <>
            <InputTitle>Name</InputTitle>
            <InputSubtitle>Enter the full name for a new talent managment collaborator</InputSubtitle>
            <InputText
              variant="outlined"
              defaultValue={manager?.name || ''}
              validate={[required('The Name is required.')]}
              source="name"
              label=""
              fullWidth
            />
          </>
        ),
        breakpoints: { xs: 8 },
      },
      {
        field: (
          // <FormControl fullWidth>
          <>
            <InputTitle>Role</InputTitle>
            <InputSubtitle>Select the role of this talent manager</InputSubtitle>
            <InputCategoryPills    
              title=''
              source='manager_role'
              reference='admins/manager_roles'
              label=""
              filter={{ type: 'role' }}
              validate={[required('The Role is required.')]}
              required={true}
              optionText='text'
              optionValue='text' />
          </>
           
        // </FormControl>
        ),
      },
      {
        field: (
          <>
            <InputTitle>Email</InputTitle>
            <InputText
              variant="outlined"
              defaultValue={manager?.email || ''}
              validate={[required('The Email is required.'), regex(emailRegex, 'Not a valid email')]}
              source="email"
              label=""
              fullWidth
            />
          </>
        ),
        breakpoints: { xs: 8 },
      },
   
    ],
  },
];
export const getDataForEditDelete = (thisManagerName, manager, emailRegex) => [
  {
    inputs: [
      {
        field:
        <div style={{width:'100%'}}>
          <InputTitle sx={{fontSize:'2em',textTransform:'none'}}>Edit {manager && manager.name}</InputTitle>
        </div>,
      },
      {
        field: (
          <>
            <InputTitle>
              {thisManagerName ? 'Your Name' : 'Talent Manager\'s Name'}
            </InputTitle>
            <InputText
              variant="outlined"
              defaultValue={manager?.name || ''}
              validate={[required('The name is required.')]}
              source="name"
              label=""
              fullWidth
            />
          </>
        ),
        breakpoints: { xs: 8 },
      },
      {
        field: (
          <>
            <FormControl fullWidth>
              <InputPhoneNumber
                thisManagerName={thisManagerName}
                variant="outlined"
                defaultValue={manager?.phone || ''}
                source="phone"
                label=""
              />
            </FormControl>
          </>
        ),
      },
      {
        field: (
          <>
            <InputTitle sx={{ marginTop: '25px' }}>
              {thisManagerName ? 'Your Email' : 'Talent Manager\'s Email'}
            </InputTitle>

            <InputText
              variant="outlined"
              defaultValue={manager?.email || ''}
              validate={[required('The Email is required.'), regex(emailRegex, 'Not a valid email')]}
              source="email"
              label=""
              fullWidth
            />
          </>
        ),
        breakpoints: { xs: 8 },
      },
      {
        field: (
          <FormControl fullWidth>
            <InputTitle>
              {thisManagerName ? 'Your Role' : 'Talent Manager\'s Role'}
            </InputTitle>
            <InputCategoryPills
              isEdit={true}    
              title=''
              source='manager_role'
              reference='admins/manager_roles'
              label=""
              filter={{ type: 'role' }}
              validate={[required('The role is required.')]}
              optionText='text'
              optionValue='text' />
          </FormControl>
        ),
      },
    ],
  },
];
// Data del antiguo TalentsEventForm >
export const getTalentsEvenData = (
  permis,
  talentName,
  talentNameTM,
  isFetching
) => {
  const [talentMessage, setTalentMessage] = useState(null);

  return [
    {
      title: `${
        permis !== 'talent_manager' ? talentName : talentNameTM
      } Events details`,
      inputs: [
        {
          field: (
            <>
              <InputTitle>EVENTS CATEGORY</InputTitle>
              <ReferenceInput source="category" reference="events/categories">
                <SelectInput
                  validate={[required('The category is required.')]}
                  fullWidth
                  InputProps={{ sx: { borderRadius: '10px' } }}
                  source="category"
                  optionValue="text"
                  label=""
                  optionText={(option) => {
                    return option.text;
                  }}
                  variant="outlined"
                />
              </ReferenceInput>
            </>
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: <EventsNameComponent isLoading={isFetching} />,
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputText
              subtitle={EVENTS_TOOLTIPS['description']}
              title="Events / workshop description"
              label=""
              multiline
              minRows={6}
              fullWidth
              source="description"
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: 
              <ReferenceArrayInput source="tags" reference="events/tags">
                <InputAutocomplete
                  title='Tags'
                  InputProps={{ sx: { borderRadius: '10px' } }}
                  source="tags"
                  label=""
                  optionValue="text"
                  isArray
                  optionText="text"
                  fullWidth
                  validate={[required('The tags is required.')]}
                />
              </ReferenceArrayInput>,
          breakpoints: { xs: 6 },
        },
        {
          field: (
            <>
              <InputTitle>Events Image</InputTitle>
              <InputSubtitle>
                You can upload a custom image for your event. If you don&apos;t
                upload an image, your profile picture will be shown.
              </InputSubtitle>
              <InputButtonAws
                sx={{ marginTop: '20px' }}
                source={'picture.original'}
              />
            </>
          ),
          // tooltip: EVENTS_TOOLTIPS['picture'],
          breakpoints: { xs: 12 },
        },
        {
          field: <ReferenceArrayInput reference="talents" source="talents_ids">
            <InputAutocomplete
              title="Talent"
              required={true}
              subtitle={EVENTS_TOOLTIPS['talentName']}
              freeSolo={false}
              optionText="name"
              isArray
              label=""
              fullWidth
              disabled={permis !== 'talent_manager' ? false : true}
              validate={permis !== 'talent_manager' ? [required('The talent is required.')] : null}
              onChange={(e, v) => setTalentMessage(v?.find(item => !item.completed))}
            />
          </ReferenceArrayInput>,
          breakpoints: { xs: 3 },
        },
        {
          field: (
            <ReferenceArrayInput reference="talents" source="collaborators_ids">
              <InputAutocomplete
                placeholder="Collaborators-TBD"
                freeSolo={false}
                popupIcon={''}
                optionText="name"
                disabled
                multiple
                subtitle={
                  'In case collaborators have been assigned to your event, this is where you can find them.'
                }
                title=" COLLABORATORS"
                label=""
                fullWidth
              />
            </ReferenceArrayInput>
          ),
          breakpoints: { xs: 12 },
        },
      ],
    },
    {
      title: 'Workshops Information for Guests',
      inputs: [
        {
          field: (
            <InputText
              validate={[required('The what to expect input is required.')]}
              subtitle={EVENTS_TOOLTIPS['what_to_expect']}
              label=""
              title="What to expect"
              fullWidth
              multiline
              minRows={2}
              source="what_to_expect"
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputText
              validate={[required('The "Good to know" input is required.')]}
              subtitle={EVENTS_TOOLTIPS['good_to_know']}
              label=""
              title="Good to know"
              multiline
              minRows={2}
              fullWidth
              source="good_to_know"
            />
          ),
          breakpoints: { xs: 12 },
        },
      ],
    },
    {
      title: 'Events Schedule',
      inputs: [
        {
          field: (
            <InputDurationMinutes
              disabled={permis === 'talent_manager'}
              label="Duration"
              source="duration"
              InputProps={{ sx: { borderRadius: '10px' } }}
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <>
              <InputTitle>Stage / Area</InputTitle>
              <InputSubtitle>{EVENTS_TOOLTIPS['stage']}</InputSubtitle>
              <ReferenceInput
                source="location_id"
                reference="locations"
                filter={{ event_ready: true }}
              >
                <InputAutocomplete
                  disabled={permis === 'talent_manager'}
                  optionText="name"
                  label=""
                  fullWidth
                />
              </ReferenceInput>
            </>
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <>
              <InputTitle>Start date</InputTitle>
              <InputDate
                InputLabelProps={{ sx: { display: 'none' } }}
                label=""
                fullWidth
                inputTime
                disabled
                source="start_date"
              />
            </>
          ),
          breakpoints: { xs: 6 },
        },
        {
          field: (
            <>
              <InputTitle>End date</InputTitle>
              <InputDate
                InputLabelProps={{ sx: { display: 'none' } }}
                variant="outlined"
                label={''}
                inputTime
                fullWidth
                disabled
                source="end_date"
              />
            </>
          ),
          breakpoints: { xs: 6 },
        },
       
      ],
    },
  ];
};
// From es el diferenciador entre Eventos desde Events > Active y/ Talents > Active > Events
export const getFullData = (args) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const { isRequiredSchema } = useRequiredSchema();

  const {
    permis,
    isFetching,
    editedFields,
  } = args;

  const pendingStyledField = (source) => {
    if(editedFields){
      if (editedFields?.includes(source)) return { color: 'blue', '-webkit-text-fill-color': 'blue' };
    }
    return;
  };
  
  return [
    {
      noShow: permis !== 'talent_manager',
      title:'submission',
      inputs:[
        {
          field: (
            <ShowEventsSubmissionModal />
          ),
          breakpoints: { xs: 2 },
        },
      ]
    },
    {
      title: 'event details',
      inputs: [
        {
          field:  permis !== 'talent_manager' ?
            <InputText
              subtitle={EVENTS_TOOLTIPS['eventName']}
              title="Event name"
              fullWidth
              label=""
              source="name"
              required={true}
              validate={[required('The event name is required')]}
            />  
            : <EventsNameComponent isLoading={isFetching} />,
          breakpoints: { xs: 8 },
        },
        {
          field:  <ReferenceArrayInput reference="talents" source="talents_ids">
            <InputAutocomplete
              title="Talent"
              subtitle={EVENTS_TOOLTIPS['talentName']}
              optionText="name"
              isArray
              label=""
              fullWidth
              disabled={permis !== 'talent_manager' ? false : true}
              validate={permis !== 'talent_manager' ? [...isRequiredSchema('talents_ids')] : null}
              required={isRequiredSchema('talents_ids').length > 0 ? true : false}
              TextFieldProps={{InputProps: {style: {height: 'auto',padding:'1px'}}}}
              limitTags={1}
            />
          </ReferenceArrayInput>,
          breakpoints: { xs: 4 },
        },
        {
          field: (
            <>
              <InputCategoryPills
                fullWidth
                source={'category'}
                reference='events/categories'
                label=""
                title='Category'
                subtitle={EVENTS_TOOLTIPS['mainCategory']}
                inputSx={pendingStyledField('description')}
              />
            </>
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputText
              subtitle={EVENTS_TOOLTIPS['description']}
              title="Event / workshop description"
              label=""
              multiline
              minRows={4}
              counter={1200}
              source="description"
              fullWidth
              required={true}
              inputSx={pendingStyledField('description')}
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: 
          <InputImage
            title={'Event Image'}
            subtitle={EVENTS_TOOLTIPS['eventImage']}
            source='picture'
            modalOn={true}
          />,
          source: 'picture',
          breakpoints: { xs: 12 },
        },
        {
          field: 
          <>
            <InputTitle>
            Gallery
            </InputTitle>
            <InputGalleryAws picture limit={15} source={'gallery'} />
          </>,
          source: 'gallery',
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputTags
              sx={pendingStyledField('tags')}
              title="Tags"
              subtitle={EVENTS_TOOLTIPS['tags']}
              source="tags"
              label=""
              reference={'events/tags'}
            />
          ),
          breakpoints: { xs: 12 },
        },
        
      ],
    },
    {
      title: '  WORKSHOPS INFORMATION FOR GUESTS',
      inputs: [
        {
          field: (
            <InputText
              // validate={[required()]}
              subtitle={EVENTS_TOOLTIPS['what_to_expect']}
              label=""
              title="What to expect"
              fullWidth
              multiline
              minRows={2}
              source="what_to_expect"
              inputSx={pendingStyledField('what_to_expect')}
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputText
              // validate={[required()]}
              subtitle={EVENTS_TOOLTIPS['good_to_know']}
              label=""
              title="Good to know"
              multiline
              minRows={2}
              fullWidth
              source="good_to_know"
              inputSx={pendingStyledField('good_to_know')}
            />
          ),
          breakpoints: { xs: 12},
        },
      ],
    },
    {
      title: 'Booking Details',
      inputs: [
        {
          field: (
            <InputText
              validate={[regex(/^\d+(\.\d+)?$/, 'Price must be a number')]}
              subtitle={EVENTS_TOOLTIPS['price']}
              label=""
              title="Price"
              fullWidth
              source="price"
              inputSx={pendingStyledField('price')}
            />
          ),
          breakpoints: {xs: 3}
        },
        {
          field: (
            <InputText
              validate={[regex(linkRegex, ' "Oops, looks like your link does not match the required structure. Have you tried https://[your_link].com?"')]}
              subtitle={EVENTS_TOOLTIPS['price']}
              label=""
              title="Book url"
              fullWidth
              source="book_url"
              inputSx={pendingStyledField('price')}
            />
          ),
          breakpoints: {xs: 9}
        },
      ]
    },
    {
      title: 'External content',
      inputs: [
        {
          field: (
            <InputImage
              action="icon"
              title={'Icon'}
              // subtitle={EVENTS_TOOLTIPS['external_content']['icon']}
              source='external_content.icon'
            />
          ),
          source: 'external_content',
          breakpoints: {xs: 2}

        },
        {
          field: (
            <InputText
              subtitle={EVENTS_TOOLTIPS['external_content']['url']}
              label=""
              validate={[regex(linkRegex, ' "Oops, looks like your link does not match the required structure. Have you tried https://[your_link].com?"')]}
              title="url"
              fullWidth
              source="external_content.url"
            />
          ),
          source: 'external_content',
          breakpoints: {xs: 10}
        },
        {
          field: (
            <InputText
              subtitle={EVENTS_TOOLTIPS['external_content']['text']}
              label=""
              title="text"
              fullWidth
              multiline
              minRows={2}
              source="external_content.text"
            />
          ),
          source: 'external_content',
          breakpoints: {xs: 12}
        }
      ]
    },
    {
      noShow: permis !== 'talent_manager' ?false : true, 
      title: 'EVENT SCHEDULE',
      inputs: [
        {
          field: (
            <InputDurationMinutes
              title="Duration (IN MINUTES)"
              source="duration"
              sx={{'& .MuiOutlinedInput-input':{padding:'0px 9px',height:'40px'}}}
              InputProps={{ sx: { borderRadius: '10px' } }}
            />
          ),
          breakpoints: { xs: 12},
        },
      ],
    },
    {
      noShow: permis !== 'talent_manager' ? true : false, 
      title:'',
      inputs:[
        {
          field: (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize={'16px'}
                  color={palette?.primary}
                >
                   SCHEDULING
                </Typography>
              </Box>
            </div>
          ),
          breakpoints: { xs: 3 },
        },
        {
          field:(
            <SchedulingEvent source={'stage'}/>
          ),
          breakpoints: { xs: 12 },
        }

      ]
    }
  ];
  
};