import React from 'react';
import { SelectInput } from 'react-admin';
import { INPUTS_VARIANT } from '../../assets/constants/Form';
import { useFormContext } from 'react-hook-form';
import { InputTitle } from '../StyledComponents/InputTitle';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
const InputSelect = ({
  approvedFields, 
  textTransform, 
  title, 
  subtitle,
  watchField, 
  optionText = 'text', 
  optionValue = 'text',
  disabledInside,
  required,
  linking,
  ...rest 
}) => {
  const { formState, watch,getValues } = useFormContext();
  let valueNotification =linking && linking;
  
  return (
    <>
      {title && 
    <InputTitle
      textTransform={textTransform}
      required={required}
      sx={{
        display: !watchField ? 'block' : watch(watchField) === 'true' || approvedFields?.includes(watch(watchField)) ? 'block' : 'none',
      }}
    >{title}</InputTitle>}
      {subtitle &&  
      <InputSubtitle {...rest}>{subtitle}</InputSubtitle> 
      }
      {rest.source !== 'linking_page' ? <SelectInput
        variant={INPUTS_VARIANT}
        optionText={optionText} 
        optionValue={optionValue} 
        {...rest} 
        sx={{
          display: !watchField ? 'block' : watch(watchField) === 'true' || approvedFields?.includes(watch(watchField)) ? 'block' : 'none',
          ...rest.sx, 

        }}
        InputProps={{
          sx: {
            borderRadius: '10px',
          },
          ...rest.InputProps
        }}
      />:valueNotification &&
        <SelectInput
          variant={INPUTS_VARIANT}
          optionText={optionText} 
          optionValue={optionValue} 
          {...rest} 
          sx={{
            display: !watchField ? 'block' : watch(watchField) === 'true' || approvedFields?.includes(watch(watchField)) ? 'block' : 'none',
            ...rest.sx, 

          }}
          InputProps={{
            disabled: disabledInside,
            sx: {
              borderRadius: '10px',
            },
            ...rest.InputProps
          }}
        />
    
      }
    </>

  );
};

export default InputSelect;