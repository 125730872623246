
export function arraySource(obj, path) {
  // Split the path string into an array of keys
  const keys = path.split('.');

  // Iterate through the keys to access nested properties
  let current = obj;
  for (const key of keys) {
    // Check if the current object has the key
    // eslint-disable-next-line no-prototype-builtins
    if (current.hasOwnProperty(key)) {
      // Update 'current' to the nested object
      current = current[key];
    } else {
      // If any key in the path is missing, return undefined
      return undefined;
    }
  }
  // Return the value of the nested property
  return current;
}