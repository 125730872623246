// Components
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
import { InputAddLinkItems } from '../Components/InputAddLinkItems.jsx';

export const HighlightedExternalContent = () => {
  // Manejo de cantidades de formularios

  const data = [
    {
      inputs: [
        {
          field: (
            <InputAddLinkItems
              source="home.highlighted_external.items"
              limit={10}
              noDisableRemove={true}
            />
          ),
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];

  return {
    data: data,
  };
};
