// Components
import InputDate from '../../../../Components/InputsForm/InputDate';
import { ColorPickerTextField } from '../../../../Components/InputsForm/InputColorPicker';
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
import InputText from '../../../../Components/InputsForm/InputText';
// Material & Icons
import {Typography} from '@mui/material';
import { InputImage } from '../../../../Components/InputsForm/InputImage';

export const CountdownForm = () => {


  const data = [
    {
      inputs: [
        {
          field: <InputText
            variant="outlined" 
            fullWidth 
            source="home.countdown.title" 
            title="title"
            label=""
            
          //  label={<Typography fontWeight={600} fontSize={'16px'} color={'#000'}> TITLE</Typography>}
          />,
          breakpoints: {xs: 5}
        },
        {
          field: <InputText
            fullWidth
            source="home.countdown.subtitle"
            label=""
            
            title="subtitle"
            //  label={<Typography fontWeight={600} fontSize={'16px'} color={'#000'}> SUBTITLE</Typography>}
          />,
          breakpoints: { xs: 5}
        },
        {
          field: 
          <InputImage
            title="Background Image"
            source='home.countdown.background_image'
          />
          ,
          breakpoints: {xs: 5}
        },
        {
          field: <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
            <ColorPickerTextField source="home.countdown.styles.font_color" label='' title="FONT COLOR" sizeBox={{width:'250px'}} hexFormat/>
            <ColorPickerTextField label="" source="home.countdown.styles.background_color" title='TIME BACKGROUND COLOR' sizeBox={{width:'250px'}} hexFormat/>
          </div>,
          breakpoints: {xs: 5}
        },
        {
          field: <InputDate fullWidth inputTime source="home.countdown.end_date" title="end date" label='' />,
          breakpoints: {xs: 5}
        },
        {
          field: <ColorPickerTextField label="" source="home.countdown.cta_background" title='CTA BACKGROUND' sizeBox={{width:'250px'}} hexFormat/>,
          breakpoints: {xs: 5}
        },
        {
          field: <InputText fullWidth source="home.countdown.call_to_action_text" label='' title={<Typography fontWeight={600} fontSize={'16px'} color={'#000'}> CTA TEXT</Typography>} />,
          breakpoints: {xs: 5}
        },
        {
          field: <InputText title={<Typography fontWeight={600} fontSize={'16px'} color={'#000'}> CTA LINK</Typography>} fullWidth source="home.countdown.call_to_action_link" label='' />,
          breakpoints: {xs: 5}
        },
      ]
    },
  ];

  return {
    data: data
  };
};