import React, { useEffect, useMemo } from 'react';
import { useGetList ,useInput} from 'react-admin';
import InputAutocomplete from '../../InputsForm/InputAutocomplete';
import { useFormContext } from 'react-hook-form';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';

export const InputDeepLinking = ({
  choices,
  approvedFields,
  textTransform,
  title,
  watchField,
  optionText = 'text',
  optionValue = 'text',
  source,
  linking,
  valueUrl,
  appConfig,
  ...rest
}) => {
  const { watch, setValue} = useFormContext();
  const { id, field, fieldState } = useInput({ source });
  
  // Este valor es unicamente local, nunca se guarda en la bd, me sirve para tener una referencia a como crear la URL.
  let linkingPage = linking;
  // Este valor lo utilizo para rellenar en los edits el defaultValue
  const contructUrl = () => {
    let url = '';
    if (linkingPage) {
      url = appConfig && `${appConfig[0]?.app_slug}://${linkingPage.toLowerCase()}`;
      console.log(url);
    }
    return url;
  };
  const pickedOptionText = () => {
    switch (linkingPage) {
    case 'Articles':
      return 'title';
    case 'FAQs':
      return 'question';
    default :
      return 'name';
    }
  };
 
  const setFormValue = (value,record) => {
    console.log(value,'1');
    let baseUrl = contructUrl();
    console.log(baseUrl,'valor');
    if(value === '-' || value === ''){
      console.log('aca first');
      const finalUrl = `${baseUrl}`;
      field.onChange(finalUrl);
    }else{
      console.log('aca second');
      const finalUrl = `${baseUrl}/${value}`;
      field.onChange(finalUrl);
      console.log(finalUrl);
    }
  };
  const { data, isFetching } = useGetList(linkingPage && `${linkingPage.toLowerCase()}`, { pagination: {perPage:9999, page: 1}, sort: { field: 'rank', order: 'DESC'}});
  
  const defaultData = () => {
    
    switch (linkingPage) {
    case 'Articles':
      return [{id:'list',title:'List'},...data];
    case 'FAQs':
      return [{id:'list',question:'List'},...data];
    case 'Events':
      return [{id:'list', name:'List'},{id:'timeline', name:'Schedule'},...data];
    default :
      return [{id:'list',name:'List'},...data];
    }
  };
  useEffect(() => {
    field.onChange(contructUrl());
    console.log('Use Effect paso');
  },[linking]);

  return (
    <>
      {linkingPage !== 'Excursions' && linkingPage !== 'FAQs' && linkingPage !== 'More' ?
        data && !isFetching ?(        
          <InputAutocomplete
            defaultValue={valueUrl}
            source='events'
            choices={linkingPage !== 'More' && linkingPage !== 'Excursions' && linkingPage !== 'FAQs' &&
            defaultData()}
            freeSolo={false}
            optionText={pickedOptionText()}
            label=""
            onChange={setFormValue}
            {...rest}
          />
        ): <Skeleton variant="rectangular" width={300} height={40} sx={{marginTop:1, borderRadius: '10px' }} />
        :
        <InputAutocomplete
          defaultValue={valueUrl}
          source=''
          choices={[{ id: '-', name: '-'}]}
          freeSolo={false}
          optionText={'name'}
          label=""
          onChange={setFormValue}
          {...rest}
        />
      }
    </>
  );
};
