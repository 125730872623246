import { useRecordContext } from 'react-admin';
import CardComponent from '../../../../Components/ShowComponents/CardComponent';
import { FieldTypo } from './FieldTypo';
import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { styledCard } from '../../../../Components/ShowComponents/CssProperties';
import { ImageButton } from '../../../../Components/ShowComponents/ImageButton';
import PlaceHolder from '../../../../assets/images/place holder.png';



export const ImagesGallery = ({switchBody}) => {
  const record = useRecordContext();
  const [pendingBody, setPendingBody] = useState(record?.submission?.status === 'pending' ? 
    record?.gallery : record?.submission?.gallery
  );

  useEffect(() => {
 
    if(switchBody){
      setPendingBody(record?.submission?.gallery ?? null);
    } else {
      setPendingBody(record?.gallery);
    }
  },[switchBody]);

  return(
    <CardComponent
      title="Images Gallery"
      sx={{
        margin: '0px',
        alignItems: 'start',
        justifyContent: 'start',
        flexDirection: 'row',
      }}>
      {
        pendingBody ? (
          <>
            {
              record?.gallery?.map((item, i) => (
                <OpenImage
                  pendingBody={pendingBody}
                  item={item}
                  index={i}
                />
              ))
            }
          </>
        ) : (
          <Typography sx={{ padding: '50px', textAlign: 'center', width: '100%', textTransform: 'uppercase', fontSize: '.9em', color: '#606060', fontWeight: '600'}}>No data found</Typography>
        )
      }
    </CardComponent>
  );
};

export const OpenImage = ({switchBody, item, index}) => {

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <img 
        src={item?.original ?? PlaceHolder}
        alt={index + 1}
        loading="lazy"
        style={{ width: '150px', height: '150px', objectFit: 'contain'}}
      />
      <ImageButton 
        size="20px" 
        sx={{
          color: !switchBody ?
            'yellow' :
            'black',
          backgroundColor: !switchBody ?
            '#606060' :
            '#fff', 
          padding: '20px',
          top: 5,
          left: 95,
          height: 'auto',
          width: 'auto'
        }} 
        image={item?.original}

      />

    </Box>
  );
};