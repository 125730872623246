import {  TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { memo, useEffect, useState } from 'react';
import { Box, FormHelperText } from '@mui/material';

const InputTimePicker = ({
  value,
  onChange,
  // minTimeSet,
  handleMinTime,
  row,
  maxTime,
  state,
  helperText,
  endsNextDay,
  field,
  ...rest
}) => {
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const date = maxTime ? dayjs(row).add(1, 'days').format('YYYY-MM-DD') : dayjs(row).format('YYYY-MM-DD');
    const time = e.format('THH:mm:ss');
    const dateTime = date + time;

    if(endsNextDay){
      onChange({...value, [field]: dateTime});
      // handleMinTime && handleMinTime(true, dateTime);
    }else{
      onChange({...value, [field]: dateTime});
    }
  };

  useEffect(() => {
    if(rest.error === value[field]){
      setError('Invalid date');
    }
  },[rest.error]);
  
  return(
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 70,
      }}
    >
      <TimePicker
        // minTime={minTimeSet}
        maxTime={maxTime ? maxTime : null}
        value={dayjs(value[field])}
        onChange={handleChange}
        onError={(e,v) => {
          if(e === 'minTime'){
            return setError('Open time must be after the days before close time.');
          }
          if(e === 'maxTime'){
            return setError('Close time cant be greater than 7:00AM');
          }
          return setError(null);
        }}
        slotProps={{
          textField: {
            error: !!error,
            inputProps: {
              style: {
                padding: '10px',
                width: '100%'
              }
            },
            variant: 'outlined'
          }
        }}
        // ampm={false}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }} 
        {...rest} />
      {error && <FormHelperText sx={{color: 'red'}}>{error}</FormHelperText>}
    </Box>
      
  );
};

export default memo(InputTimePicker);