import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetList, useResourceContext } from 'react-admin';
//Hooks & Utils
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { dynamicColor } from '../../helpers/dynamicColor';
//Components
import dataProvider from '../../dataProvider';
//Material & Icons
import { Box, Modal, useMediaQuery } from '@mui/material';
import styles from './OrderModal.module.css';
import { orderFunctions } from './orderModalFunctions';
import { MdDragIndicator } from 'react-icons/md';
import { Button } from '../Generics';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  backgroundColor: '#fff',
  borderRadius: '10px',
  boxShadow: 24,
  p: '1em 3em',
  height: '700px',
};

const OrderModalComponent = ({ actionText }) => {
  const [openModal, setOpenModal] = useState(false);
  const resource = useResourceContext();
  const palette = useSelector((state) => state?.palette?.palette);
  const dynamicBg = dynamicColor(palette?.primary, 0.2).rgba;
  const { data, refetch, isRefetching } = useGetList(resource, {
    filter: resource === 'locations' ? { event_ready: true } : {},
    pagination: { perPage: 9999, page: 1 },
    sort: { field: 'rank', order: 'DESC' },
  }); 
  const handleClick = () => {
    setOpenModal(true);
    refetch();
  };
  return (
    <>
      <Button
        style={{
          width: '100px',
          textTransform: 'capitalize',
          backgroundColor: dynamicBg,
          color: palette?.primary,
          fontWeight: 500,
          fontSize: '0.875rem',
          lineHeight: '1.75',
          borderRadius: '10px',
          height: '45px',
        }}
        hoverStyle={{
          backgroundColor: '#ffffff',
          color: '#000000',
        }}
        onClick={handleClick}
      >
        Order
      </Button>
      {openModal && (
        <OrderModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          data={data}
          isRefetching={isRefetching}
          actionText={actionText}
        />
      )}
    </>
  );
};
const OrderModal = ({
  openModal,
  setOpenModal,
  data,
  isRefetching,
  actionText,
}) => {
  const customToast = useCustomToasty();
  const [cards, setCards] = useState();
  const resource = useResourceContext();
  const [hoverCard, setHoverCard] = useState(null);
  const [draggedCard, setDraggedCard] = useState(null);
  const isNetbook = useMediaQuery('(max-height:600px)');
  const isNotebook = useMediaQuery('(max-height:820px)');
  const isMonitor = useMediaQuery('(min-height:821px)');

  const responsiveHeight = () => {
    switch (true) {
    case isNetbook:
      return '550px';
    case isNotebook:
      return '600px';
    case isMonitor:
      return '700px';
    default:
      return '700px';
    }
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const { handleDragOver, handleDragStart, handleDrop } = orderFunctions({
    cards,
    customToast,
    setCards,
    dataProvider,
    resource,
    setHoverCard,
    setDraggedCard,
    draggedCard,
  });
  useEffect(() => {
    setCards(data);
  }, [isRefetching]);

  const pickTitle = () => {
    switch (resource) {
    case 'locations':
      return 'Order Event Ready Locations';
    case 'faqs':
      return 'Order FAQs';
    default:
      return `Order ${resource}`;
    }
  };
  const pickDescription = () => {
    switch (resource) {
    case 'locations':
      return 'Drag and drop event ready locations to order how they display in your app.';
    case 'faqs':
      return 'Drag and drop FAQs to order how they display in your app.';
    default:
      return `Drag and drop ${resource} to order how they display in your app.`;
    }
  };
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ overflowY: 'auto' }}
    >
      <Box sx={{ ...style, height: responsiveHeight() }}>
        <div className={styles.textContainer}>
          <p style={{ fontWeight: 600, fontSize: '24px' }}>{pickTitle()}</p>
          <p>{pickDescription()}</p>
          <p
            style={{
              borderBottom: '1px solid #b9b7b7',
              color: 'grey',
            }}
          >
            Name
          </p>
        </div>
        {isRefetching ? (
          <div className={styles.loaderContainer}>
            <div className='progress-container'>
              <div className='progress'></div>
            </div>
          </div>
        ) : (
          <div className={styles.cardContainer}>
            {cards?.map((card, index) => (
              <div
                key={card?.id}
                className={`${styles.card} ${hoverCard === index ? styles.cardHover : ''} ${draggedCard === index ? styles.cardDragged : ''}`}
                style={{ marginTop: index === 0 ? '5px' : '0px' }}
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={(e) => handleDragOver(e, index)}
                onDragLeave={() => setHoverCard(null)}
                onDrop={(e) => handleDrop(e, index)}
              >
                <div
                  className={`${styles.cardLeftStyle} ${hoverCard === index ? styles.cardLeftStyleHover : styles.cardLeftStyle}`}
                >
                  <MdDragIndicator />
                </div>
                <p>{card[actionText]}</p>
              </div>
            ))}
          </div>
        )}
      </Box>
    </Modal>
  );
};
export default OrderModalComponent;
