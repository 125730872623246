import React, { memo, useMemo, useRef } from 'react';
import { useOnClickOutside } from '../../../Hooks/useOnClickOutside';
import { Box } from '@mui/material';
import PropType from 'prop-types';

const EditModal = (props) => {

  const {
    placement,
    EditInputs,
    setOpen,
    parentRef,
  } = props;
  
  const newRef = useRef(false);
  useOnClickOutside(newRef, () => setOpen(false));
 
  const placementProps = useMemo(() => {
    switch(true){
    case placement === 'top-right':
      return {
        bottom: 20,
        left: 100,
        transform: 'translate(0%, 0%)'
      };
    case placement === 'right-bottom':
      return {
        top: 50,
        left: 100,
        transform: 'translate(0%, 0%)'
      };
    case placement === 'left-bottom':
      return {
        top: 50,
        right: 0,
        transform: 'translate(0%, 0%)'
      };
    case placement === 'bottom':
      return {
        top: 50,
        left: '50%',
        transform: 'translate(-50%, 0%)'
      };
    case placement === 'top':
      return {
        bottom: 50,
        left: '50%',
        transform: 'translate(-50%, 0%)'
      };
    case placement === 'left':
      return {
        top: '50%',
        right: 50,
        transform: 'translate(0%, -50%)'
      };
    case placement === 'right':
      return {
        top: '50%',
        left: 0,
        transform: 'translate(25%, -50%)'
      };
    }
  },[parentRef]);
  
  
  return (
    <Box 
      ref={newRef}
      sx={{
        position: 'absolute',
        width: 'auto',
        height: 'auto',
        backgroundColor: '#fff',
        outline: '1px solid black',
        borderRadius: 1,
        padding: 2,
        cursor: 'auto',
        zIndex: 10000,
        ...placementProps
      }}>
      <EditInputs {...props} />
    </Box>
  );
};
  
export default memo(EditModal);
  
EditModal.proptype = {
  placement: PropType.oneOf(['left', 'right', 'top', 'bottom']),
  EditInputs: PropType.element.isRequired
};
  
EditModal.defaultProps = {
  placement: 'right',
};