import React, { useMemo, useState } from 'react';
import { useInput } from 'react-admin';
import { BannerInputs } from './BannerInputs';
import { Box } from '@mui/material';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';
import { ImageShow } from './ImageShow';
import { useSelector } from 'react-redux';
import { Button } from '../../../../Components/Generics/index';

export const BannerItems = (props) => {

  const {
    source
  } = props;
  const { field } = useInput({source, defaultValue: []});
  const [open, setOpen] = useState(0);
    
  const onDelete = (index) => {
    let newArr = field.value?.filter((_, i) => i !== index);
    field.onChange(newArr);
  };

  const addBanner = () => {
    let updatedArr = field?.value.concat({
      title: '',
      size: 1,
      font_color: '',
      background_color: '',
      link: '',
      image: {}
    });
    field.onChange(updatedArr);
    setOpen(field?.value.length);
  };

  const MemoInputs = useMemo(() => {
    return (
      <BannerInputs {...props} value={field?.value} onChange={field.onChange} index={open} />
    );
  }, [field, open]);
    
  const MemoImage = useMemo(() => { 
    return (
      <>
        {
          field?.value?.map((item, index) => {
            return (
              <div onClick={() => setOpen(index)} style={{width:'100%'}}>
                <ImageShow index={index} onDelete={onDelete} selected={open === index} src={field?.value[index]?.image?.original} item={field?.value[index]}/>
              </div>
            );
          })
        }
      </>
    );}, [field]);
  
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%'
    }}>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '10px'}}>
        <InputTitle sx={{marginBottom: '20px'}}>Banners</InputTitle>
        {MemoImage}
        <AddBanner onClick={addBanner} />
      </Box>
      <Box>
        <InputTitle sx={{marginBottom: '20px'}}>Edit banner</InputTitle>
        {MemoInputs}
      </Box>
    </Box>
  );
};
    
  
const AddBanner = (props) => {
  const {
    onClick
  } = props;
  const palette = useSelector((state) => state?.palette?.palette);
  return (
    <Button
      style={{
        fontWeight: 600,
        backgroundColor: palette?.primary,
        color:'#ffffff'
      }}
      hoverStyle={{ backgroundColor: palette?.secondary }}
      onClick={onClick}
    >
      Add Banner
    </Button>
  );
};