export const onDrag = (e, id, _callback) => {
  e.dataTransfer.setData('text/plain', id);
  _callback && _callback(true);
};
  
export const idString = (e) => {
  
  let target = e.target;
  let idBoolean = target.id.includes('draggable');
  let id = idBoolean ? target.id : target.parentElement.id.includes('draggable') ? target.parentElement.id : target.firstChild.id;
  const boundingRect = idBoolean ?  e.target.getBoundingClientRect() : e.target.parentElement.getBoundingClientRect();
  const mid = boundingRect.bottom - (boundingRect.height / 2);
    
  let parts = id.split('-');
  const number = parseInt(parts.pop());
  const parsedNumber = e.clientY > mid ? number : number - 1;
  const newString = parts.join('-') + `-${parsedNumber}`;
  return {
    id,
    newString,
    number: parsedNumber
  };
};
  
export const onDragOver = (e, _callback) => {
  const { id, newString } = idString(e);
  _callback && _callback(!['', null].includes(id) && newString);
  e.stopPropagation();
  e.preventDefault();
};
  
export const drop = (e, _callback) => {
    
  e.preventDefault();
  _callback && _callback(false);
  const { number } = idString(e);
  const data = e.dataTransfer.getData('text');
  return {
    selected: data,
    dropped: number
  };
};


export const moveElement = (arr, fromIndex, toIndex) => {
  let newArr = arr;
  if (fromIndex === toIndex) {
    return arr; 
  }
  if (fromIndex < 0 || fromIndex >= newArr.length || toIndex < 0 || toIndex >= newArr.length) {
    throw new Error('error index');
  }
  const element = newArr[fromIndex];
  if (fromIndex < toIndex) {
    for (let i = fromIndex; i < toIndex; i++) {
      newArr[i] = newArr[i + 1];
    }
  } else {
    for (let i = fromIndex; i > toIndex; i--) {
      newArr[i] = newArr[i - 1];
    }
  }
  newArr[toIndex] = element;

  return newArr;
};

export const reorderRank = (arr) => {
  return arr.sort((a,b) => a.rank > b.rank ? 1 : -1);
};