import { regex, required } from 'react-admin';
import { LocationMapbox } from '../../../../Components/LocationComponents/LocationMapbox';
import { TimezoneSelect } from '../../../../Components/InputsForm/TimezoneSelect';
import InputText from '../../../../Components/InputsForm/InputText';


export const locationInformation = () => {
  const mapboxRegex = /^mapbox:\/\/styles\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/;
  return {
    title: 'Location',
    subtitle: 'Location settings to improve your festival`s localization.',
    inputs: [
      {
        field: <TimezoneSelect
          title="timezone"
          subtitle="Timezone defaults to UTC setting it to your local time zone will improve any date/time-related features of your content" 
          label=""
          source="timezone"
          required
          validate={[required()]}
        />,
        breakpoints: {xs: 6}
          
      },
      {
        field: <InputText
          title="Mapbox style url"
          subtitle="Upload your mapbox map style url. Without a url you`ll be unable to have a map."
          source="location.properties.link" 
          fullWidth
          label=""
          placeholder="mapbox://styles/businessName/cln3hlwst0665443l"
          validate={[
            // required(),
            regex(
              mapboxRegex,
              'Oops, looks like your link does not match the required structure.'
            ),
          ]}
        />,
        breakpoints: { xs: 12},
      },
      {
        field: <LocationMapbox
          title="Festival Location"
          subtitle="Select from the map the center point of your festival or enter the coordinates"
          source="location" 
          fullWidth
          validate={[required('required')]}
        />,
        breakpoints: { xs: 12},
      },
     
    ]
  };
};