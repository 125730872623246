import { FormLabel } from '@mui/material';


export const InputSubtitle = ({children, ...rest}) => {

  return (
    <FormLabel
      style={{
        marginTop:'4px',
        display:'block',
        fontSize: '.9em',
        lineHeight: '1.5',
        minHeight: '3em',  
        maxHeight: '3em',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '& span': {
          height: '3em',
          fontSize: '.9em',
          fontStyle: 'italic',
        },
        ...rest.sx,

      }}
      {...rest}
    >
      {children}
    </FormLabel>
  );

};