import dayjs from 'dayjs';
export const scheduleFunctions = (props)=>{
  const { dataProvider, refetch, updatedEvents, parseFestivalTime, customToast, handleItemClick } = props;
  const handleMove = async (props) => {
    const { idElement, startTime, venueId, message } = props;
    const invalidPosition = startTime.includes('-noDrop');
    if (invalidPosition === true) {
      customToast('error', 'Please select a valid time');
      throw new Error('Please select a valid time');
    }
    if (message === 'ERROR: An event already exists on this date') {
      customToast('error', 'An event already exists on this date');
      throw new Error('An event already exists on this date');
    }
    try {
      let venueFormat = venueId.split('venue-group-')[1];
      let startDateFormat = dayjs(Number(startTime)).format(
        'YYYY-MM-DDTHH:mm:ss',
      );
      let parsetToUTC = parseFestivalTime(startDateFormat);
      let updateEvent = {
        location_id: venueFormat,
        start_date: parsetToUTC,
      };
      await dataProvider.update('events', { id: idElement, data: updateEvent });
      customToast('success', 'Event updated successfully');
    } catch (error) {
      console.error('Error', error);
      customToast('error', 'Error updating event');
      throw new Error('Please select a valid time');
    }
    refetch();
  };
  const scheduleEvent = async (props) => {
    const { eventId, venue, startTime } = props;
    const invalidPosition = startTime.includes('-noDrop');
    if (invalidPosition === true) {
      customToast('error', 'Please select a valid time');
      throw new Error('Please select a valid time');
    }
    if(!eventId.includes('unscheduledEvent-')){
      customToast('error', 'This event is already scheduled or does not exist');
      throw new Error('This event is already scheduled or does not exist');
    }
    const venueFormat = venue.split('venue-group-')[1];
    let startDateFormat = dayjs(Number(startTime)).format(
      'YYYY-MM-DDTHH:mm:ss',
    );
    let parsetToUTC = parseFestivalTime(startDateFormat);
    const eventIdFormat = eventId.split('unscheduledEvent-')[1];
    const updatedEventsLocal = updatedEvents.filter(
      (event) => event.id === eventIdFormat,
    )[0];
    updatedEventsLocal.location_id = venueFormat;
    updatedEventsLocal.start_date = parsetToUTC;
    try {
      await dataProvider
        .update('events', { id: eventIdFormat, data: updatedEventsLocal })
        .then((res) => {
          console.log(res, 'res');
        });
      customToast('success', 'Event scheduled successfully');
      refetch();
    } catch (error) {
      console.error('Error', error);
      customToast('error', 'Error updating event');
      throw new Error('Please select a valid time');
    }
  };
  const onResize = async (props) => {
    const { eventId, newDuration } = props;
    const originalEvent = updatedEvents.find((event) => event.id === eventId);
    if(typeof newDuration !== 'number'){
      customToast('error', 'Error updating event duration');
      throw new Error('The duration must be a number');
    }
    if(!originalEvent){
      customToast('error', 'Error updating event duration');
      throw new Error('Event not found');
    }
    if (newDuration !== originalEvent.duration) {
      originalEvent.duration = newDuration;
      try {
        await dataProvider.update('events', {
          id: eventId,
          data: originalEvent,
        });
        customToast('success', 'Event updated successfully');
        refetch();
      } catch (error) {
        console.error('Error', error);
        customToast('error', 'Error updating event');
        throw new Error('Please select a valid time');
      }
    }
  };
  const onClick = (props, itemCellId) => {
    if(itemCellId === undefined || itemCellId === null){
      customToast('error', 'Error trying to open event');
      return;
    }
    handleItemClick(props.id, itemCellId, props);
  };
  return {
    handleMove, 
    scheduleEvent,
    onResize,
    onClick
  };
};
