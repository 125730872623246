
export const isArrayEqual = (arr1, arr2) => {
  let bool = false;
  if(!Array.isArray(arr2) || !Array.isArray(arr1)) return bool;
  if(arr1.length !== arr2.lengt) return bool;
  for(let i = 0; i < arr1.length; i++) {
    if(arr1[i] !== arr2[i]){
      bool = false;
      break;
    }
    bool = true;
  }
  return bool;
};

export function nullableKeys(obj) {
  return Object.entries(obj).reduce((a,b,index) => {
    return [...a, b[0]];
  },[]);
}

export const editedFields = (body,field = 'submission') => {
  let values = {...body};
  let keyValues ={...body[field]};
  delete values[field];
  delete keyValues[field];
  let arr = Object.keys(keyValues)?.reduce((a,b, i) => {
    let value = Object.values(keyValues)[i];
    let mainValue = values?.[b];
    if(b === 'options' && value) {
      let nestedObj = Object.keys(value)?.reduce((acc, key) => {
        return [...acc, `${b}.${key}`];
      },'');
      return [...a].concat(nestedObj);
    }
    if(!Array.isArray(value) && mainValue !== value) {
      return [...a,b];
    } else {
      if(Array.isArray(value) && !isArrayEqual(value, mainValue)) return [...a,b];
      return [...a];
    }
  }, []);
  return arr;
};

// Para eventos submissions:
// export const editedFieldsEvents = (body, field = 'submission') => {
//   let values = Array.isArray(body) ? body.map(obj => ({ ...obj })) : { ...body };
  
//   let arr = Object.keys(values)?.reduce((a, b, i) => {
//     let value = Array.isArray(values) ? values[i][field] : values[field];
//     let mainValue = values?.[b];
    
//     if (b === 'options') {
//       let nestedObj = Object.keys(value)?.reduce((acc, key) => {
//         return [...acc, `${b}.${key}`];
//       }, '');
//       return [...a].concat(nestedObj);
//     }
    
//     if (!Array.isArray(value) && mainValue !== value) {
//       return [...a, b];
//     } else {
//       if (Array.isArray(value) && !isArrayEqual(value, mainValue)) return [...a, b];
//       return [...a];
//     }
//   }, []);

//   return arr;
// };