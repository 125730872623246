import { styled } from 'styled-components';


export const styledModal = {
  'position': 'relative',
  'top': '50vh',
  'left': '50%',
  'display': 'flex',
  'align-items': 'center',
  'justify-content': 'center',
  'padding': '20px',
  'transform': 'translate(-50%, -50%)',
  'height':'auto',
  'margin-top': '30px',
  'background-color': '#dedede',
  'width': '30%',
  'min-width': '500px',
  'border': '1px solid ##c2c2c2',
  'border-radius': '10px',
  'z-index': '100'
};

export const styledButton = {
  'padding': 10,
  'align-items': 'center',
  'margin': '30px'
};

export const styledBanner = {
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};

export const styledImage = {
  alignSelf: 'center',
  width: '90%',
  height: '200px',
  objectFit: 'cover',
  backgroundColor: '#fff'
};

export const appCard = 'width:96%;padding:6% 8%;border-radius:9px;background-color:#006748;color:white;font-weight:600;font-style:italic;' + '& * {color:inherit;}';
export const appCard2 = 'width:96%;background-color:#006748;color:white;padding:3% 6%; border-radius:9px;' + '& * {color:inherit;}' ;
