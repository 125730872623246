import { Fragment, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
// Hooks & Utils
import { useFormContext } from 'react-hook-form';
// Components
import { Button } from '../../../Components/Generics';
// Material & Icons
import styled from 'styled-components';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { Box } from '@mui/material';

const StyledFaGithub = styled.svg`
  cursor: pointer;
  width: ${(props) => props.size ?? '100%'};
  height: ${(props) => props.size ?? '100%'};
  &:hover {
    color: ${(props) => props.palette ?? '#fff'};
  }
`;

export const StepSlider = (props) => {
  const { value, onChange, source } = props;

  const size = '25px';
  const palette = useSelector((state) => state.palette.palette);
  const { getValues } = useFormContext();
  const nextOrPrev = useCallback(
    (boolean) => {
      if (boolean) {
        if (source === 0) return;
        return onChange(source - 1);
      } else {
        if (source === getValues()?.onboarding?.length - 1) return;
        return onChange(source + 1);
      }
    },
    [source, getValues()?.onboarding],
  );

  const handleChange = (i) => {
    onChange && onChange(i);
  };

  const StepsNumber = useMemo(() => {
    const numberSteps = getValues()?.onboarding?.length;
    
    return (
      <Fragment key="slider-fragment">
        {Array.from({ length: numberSteps }, (_, index) => {
          let numb = index + 1;
          let color = source === index ? palette?.primary : 'black';

          return (
            <Button
              onClick={() => handleChange(index)}
              style={{
                fontSize: size,
                fontWeight: '600',
                color: color,
                cursor: 'pointer',
                margin: '0 0.5em',
              }}
              hoverStyle={{ color: palette?.secondary }}
            >
              {numb}
            </Button>
          );
        })}
      </Fragment>
    );
  }, [getValues()?.onboarding, source]);
  return (
    <Box
      mt={5}
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box />
      <Box
        key="step-slider"
        id="step-slider"
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
        }}
        {...props}
      >
        <StyledFaGithub
          data-testid="arrow-forward"
          size={size}
          palette={palette}
          onClick={() => nextOrPrev(true)}
        >
          <IoIosArrowBack id="arrow-forward" size={size} />
        </StyledFaGithub>
        {StepsNumber}
        <StyledFaGithub
          size={size}
          palette={palette.primary}
          onClick={() => nextOrPrev(false)}
        >
          <IoIosArrowForward id="arrow-back" size={size} />
        </StyledFaGithub>
      </Box>
    </Box>
  );
};
