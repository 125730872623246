import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import { useSelector } from 'react-redux';
// import {useSelector} from 'react-redux';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
//Nuevo para el SAAS
export const convertToTimezone = (utcTime) => {
  // Simulacion de uso de REDUX TOOLKIT para obtener del estado global la configuracion de TIMEZONE del usuario :
  // const { timezone } = useSelector((state) => state.settings);
  // const formattedDate = dayjs.utc(utcTime).tz(userTimezone).format('YYYY-MM-DDTHH:mm:ss');
  // return formattedDate;
};


export const convertEndDateToDuration = (startDate, endDate) => {
  let x = new dayjs(startDate);
  let y = new dayjs(endDate);
  let duration = dayjs.duration(x.diff(y)).as('minutes');
  let hours = Math.floor(duration / 60);
  let minutes = Math.ceil(duration % 60);
  return [hours, minutes];
};

export const dateFormat = (date) => {
  const formatedDate = dayjs(date).format('MMMM Do YYYY, h:mm:ss a');
  return formatedDate;
};
export const formatOnlyDate = (date) => {
  const formattedDate = dayjs(date).format('M/DD/YYYY');
  return formattedDate;
};
export const customValidationStartDate = (e, date) => {
  // const firstDate = dayjs(festivalDates.start_date);
  // const lastDate = dayjs(festivalDates.end_date);
  const firstDate = dayjs('2024-03-04T00:00:00');
  const lastDate = dayjs('2024-03-11T23:59:59');
  console.log(lastDate);
  if(!e) {
    return undefined;
  }
  if(dayjs(e).isBefore(firstDate) || dayjs(e).isAfter(lastDate)){
    // return `Start date must be between ${firstDate.format('DD/MM/YYYY')} date and ${lastDate.format('DD/MM/YYYY')} date`;
    return 'Start date must be between March 4th and March 11th, 2024';

  }
  return undefined;
};

export const getDateTime = (date) => {
  const formatedTime = dayjs(date).format('h:mm:ss a');
  return formatedTime;
};

export const getDiffArray = (startDate, endDate, diff = 'days') => {
  let end_date = dayjs(endDate).format('YYYY-MM-DD');
  let arr = [dayjs(startDate).format('YYYY-MM-DD')];
  while (dayjs(arr[arr.length - 1], 'YYYY-MM-DD').isBefore(end_date)) {
    if(dayjs(arr[arr.length - 1]).isSame(end_date)) {
      break;
    }
    arr.push(dayjs(startDate).add(1, diff).format('YYYY-MM-DD'));
    startDate = dayjs(startDate).add(1, diff); // Actualizar startDate en cada iteración
  }
  return arr;
};


export const useUtcFormat = () => {
  const utc = useSelector((state) => {
    return state.data.info?.timezone?.offset;
  });

  const isPos = Math.sign(utc);

  const formatFestivalTime = (utcTime) => {
   
    switch(true){
    case isPos === 0:
      return dayjs.utc(utcTime).format('YYYY-MM-DDTHH:mm:ss');
    case isPos === -1:
      return dayjs.utc(utcTime).subtract(Math.abs(utc), 'hours').format('YYYY-MM-DDTHH:mm:ss');
    case isPos === 1:
      return dayjs.utc(utcTime).add(Math.abs(utc), 'hours').format('YYYY-MM-DDTHH:mm:ss');
    }
  };

  const parseFestivalTime = (utcTime) => {
    
    switch(true){
    case isPos === 0:
      return dayjs.utc(utcTime).format('YYYY-MM-DDTHH:mm:ss');
    case isPos === -1:
      return dayjs.utc(utcTime).add(Math.abs(utc), 'hours').format('YYYY-MM-DDTHH:mm:ss');
    case isPos === 1:
      return dayjs.utc(utcTime).subtract(Math.abs(utc), 'hours').format('YYYY-MM-DDTHH:mm:ss');
    }
  };
  return {
    formatFestivalTime, // convertToCostaRicaTime
    parseFestivalTime, // convertToUtcMinusSix
  };
};
