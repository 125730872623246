import FaqsList from './FaqsList';
import CreateFaqs from './Form/CreateFaqs';
import EditFaqs from './Form/EditFaqs';
import { RiQuestionLine } from 'react-icons/ri';

export default {
  list: FaqsList,
  create: CreateFaqs,
  edit: EditFaqs,
  icon: RiQuestionLine
};