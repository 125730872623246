import React from 'react';
// Components
import InputText from '../../../Components/InputsForm/InputText';
import InputRichText from '../../../Components/InputsForm/InputRichText';
import InputBoolean from '../../../Components/InputsForm/InputBoolean';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import InputCategoryPills from '../../../Components/RedesignComponents/Inputs/InputCategoryPills';
// Material & Icons
import { InputTitle } from '../../../Components/StyledComponents/InputTitle';
import { InputPillsSelect } from '../../../Components/RedesignComponents/Inputs';
import { useGetList } from 'react-admin';

const FormFaqs = ({ isEdit, from }) => {
  const { data: faqsCategories } = useGetList('faqs/categories', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
  });
  const data = [
    {
      title: 'INFORMATION',
      inputs: [
        {
          field: (
            <InputText
              required={true}
              fullWidth
              source="question"
              title={'Question'}
              label=""
            />
          ),
          breakpoints: { xs: 6 },
        },
        {
          field: (
            <InputBoolean
              label=""
              fullWidth
              source="_archived"
              title={'Archived'}
              isColumn={true}
            />
          ),
          breakpoints: { xs: 6 },
        },
        {
          field: (
            <>
              {/* <InputCategoryPills
                source="category"
                reference="faqs/categories"
                label=""
                optionText="text"
                optionValue={'text'}
                title="Category"
                sx={{ marginTop: '10px' }}
              /> */}
              <InputPillsSelect
                choices={faqsCategories && faqsCategories}
                withText={true}
                source="category"
                label=""
                title={'Category'}
              />
            </>
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <>
              <InputTitle required={true}>ANSWER</InputTitle>
              <InputRichText fullWidth source="answer" label=" " />
            </>
          ),
          source: 'answer',
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];
  return (
    <AdaptableFormRedesign
      isEdit={isEdit}
      data={data}
      from={from}
      icon={false}
      saveBackground={true}
      showDelete
      noName
    />
  );
};

export default FormFaqs;
