import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useRecordContext, useRedirect, useResourceContext } from 'react-admin';
import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useParams } from 'react-router-dom';
import { IconButton } from '../Generics';

const styledHeaderSubmitter = {
  fontSize: '.9em',
  textAlign: 'start',
  fontColor: 'black',
  width: '100%',
  gap: '50px',
  marginBottom: '0px',
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
  height: '45px',
};
const styleSpan = {
  fontWeight: '600',
  color: '#000000',
};

export const NavbarTitle = ({
  sx,
  lastSubmitter,
  lastModified,
  isEdit,
  actionText = 'name',
}) => {
  const record = useRecordContext();
  const message = 'Last edited by: ';
  const redirect = useRedirect();
  const resource = useResourceContext();
  const resourceNoPlural = resource?.slice(0, -1);
  const paramss = useParams();
  const urlparams = paramss['*']?.split('/')[0];
  const fromTalentId = urlparams?.split('talentId=')[1];

  //Este redirect va tener una condicional para aquel caso en el cual provenga de la tab TALENTS
  const goBack = () => {
    if(fromTalentId){
      redirect(`/talents/${fromTalentId}`);
    }else{
      redirect(`/${resource}`);
    }
  };
  return (
    <>
      <Box sx={{ ...styledHeaderSubmitter, ...sx }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <IconButton onClick={() => goBack()} icon={<BiArrowBack size={28} color="#000000" />}/>
            
          <Typography fontSize={'30px'} fontWeight={600}>
            {isEdit
              ? `Edit ${resource === 'faqs' ? 'FAQ' : resourceNoPlural} - ` +
                record[actionText]
              : `New ${resource === 'faqs' ? 'FAQ' : resourceNoPlural}`}
          </Typography>
        </div>
        {lastSubmitter || record?.last_modified_by ? (
          <Typography>
            {message}
            <span style={styleSpan}>
              {lastSubmitter ?? record?.last_modified_by}
            </span>
          </Typography>
        ) : null}
        {lastModified || record?.last_modified ? (
          <Typography>
            Last modified:{' '}
            <span style={styleSpan}>
              {lastModified ??
                dayjs(record?.last_modified).format('MMMM Do YYYY')}
            </span>
          </Typography>
        ) : null}
      </Box>
    </>
  );
};
