import { CircularProgress, TextField } from '@mui/material';
import { Controller, useController, useForm, useFormContext } from 'react-hook-form';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useCheckField } from '../../Hooks/useCheckField';
import { mainColor } from '../../Theme';
import { required } from 'react-admin';
import { useState } from 'react';

  
export const InputRequestField = ({inputChange, control, resource, fieldName, name, label, pattern, validate, ...rest}) => {
  const [inputValue, setInputValue] = useState(null);
  const {isFetching, data: { data: { results } = {} } = {}, refetch, isSubmitted} = useCheckField(resource, fieldName, inputValue);

  const handleChange = (e) => {
    let value = e.target.value;
    inputChange && inputChange(value);
    setInputValue(value);
    setTimeout(() => refetch(), 200);
  };

  const isEqual = (arr) => {
    if(validate) {
      return validate(arr);
    }
    if(arr?.length > 0) return `The ${name.split('.')[0]} is already taken.`;
    return undefined;
  };
  
  return (
    <Controller
      name={name}
      value={inputValue}
      control={control}
      rules={{
        required: `The ${label} field is required.`,
        pattern: pattern && {
          value: pattern,
          message: `The ${label} format is not valid.`
        },
        validate: {
          isEqual: () => isEqual(results)
        }
      }}
      render={({field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState: {defaultValues},}) => {
        return (<TextField
          {...rest}
          label={label}
          ref={ref}
          onBlur={(e) => {onBlur(e) && handleChange(e);}}
          onChange={(e) => {onChange(e) && handleChange(e); }}
          error={
            error || (isTouched || isSubmitted)
           && (
             !!isEqual(results) || 
             (pattern ? !pattern.test(inputValue) : false))
          }
          helperText={
            error?.message || 
            (
              (isTouched || isSubmitted) &&
               value !== '' && 
               results?.length > 0 ?
                isEqual(results) :
                // results?.length > 0 ?
                // `The ${name.split('.')[0]} name is already taken.` :
                pattern && isTouched && !pattern.test(value) ?
                  `The ${label} format is not valid, please try again.` : ''
            )
          }
          InputProps={{
            ...rest.InputProps,
            endAdornment: !results && !isFetching ?
              null : name === 'name' ?   ((results?.length > 0)) && !isFetching ? 
                <HighlightOffIcon sx={{color: 'red'}} /> : 
                isFetching ? 
                  <CircularProgress size="20px" /> : 
                  <CheckCircleOutlineIcon sx={{color: mainColor}} />:
                ((results?.length > 0 && (validate && validate(results)) || (pattern ? !pattern.test(inputValue) : false))) && !isFetching ? 
                  <HighlightOffIcon sx={{color: 'red'}} /> : 
                  isFetching ? 
                    <CircularProgress size="20px" /> : 
                    <CheckCircleOutlineIcon sx={{color: mainColor}} />            
            // ((results?.length > 0 && (validate && validate(results))) || (pattern ? !pattern.test(inputValue) : false)) && !isFetching ? 
            //   <HighlightOffIcon sx={{color: 'red'}} /> : 
            //   isFetching ? 
            //     <CircularProgress size="20px" /> : 
            //     <CheckCircleOutlineIcon sx={{color: mainColor}} />
          }}
        />);
      }}
    />
  );
};