import { parseFestivalTime, useUtcFormat } from '../../../helpers/date';
export const transform = (data) => {

  const { formatFestivalTime, parseFestivalTime } = useUtcFormat();

  console.log(data,'DATA TO SEND');
  const { start_dates, ...rest } = data; 
  if(start_dates?.length >= 1){
    if(start_dates?.includes('')){
      const newStartDates = start_dates.filter(date => date !== '');
      const startDatesUtc = newStartDates.map(date => parseFestivalTime(date));
      return {
        ...rest,
        start_dates: startDatesUtc
      };
    }else{
      const startDatesUtc = start_dates?.map(date => parseFestivalTime(date));
      return {
        ...rest,
        start_dates: startDatesUtc
      };
    }
  }else{
    return {
      ...rest,
      start_dates: []
    };
  }
 
};