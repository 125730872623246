import React from 'react';
import { Create } from 'react-admin';
import FormActivities from './FormActivities';
import { transform } from './transform';
import { CreateCustomComponent } from '../../../Components/CreateCustomComponent/CreateCustomComponent';
const CreateActivities = () => {
  return (
    <CreateCustomComponent redirect='list'  mutationMode={'pessimistic'} transform={transform}>
      <FormActivities from={'activities'}/>
    </CreateCustomComponent>
  );
};

export default CreateActivities;