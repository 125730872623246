import { Collapse, Grid, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { FormTitle } from '../../StyledComponents/FormTitle';
import { IoChevronBack, IoChevronDownSharp } from 'react-icons/io5';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
import { MessageInput } from '../../InputsForm/MessageAutocomplete';

export const CollapseGridSection = (props) => {
  const [open, setOpen] = useState(false);
  const {
    index,
    data,
    item,
  } = props;
  return (
    <Grid
      container
      direction={'row'}
      sx={{ borderBottom: index !== data.length - 1 && '1px solid rgba(0,0,0, .15)', marginY: 2 }}
      key={`AdaptableFormKey-${index}`}
    >
      {
        item.title && (
          <Grid 
            item 
            xs={3}
            sx={{
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'center',
              position: 'relative',
              height: '60px'
            }}>
            <FormTitle
            >
              {item.title.toUpperCase()}
            </FormTitle>
            <IconButton sx={{
              position: 'absolute',
              right: 10, 
            }} onClick={() => setOpen(!open)}>
              { !open ? <IoChevronDownSharp size={24} /> : <IoChevronBack size={24} />}
            </IconButton>
          </Grid>
        )
      }
      <Grid item xs={12} sx={{maxWidth: '100%'}}>
        <Collapse in={open}>
          <GridInputs {...props}  xs={12}/>
        </Collapse>
      </Grid>
    </Grid>
  );
};
  
export const GridInputs = (props) => {
  const {
    index,
    data,
    item,
    formCount,
    indexFormCount,
    xs = 8, // Se cambia para que siempre se ocupe primero el ancho del form.
  } = props;
  return (
    <>
      <Grid container
        columnSpacing={2}
        xs={xs}
        xl={8}
        sx={{
          justifyContent: 'start',
          rowGap: '30px'
        }}
      >
        {item.warning && <MessageInput text={item.warning} />}

        {
          item.collapse && <Grid item sx={{height: '50px', width: '100%'}} xs={12}/>
        }
        {item?.inputs?.map((input, index,key) => {
          // Esto es un condicional para no mostrar el boton de ADD en las home components en aquellos formularios que no sean los ultimos del total renderizado.
          if (input?.title === 'add' && formCount - 1 !== indexFormCount) {
            return null;
          }
          if (input?.noShow) return null;
          return (
            <Tooltip
              placement={input?.placement ?? 'top'}
              title={input?.tooltip ? input?.tooltip : ''}
            >
              {input?.field?.type === 'div' ? (
                <Grid
                  key={key}
                  item
                  {...input?.breakpoints}
                >
                  {input?.field}
                </Grid>
              ) : input?.title === 'add' && formCount -1 === indexFormCount ?<>{input?.field}</> : (
                <Grid
                  key={key}
                  sx={{
                    padding: '0px 10px',
                  }}
                  item
                  {...input?.breakpoints}
                >
                  {input?.field}
                </Grid>
              )}
            </Tooltip>
          );
        })}
      </Grid>
      <Grid 
        item 
        xs={2}
        xl={2}
        sx={{
          display: 'flex',
          alignItems: 'start',
          justifyContent: 'center'
        }}/>
      {
        item.collapse && <Grid item sx={{height: '50px', width: '100%'}} xs={10}/>
      }
    </>
  );
};
  
export const GridSection = (props) => {
  const {
    index,
    data,
    item,
  } = props;
  return (
    <Grid
      container
      direction={item.direction ?? 'row'}
      sx={{ 
        borderBottom: index !== data.length - 1 && '1px solid rgba(0,0,0, .15)',
        paddingY: 3,
      }}
      key={`AdaptableFormKey-${index}`}
    >
      {
        item.title && (
          <Grid 
            xs={3}
            xl={2}
            item 
            wrap
            direction="column"
            justifyContent="flex-start"
            sx={{
              display: 'flex',
              alignItems: 'start',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height: 'auto',
              paddingRight: '20px'
            }}
          >
            <FormTitle
            >
              {item.title.toUpperCase()}
            </FormTitle>
            <InputSubtitle sx={{fontStyle: 'italic'}}>{item.subtitle}</InputSubtitle>
            {item.children}
          </Grid>
        )
      }
      <GridInputs {...props} />
    </Grid>
  );
};