import React,{useEffect,useState} from 'react';
import axios from 'axios';
import ListCustom from '../../../Components/ListCustom/ListCustom';
import { TextField,FunctionField } from 'react-admin';
import { Chip } from '@mui/material';
import { COLORS } from '../../../assets/constants/theme';
import { ListActions } from '../../../Components/ListActions/ListActions';

const BlogList = () => {
  const [categories,setCategories] = useState(null);
  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_BASE_PATH}/articles/categories`,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
        'x-api-key': process.env.REACT_APP_API_KEY ,
      }
    }).then(res => {
      setCategories(res.data.results);
    }).catch(err => {
      throw err;
    });
  },[]);
  const paramsList = {
    listParams: {
      hasCreate: true,
      actions: <ListActions from={'articles'} search={true} placeholder={'Search by title, subtitle, body and author'}/>,
      queryOptions: {
        meta: { field: 'last_modified'},
      }
    },
    datagridParams: {
      bulkActionButtons: false,
      rowClick: 'edit'
    }
  };
  return (
    <div>
      <ListCustom {...paramsList} datagridParams={{
        sx: { 
          padding:'0px 20px',
          zIndex:100,
          '& .MuiTypography-root':{
            color:'#000000',
            fontWeight:400
          },
          '& .column-category': {
            width: '20%',
            textAlign: 'center',
          },
          '& .column-title': {
            width: '60%',
          },
          '& .column-author': {
            width: '20%',
          },
        },
        ...paramsList.datagridParams,
      }}>
        <TextField source="title" />
        <TextField source="author" />
        <FunctionField label="Category" source="category" 
          render={(record)=> record?.category ? <Chip sx={{width:'auto'}} label={record?.category} /> : null }/> 
      </ListCustom>
    </div>
  );
};

export default BlogList;