import { Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import { useInput } from 'react-admin';
import { useSelector } from 'react-redux';
import { InputTitle } from '../StyledComponents/InputTitle';
import { useFormContext } from 'react-hook-form';
import { TimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import {
  AddButton,
  RemoveButton,
} from '../../Resources/Talents/Form/TalentsListComponents/InputAddTextInputs';
import { findTimes, handlerFormatTimes, handlerParseTimes } from './functions';
import { useUtcFormat } from '../../helpers/date';
import { Button } from '../Generics/index';

const styledDates = {
  fontSize: '.9em',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#D9D9D9',
  textTransform: 'uppercase',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '100%',
  width: '70px',
  height: '70px',
  margin: 0,
  padding: 0,
};

const StartDates = (props) => {
  const { days, source } = props;
  const [dayPicked, setDayPicked] = useState(days[0]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        gap: 30,
      }}
    >
      <DaysComponent
        days={days}
        dayPicked={dayPicked}
        setDayPicked={setDayPicked}
      />
      <InputsComponent dayPicked={dayPicked} source={source} />
    </div>
  );
};

const DaysComponent = ({ days, dayPicked, setDayPicked }) => {
  const palette = useSelector((state) => state?.palette?.palette);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      {days?.map((item, index) => (
        <Button
          key={index}
          onClick={() => setDayPicked(item)}
          style={{
            ...styledDates,
            backgroundColor: dayPicked === item ? palette?.primary : '#D9D9D9',
            color: dayPicked === item ? 'white' : 'black',
          }}
        >
          <Typography fontSize={12} sx={{ textTransform: 'none' }}>
            {dayjs(item).format('MMMM')}
          </Typography>
          <Typography fontSize={18} sx={{ textTransform: 'none' }}>
            {dayjs(item).format('D')}
          </Typography>
        </Button>
      ))}
    </div>
  );
};
const InputsComponent = ({ dayPicked, source }) => {
  const { formatFestivalTime, parseFestivalTime } = useUtcFormat();
  const palette = useSelector((state) => state?.palette?.palette);
  const { field: openField, fieldState: openFieldState } = useInput({
    source,
    format: (e) => handlerFormatTimes(e, formatFestivalTime),
    parse: (e) => handlerParseTimes(e, parseFestivalTime),
  });
  const { getValues } = useFormContext();
  const duration = getValues('duration');
  //Este STATE controlara la cantidad de HORARIOS en un dia (daypicked)
  // ya que cada dia puede tener varios horarios, pero al final se envia un array de todos los horario
  // sin importar el dia, pero en el codigo necesitamos estructurarlo de esta manera.
  // Para que cada que cambie de dia se carguen todos los horarios de ese dia y se renderize lo necesario.
  const [arrDays, setArrDays] = useState([]);

  const startTime = useMemo(() => {
    const startDates = openField?.value || [];
    return startDates.find((date) => dayjs(date).isSame(dayPicked, 'day'));
  }, [openField.value, dayPicked]);
  const handleStartTimeChange = (newTime, index) => {
    const newStartTime = dayjs(newTime)
      .set('year', dayjs(dayPicked).year())
      .set('month', dayjs(dayPicked).month())
      .set('date', dayjs(dayPicked).date())
      .format('YYYY-MM-DDTHH:mm:ss');
    // Se agrega el nuevo horario
    const updatedArrDays = arrDays.map((time, i) =>
      i === index ? newStartTime : time,
    );
    setArrDays(updatedArrDays);
    console.log(updatedArrDays);
    setArrDays(updatedArrDays);

    // Actualiza el openField con los nuevos horarios
    const otherDays =
      openField.value?.filter(
        (date) => !dayjs(date).isSame(dayPicked, 'day'),
      ) || [];
    openField.onChange([...otherDays, ...updatedArrDays]);
  };
  const handlerAddTime = () => {
    setArrDays([...arrDays, dayjs(dayPicked).format('YYYY-MM-DDTHH:mm:ss')]);
  };
  const handlerDeleteTime = (index) => {
    const updatedArrDays = arrDays.filter((_, i) => i !== index);
    setArrDays(updatedArrDays);

    const otherDays =
      openField.value?.filter(
        (date) => !dayjs(date).isSame(dayPicked, 'day'),
      ) || [];
    openField.onChange([...otherDays, ...updatedArrDays]);
  };

  useEffect(() => {
    //Cada vez que se cambie de dia, se tiene que asignar al array de dias todos los horarios del dia correspondientes
    const validTimes = findTimes(dayPicked, openField?.value);
    if (validTimes.length === 0) {
      setArrDays([dayjs(dayPicked).format('YYYY-MM-DDTHH:mm:ss')]);
    } else {
      setArrDays(validTimes);
    }
  }, [dayPicked]);

  return (
    <div style={{ width: '100%' }}>
      {arrDays?.map((time, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', flexDiretion: 'row' }}>
            <div style={{ width: '17rem' }}>
              <InputTitle sx={{ marginLeft: '10px', marginBottom: '10px' }}>
                START TIME
              </InputTitle>
              <TimePicker
                onChange={(date) => handleStartTimeChange(date, index)}
                value={startTime ? dayjs(time) : dayjs(dayPicked)}
                fieldState={openFieldState}
                variant="outlined"
                sx={{
                  width: '100%',
                  maxWidth: '220px',
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                ampm={false}
              />
            </div>
            <div style={{ width: '17rem' }}>
              <InputTitle sx={{ marginLeft: '10px', marginBottom: '10px' }}>
                END TIME
              </InputTitle>
              <TimePicker
                value={dayjs(time).add(duration, 'minutes')}
                disabled
                variant="outlined"
                sx={{
                  width: '100%',
                  maxWidth: '220px',
                }}
                viewRenderers={{
                  hours: renderTimeViewClock,
                  minutes: renderTimeViewClock,
                  seconds: renderTimeViewClock,
                }}
                ampm={false}
              />
            </div>
          </div>
          <RemoveButton
            handleDelete={() => handlerDeleteTime(index)}
            sx={{ height: '10px', alignSelf: 'center', marginTop: '20px' }}
          />
        </div>
      ))}

      <AddButton
        handle={handlerAddTime}
        addText="+ Add Hours"
        typosx={{ color: palette?.primary }}
      />
    </div>
  );
};

export default StartDates;
