import React, { useState } from 'react';
import {
  FunctionField,
  ImageField,
  TextField,
  usePermissions,
  useRedirect,
  useRefresh,
  useResourceContext,
  useStore,
  useUpdate,
} from 'react-admin';
import ListCustom from '../../Components/ListCustom/ListCustom';
import { mainColor } from '../../Theme';
import { ListActions } from '../../Components/ListActions/ListActions';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { switchStyleIos } from '../../Components/StyledComponents/Inputs';
import { Indicator } from '../../Components/RedesignComponents/Indicator';
import { COLORS } from '../../assets/constants/theme';
import { Button } from '../../Components/Generics';
// Material & Icons
import {
  Box,
  Chip,
  IconButton as IconButtonMui,
  Typography,
  Modal,
  Switch,
  Tooltip,
} from '@mui/material';
import { MdPersonSearch, MdCheckCircle } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { useSelector } from 'react-redux';
import {IconButton} from '../../Components/Generics';
const paramsList = {
  listParams: {
    hasCreate: true,
    queryOptions: {
      meta: { field: 'last_modified' },
    },
  },
  datagridParams: {
    bulkActionButtons: false,
    rowClick: 'show',
  },
};

const TalentsList = () => {
  // ESTADOS GLOBALES
  const [selectedTalent, setSelectedTalent] = useStore('selectedTalent', {
    selected: false,
    talent: '',
  });
  const permis = usePermissions().permissions;
  const [redirectModal, setRedirectModal] = useState({ bool: false, id: null });
  const [filter, setFilter] = useState({});
  const refresh = useRefresh();
  React.useEffect(() => {
    refresh();
  }, [filter]);
  const handlePopup = (status, id) => {
    setSelectedTalent({ selected: true, talent: id });
    if (status === 'pending' && permis !== 'talent_manager')
      return setRedirectModal({ bool: true, id: id });
    return 'edit';
  };
  return (
    <div>
      {permis === 'talent_manager' && (
        <div style={{ width: '100%', padding: '10px 15px 20px 20px' }}>
          <Typography fontSize={30} fontWeight={600}>
            My Talents
          </Typography>
        </div>
      )}
      <ListCustom
        filter={filter}
        empty={<Typography>No submissions</Typography>}
        setFilter={setFilter}
        defaultTab={'pending'}
        {...paramsList}
        // kevin: agregue esto para pasarle los permisos actuales al filter
        listParams={{
          ...paramsList.listParams,
          actions: (
            <ListActions
              from={'talents'}
              search={true}
              permission={permis}
              placeholder={'Search by name, slogan, title and bio...'}
              csv={true}
            />
          ),
        }}
        datagridParams={{
          empty: <EmptyTalentsList filter={filter} />,
          sx: {
            padding: '0px 20px',
            zIndex: 100,
            '& .MuiTypography-root': {
              color: '#000000',
              fontWeight: 400,
            },
            '& .column-imagen': {
              width: '10%',
              textAlign: 'center',
              padding:'0px'
            },
            '& .column-name': {
              width: '20%',
            },
            '& .column-maincategory': {
              width: '15%',
              textAlign: 'center',
            },
            '& .column-id': {
              width: '10%',
            },
            '& .column-completed': {
              width: '17.5%',
              textAlign: 'center',
            },
            '& .column-status': {
              width: '17.5%',
              textAlign: 'center',
            },
            '& .column-draft': {
              width: '10%',
              textAlign: 'center',
            },
          },
          ...paramsList.datagridParams,
          rowClick: (id, resource, record, e) =>
            handlePopup(record?.submission?.status, id),
        }}
      >
        <FunctionField
          label="Picture"
          source="imagen"
          render={(record) => (
            <ImageField
              source="picture.original"
              label="Picture"
              sx={{
                '& .RaImageField-image':{
                  padding:0,
                  margin:0,
                },
                '& img': {
                  borderRadius: 200,
                  width: 'auto',
                  height: 'auto',
                  maxHeight: '40px',
                  maxWidth: '40px',
                  objectFit: 'cover',
                },
              }}
            />
          )}
        />

        <TextField source="name" />
        <FunctionField
          label="Category"
          source="category"
          render={(record) =>
            record?.category ? (
              <Chip sx={{ width: 'auto',backgroundColor:'#D9D9D9'}} label={record?.category} />
            ) : null
          }
        />
        <span label="" style={{ visibility: 'hidden' }}></span>
        <FunctionField
          label="Completed?"
          source="completed"
          render={(record) => (
            <Typography
              sx={{
                fontSize: '.9em',
                fontWeight: '600',
                color: record?.completed ? '#009b00' : '#ffc990',
                textTransform: 'uppercase',
              }}
            >
              {record?.completed ? (
                <MdCheckCircle color="#21B141" size={26} />
              ) : (
                <IoMdCloseCircle color="grey" size={26} />
              )}
            </Typography>
          )}
        />
        <FunctionField
          label="Last submission status"
          source="status"
          render={(record) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {record?.submission?.status === 'draft' ||
              record?.submission === null ||
              record?.submission?.status === 'not_submitted' ? (
                  <Indicator data={{ status: 'not_submitted' }} />
                ) : (
                  <Indicator data={record?.submission} />
                )}
            </div>
          )}
        />
        {permis !== 'talent_manager' && (
          <FunctionField
            label="Visibility"
            source="draft"
            render={(record) =>
              record.completed === true ? (
                <SideActions {...record} />
              ) : (
                <SideActions {...record} disabled={true} />
              )
            }
          />
        )}
      </ListCustom>
      <RedirectModal
        resource="talents"
        open={redirectModal.bool}
        id={redirectModal.id}
        setOpen={setRedirectModal}
      />
    </div>
  );
};

export const EmptyTalentsList = ({ filter }) => {
  return (
    <Box textAlign="center" m={'100px 1px 1px 1px'}>
      <MdPersonSearch color={mainColor} size="120px" />
      <Typography variant="h4" color={mainColor} paragraph>
        {filter?.status?.length === 1
          ? 'No submissions pending'
          : 'No talents found.'}
      </Typography>
    </Box>
  );
};

export const SideActions = (record, disabled) => {
  const { id, _draft, completed } = record;
  const resource = useResourceContext();

  const permis = usePermissions()?.permissions;
  const customToast = useCustomToasty();
  const [checked, setChecked] = useState(!_draft);
  const [refetch] = useUpdate(
    resource,
    { id: id, data: { _draft: !_draft }, previousData: record },
    {
      onError: (err) => {
        customToast('error', err);
      },
      onSuccess: (data) => {
        if (data['_draft'])
          return customToast('success', 'Talent has been unpublished');
        return customToast('success', 'Talent published succesfuly');
      },
    },
  );

  const handleRefetch = (e) => {
    e.stopPropagation();
  };
  const handleRefetchSwitch = (e) => {
    e.stopPropagation();
    setChecked(!checked);
    refetch();
  };
  return completed === true ? (
    <IconButton
      disabled={permis === 'talent_manager'}
      onClick={handleRefetch}
    >
      <Switch
        sx={switchStyleIos}
        onChange={handleRefetchSwitch}
        checked={checked}
        disabled={completed === false ? true : false}
      />
    </IconButton>
  ) : (
    <Tooltip
      title="Complete the talent first to publish it"
      placement="bottom-end"
    >
      <IconButtonMui
        disabled={permis === 'talent_manager'}
        onClick={handleRefetch}
      >
        <Switch
          sx={switchStyleIos}
          onChange={handleRefetchSwitch}
          checked={checked}
          disabled={completed === false ? true : false}
        />
      </IconButtonMui>
    </Tooltip>
  );
};

export const RedirectModal = ({ open, setOpen, id, resource }) => {
  const redirect = useRedirect();
  const palette = useSelector((state) => state?.palette?.palette);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height: 'auto',
    bgcolor: 'background.paper',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 24,
    p: 4,
  };

  const titleStyle = {
    width: '100%',
    borderBottom: `1px ${palette?.primary} solid`,
    padding: '5px',
    textTransform: 'uppercase',
    fontSize: '18px',
    fontWeight: '600',
    color: palette?.primary,
  };

  const redirectSubmission = () => {
    return redirect('show', resource, id);
  };

  const redirectEdit = () => {
    return redirect('edit', resource, id);
  };

  return (
    <Modal
      sx={{ height: 'auto' }}
      open={open}
      onClose={() => setOpen({ bool: false, id: null })}
    >
      <Box sx={style}>
        <Typography sx={titleStyle}>Pending Submission</Typography>
        <Typography
          sx={{
            fontWeight: '400',
            color: '#757575',
            margin:'10px 0px'
          }}
        >
          This talent currently has submitted data pending of reviewal. Its
          important to note that if the aforementioned submission gets approved,
          any changes you edit will be overriden.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            gap: '20px',
            marginTop: '15px',
          }}
        >
          <Button onClick={redirectSubmission} style={{ backgroundColor: palette?.primary, color:'#ffffff',fontWeight:600}}>
            Review Submission
          </Button>
          <Button onClick={redirectEdit} variant="outlined">
            Continue to edit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TalentsList;
