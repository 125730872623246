import { Grid, MenuItem, TextField } from '@mui/material';
import { useMemo } from 'react';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';
import { ColorPickerTextField } from '../../../../Components/InputsForm/InputColorPicker';
import {InputImage} from '../../../../Components/InputsForm/InputImage';

export const BannerInputs = (props) => {

  const {
    index,
    onChange,
    value
  } = props;
  

  const handleChange = (e, key) => {
    let val = ['background_color','font_color'].includes(key) ? e : e.target.value;
    let updateValues = value?.map((item, i) => {
      if(index === i) return {...value[index], [key]: val};
      return item;
    });
    onChange(updateValues);
    return val;
  };

  const handleImage = (e) => {
    let val = e;
    console.log(val);
    let updateValues = value?.map((item, i) => {
      if(index === i) return {...value[index], image: val};
      return item;
    });
    onChange(updateValues);
  };
  const MemoComponent = useMemo(() => {
    return (
      <Grid container rowGap={3} columnGap={1} style={{width: '400px', marginBottom: '50px', justifyContent: 'space-between'}}>
        <Grid item xs={12} style={{width: '100%'}}>
          <InputTitle>title</InputTitle>
          <TextField
            onChange={(e) => handleChange(e, 'title')} 
            fullWidth
            variant="outlined"
            label=""
            value={value[index]?.title}
          />
        </Grid>
        <Grid item xs={3} >
          <InputTitle>Size</InputTitle>
          <TextField fullWidth variant="outlined" onChange={(e) => handleChange(e, 'size')} select value={value[index]?.size}>
            {[1,0.5]?.map(item => <MenuItem value={item}>{item}</MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={5} >
          <ColorPickerTextField 
            value={value[index]?.font_color}
            title="Font Color" 
            hexFormat 
            source="font_color"
            onChange={(e) => handleChange(e, 'font_color')} 
          />
        </Grid>
        <Grid item xs={12} style={{width: '100%'}}>
          <InputTitle>Link</InputTitle>
          <TextField 
            onChange={(e) => handleChange(e, 'link')} 
            fullWidth
            variant="outlined"
            label="" 
            value={value[index]?.link} 
          />
        </Grid>
        <Grid item xs={6} style={{width: '100%'}}>
          <InputImage
            title="Image"
            onChange={handleImage}
            source="image"
            value={value[index]?.image}
          />
        </Grid>
        <Grid item xs={5} style={{width: '100%'}}>
          <ColorPickerTextField  
            value={value[index]?.background_color} 
            title="Background Color" 
            hexFormat 
            source="background_color"
            onChange={(e) => handleChange(e, 'background_color')} 
          />
        </Grid>
      </Grid>
    );
  }, [value, index]);
    
  return MemoComponent;
};

