import axios from 'axios';

export const handleDownloadCsv = (props) => {
  const {resource, talent, filterValues, params, apiUrl, customToast} = props;

  const downLoadCsv = async () => {
    const talent_id = talent ? [talent] : [params['*']?.split('/')[1]];
    let url;
    if(resource === 'talents'){
      if(Object.keys(filterValues).length > 0){
        const filterParam = encodeURIComponent(JSON.stringify(filterValues));
        const decodedFilterParam = decodeURIComponent(filterParam);
        url = `${apiUrl}/${resource}/export_to_csv?filter=${decodedFilterParam}`;
      }else{
        url = `${apiUrl}/${resource}/export_to_csv`;
      }
    }
    else if(resource === 'events'){
      if(Object.keys(filterValues).length > 0){
        const filterParam = encodeURIComponent(JSON.stringify(filterValues));
        const decodedFilterParam = decodeURIComponent(filterParam);
        url = `${apiUrl}/${resource}/export_to_csv?filter=${decodedFilterParam}`;
      }else{
        url = `${apiUrl}/${resource}/export_to_csv`;
      }
    }
    else if(resource === 'admins'){
      if(filterValues !== undefined && Object.keys(filterValues).length > 0){
        let newFilterValues ={
          ...filterValues,
        };
        const filterParam = encodeURIComponent(JSON.stringify(newFilterValues));
        const decodedFilterParam = decodeURIComponent(filterParam);
        url = `${apiUrl}/${resource}/export_to_csv?filter=${decodedFilterParam}`;
      }else{
        url = `${apiUrl}/${resource}/export_to_csv`;
      }
    }
    else{
      url = `${apiUrl}/${resource}/export_to_csv`;
    }
    try {
      let response = await axios({
        method: 'GET',
        url: url,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'content-range',
          Authorization: 'Bearer ' + localStorage.getItem('auth'),
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'text/csv',
        },
        responseType: 'arrayBuffer',
      });
      if (response) {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'downloaded_file.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        customToast('success', 'CSV downloaded succesfuly!');
      }
    } catch (error) {
      customToast('error', 'There was an error, please try again later.');

      throw new Error(error);
    }
  };
  
  return {
    downLoadCsv
  };
};