import { useState,useRef} from 'react';
import { useDataProvider, useGetList, usePermissions, List, Datagrid,TextField, FunctionField, useRefresh, useStore,useResourceContext,useListContext,useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// Hooks & Utils
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { COLORS } from '../../assets/constants/theme';
// Components
import DeleteModal from '../../Components/DeleteModal/DeleteModal';
import { CsvDownloader } from '../../Components/ReportButtons/Csv/CsvDownloader';
import {Button} from '../../Components/Generics';
import { IconButton } from '../../Components/Generics';
import ActionButtons from '../../Components/StyledComponents/ActionButtons';
// Materials & Icons
import { Typography, Alert, Popover } from '@mui/material';
import { HiDotsVertical } from 'react-icons/hi';

export const ContactInformation = () => {
  const params = useParams();
  const talent_id = params['*']?.split('/')[1] ?? params?.id;
  const permisos = usePermissions();
  const [selectedTalent] = useStore('selectedTalent', 'selectedTalent', { selected: false, talent: '' });
  const newtalent_id = selectedTalent?.talent;
  const [openModal, setOpenModal] = useState(false);
  const [filter ,setFilter]= useState({
    talent_id: permisos.permissions !== 'talent_manager' ? talent_id : newtalent_id,

  });
  const { data } = useGetList('admins', { filter: { ...filter } });
  const redirect = useRedirect();
  const handlerCreate = () => {
    redirect(`/admins/create/talentId=${newtalent_id}`);
  };
  return (
    <>
      {permisos?.permissions === 'talent_manager' && (
        <div style={{ marginTop: '20px' }}>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight={400}
            alignSelf={'flex-start'}
          >
              📧 Contact Information
          </Typography>
          <Typography
            variant="subtitle1"
            fontWeight={400}
            sx={{ pb: 2 }}
            alignSelf={'flex-start'}
            gutterBottom
          >
              Use this section to update contact details and add team members to your team
          </Typography>
          <Alert severity="info" >
            <Typography>
              <b>REMEMBER! </b>Each user you create will have the ability to modify and submit your profile and events information.
            </Typography>
          </Alert>
        </div>
      )}
      <div style={{marginTop:'3em'}}>
        <List actions={<ListActions handlerCreate={handlerCreate} talentId={talent_id} setFilter={setFilter}/>} pagination={false} filter={filter} resource='admins' setFilter={setFilter} empty={false} >
          <Datagrid
            empty={<EmptyContactList/>}
            sx={{
              padding:'0px 20px',
              '& .RaDatagrid-row': {
                justifyContent: 'space-between',
              },
              '& .MuiTableCell-body': {
                borderBottom:'0px'
              },
              '& .RaDatagrid-headerCell': {
                color: '#7C7C7C',
                fontWeight: 400
              },
              '& .MuiTypography-root':{
                color:'#000000',
                fontWeight:400
              },
              '& .MuiTableSortLabel-root':{
                color:COLORS.table.headerSorteable,
                fontWeight:400
              },
              '& .column-name': {
                width: permisos?.permissions !== 'talent_manager' ? '30%' : '40%',
              },
              '& .column-email': {
                width: '30%'
              },
              '& .column-role': {
                width: '30%'
              }
            }}
            data={data}
            bulkActionButtons={false}
          >
            <FunctionField source="name" label='Member' render={(record, index) => <Typography color={'#000000'} fontWeight={600} fontSize={'0.875rem'}>{record?.name}</Typography>}/>
            <TextField source="email" />
            <FunctionField source="manager_role" label='Role' render={(record, index) => <Typography color={'#000000'} fontWeight={600} fontSize={'0.875rem'}>{record?.manager_role && record?.manager_role[0]}</Typography>}/>
            {permisos?.permissions !== 'talent_manager' && <FunctionField label="" render={(record, index) => <AdminActions setOpen={setOpenModal} record={record} fromTalent={newtalent_id}/>} />}
          </Datagrid>
        </List>
      </div>
    </>

  );
};
export const ListActions = ({handlerCreate,talentId})=>{
  const { filterValues } = useListContext();
  const permission = usePermissions();
  const palette = useSelector((state) => state?.palette?.palette);

  return(
    <div style={{height:'50px',width:'100%'}}>
      <div style={{width: '100%',display:'flex',flexDirection: 'row', justifyContent: 'flex-end',gap:'20px', height:'100%'}}>
        <CsvDownloader minWidth={'(min-width:1060px)'} resource={'admins'} filterValues={filterValues} talent={talentId}/>
        {permission?.permissions !== 'talent_manager' && <ActionButtons sx={{width: '100px', textTransform: 'capitalize',height:'100%'}} noIcon onClick={handlerCreate} label={'+ Add'} palette={palette} />}
      </div>
    </div>
  );
};

export const AdminActions = ({ record,setOpen, fromTalent}) => {
  const dataProvider = useDataProvider();
  const [openPopover, setOpenPopover] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const ref = useRef(null);
  const customToast = useCustomToasty();
  const refresh = useRefresh();
  const resource = useResourceContext();  
  const redirect = useRedirect();
  const palette = useSelector((state)=> state?.palette?.palette);
  const confirmDelete = async (iddelete) => {
    try {
      let request = await dataProvider.delete(resource, { id: iddelete });
      console.log(request);
      if (request) {
        customToast('success', 'Deleted succesfuly');
        setOpen(false);
        refresh();
      }
    } catch (error) {
      customToast('error', 'There was an error');

      throw error;
    }
  };
  const handleRedirect = (e) => {
    e.stopPropagation();
    if(fromTalent){
      redirect(`/admins/${record?.id}/talentId=${fromTalent}`);
    }else{
      redirect(`/admins/${record?.id}`);
    }
  };
  const handleOpenPopover = (e)=>{
    e.stopPropagation();
    setOpenPopover(!openPopover);
  };
  const handleDeleteModal = (e)=>{
    e.stopPropagation();
    setOpenDeleteModal(!openDeleteModal);
  };
  return (
    <div key={record.id}>
      <div ref={ref}><IconButton style={{height:'38px',width:'38px'}} onClick={handleOpenPopover} icon={<HiDotsVertical size={34} color={palette?.primary}/>}/></div>
      <Popover
        open={openPopover}
        anchorEl={ref?.current}
        onClose={handleOpenPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '10em',
          },
          '& .MuiPopover-paper': {
            borderRadius: '10px',
          },
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column',alignItems:'flex-start', backgroundColor: '#ECECEC', borderRadius: '10px' }}>
          <Button style={{textTransform:'uppercase', fontWeight:500, fontSize: '0.8em',color:'black', justifyContent: 'flex-start',padding:'10px',width:'100%'}} onClick={handleRedirect} >
            Edit
          </Button>
          <Button style={{textTransform:'uppercase',fontWeight:500, fontSize: '0.8em',color:COLORS.table.indicators.fontColor.rejected, justifyContent: 'flex-start',padding:'10px',width:'100%'}} onClick={handleDeleteModal}>
            Remove Member
          </Button>
          {openDeleteModal && (
            <DeleteModal setOpenModal={setOpenDeleteModal} openModal={handleDeleteModal} idToAction={record?.id} confirmDelete={confirmDelete} textAction={'Member'} preventRowClick={true}/>
          )}
        </div>
      </Popover>
    </div>
  );
};
export const EmptyContactList= ()=>{
  return (
    <div style={{padding:'10px', display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems: 'center', width: '100%',backgroundColor:'transparent' }}>
      <Typography variant='h6' fontWeight={400}>No contacts associated with this talent</Typography>
    </div>
  );
};