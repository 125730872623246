import CreateVenues from './Form/CreateVenues';
import EditVenues from './Form/EditVenues';
import VenuesList from './VenuesList';
import { TbMap2 } from 'react-icons/tb';

export default {
  list: VenuesList,
  create: CreateVenues,
  edit: EditVenues,
  icon: TbMap2
};