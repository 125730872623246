import React, { useState, useRef } from 'react';
import {
  useRedirect,
  useDataProvider,
  useRefresh,
  useResourceContext,
  useStore,
} from 'react-admin';
//Hooks & Utils
import { COLORS } from '../../assets/constants/theme';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
//Components
import DeleteModal from '../DeleteModal/DeleteModal';
// Material & Icons
import { Popover, IconButton } from '@mui/material';
import { Button } from '../Generics/index';
import { HiDotsVertical } from 'react-icons/hi';
// COMPONENTE tipo POPOVER con 3 botones los cuales proveen de manera accesible 3 casos de uso con las submissions. DELETE - REVIEW - EDIT
export const SubmissionQuickNavigate = ({
  record,
  openEditModal,
  setOpen,
  redirectEdit,
  from,
}) => {
  const setEditSubmission = useStore('edit_submission', false);
  const dataProvider = useDataProvider();
  const [openPopover, setOpenPopover] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const ref = useRef(null);
  const customToast = useCustomToasty();
  const refresh = useRefresh();
  const resource = useResourceContext();
  const redirect = useRedirect();
  const handleDeleteModal = (e) => {
    e.stopPropagation();
    setOpenDeleteModal(!openDeleteModal);
  };
  const confirmDelete = async (iddelete, e) => {
    e.stopPropagation();
    try {
      let request = await dataProvider.delete(resource, { id: iddelete });
      console.log(request);
      if (request) {
        customToast('success', 'Deleted succesfuly');
        setOpen(false);
        refresh();
      }
    } catch (error) {
      customToast('error', 'There was an error');

      throw error;
    }
  };
  const redirectProfile = (e) => {
    e.stopPropagation();
    setEditSubmission[1](true);
    return redirect('edit', resource, record?.id);
  };
  const redirectReview = (e) => {
    e.stopPropagation();
    if (from === 'talents') {
      return redirect('show', 'talents', record?.id);
    } else {
      return redirect('show', 'events', record?.id);
    }
  };
  const handleOpenPopover = (e) => {
    e.stopPropagation();
    setOpenPopover(!openPopover);
  };

  return (
    <div key={record.id}>
      <IconButton ref={ref} onClick={handleOpenPopover} sx={{ zIndex: 9999 }}>
        <HiDotsVertical />
      </IconButton>
      <Popover
        open={openPopover}
        anchorEl={ref.current}
        onClose={handleOpenPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          zIndex: 9999,
          '& .MuiPaper-root': {
            width: '10em',
          },
          '& .MuiPopover-paper': {
            borderRadius: '10px',
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            backgroundColor: '#ECECEC',
            borderRadius: '10px',
          }}
        >
          <Button
            style={{
              fontSize: '0.8em',
              color: 'black',
              justifyContent: 'flex-start',
              padding: '10px',
              width: '100%',
            }}
            onClick={redirectReview}
          >
            Review Submission
          </Button>
          {redirectEdit && (
            <Button
              style={{
                fontSize: '0.8em',
                color: 'black',
                justifyContent: 'flex-start',
                padding: '10px',
                width: '100%',
              }}
              onClick={redirectProfile}
            >
              Edit Submission
            </Button>
          )}
          <Button
            style={{
              fontSize: '0.8em',
              color: COLORS.table.indicators.fontColor.rejected,
              justifyContent: 'flex-start',
              padding: '10px',
              width: '100%',
            }}
            onClick={handleDeleteModal}
          >
            Delete
          </Button>
          {openDeleteModal && (
            <DeleteModal
              setOpenModal={handleDeleteModal}
              openModal={open}
              idToAction={record?.id}
              confirmDelete={confirmDelete}
              textAction={from === 'talents' ? 'Talent' : 'Event'}
            />
          )}
        </div>
      </Popover>
    </div>
  );
};
