import { TextField } from '@mui/material';

export const EditInterestTitle = ({ maxLength = 40, index, titleValue, onEditChange }) => (
  <TextField 
    key="edit-interest-title"
    inputProps={{ maxLength: maxLength }}
    defaultValue={titleValue} 
    sx={{ minWidth: '300px', width: '100%', height: 'auto' }}
    multiline
    variant="outlined"
    onChange={(e) => onEditChange(e, index)}
  />
);