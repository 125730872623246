import { useInput, useRecordContext } from 'react-admin';
import { InputTitle } from '../StyledComponents/InputTitle';
import { Autocomplete, TextField } from '@mui/material';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '../Generics/index';

const formatOption = (option, arr) => {
  if (!option) return '';
  if (arr && arr.length > 0) {
    let found = arr.find((item) => item.name === option);
    return `GMT${found?.offset < 0 ? found?.offset : '+' + found?.offset} ${found?.name}`;
  }
  return `GMT${option?.offset < 0 ? option?.offset : '+' + option?.offset} ${option?.name}`;
};

export const TimezoneSelect = ({
  source,
  validate,
  title,
  subtitle,
  ...rest
}) => {
  const record = useRecordContext();
  const palette = useSelector((state) => state?.palette?.palette);

  const [inputValue, setInputValue] = useState(
    record ? formatOption(record[source]) : ''
  );
  const { field, formState } = useInput({ source, validate });
  function getTimezones() {
    const timezones = [];
    for (const tz of Intl.supportedValuesOf('timeZone')) {
      const utcHours = new Date().getUTCHours();
      const date = new Date().toLocaleString('en-US', { timeZone: tz });
      const dateHours = new Date(date).getHours();
      timezones.push({ name: tz, offset: dateHours - utcHours });
    }
    return timezones;
  }
  const timezones = getTimezones();

  const handleDetect = () => {
    const found = timezones.find(
      (item, index) =>
        item?.name === Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    setInputValue(formatOption(found));
    field.onChange(found);
  };

  const handleSelect = (e, v) => {
    setInputValue(v);
  };

  return (
    <>
      <InputTitle>{title}</InputTitle>
      <InputSubtitle>{subtitle}</InputSubtitle>
      <Autocomplete
        value={field.value}
        inputValue={inputValue}
        onChange={(e,v) => field.onChange(v)}
        onInputChange={handleSelect}
        options={timezones}
        getOptionLabel={(option) => formatOption(option)}
        isOptionEqualToValue={(option,v) => {
          return option.name === v.name && option.offset === v.offset}}
        renderInput={(params) => (
          <TextField
            {...params}
            helperText={validate && formState.errors[source] && 'Required'}
            variant='outlined'
            label={rest.label}
            error={formState.errors[source]}
          />
        )}
        {...rest}
      />
      <Button
        onClick={handleDetect}
        style={{
          borderRadius: '12px',
          backgroundColor: palette?.primary,
          color: '#fff',
          marginTop: '10px',
        }}
        hoverStyle={{ backgroundColor: palette?.primary, opacity: 0.9 }}
      >
        Detect my timezone
      </Button>
    </>
  );
};
