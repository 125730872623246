import React, { useEffect, useState } from 'react';
import { useGetList, useSidebarState } from 'react-admin';
// Hooks & Utils
import dataProvider from '../../../../dataProvider';
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';
import { actionBarFunctions } from './actionBarFunctions';
import { switchStyleIos } from '../../../../Components/StyledComponents/Inputs';
import styles from './ActionBar.module.css';
// Components
import { IconButton, TextField } from '../../../../Components/Generics';
// Material & Icons
import {
  Paper,
  Skeleton,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import { IoMdSearch, IoMdClose } from 'react-icons/io';
import { MdTune } from 'react-icons/md';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { MultiSelectFilter } from '../../../../Components/Filters/FilterComponents';

export const ActionBar = (props) => {
  const {
    totalEvents,
    zoomIn,
    zoomOut,
    style,
    drag,
    palette,
    actualPeriod,
    setActualDay,
    loadingState,
    setLoadingState,
    setFilterState,
    filterState,
  } = props;
  const { data, refetch } = useGetList('app_config', {
    pagination: { perPage: 9999, page: 1 },
  });
  const customToast = useCustomToasty();
  const isMonitor = useMediaQuery('(min-width:1301px)');
  const isNetbook = useMediaQuery('(max-width:1300px)');

  const [open, setOpen] = useSidebarState();
  const [openBox, setOpenBox] = useState(false);
  const [eventsTotal, setEventsTotal] = useState(undefined);
  const [pubSchedule, setPubSchedule] = useState(undefined);
  const [filterModal, setFilterModal] = useState(false);

  const [filterEventsTotal, setFilterEventsTotal] = useState(totalEvents);
  const [value, setValue] = useState('');
  const {
    handleOpenUnscheduled,
    handleCleanInput,
    moveToLeft,
    moveToRight,
    handleSearchInputChange,
  } = actionBarFunctions({
    setOpenBox,
    setEventsTotal,
    openBox,
    setValue,
    setFilterEventsTotal,
    totalEvents,
  });
  const handleGoToEvent = (eventId, locationId, startDate) => {
    const startDateFormat = startDate.split('T')[0];
    if (actualPeriod.some((date) => date.includes(startDateFormat))) {
      scrollInTable(eventId, locationId);
      return;
    } else {
      setActualDay(startDateFormat);
      setLoadingState(true);
      setTimeout(() => {
        setLoadingState(false);
      }, 2500);
      setTimeout(() => {
        scrollInTable(eventId, locationId);
      }, 3000);
    }
  };
  const scrollInTable = (eventId, locationId) => {
    if (loadingState === false) {
      const targetElement = document.querySelector(`[id*="id${eventId}"]`);
      const columnElement = document.querySelector(
        `[id*="venue-group-${locationId}"]`,
      );
      const eventsElement = document.getElementById(
        'datagrid-events-scrolleable',
      );
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (columnElement && eventsElement) {
          const targetRect = targetElement.getBoundingClientRect();
          const eventsRect = eventsElement.getBoundingClientRect();
          const columnRect = columnElement.getBoundingClientRect();
          const isInViewportX =
            targetRect.left >= eventsRect.left &&
            targetRect.right <= eventsRect.right;

          if (!isInViewportX) {
            const scrollX =
              columnRect.left - eventsRect.left + eventsElement.scrollLeft;
            setTimeout(() => {
              eventsElement.scrollTo({
                left: scrollX - 250,
                behavior: 'smooth',
              });
            }, 1500);
          }
        } else {
          console.warn(`No element found with ID containing: ${eventId}`);
        }
      }
    }
  };
  const publishSchedule = async () => {
    let comingSoon = data[0]?.coming_soon;
    if (comingSoon.includes('events')) {
      comingSoon = comingSoon.filter((item) => item !== 'events');
      await updateComingSoon(comingSoon, 'remove');
    } else {
      comingSoon = [...comingSoon, 'events'];
      await updateComingSoon(comingSoon, 'add');
    }
    refetch();
  };
  const updateComingSoon = async (comingSoon, action) => {
    try {
      await dataProvider.update('app_config', {
        id: data[0]?.id,
        data: {
          coming_soon: comingSoon,
        },
      });
      const successMessage = action === 'remove' ? 'Published' : 'Unpublished';
      customToast('success', successMessage);
      setPubSchedule(action === 'remove' ? false : true);
    } catch (error) {
      console.log(error);
      const errorMessage =
        action === 'remove' ? 'Error unpublishing' : 'Error publishing';
      customToast('error', errorMessage);
    }
  };
  const handlerOpenFilters = () => {
    setFilterModal(!filterModal);
  };
  const widthStyle = () => {
    if (open) {
      switch (true) {
      case isMonitor:
        return '52em';
      case isNetbook:
        return '43em';
      default:
        return '52em';
      }
    } else {
      switch (true) {
      case isMonitor:
        return '52em';
      case isNetbook:
        return '43em';
      default:
        return '52em';
      }
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (!event.target.closest('.events-box')) {
        setOpenBox(false);
      }
    };

    if (openBox) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [openBox]);
  useEffect(() => {
    if (data) {
      if (data[0]) {
        setPubSchedule(data[0].coming_soon.includes('events') ? false : true);
      }
    }
  }, [data]);

  return (
    <div
      className={styles.container}
      style={{
        left: open ? '35%' : '27%',
        bottom: isMonitor ? '4%' : '5%',
        border: `2px solid ${palette?.primary}`,
        width: widthStyle(),
        ...style,
      }}
    >
      <div className={styles.subContainer}>
        <div className={styles.firstContainer}>
          <div className={styles.containerMoveZoom}>
            <div className={styles.moveZoomDiv}>
              <IconButton
                onClick={moveToLeft}
                icon={
                  <KeyboardArrowLeftIcon
                    color="primary"
                    sx={{ height: '20px' }}
                  />
                }
              />
              <IconButton
                onClick={moveToRight}
                icon={
                  <KeyboardArrowRightIcon
                    color="primary"
                    sx={{ height: '20px' }}
                  />
                }
              />
            </div>
            <div className={styles.moveZoomDiv}>
              <IconButton
                id="zoom-buttonPlus"
                onClick={zoomIn}
                style={{
                  color: palette?.primary,
                }}
                icon={<AddIcon color="primary" sx={{ height: '20px' }} />}
              />
              <IconButton
                id="zoom-buttonMinus"
                onClick={zoomOut}
                style={{
                  color: palette?.primary,
                }}
                icon={<RemoveIcon color="primary" sx={{ height: '20px' }} />}
              />
            </div>
          </div>
          <div className={styles.publishContainer}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                width: '100%',
                height: '100%',
                gap: '5px',
              }}
            >
              <p
                style={{
                  color: '#AAA2A2',
                  fontSize: isNetbook ? '14px' : '15px',
                  fontWeight: 600,
                }}
              >
                Publish Schedule
              </p>
              {pubSchedule !== undefined ? (
                <Switch
                  sx={switchStyleIos}
                  checked={pubSchedule}
                  onChange={publishSchedule}
                />
              ) : (
                <Skeleton
                  sx={{ marginLeft: '5px', borderRadius: '20px' }}
                  animation="wave"
                  height={64}
                  width={70}
                />
              )}
            </div>
          </div>
        </div>
        <div className={styles.containerSearchUnschedule}>
          <div className={styles.searchDiv}>
            <TextField
              style={{
                width: '100%',
                backgroundColor: 'white',
                height: 'auto',
              }}
              value={value}
              onChange={handleSearchInputChange}
              placeholder={'Search events'}
              icon={<IoMdSearch size={24} />}
              clearIcon={
                <IconButton
                  onClick={handleCleanInput}
                  style={{
                    height: '23px',
                    width: '23px',
                    padding: 0,
                    backgroundColor: '#D9D9D9',
                  }}
                  icon={<IoMdClose size={14} sx={{ color: '#AAA2A2' }} />}
                />
              }
            />
          </div>
          <div className={styles.filterDiv}>
            <IconButton
              style={{
                backgroundColor: filterModal ? '#D9D9D9' : '',
              }}
              icon={<MdTune size={100} />}
              onClick={handlerOpenFilters}
            />
            {filterModal && (
              <FiltersBox
                setFilterState={setFilterState}
                openSidebar={open}
                filterState={filterState}
              />
            )}
          </div>
          <div className={styles.unscheduleDiv}>
            <button
              onClick={handleOpenUnscheduled}
              style={{
                backgroundColor: palette?.primary,
              }}
              className={styles.unscheduleBt}
            >
              <EventBusyIcon sx={{ height: '20px' }} />
              <p>
                {totalEvents
                  ? totalEvents?.filter(
                    (item) =>
                      item.start_date === null && item.end_date === null,
                  ).length
                  : 0}
              </p>
            </button>
          </div>
          {openBox ? (
            eventsTotal ? (
              value.length > 0 && (
                <EventsBox
                  events={filterEventsTotal}
                  drag={drag}
                  total={true}
                  handleGoToEvent={handleGoToEvent}
                  openSidebar={open}
                />
              )
            ) : (
              <EventsBox
                events={totalEvents?.filter(
                  (item) => item.start_date === null && item.end_date === null,
                )}
                drag={drag}
                total={false}
                openSidebar={open}
              />
            )
          ) : null}
        </div>
      </div>
    </div>
  );
};
export const EventsBox = ({
  events,
  drag,
  total,
  handleGoToEvent,
  openSidebar,
}) => {
  const isMonitor = useMediaQuery('(min-width:1301px)');
  const isNetbook = useMediaQuery('(max-width:1300px)');
  const leftStyle = () => {
    if (total) {
      switch (true) {
      case openSidebar && isMonitor:
        return '27rem';
      case openSidebar && isNetbook:
        return '20rem';
      case !openSidebar && isMonitor:
        return '25rem';
      case !openSidebar && isNetbook:
        return '20rem';
      default:
        return '30rem';
      }
    } else {
      switch (true) {
      case openSidebar && isMonitor:
        return '35rem';
      case openSidebar && isNetbook:
        return '30rem';
      case !openSidebar && isMonitor:
        return '40rem';
      case !openSidebar && isNetbook:
        return '35rem';
      default:
        return '30rem';
      }
    }
  };

  return (
    <Paper
      className="events-box"
      elevation={10}
      sx={{
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: 999,
        bottom: '50px',
        left: leftStyle(),
        width: total ? '300px' : '250px',
        maxHeight: '200px',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        borderRadius: '10px',
      }}
    >
      {events?.map((event, index) => (
        <div
          onClick={
            event.start_date === null
              ? null
              : total
                ? () =>
                  handleGoToEvent(
                    event.id,
                    event.location_id,
                    event.start_date,
                  )
                : null
          }
          draggable={event.start_date === null ? true : false}
          id={
            event.start_date === null
              ? `unscheduledEvent-${event.id}`
              : `eventBox-${event.id}`
          }
          onDragStart={drag}
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '10px',
            padding: '10px',
            textAlign: 'left',
            cursor: total
              ? 'pointer'
              : event.start_date === null
                ? 'pointer'
                : 'default',
          }}
        >
          {event.start_date !== null && event.end_date !== null ? (
            <EventAvailableIcon color="success" />
          ) : (
            <EventBusyIcon />
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              flex: 1,
            }}
          >
            <div style={{ marginRight: 'auto' }}>
              <Typography
                color={
                  event.start_date !== null && event.end_date !== null
                    ? '#21B141'
                    : 'black'
                }
                sx={{ fontWeight: 600, fontSize: '14px' }}
              >
                {event.name}
              </Typography>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <Typography
                color={
                  event.start_date !== null && event.end_date !== null
                    ? '#21B141'
                    : 'black'
                }
                sx={{ fontSize: '14px' }}
              >
                {event.duration}min
              </Typography>
            </div>
          </div>
        </div>
      ))}
    </Paper>
  );
};
export const FiltersBox = ({ setFilterState, openSidebar, filterState }) => {
  const handlerLocations = (e, v) => {
    setFilterState((f) => ({
      ...f,
      filterVenues: v?.map((item) => item?.id),
    }));
  };
  const handlerCategorys = (e, v) => {
    setFilterState((f) => ({
      ...f,
      filterCategorys: v?.map((item) => item?.text),
    }));
  };
  const isMonitor = useMediaQuery('(min-width:1301px)');
  const isNetbook = useMediaQuery('(max-width:1300px)');
  const leftStyle = () => {
    if (openSidebar) {
      switch (true) {
      case isMonitor:
        return '37rem';
      case isNetbook:
        return '30rem';
      default:
        return '30rem';
      }
    } else {
      switch (true) {
      case isMonitor:
        return '40rem';
      case isNetbook:
        return '30rem';
      default:
        return '30rem';
      }
    }
  };
  return (
    <Paper
      className="filters-box"
      sx={{
        position: 'absolute',
        backgroundColor: 'white',
        zIndex: 999,
        width: '200px',
        borderRadius: '10px',
        top: '-120px',
        left: leftStyle(),
        padding: '0px 10px 10px 10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
      }}
    >
      <MultiSelectFilter
        responsive={'100%'}
        fetchUrl={'locations'}
        label={'Locations'}
        from={'schedule'}
        _callback={handlerLocations}
        textFieldVariant="standard"
        scheduleFilter={filterState}
        scheduleOptionBase={true}
        filterSchedule="location"
      />
      <MultiSelectFilter
        responsive={'100%'}
        fetchUrl={'events/categories'}
        label={'Category'}
        textFieldVariant="standard"
        from={'schedule'}
        scheduleFilter={filterState}
        _callback={handlerCategorys}
        scheduleOptionBase={false}
        filterSchedule="category"
      />
    </Paper>
  );
};
