import { toast } from 'react-toastify';

export const useCustomToasty = () => {

  const customToast = (type, msg, options) => {

    if(type === 'success') {
      return toast.success(msg, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        // ...options
      });
    }
    if(type === 'info') {
      return toast.info(msg, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        // ...options
      });
    }
    if(type === 'error') {
      return toast.error(msg, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
        // ...options
      });
    }
    if(type === 'warning') {
      return toast.warn(msg, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return customToast;
};

