import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import Map, {
  Marker,
  NavigationControl,
  GeolocateControl,
  Layer,
  Source,
} from 'react-map-gl';
import { useFormContext } from 'react-hook-form';
import { useGetList, useInput, useRecordContext } from 'react-admin';
import { useSelector } from 'react-redux';
import { useControl } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
// Hooks & Utils
import { dynamicColor } from '../../../../helpers/dynamicColor';
import { LOCATIONS_TOOLTIPS } from '../../../TalentForm/Tooltips';
// Components
import InputText from '../../../../Components/InputsForm/InputText';
import { switchStyleIos } from '../../../../Components/StyledComponents/Inputs';
import { InputSubtitle } from '../../../../Components/StyledComponents/InputSubtitle';
import { InputImage } from '../../../../Components/InputsForm/InputImage';
// Material & Icons
import { Typography, TextField, Switch } from '@mui/material';
import { Button } from '../../../../Components/Generics';

const VenueMap = () => {
  const [allCoordinates, setAllCoordinates] = useState(undefined);
  const CENTER_COORD = useSelector((state) => {
    return state?.data?.info?.location?.geometry?.coordinates;
  });
  const { getValues } = useFormContext();
  const record = useRecordContext();
  const { refetch } = useGetList(
    'locations',
    {
      filter: {
        geo_tagged: true,
      },
    },
    {
      onSuccess: ({ data }) => {
        const onlyCoordinates = data?.flatMap((location) =>
          location?.location?.map((feature) => ({
            type: feature.geometry.type,
            coordinates: feature.geometry.coordinates,
            name: location.name,
            icon: location.icon,
            id: location.id,
          })),
        );
        setAllCoordinates(onlyCoordinates);
      },
    },
  );
  const [showMap, setShowMap] = useState(false);
  useEffect(() => {
    const form = getValues();
    let location = form.location;
    if (location?.length >= 1) {
      setShowMap(true);
    }
  }, []);
  return (
    <>
      {!CENTER_COORD ? (
        <Typography>
          You can not put this location on the map as the festival owner has not
          established the location of the festival, please contact them
        </Typography>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'flex-start',
            marginBottom: '20px',
          }}
        >
          <InputSubtitle>
            Mark this if your location will be visible in the interactive app
            map
          </InputSubtitle>
          <Switch
            sx={{ ...switchStyleIos }}
            checked={showMap}
            onClick={() => setShowMap(!showMap)}
          />
        </div>
      )}
      {showMap && CENTER_COORD && (
        <>
          <InputImage
            title={'Icon'}
            tooltip={LOCATIONS_TOOLTIPS['icon']}
            source="icon"
            action="url"
          />
          <InputText
            source={'brief_description'}
            fullWidth
            counter={150}
            title={'Brief description'}
            subtitle={'This is the description that will appear in the map pin'}
          />
          <MapComponent
            CENTER_COORD={CENTER_COORD}
            allCoordinates={allCoordinates}
          />
        </>
      )}
    </>
  );
};
//Con el nuevo geojson
export const MapComponent = ({ CENTER_COORD, allCoordinates }) => {
  //Hooks
  const mapRef = useRef(null);
  const { getValues, setValue } = useFormContext();
  const [selectedElement, setSelectedElement] = useState(null);
  const [isBorderOn, setIsBorderOn] = useState(false);
  const form = getValues();
  const title = form.name;
  const icon = form.icon;
  const lng =
    form?.location?.length >= 1
      ? form.location[0]?.type === 'Point'
        ? form.location[0]?.geometry?.coordinates?.[0]
        : CENTER_COORD[0]
      : CENTER_COORD[0];
  const lat =
    form?.location?.length >= 1
      ? form.location[0]?.type === 'Point'
        ? form.location[0]?.geometry?.coordinates?.[1]
        : CENTER_COORD[1]
      : CENTER_COORD[1];
  const { field } = useInput({
    source: 'location',
    defaultValue: [],
  });
  const allCoordinatesNoOriginal = allCoordinates?.filter(
    (location) => location?.id !== form?.id,
  );

  //Functions
  function addMarker(event) {
    const newFeatures = event.features;
    console.log(newFeatures, 'nueva');
    if (newFeatures && newFeatures.length > 0) {
      let updatedFeatures = [...field.value];
      updatedFeatures.push(newFeatures[0]);
      field.onChange(updatedFeatures);
      setValue('location', updatedFeatures);
    }
  }

  function DrawControl(props) {
    useControl(
      ({ map }) => {
        const draw = new MapboxDraw(props);

        map.on('draw.create', (e) => {
          if (props.onCreate) {
            props.onCreate(e);
          }
        });

        map.on('draw.update', (e) => {
          if (props.onUpdate) {
            props.onUpdate(e);
          }
        });
        return draw;
      },
      ({ map }) => {
        map.off('draw.create', props.onCreate);
        map.off('draw.update', props.onUpdate);
      },
      {
        position: props.position,
      },
    );

    return null;
  }
  // Handlers
  const handlerClickPolygon = (index, feature) => {
    const map = mapRef.current.getMap();
    const clickedPolygon = field.value.find((location, i) => {
      return location.geometry.type === 'Polygon' && i === index;
    });

    if (clickedPolygon && map) {
      const layerId = `polygon-layer-${index}`;
      map.setPaintProperty(layerId, 'fill-outline-color', '#f00');
      setSelectedElement({ type: 'polygon', element: clickedPolygon });
      setIsBorderOn(true);
      field?.value?.forEach((location, index) => {
        if (
          location?.geometry?.coordinates ===
          clickedPolygon?.geometry?.coordinates
        ) {
          return;
        }
        if (location?.type === null) {
          return;
        }
        if (location?.geometry?.type === 'Polygon') {
          const layerId = `polygon-layer-${index}`;
          map.setPaintProperty(layerId, 'fill-outline-color', 'transparent');
        }
      });
    } else {
      console.log('No polygon found for index:', index);
    }
  };
  const handleCreate = (event) => {
    addMarker(event);
  };
  const handleUpdate = (e) => {
    console.log(e, 'update e');
  };
  const handlerClickMap = (e) => {
    let target = e.originalEvent?.target?.id;
    if(target === 'pinValue') return;
    setSelectedElement(null);
    if(isBorderOn) {
      field?.value?.forEach((location, index) => {
        if (location?.type === null) {
          return;
        }
        if (location?.geometry?.type === 'Polygon') {
          const layerId = `polygon-layer-${index}`;
          mapRef.current.getMap().setPaintProperty(
            layerId,
            'fill-outline-color',
            'transparent',
          );
        }
      });
    }
  };
  const onShowLatLng = (e) => {
    let value = e.target._lngLat;
    setSelectedElement({ type: 'point', element: value });
  };
  const onDeleteElement = (e) => {
    if (selectedElement.type === 'polygon') {
      const updatedFeatures = field.value.filter((feature) => {
        return (
          feature.geometry.coordinates !==
          selectedElement.element.geometry.coordinates
        );
      });
      field.onChange(updatedFeatures);
      setValue('location', updatedFeatures);
    } else {
      let value = selectedElement.element.lat;
      let value2 = selectedElement.element.lng;
      const updatedFields = field.value.filter((feature) => {
        return (
          feature.geometry.coordinates[0] !== value2 &&
          feature.geometry.coordinates[1] !== value
        );
      });
      field.onChange(updatedFields);
      setValue('location', updatedFields);
    }
  };
  const onMarkerDragStart = useCallback(() => {}, []);
  const onMarkerDrag = useCallback(() => {}, []);
  const onMarkerDragEnd = useCallback(
    (event, coordinates) => {
      const newLngLat = event.lngLat;
      const updatedFeatures = field.value.map((feature) => {
        if (feature.geometry.coordinates === coordinates) {
          return {
            ...feature,
            geometry: {
              ...feature.geometry,
              coordinates: [newLngLat.lng, newLngLat.lat],
            },
          };
        }
        return feature;
      });
      field.onChange(updatedFeatures);
      setValue('location', updatedFeatures);
    },
    [field, setValue],
  );
  useEffect(() => {
    if (!mapRef.current) return;
    const map = mapRef.current.getMap();
    field?.value?.forEach((location, index) => {
      if (location?.type === null) {
        return;
      }
      if (location?.geometry?.type === 'Polygon') {
        const layerId = `polygon-layer-${index}`;

        map.on('mouseenter', layerId, () => {
          map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', layerId, () => {
          map.getCanvas().style.cursor = '';
        });

        map.on('click', layerId, (e) => {
          e?.originalEvent?.stopPropagation();
          handlerClickPolygon(index, e.features[0]);
        });
      }
    });

    return () => {
      if (map) {
        field?.value?.forEach((location, index) => {
          if (location?.type === null) {
            return;
          }
          if (location?.geometry?.type === 'Polygon') {
            const layerId = `polygon-layer-${index}`;
            map.off('mouseenter', layerId);
            map.off('mouseleave', layerId);
            map.off('click', layerId);
          }
        });
      }
    };
  }, [mapRef.current, field.value]);
  return (
    <>
      <InputSubtitle>
        Select from the map where your location will be, by marking the pin you
        will be able to see how it looks against other locations
      </InputSubtitle>
      <Map
        ref={mapRef}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapLib={import('mapbox-gl')}
        initialViewState={{
          longitude: lng,
          latitude: lat,
          zoom: 15,
        }}
        style={{ height: '60vh', width: '100%', marginTop: '10px' }}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        attributionControl={false}
        onClick={handlerClickMap}
      >
        {selectedElement && (
          <div
            style={{
              position: 'absolute',
              bottom: '5px',
              right: '10px',
              zIndex: 100,
              backgroundColor: '#000000',
              borderRadius: '10px',
            }}
          >
            {selectedElement.type !== 'polygon' && (
              <Button
                style={{
                  color: '#ffffff',
                  fontWeight: 600,
                }}
              >
                {selectedElement?.element.lng +
                  ', ' +
                  selectedElement?.element.lat}
              </Button>
            )}
            <Button
              style={{
                fontWeight: 700,
                color: '#C94A4A',
              }}
              onClick={onDeleteElement}
            >
              Delete
            </Button>
          </div>
        )}
        <NavigationControl />
        <GeolocateControl />
        <DrawControl
          displayControlsDefault={false}
          controls={{
            polygon: true,
            point: true,
          }}
          position="top-right"
          onCreate={handleCreate}
          onUpdate={handleUpdate}
        />
        {field?.value?.map((location, index) => {
          if (
            location.type === null &&
            location?.geometry?.coordinates?.length < 2
          ) {
            return;
          }
          if (
            location.type === null &&
            location?.geometry?.coordinates?.length === 2
          ) {
            <Marker
              key={`original-noType-${index}`}
              id={'markerValue'}
              anchor="bottom"
              longitude={location?.geometry.coordinates[0]}
              latitude={location?.geometry.coordinates[1]}
              draggable
              onClick={onShowLatLng}
              onDragStart={onMarkerDragStart}
              onDrag={onMarkerDrag}
              onDragEnd={(event) =>
                onMarkerDragEnd(event, location.geometry.coordinates)
              }
            >
              {icon?.url ? (
                <LocationPin pin={true} src={icon?.url} titleForPin={title} />
              ) : (
                <LocationPin titleForPin={title} src={icon?.url} />
              )}
            </Marker>;
          }
          if (location?.geometry?.type === 'Polygon') {
            return (
              <Source
                key={`polygon-source-${index}`}
                id={`polygon-source-${index}`}
                type="geojson"
                data={location}
              >
                <Layer
                  id={`polygon-layer-${index}`}
                  type="fill"
                  paint={{
                    'fill-color': '#892fdb',
                    'fill-opacity': 0.5,
                  }}
                />
              </Source>
            );
          } else if (location.geometry?.coordinates?.length === 2) {
            return (
              <Marker
                key={`original-${index}`}
                anchor="bottom"
                longitude={location?.geometry.coordinates[0]}
                latitude={location?.geometry.coordinates[1]}
                draggable
                onClick={onShowLatLng}
                onDragStart={onMarkerDragStart}
                onDrag={onMarkerDrag}
                onDragEnd={(event) =>
                  onMarkerDragEnd(event, location.geometry.coordinates)
                }
              >
                {icon?.url ? (
                  <LocationPin pin={true} src={icon?.url} titleForPin={title} />
                ) : (
                  <LocationPin titleForPin={title} src={icon?.url} />
                )}
              </Marker>
            );
          }
        })}
        {allCoordinatesNoOriginal?.map((location, index) => {
          if (location.type === 'Polygon') {
            return (
              <Source
                key={`rest-polygon-source-${index}`}
                id={`rest-polygon-source-${index}`}
                type="geojson"
                data={location}
              >
                <Layer
                  id={`rest-polygon-layer-${index}`}
                  type="fill"
                  paint={{
                    'fill-color': '#892fdb',
                    'fill-opacity': 0.5,
                  }}
                />
              </Source>
            );
          } else if (location.coordinates.length === 2) {
            return (
              <Marker
                key={`rest-marker-${index}`}
                anchor="bottom"
                longitude={location?.coordinates[0]}
                latitude={location?.coordinates[1]}
              >
                {location?.icon?.url ? (
                  <LocationPin
                    pin={true}
                    src={location?.icon?.url}
                    titleForPin={location.name}
                  />
                ) : (
                  <LocationPin titleForPin={location.name} />
                )}
              </Marker>
            );
          }
        })}
      </Map>
    </>
  );
};

export const LocationPin = ({ size = 30, pin, src, titleForPin, original }) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const backgroundColor = dynamicColor(palette?.primary, 0.2)?.rgba;

  const pinStyle = {
    cursor: 'pointer',
    fill: original ? palette?.primary : '#348feb',
    stroke: 'none',
  };

  return (
    <>
      {pin === true ? (
        <div
          id={'pinValue'}
          style={{
            padding: 1,
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <img src={src} style={{ width: size, height: size }} id={'pinValue'}/>
          {titleForPin && (
            <div
              id={'pinValue'}
              style={{
                backgroundColor: original && 'white',
                borderRadius: '10px',
              }}
            >
              <span
                id={'pinValue'}
                style={{
                  color: original ? 'black' : 'white',
                  fontSize: '14px',
                  backgroundColor: original ? backgroundColor : '#000000AD',
                  padding: 5,
                  borderRadius: '10px',
                }}
              >
                {titleForPin}
              </span>
            </div>
          )}
        </div>
      ) : (
        <div
          id={'pinValue'}
          style={{
            padding: 1,
            margin: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <div
            id={'pinValue'}
            style={{
              backgroundColor: original && 'white',
              borderRadius: '10px',
            }}
          >
            <span
              id={'pinValue'}
              style={{
                color: original ? 'black' : 'white',
                fontSize: '14px',
                backgroundColor: original ? backgroundColor : '#000000AD',
                padding: 5,
                borderRadius: '10px',
              }}
            >
              {titleForPin}
            </span>
          </div>
          <svg id={'pinValue'} height={size} viewBox="0 0 24 24" style={pinStyle}>
            <circle id={'pinValue'} cx="10" cy="10" r="6" />
          </svg>
        </div>
      )}
    </>
  );
};

export const CustomTextInput = ({
  label,
  value,
  inputProps,
  onChange,
  errors,
}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography fontSize={'14.4px'} color={'rgba(0, 0, 0, 0.92)'}>
        {label}
      </Typography>
      <TextField
        type="number"
        fullWidth
        variant="standard"
        value={value}
        onChange={onChange}
        InputProps={{
          InputProps: {
            sx: {
              '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                appearance: 'none',
                margin: 0,
              },
            },
          },
          sx: {
            padding: 0,
          },
          ...inputProps,
        }}
        sx={{ '& .MuiInputBase-input': { padding: 0, margin: 0 } }}
      />
    </div>
  );
};

export default VenueMap;
