import { useEffect } from 'react';

export const useOnClickOutside = (ref, handler) => {
  const newListener = (event) => {
    let found = ref?.find(item => {
      if(!item.current || item.current.contains(event.target)) {
      
        return item;
      } 
    });
    if (!found && event.target.tagName !== 'LI') return handler(event);
    return;
  };


  const listener = (event) => {

    if (!ref.current || ref.current.contains(event.target)) {
      return;
    }
    if (event.target.tagName !== 'LI') {
      handler(event);
    }
  };

  useEffect(
    () => {
      if(ref?.length >= 2){
        document.addEventListener('mousedown', newListener);
        document.addEventListener('touchstart', newListener);
      } else {
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
      }
      return () => {
        if(ref.length >= 2){
          document.removeEventListener('mousedown', newListener);
          document.removeEventListener('touchstart', newListener);

        } else {

          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        }
      };
    },
    [ref, handler]
  );
};