import React, { useState } from 'react';
import Ravelogo from '../../assets/images/Rave_logo_2.png';
import { dynamicColor } from '../../helpers/dynamicColor';
import { useSelector } from 'react-redux';
const container={
  display:'flex',
  flexDirection:'column',
  alignItems:'center',
  height:'100%',
  width:'100%',
  justifyContent:'center'
};
const containerText={
  display:'flex',
  flexDirection:'column',
  alignItems:'center',
  color:'#000000'
};
const textStyle={
  fontSize: '24px',
  fontWeight: '700',
  margin:0,
  padding:0,
  color:'#474747'
};

const ErrorCustom = () => {
  const [isHovered, setIsHovered] = useState(false);
  const palette = useSelector((state) => state?.palette?.palette);
  const backgroundColorBt = dynamicColor(palette?.primary,0.4)?.rgba;
  const buttonStyle = {
    width: '200px',
    height: '40px',
    color: 'white',
    backgroundColor: backgroundColorBt, 
    borderRadius: '5px',
    marginTop: '30px',
    fontWeight: '600',
    fontSize: '14px',
    border: 'none',
    boxShadow: isHovered ? '0px 6px 12px rgba(0, 0, 0, 0.3)' : '0px 4px 10px rgba(0, 0, 0, 0.18)',
    transition: 'box-shadow 0.3s ease',
    cursor: 'pointer', 
  };
  const showHelpscout = () => {
    window.Beacon('init', process.env.REACT_APP_HELPSCOUT_ID);
    window.Beacon('open');
    window.Beacon('navigate','/ask');
  };
  return (
    <div style={container}>
      <img src={Ravelogo} style={{width: '400px', height: '100px', objectFit: 'contain'}}/>
      <div style={containerText}>
        <p style={{fontSize:'30px',fontWeight:'900',margin:0,padding:0, color:palette ? palette?.primary : 'black'}}>Whoops...</p>
        <p style={textStyle}>an error has been found</p>
      </div>
      <button style={buttonStyle} 
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)} onClick={showHelpscout}>Report</button>
    </div>
  );
};

export default ErrorCustom;