// Hooks & Utils
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';
// Components
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
import InputText from '../../../../Components/InputsForm/InputText.jsx';
// Material & Icons
import { ColorPickerTextField } from '../../../../Components/InputsForm/InputColorPicker.jsx';

export const AnnouncementForm = () => {
  const data = [
    {
      direction: 'column',
      inputs: [
        {
          field: (
            <InputText parse={(e) => !e ? '' : e} title="Announcement" source="home.announcement_bar.text" fullWidth label="" />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: <ColorPickerTextField  title="Background Color" source="home.announcement_bar.background" hexFormat/>,
          breakpoints: { xs: 3}
        },
        {
          field: <ColorPickerTextField title="Font Color" source="home.announcement_bar.font_color" hexFormat/>,
          breakpoints: { xs: 3}
        },
      ],
    },
  ];

  return {
    data: data
  };
};