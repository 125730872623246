import { Box, Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { BsInstagram, BsWhatsapp, BsSpotify } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { RiSoundcloudLine } from 'react-icons/ri';
import { AiOutlineYoutube } from 'react-icons/ai';
import { FieldTypo } from './FieldTypo';
import React, { useMemo, useState } from 'react';
import CardComponent from '../../../../Components/ShowComponents/CardComponent';
import { CgWebsite } from 'react-icons/cg';

export const SOCIALS = [
  <BsInstagram color='#606060' key={'instagram'} size="18px" />,
  <AiOutlineYoutube color='#606060' key={'youtube'} size="18px" />,
  <BsSpotify color='#606060' key={'spotify'} size="18px" />,
  // <HiOutlineMail color='#606060' key={'email'} size="18px" />,
  // <CgWebsite color='#606060' key={'email'} size="18px" />,
  <RiSoundcloudLine color='#606060' key={'soundcloud'} size="18px" />

];
export const Socials = ({switchBody}) => {

  const record = useRecordContext();
  const pendingBody = switchBody ? record?.submission : record;
  const socialsArr = useMemo(() => {
    const modifiedSocials = SOCIALS.map((social) => {
      if (pendingBody?.media?.[social.key]?.url && pendingBody?.media?.[social.key]?.url !== record?.media?.[social.key]?.url) {
        const url = pendingBody?.media?.[social.key]?.url;
        if (url && url.length > 0) {
          return React.cloneElement(social, { pending: true, url: url });
        }
      } else if (record?.media?.[social.key]?.url?.length > 0) {
        return React.cloneElement(social, { url: record?.media?.[social.key]?.url });
      }
      return null;
    });

    return modifiedSocials.filter((social) => social !== null); 
  },[record, pendingBody]);

  return (
    <CardComponent
      title="Socials"
    >
      {
        socialsArr.length > 0 ? (
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              gap: '50px'
            }}
          >
            { socialsArr.map((item, i) => (
              <FieldTypo 
                urlSx={{textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} 
                noShort
                pending={item.props.pending}
                field={item.key} 
                url={item.props.url}
                icon={item} 
              />
            ))   
            }
          </Box>
        ) : (
          <Typography sx={{ padding: '50px', textAlign: 'center', width: '100%', textTransform: 'uppercase', fontSize: '.9em', color: '#606060', fontWeight: '600'}}>No data found</Typography>
        )
      }

    </CardComponent>
  );
};