import { useState } from 'react';
import { memo } from 'react';
import { useDataProvider, useRedirect } from 'react-admin';
// Hooks & Utils
import { useCustomToasty } from '../../../Hooks/useCustomToasty';
import dayjs from 'dayjs';
// Components
import DeleteModal from '../../../Components/DeleteModal/DeleteModal';
// Material & Icons
import { BiTrashAlt, BiEdit, BiCalendarX } from 'react-icons/bi';
import { Box, Chip, Popover, Typography } from '@mui/material';
import { IconButton } from '../../../Components/Generics';

export const EventActions = memo(function ModalContent({
  openModal,
  closeModal,
  anchorEl,
  selectedItemId,
  eventSelected,
  refetch,
}) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const customToast = useCustomToasty();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const handleRedirect = () => {
    redirect(`/events/${eventSelected?.id}`);
  };
  const confirmDelete = async (iddelete) => {
    try {
      let request = await dataProvider.delete('events', { id: iddelete });
      if (request) {
        customToast('success', 'Deleted succesfuly');
        closeModal();
        setOpenDeleteModal(false);
        refetch();
      }
    } catch (error) {
      customToast('error', 'There was an error');
  
      throw error;
    }
  };
  const unscheduleEvent = async () => {
    try {
      await dataProvider
        .update('events', {
          id: eventSelected?.id,
          data: {
            start_date: null,
            end_date: null,
          },
        })
        .then((res) => {});
      closeModal();
      customToast('success', 'Event unscheduled succesfuly');
      refetch();
    } catch (error) {
      console.error('Error', error);
      customToast('error', 'Error updating event');
      throw new Error('Please select a valid time');
    }
  };
  return (
    <Popover
      id={selectedItemId}
      open={openModal}
      anchorEl={anchorEl}
      onClose={closeModal}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <Box
        sx={{ p: 2, width: '300px', display: 'flex', flexDirection: 'column' }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 5,
              width: '60%',
            }}
          >
            <Typography sx={{ fontSize: '15px', fontWeight: 600 }}>
              {eventSelected?.name}
            </Typography>
            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
              {dayjs(eventSelected?.start_date).format('dddd D, MMM')}
            </Typography>
          </div>
          <div
            style={{
              width: '40%',
              margin: 0,
              padding: 0,
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row',
            }}
          >
            <IconButton
              style={{ height: '30px' }}
              onClick={() => setOpenDeleteModal(true)}
              icon={<BiTrashAlt size={18} color="black" />}
            />
            <IconButton style={{ height: '30px' }} onClick={unscheduleEvent} icon={<BiCalendarX size={18} color="black" />}/>
            <IconButton style={{ height: '30px' }} onClick={handleRedirect} icon={<BiEdit size={18} color="black" />}/>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 5,
            overflow: 'hidden',
          }}
        >
          <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>
              Talents :
          </Typography>
          <Chip
            sx={{ margin: 0, padding: 1, width: 'fit-content' }}
            label={eventSelected?.talents[0]?.name}
          />
        </div>
      </Box>
      {openDeleteModal && (
        <DeleteModal
          setOpenModal={setOpenDeleteModal}
          openModal={open}
          idToAction={eventSelected?.id}
          confirmDelete={confirmDelete}
          textAction={'Event'}
        />
      )}
    </Popover>
  );
});