import { TextField, Tooltip } from '@mui/material';
import { DateField, DateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { required } from 'react-admin';


export const travelForms = () => {


  const textProps = {
    variant: 'outlined' ,
    InputProps: {
      sx: {
        borderRadius: '10px'
      }
    }
  };
  return [
    <TextField
      tooltip="Upload a copy of your talent and crews' passport for our records - this is an urgent requirement. Not Applicable for anyone Living in Costa Rica. "
      sx={{   
        flexGrow: 1
      }}
      fieldName="name"
      label="Name"
      {...textProps}
    />,
    <TextField
      sx={{   
        flexGrow: 1
      }}
      fieldName="role"
      label="Role"
      {...textProps}
    />,
    <TextField
      sx={{   
        flexGrow: 1
      }}
      fieldName="passport"
      label="Passport"
      {...textProps}
    />,
    <TextField
      sx={{   
        flexGrow: 1
      }}
      fieldName="arriving_from"
      label="Arriving from"
      {...textProps}
    />,
    <TextField
      tooltip="Please specify if you will be arriving at San Jose Airport (SJO) or Quepos Airport (XQP). If traveling by land, please indicate it here and enter your Raveapp site arrival information so we know when to expect you."
      sx={{   
        flexGrow: 1
      }}
      label="Arriving to"
      fieldName="arriving_to"
      {...textProps}
    />,
    // <DateTimePicker
    //   time
    //   fieldName="inbound_date"
    //   sx={{
    //     borderRadius: '10px',
    //     flexGrow: 1,
    //   }}
    //   slotProps={{
    //     textField: {
    //       variant: 'outlined',
    //       fullWidth: true,
    //       borderRadius: '10px',
    //     }
    //   }}
    //   viewRenderers={{
    //     hours: renderTimeViewClock,
    //     minutes: renderTimeViewClock,
    //     seconds: renderTimeViewClock,
    //   }}
    // />,
    <TextField
      sx={{   
        flexGrow: 1
      }}
      label="Inbound Airline"
      fieldName="inbound_airline"
      {...textProps}
    />,
    <TextField
      sx={{   
        flexGrow: 1
      }}
      label="Inbound Flight Number"
      fieldName="inbound_flight_number"
      {...textProps}
    />,
    <TextField
      sx={{   
        flexGrow: 1
      }}
      label="Departing to"
      fieldName="departing_to"
      {...textProps}
    />,
    <TextField
      sx={{   
        flexGrow: 1
      }}
      label="Outbound Date"
      fieldName="outbound_date"
      {...textProps}
    />,
    <TextField
      tooltip="Please specify if you will be departing from San Jose Airport (SJO) or Quepos Airport (XQP). If traveling by land, please indicate here and enter your Raveapp site departure information so we know how long you intend to be on-site."
      sx={{   
        flexGrow: 1
      }}
      label="Departing From"
      fieldName="departing_from"
      {...textProps}
    />,
    <TextField
      sx={{   
        flexGrow: 1
      }}
      label="Outbound Flight Number"
      fieldName="outbound_flight_number"
      {...textProps}
    />,
  ];
};