import React from 'react';
import FormFaqs from './FormFaqs';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';

const EditFaqs = () => {
  return (
    <EditFormCustom actionText="question" >
      <FormFaqs isEdit={true} />
    </EditFormCustom>
  );
};

export default EditFaqs;