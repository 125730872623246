import React, { useEffect, useState } from 'react';
import { useGetList, useInput } from 'react-admin';
import axios from 'axios';
// Hooks & Utils
// Components
import { FilterPosts } from './ModalFeaturedPosts';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { IconButton, Card as CardGeneric } from '../Generics/index';
// Material & Icons
import { Box, Modal, Skeleton, TextField, Typography } from '@mui/material';
import { IoMdSearch } from 'react-icons/io';
import { TbHandMove } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import { AiFillDelete } from 'react-icons/ai';
import styles from './OrderArticles.module.css';
const iconBtStyle = {
  width: '25px',
  height: '25px',
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  outline: 'none',
  padding: 0.5,
  backgroundColor: '#00000082',
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60%',
  height: '70%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '30px',
  overflowY: 'auto',
  borderRadius: '10px 5px 5px 10px',
};
// Este COMPONENTE es utilizado en FEATURED ARTICLES, para hacer funcionalida de DRAG & DROP
export const Card = ({
  item,
  setIsDeleted,
  onDrag,
  onDragOver,
  setIsDragging,
  style,
  setHoverCard,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handlerDelete = (e, item) => {
    e.stopPropagation();
    setIsDeleted(item.id);
  };
  const handleDragEnd = () => {
    setIsDragging(false);
    setHoverCard(null);
  };
  return (
    <div
      id={item.id}
      onDragEnd={handleDragEnd}
      onDragStart={(e) => {
        onDrag(e, item.id);
      }}
      onDragLeave={() => setIsDragging(true)}
      onDragOver={onDragOver}
      draggable="true"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        height: '120px',
        position: 'relative',
        width: '200px',
        borderRadius: '10px',
        cursor: 'grab',
        ...style,
      }}
    >
      <img
        src={item?.picture?.original}
        alt="postImagen"
        style={{
          width: '200px',
          height: '120px',
          objectFit: 'cover',
          borderRadius: '10px',
          position: 'relative',
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '200px',
          padding: '10px 8px',
          borderRadius: '10px',
          zIndex: 1,
        }}
      >
        {isHovered && (
          <IconButton
            onClick={(e) => handlerDelete(e, item)}
            style={{
              ...iconBtStyle,
              position: 'absolute',
              top: '4px',
              right: '5px',
            }}
            icon={<AiFillDelete size={16} color="white" />}
          />
        )}
        <p
          style={{
            color: 'white',
            fontSize: '14px',
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            marginTop: '30px',
          }}
        >
          {item?.title}
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: 400,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: 'white',
          }}
        >
          {item?.subtitle}
        </p>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '10px',
            zIndex: 0,
          }}
        ></div>
      </div>
    </div>
  );
};
// Este COMPONENTE es utilizado en el MODAL de FEATURED ARTICLES
export const CardAll = ({ item, onDrag, onDragOver, setIsDragging }) => {
  return (
    <div
      id={item.id}
      onDragEnd={() => {
        setIsDragging(false);
      }}
      onDragStart={(e) => {
        onDrag(e, item.id, item);
      }}
      onDragLeave={() => setIsDragging(true)}
      onDragOver={onDragOver}
      draggable="true"
      style={{
        height: '120px',
        position: 'relative',
        width: '200px',
        borderRadius: '10px',
        cursor: 'grab',
      }}
    >
      <img
        src={item?.picture?.original}
        alt="postImagen"
        style={{
          width: '200px',
          height: '120px',
          objectFit: 'cover',
          borderRadius: '10px',
          position: 'relative',
        }}
      />
      <div
        style={{
          position: 'absolute',
          bottom: '0',
          left: '0',
          width: '200px',
          padding: '10px',
          borderRadius: '10px',
          zIndex: 1,
        }}
      >
        <p
          style={{
            color: 'white',
            fontSize: '14px',
            fontWeight: 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {item?.title}
        </p>
        <p
          style={{
            fontSize: '13px',
            fontWeight: 400,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: 'white',
          }}
        >
          {item?.subtitle}
        </p>
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '10px',
            zIndex: 0,
          }}
        ></div>
      </div>
    </div>
  );
};
// COMPONENTE PADRE de los anteriores
export const DnDArticles = ({ options, isModal, source }) => {
  const { field } = useInput({ source });
  const palette = useSelector((state) => state?.palette?.palette);

  const styleBox = {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    overflowY: 'hidden',
    paddingY: '10px',
    gap: '10px',
    alignItems: 'center',
    '&::-webkit-scrollbar': {
      height: '10px',
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      borderRadius: '20px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: palette?.primary,
      borderRadius: '20px',
    },
  };
  //States
  const [isDragging, setIsDragging] = useState(false);
  const [hoverCard, setHoverCard] = useState(null);
  const [targetId, setTargetId] = useState('');
  const [parseElement, setParseElement] = useState(null);
  const [isDeleted, setIsDeleted] = useState(undefined);
  const [isDeletedInModal, setIsDeletedInModal] = useState(undefined);
  const customToast = useCustomToasty();
  const [cards, setCards] = useState(null);
  const [filterValue, setFilterValue] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [openModal, setOpenModal] = useState(false);
  // PETICIONES
  const { data: allArticles, isFetched } = useGetList('articles', {
    pagination: {
      perPage: 100,
      page: 1,
    },
  });

  // SECTION GENERAL

  //La funcion recibe , 2 ID, el primero es el ID que corresponde al elemento que se esta arrastrando, y el segundo al elemento que se le paso por encima para intercambiar posiciones.
  const moveCard = (idDraggElement, idHoverElement) => {
    // FLUJO >>>>>
    // Comenzamos buscando el INDEX en base a los IDs provistos.
    const elIndxDraggeadoIndex = cards.findIndex(
      (item) => item.id === idDraggElement,
    );
    const elIndxHoverIndex = cards.findIndex(
      (item) => item.id === idHoverElement,
    );
    // Crea una copia del array de cards
    const newCards = [...cards];
    // Elimina el elemento arrastrado de la lista y lo guarda en una variable.
    const [draggedCard] = newCards.splice(elIndxDraggeadoIndex, 1);
    // Inserta el elemento en la nueva posición.
    newCards.splice(elIndxHoverIndex, 0, draggedCard);
    // Establece el RANK basado en la posición actual del elemento
    newCards.forEach((card, index) => {
      card.rank = index;
    });
    setCards(newCards);
    // Finalmente actualizo el campo para poder activar el boton de SAVE del formulario.
    if (newCards) {
      field.onChange(newCards);
    }
  };
  const handlerModal = () => {
    if (openModal === true) {
      return;
    } else {
      setOpenModal(!openModal);
    }
  };
  const showDeleteIcon = () => {};
  // SECTION MODAL >>>>
  const moveCardModal = (element, idDraggElement, idHoverElement) => {
    // FLUJO >>>>>
    // Comenzamos buscando el INDEX en base a los IDs provistos.
    const elIndxDraggeadoIndex = cards.findIndex(
      (item) => item.id === idDraggElement,
    );
    const elIndxHoverIndex = cards.findIndex(
      (item) => item.id === idHoverElement,
    );
    // Genera una copia de las cards.
    const newCards = [...cards];
    // Encuentra el índice del elemento en el nuevo array.
    const index = newCards.findIndex((card) => card.id === element.id);
    if (index !== -1) {
      // Si el elemento ya existe en el nuevo array y no se está moviendo a la misma posición, no se hace nada.
      if (index !== elIndxHoverIndex) {
        // Si existe se elimina de la lista :
        newCards.splice(index, 1);
        setCards(newCards);
        // Al momento de eliminar la carta, el valor que le sigue a este, deberia obtener su antigua posicion, si no van a suceder casos de saltos de rank, como : 1-2-3-5
        //Con esto se genera un 1-2-3-4-5, y se agrega la carta en la nueva posicion (ultima)
        let rankActual = 0;
        newCards?.forEach((card, index) => {
          card.rank = index;
          if (card.rank > rankActual) {
            rankActual = card.rank;
          }
        });
        newCards.sort((a, b) => a.rank - b.rank);
        // Verifico si existe un rank perdido entre los valores
        //Luego se agrega a la lista
        newCards.push(element);
        // Se establece el nuevo rank de la nueva carta
        element.rank = newCards.length - 1;
        // Tercero, seteo el nuevo estado de cards con lo modificado de la copia
        setCards(newCards);
        // moveCard(elIndxDraggeado, elIndxHover);
      }
    } else {
      const fullCards = cards.filter((card) => card.rank !== null);
      if (fullCards?.length < 10) {
        // Segundo, agrego el nuevo elemento al final del ARRAY y le asigno un rank siguiente al último elemento.
        newCards.push(element);
        setCards(newCards);
        newCards[newCards.length - 1].rank = newCards?.length - 1;
        // Tercer paso, seteo el nuevo estado de cards con lo modificado de la copia
        setCards(newCards);

        // Finalmente actualizo el campo para poder activar el boton de SAVE del formulario.
        field.onChange(newCards);
      } else {
        customToast('error', 'You can not add more than 10 articles');
      }
    }
  };
  const handleNameFilter = (event) => {
    setFilterValue(event.target.value);
  };
  const handleCategoryFilter = (event) => {
    setCategoryFilter(event.target.value);
  };
  const filteredArticles = allArticles?.filter(
    (article) =>
      article.title.toLowerCase().includes(filterValue.toLowerCase()) &&
      (categoryFilter === '' || article.category === categoryFilter),
  );
  const filteredWithCards =
    cards &&
    filteredArticles?.filter(
      (post) =>
        !cards.some((card) => card?.id === post?.id && card?.rank !== null),
    );
  // CARGA INICIAL
  useEffect(() => {
    // En base al array de objetos de OPTIONS, creamos uno nuevo ordenados por rank!
    if (cards?.length >= 1) {
      return;
    } else {
      const optiones = [...options].sort((a, b) => a?.rank - b?.rank);
      setCards(optiones);
    }
  }, []);
  useEffect(() => {
    //Si se llega activar esto, es porque se ha UNRANKEADO un article de la lista.
    const unrankArticle = (articleId) => {
      const newCards = [...cards];
      // Buscamos el indice de la carta
      const index = newCards?.findIndex((card) => card?.id === articleId);
      if (index !== -1) {
        // Si lo encontro , procedecemos a colocar su KEY RANK en NULL.
        newCards[index].rank = null;
        // Seteamos la nueva lista
        setCards(newCards);
        // Hacemos la peticion
        axios({
          url:
            process.env.REACT_APP_BASE_PATH + `/articles/${newCards[index].id}`,
          method: 'PUT',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Expose-Headers': 'content-range',
            Authorization: 'Bearer ' + localStorage.getItem('auth'),
            'x-api-key': process.env.REACT_APP_API_KEY,
          },
          data: newCards[index],
        })
          .then((res) => {
            customToast('success', 'The article has been unranked succesfuly.');
          })
          .catch((err) => {
            customToast('error', 'There was an error, please try again later.');
          });
      }
    };
    if (isDeleted) {
      unrankArticle(isDeleted);
    } else if (isDeletedInModal) {
      unrankArticle(isDeletedInModal);
    }
  }, [isDeleted, isDeletedInModal]);

  // SECTION DRAG & DROP
  const onDrag = (e, id, item) => {
    e.dataTransfer.setData('text/plain', id);
    if (item) {
      e.dataTransfer.setData('item', JSON.stringify(item));
      setParseElement(item);
    }
  };

  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!e.currentTarget) {
      return;
    }
    const hoveredId = e.currentTarget.id;
    setTargetId(hoveredId);
    setHoverCard(hoveredId);
  };

  const drop = (e, modalOn) => {
    setIsDragging(false);
    e.preventDefault();
    setHoverCard(null);
    if (modalOn) {
      //En el modal tengo 3 FUNCIONALIDADES:
      // 1 : Permite entre las CARDS del INPUT intercambiar posiciones
      // 2 : Desde ALL ARTICLES, permite DRAGEAR CARDS y DROPEAR en el INPUT
      // 3 : Desde el INPUT puedo DRAGEAR CARDS y DROPEAR en ALL ARTICLES para eliminarlo del INPUT.

      //Primero busco el ID del elemento DRAGEADO.
      const data = e.dataTransfer.getData('text/plain');
      //Luego verifico si el ID que se guardo como TARGET, coincide con alguno en la lista de CARDS.
      const idIn = cards?.filter((card) => card.id === targetId);
      const dataElement = filteredWithCards?.filter((card) => card.id === data);
      //2. Funcionalidad
      if (dataElement[0]?.rank === null && idIn[0]?.rank !== null) {
        const data2 = JSON.parse(e.dataTransfer.getData('item'));
        moveCardModal(data2, data, targetId);
        setParseElement(null);
        return;
      }
      //1. Funcionalidad
      if (idIn.length > 0) {
        moveCard(data, targetId);
        return;
      } else {
        //3. Funcionalidad
        const data = e.dataTransfer.getData('text/plain');
        if (parseElement) {
          const data2 = JSON.parse(e.dataTransfer.getData('item'));
          moveCardModal(data2, data, targetId);
          setParseElement(null);
        } else {
          setIsDeletedInModal(data);
        }
      }
    } else {
      const data = e.dataTransfer.getData('text/plain');
      moveCard(data, targetId);
    }
  };

  return (
    <div
      className={styles.container}
      style={{
        width: isModal ? '97%' : '100%',
      }}
    >
      <Box
        sx={styleBox}
        onClick={handlerModal}
        onDrop={(e) => drop(e, openModal ? true : null)}
      >
        {!isModal && (
          <button className={styles.btOpenModal} onClick={handlerModal}>
            <p className={styles.btOpenModalText}>
              Click here to select articles
            </p>
            <p className={styles.btOpenModalTextsub}>
              Select the articles you want to feature
            </p>
          </button>
        )}
        {cards
          ?.filter((card) => card?.rank !== null)
          .map((post, index) => (
            <Card
              key={post?.id}
              item={post}
              index={index}
              moveCard={moveCard}
              setIsDeleted={setIsDeleted}
              onDrag={onDrag}
              onDragOver={onDragOver}
              setIsDragging={setIsDragging}
              setHoverCard={setHoverCard}
              style={{
                boxShadow:
                  hoverCard === post.id
                    ? `0px 0px 2px 2px ${palette.primary}`
                    : 'none',
              }}
            />
          ))}
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: 'auto' }}
        >
          <CardGeneric style={style}>
            <IconButton
              onClick={() => setOpenModal(false)}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
              }}
              icon={<>X</>}
            />
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
              {isFetched && (
                <TextField
                  sx={{ width: '100%', backgroundColor: 'white' }}
                  value={filterValue}
                  variant="outlined"
                  hiddenLabel={true}
                  onChange={handleNameFilter}
                  InputProps={{
                    style: {
                      borderRadius: '10px',
                      fontSize: '14px',
                    },
                    startAdornment: <IoMdSearch size={24} />,
                  }}
                />
              )}
              <FilterPosts
                from="articles"
                handleCategoryFilter={handleCategoryFilter}
                categoryFilter={categoryFilter}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                  width: '90%',
                }}
              >
                <Typography fontWeight={600} fontSize={18}>
                  FEATURED ARTICLES
                  <span style={{ color: 'gray', fontSize: 14 }}>
                    {'(UP TO 10)'}
                  </span>
                </Typography>
                <div
                  style={{
                    border: '1px solid grey',
                    borderRadius: '10px',
                    padding: 5,
                  }}
                >
                  <Box
                    sx={{
                      padding: '10px 10px',
                      justifyContent: cards?.length === 0 && 'flex-end',
                      ...styleBox,
                    }}
                  >
                    {cards
                      ?.filter((card) => card?.rank !== null)
                      .map((post, index) => (
                        <Card
                          key={post?.id}
                          item={post}
                          index={index}
                          moveCard={moveCard}
                          isAllArticles={true}
                          setIsDeleted={setIsDeleted}
                          onDrag={onDrag}
                          onDragOver={onDragOver}
                          setIsDragging={setIsDragging}
                          setHoverCard={setHoverCard}
                          style={{
                            boxShadow:
                              hoverCard === post.id
                                ? `0px 0px 2px 2px ${palette.primary}`
                                : 'none',
                          }}
                        />
                      ))}
                    {cards?.length === 0 && (
                      <button
                        style={{
                          cursor: 'auto',
                          height: '120px',
                          width: '120px',
                          border: '1px solid grey',
                          borderRadius: '20px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexShrink: 0,
                        }}
                      >
                        <TbHandMove size={25} />
                        <p
                          style={{
                            fontSize: '14px',
                            fontWeight: 700,
                            color: '#515151',
                          }}
                        >
                          Drag and drop files here
                        </p>
                      </button>
                    )}
                  </Box>
                </div>
              </div>
            </div>
            <div style={{ marginTop: 20, width: '100%' }}>
              <Typography fontWeight={600} fontSize={18}>
                ALL ARTICLES
              </Typography>
              <div
                style={{
                  width: '100%',
                  marginTop: 5,
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: 20,
                }}
              >
                {isFetched ? (
                  filteredWithCards?.map((post, index) => (
                    <CardAll
                      key={post?.id}
                      item={post}
                      index={index}
                      isAllArticles={true}
                      onDrag={onDrag}
                      onDragOver={onDragOver}
                      setIsDragging={setIsDragging}
                    />
                  ))
                ) : (
                  <div
                    style={{
                      width: '100%',
                      marginTop: 5,
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      gap: 20,
                    }}
                  >
                    <Skeleton variant="rounded" width={200} height={120} />
                    <Skeleton variant="rounded" width={200} height={120} />
                    <Skeleton variant="rounded" width={200} height={120} />
                  </div>
                )}
              </div>
            </div>
          </CardGeneric>
        </Modal>
      </Box>
    </div>
  );
};
