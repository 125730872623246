import React from 'react';
import { DateField, TextField, ImageField, FunctionField } from 'react-admin';
import ListCustom from '../../Components/ListCustom/ListCustom';
import { COLORS } from '../../assets/constants/theme';
import { Chip, Typography } from '@mui/material';
import { TooltipTitle } from '../Talents/Form/TalentsListComponents/SimpleTalentForm';
import { ListActions } from '../../Components/ListActions/ListActions';

const paramsList = {
  listParams: {
    hasCreate: true,
    actions: (
      <ListActions
        search
        from={'locations'}
        label={'+ Add'}
        placeholder={'Search by name and description...'}
        csv={true}
      />
    ),
    queryOptions: {
      meta: { field: 'last_modified' },
    },
  },
  datagridParams: {
    bulkActionButtons: false,
    rowClick: 'edit',
    sx: {
      padding: '0px 20px',
      '& .column-icon': {
        width: '20%',
      },
      '& .MuiTypography-root': {
        color: '#000000',
        fontWeight: 400,
      },
      '& .column-icono': {
        width: '10%',
        textAlign: 'center',
      },
      '& .column-name': {
        width: '22.5%',
      },
      '& .column-type': {
        width: '22.5%',
      },
      '& .column-location': {
        width: '22.5%',
      },
      '& .column-last_modified': {
        width: '22.5%',
      },
      '& .column-imagen': {
        width: '10%',
        textAlign: 'center',
        padding:'0px'
      },
    },
  },
};

const VenuesList = () => {
  return (
    <ListCustom {...paramsList}>
      <FunctionField
        source="imagen"
        label="Picture"
        render={(record) => (
          <ImageField
            source="picture.original"
            label="Image"
            sx={{
              '& img': {
                borderRadius: 200,
                maxWidth: 50,
                maxHeight: 45,
                objectFit: 'cover',
              },
            }}
          />
        )}
      />
      <TextField source="name" />
      <FunctionField
        label="Category"
        source="category"
        render={(record) =>
          record?.category ? (
            <Chip sx={{ width: 'auto' }} label={record?.category} />
          ) : null
        }
      />
      <FunctionField
        source="location"
        label={
          <>
            Geo-tagged
            <TooltipTitle tooltip="The element has a geographical tag and is visible on the map" />
          </>
        }
        render={(record) => {
          if (record.location?.length === 0) {
            return <Typography>NO</Typography>;
          }
          if (record.location?.length >= 1) {
            let findingNull = record.location?.filter(
              (location) => location?.geometry === null,
            );
            if (findingNull.length >= 1) {
              return <Typography>NO</Typography>;
            } else {
              return <Typography>YES</Typography>;
            }
          }
        }}
      />
      <DateField source="last_modified" />
    </ListCustom>
  );
};

export default VenuesList;
