import React, { useState } from 'react';
import {
  required,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useRecordContext,
  useGetList,
} from 'react-admin';
// Hooks & Utils
import { DEEP_LINKING_OPTIONS } from '../../../assets/constants/types';
import { INPUTS_VARIANT } from '../../../assets/constants/Form';
// Components
import {
  InputTabSingleSelector,
  InputDeepLinking,
} from '../../../Components/RedesignComponents/Inputs/index';
import { autoCompleteInput } from '../../../Components/StyledComponents/Inputs';
import { InputTitle } from '../../../Components/StyledComponents/InputTitle';
import { InputSubtitle } from '../../../Components/StyledComponents/InputSubtitle';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import InputBoolean from '../../../Components/InputsForm/InputBoolean';
import InputText from '../../../Components/InputsForm/InputText';
// Material & Icons
import { Box, Typography, Select, MenuItem, Alert } from '@mui/material';
import { useUtcFormat } from '../../../helpers/date';
import { useSelector } from 'react-redux';
import {
  InputBooleanSelector,
  InputDateTimeSelector,
  InputOsSelector,
  InputVersionSelector,
} from '../../../Components/InputsForm/InputNotifications';

const FormNotifications = ({ isEdit }) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const record = useRecordContext();
  const recordScheduled = record?.status;
  const [sendNow, setSendNow] = useState(false);
  const [type, setType] = useState(
    isEdit
      ? record?.internal_deeplink === true
        ? 'Internal'
        : 'External'
      : undefined
  );
  const [linking, setLinking] = useState(undefined);
  const [valueUrl, setValueUrl] = useState(undefined);
  const { formatFestivalTime, parseFestivalTime } = useUtcFormat();
  const { data: appConfig }= useGetList('app_config', {pagination: {perPage:9999, page: 1}, sort: { field: 'rank', order: 'DESC'}});

  const handleSelectChange = (event) => {
    setLinking(event.target.value);
  };
  console.log('FORM ORIGINAL');
  const data = [
    {
      title: 'CONTENT',
      inputs: [
        {
          field: (
            <InputText
              required={true}
              title={'Title'}
              label=''
              subtitle=''
              source='title'
              fullWidth
              validate={[required('The title is required.')]}
              disabled={
                !isEdit ? false : recordScheduled === 'scheduled' ? false : true
              }
            />
          ),
          breakpoints: { xs: 8 },
        },
        {
          field: (
            <InputText
              required={true}
              title={'Body'}
              subtitle={'What are you trying to say with this notification'}
              label=''
              source='body'
              fullWidth
              inputSx={{ height: '100px' }}
              validate={[required('The body is required.')]}
              disabled={
                !isEdit ? false : recordScheduled === 'scheduled' ? false : true
              }
            />
          ),
          breakpoints: { xs: 8 },
        },
      ],
    },
    {
      title: 'LINKING',
      subtitle:
        'You can send links in your notification. You can either link to internal content in the app or to external.',
      inputs: [
        {
          field: (
            <InputTabSingleSelector
              setLinking={setLinking}
              setValueUrl={setValueUrl}
              setState={setType}
              state={type}
              label=''
              title='TYPE'
              options={[
                { id: 1, text: 'Internal' },
                { id: 2, text: 'External' },
              ]}
              fullWidth
              source='internal_deeplink'
              resource='notifications'
              validate={[required('The internal deeplink is required.')]}
              disabled={
                !isEdit ? false : recordScheduled === 'scheduled' ? false : true
              }
            />
          ),
          breakpoints: { xs: 4 },
        },
        {
          noShow: type === 'Internal' ? false : true,
          field: (
            <>
              {appConfig && appConfig[0]?.app_slug === null && (
                <Alert
                  severity='warning'
                  style={{ backgroundColor: '#FFF8B7', width: 'fit-content' }}
                >
                  App Slug has not been created, please create one to send
                  internally linked notifications.
                </Alert>
              )}
              <InputTitle sx={{ marginTop: '30px' }}>
                INTERNAL LINKING
              </InputTitle>
              <InputSubtitle>
                If your notification links to an internal page in the app you
                need to specify it
              </InputSubtitle>
            </>
          ),
          breakpoints: { xs: 12 },
        },
        {
          noShow: type === 'Internal' ? false : true,
          field: linking && (
            <Select
              disabled={
                !isEdit ? false : recordScheduled === 'scheduled' ? false : true
              }
              value={linking ? linking : undefined}
              onChange={handleSelectChange}
              size='small'
              fullWidth
              sx={{ marginTop: '9px', borderRadius: '10px' }}
            >
              {DEEP_LINKING_OPTIONS.map((option) => (
                <MenuItem key={option.id} value={option.text}>
                  {option.text}
                </MenuItem>
              ))}
            </Select>
          ),
          breakpoints: { xs: 6 },
        },
        {
          noShow: type === 'Internal' ? false : true,
          field: (
            <InputDeepLinking
              linking={linking}
              valueUrl={valueUrl}
              optionValue={'text'}
              optionText={'text'}
              fullWidth
              source='url'
              title={'Type'}
              label={''}
              disabled={
                !isEdit ? false : recordScheduled === 'scheduled' ? false : true
              }
              appConfig={appConfig}
            />
          ),
          breakpoints: { xs: 6 },
        },
        {
          noShow: type === 'External' ? false : true,
          field: (
            <InputText
              title={
                <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                  URL
                </Typography>
              }
              subtitle={'Link to external content'}
              label=''
              source='url'
              fullWidth
              disabled={
                !isEdit ? false : recordScheduled === 'scheduled' ? false : true
              }
            />
          ),
          breakpoints: { xs: 6 },
        },
        {
          field: <></>,
          breakpoints: { xs: 3.5 },
        },
      ],
    },
    {
      title: 'Schedule',
      inputs: [
        {
          field: (
            <InputDateTimeSelector
              isEdit={isEdit}
              recordScheduled={recordScheduled}
            />
          ),
          breakpoints: { xs: 4 },
        },
        {
          field: (
            <InputBooleanSelector
              isEdit={isEdit}
              setSendNow={setSendNow}
              sendNow={sendNow}
              source={'send_now'}
            />
          ),
          breakpoints: { xs: 4 },
        },
      ],
    },
    {
      title: '',
      inputs: [
        {
          field: (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize={'16px'}
                  color={palette?.primary}
                >
                  AUDIENCE
                </Typography>
                <Typography
                  fontWeight={300}
                  fontSize={'14px'}
                  sx={{ marginTop: '20px' }}
                >
                  If you want to send this notification to a specific group of
                  users.You can segment according to artists and events.
                </Typography>
              </Box>
            </div>
          ),
          breakpoints: { xs: 3 },
        },
        {
          field: (
            <>
              <InputTitle
                sx={{
                  fontWeight: 600,
                  fontSize: '16px',
                  color: '#000',
                  marginBottom: '20px',
                }}
              >
                FAVORITE ARTISTS
              </InputTitle>
              <ReferenceArrayInput
                perPage={100}
                fullWidth
                source='filter.favourite_talents'
                reference='talents'
              >
                <AutocompleteArrayInput
                  fullWidth
                  optionText={'name'}
                  label=''
                  variant='outlined'
                  sx={autoCompleteInput}
                  disabled={
                    !isEdit
                      ? false
                      : recordScheduled === 'scheduled'
                        ? false
                        : true
                  }
                />
              </ReferenceArrayInput>
            </>
          ),
          breakpoints: { xs: 4 },
        },
        {
          field: (
            <>
              <InputTitle
                sx={{
                  fontWeight: 600,
                  fontSize: '16px',
                  color: '#000',
                  marginBottom: '20px',
                }}
              >
                FAVORITE EVENTS
              </InputTitle>

              <ReferenceArrayInput
                perPage={100}
                fullWidth
                source='filter.favourite_events'
                reference='events'
              >
                <AutocompleteArrayInput
                  fullWidth
                  optionText={'name'}
                  label=''
                  variant='outlined'
                  sx={autoCompleteInput}
                  disabled={
                    !isEdit
                      ? false
                      : recordScheduled === 'scheduled'
                        ? false
                        : true
                  }
                />
              </ReferenceArrayInput>
            </>
          ),
          breakpoints: { xs: 4 },
        },
        { breakpoints: { xs: 3 } },
        {
          field: <InputOsSelector source='filter.mobile_os' />,
          breakpoints: { xs: 4 },
        },
        {
          field: <InputVersionSelector source='filter.app_version' />,
          breakpoints: { xs: 4 },
        },
        // {
        //   field: <InputBoolean
        //     source='test'
        //     fullWidth
        //   />,
        //   breakpoints: { xs: 8 }
        // }
      ],
    },
  ];
  return (
    <AdaptableFormRedesign
      isEdit={isEdit}
      data={data}
      from={false}
      icon={false}
      saveBackground={true}
    />
  );
};

export default FormNotifications;
