import { Box, Modal, Typography } from '@mui/material';
import { useRecordContext, useRedirect, useRefresh, useResourceContext, useStore } from 'react-admin';
import { useRef, useState } from 'react';
import { useOnClickOutside } from '../../../Hooks/useOnClickOutside';
import axios from 'axios';
import { apiUrl } from '../../../dataProvider';
import { useCustomToasty } from '../../../Hooks/useCustomToasty';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { strCapitalize } from '../../../helpers/strCapitilize';
import {Button} from '../../Generics/index';

function updatePutData (record, newValues) {
  let updatedData ={};
  let options = newValues['options'];

  if(options){
    Object.keys(options)?.map((item, index) => {
      updatedData[item] = Object.values(options)[index];
    });
  }
  Object.keys(record?.submission).reduce((a,b, index) => {
    if(Object.keys(record)?.find(item => item === b)) {
      console.log('entro', b, Object.values(record?.submission)[index]);
      updatedData[b] = Object.values(record?.submission)[index];
    };
    return [...a, b];
  }, []);
  console.log(updatedData);
  return {...updatedData};
}

export const ApprovedButton = ({publish,id, route, setSwitchBody, color, children}) => {

  const [open, setOpen] = useState(false);
  const updatedValues = useStore('approveData');
  const record = useRecordContext();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const customToast = useCustomToasty();
  const ref  = useRef(false);

  const handleClick = () => {
    // if(validate[0]) return customToast('error', 'There are options to select, please choose one before approving changes.');
    setOpen(true);
  };
  useOnClickOutside(ref, () => { setOpen(false);});
  const handleApprove = async () => {
        
    if(updatedValues[0]) {
      try {
        const request = axios({
          method: 'PUT',
          url: `${apiUrl}/${route}/${id}`,
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('auth')
          },
          data: updatePutData(record, updatedValues[0])
        });
        const response = await request;
        if (response) {
          refresh();
          setOpen(false);
          customToast('success', `${strCapitalize(resource)} changes have been approved`);
          redirect(`/${route}`);        
        }
      } catch (error) {
        console.log(error);
        customToast('error', 'There was an error, please try again later.');
      }
    } else {
      // Cuando este el back listo >
      // const paramss = {
      //   draft:false
      // }
      try {
        const request = axios({
          method: 'PUT',
          url: `${apiUrl}/${route}/${id}/approve`,
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('auth')
          }
        });
        
        const response = await request;
        if (response) {
          refresh();
          setOpen(false);
          customToast('success', 'Talent changes have been approved');
          redirect(`/${route}`);        

        }
      } catch (error) {
        customToast('error', 'There was an error, please try again later.');
      }
    }

  };

  return (
    <>
      <Button
        style={{
          fontSize: '1em',
          fontWeight:'600',
          'text-transform': 'uppercase',
          'place-self': 'center',
          'align-items': 'center',
          color:'green',
          height:'70%',
          borderRadius:'10px',
          borderColor:'green'
        }}
        variant="outlined"
        onClick={() => handleClick()}
      >
        <TaskAltIcon style={{width: '20px', height: '20px', color: 'green', marginRight: '8px'}}  />
        {children}
      </Button>
      <Modal
        open={open}
        ref={ref}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            width: '400px',
            height: '200px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '12px',
            padding: '20px'
          }}>
          <Typography sx={{fontSize: '20px', alignSelf: 'start', marginLeft: '20px', fontWeight: '600', textTransform: 'uppercase'}}>Approve Changes</Typography   >
          <Typography>Are you sure to approve the pending changes?</Typography>
          <Button variant="outlined" onClick={handleApprove}>SAVE</Button>
        </Box>
      </Modal>
    </>
  );
};