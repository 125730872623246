
import { IoSettingsOutline } from 'react-icons/io5';
import { InfoList, SettingsForm, SettingsList } from './InfoList.jsx';
import { CreateInfo } from './Form/CreateInfo.jsx';
import { EditInfo } from './Form/EditInfo.jsx';
  
export default {
  list: InfoList,
  edit: EditInfo,
  // create: CreateInfo,
  icon: IoSettingsOutline
};
