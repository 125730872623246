import React, {  useEffect, useMemo, useState } from 'react';
import { InputAddSubsets } from './Components/InputAddSubsets';
import { Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { RadioInput } from './Components/RadioInput';
import OptionsInput from './Components/OptionsInput';
import { useInput } from 'react-admin';


export const interestsForm = () => {
  const [selectedInterest, setSelectedInterest] = useState(0);



  const data = [
    { 
      inputs: [
        {
          field: <InputAddSubsets source={'interests'} index={selectedInterest} setIndex={setSelectedInterest} />,
          breakpoints: { xs: 6}
        },
        {
          field: <InterestsDetails source="interests" index={selectedInterest} />,
          breakpoints: { xs: 6},
        }
      ],
    },
  ];
    
  return data;
};

export const InterestsDetails = (props) => {

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems:'flex-start',
        gap: '60px'
      }}
    >
      <RadioInput
        {...props}
        sx={{
          maxWidth: '300px',
          alignItems: 'flex-start',
          alignSelf: 'flex-start'
        }}
        choices={[{ name: 'multiple', value: true}, {name: 'single', value: false}]}
        defaultValue={false}
        title="Multiselect options"
        subtitle="Select if this interest can have more than one option"
      />
      <OptionsInput
        {...props}
        defaultValue={[]}
      />

    </Box>
  );
};



