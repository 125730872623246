import { Box, FormControl, FormGroup, TextField } from '@mui/material';
import PropType from 'prop-types';
import { useMemo } from 'react';
import EditModalButton from './EditModalButton.jsx';
import { InputImage } from '../../../Components/InputsForm/InputImage';
import InputText from '../../../Components/InputsForm/InputText.jsx';
import RaveLogo from '../../../assets/images/Rave_logo_2.png';
import { useFormContext } from 'react-hook-form';
import { ColorPickerTextField } from '../../../Components/InputsForm/InputColorPicker.jsx';
import { MessageInput } from '../../../Components/InputsForm/MessageAutocomplete.jsx';
import { RadioButtonGroupInput } from 'react-admin';
import { InputTitle } from '../../../Components/StyledComponents/InputTitle.jsx';


export const BackgroundImageRepeat = (props) => {
  const { source } = props;

  const { watch } = useFormContext();
  const { onboarding } = watch();
  const resize = onboarding?.[source]?.background_image_resize;
  
  return (
    <Box sx={{
      position: 'absolute',
      width: 'inherit',
      height: 'inherit',
    }}>
      <Box 
        id="background_image_repeat_box"
        sx={{
          backgroundColor: onboarding?.[source]?.['background_color'],
          padding: 1,
          left: 0,
          display: 'grid',
          gridTemplateColumns: resize === 'mosaic' ? '1fr 1fr 1fr' : '1fr',
          width: '100%',
          height: '100%',
          position: 'relative',
          gap: '20px',
          overflow: 'hidden',
          zIndex: -6,
          '*': {
            left: -15
          }
        }}>
        <BackImage 
          url={onboarding?.[source]?.background_image?.original}
          resize={resize}
          {...props} 
        />
      </Box>
      <EditModalButton placement="right-bottom" EditInputs={EditInputs} {...props} />
    </Box>
  );
};

export const BackImage = ({ url, size, resize}) => {

  const totalDivs = useMemo(() => {
    return setTimeout(() => {
      const parentDiv = document.getElementById('background_image_repeat_box');
      if(parentDiv) {
        const parentWidth = parentDiv?.clientWidth; 
        const parentHeight = parentDiv?.clientHeight; 
        const numCols = Math.floor(parentWidth / size);
        const numRows = Math.floor(parentHeight / size);
        return  Math.floor(numCols * numRows * 3);
      }
    }, 100);
  },[]);
  
  return (
    <>
      {
        resize === 'mosaic' ? (
          totalDivs && Array.from({length: totalDivs}, (_, index) => (
            <img
              key={`image-${index}`} 
              src={url}
              style={{
                transform: 'translateX(-20%)',
                width: size,
                height: 'auto',
                opacity: 0.06,
    
              }} />
          ))
        ) : (
          <div style={{
            width: '100%',
            height: '100%',
            backgroundImage: `url(${url})`,
            backgroundSize: 'cover'
          }} />
        )
      }
    </>
  );
};

const EditInputs = ({ source }) => (
  <Box sx={{display: 'flex', flexDirection: 'column',gap:'5px'}}>
    <ColorPickerTextField hexFormat sizeBox={{width: '100%'}} source={`onboarding.${source}.background_color`} title={'Background Color'} placeholder="#fff" label="" />
    <InputImage title="Background Image" source={`onboarding.${source}.background_image`} />
    <InputTitle>Image format</InputTitle>
    <RadioButtonGroupInput 
      helperText={false}
      source={`onboarding.${source}.background_image_resize`} label="" choices={[
        { id: 'mosaic', name: 'Mosaic' },
        { id: 'cover', name: 'Cover' },
      ]} />
    <MessageInput noIcon text="We recommend background image to be 1000 x 500 to ensure  appropriate visibility in all devices" />
  </Box>
);

BackImage.proptype = {
  url: PropType.string.isRequired,
  size: PropType.number
};

BackImage.defaultProps = {
  size: 120,
  url: RaveLogo
};