function isValidJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export function inputErrorMessage(error_message) { 
  let pattern = /"([^"]*)"/;

  if(!error_message) return undefined;
  let splitString = error_message?.includes('react-admin') ? error_message.split('@@react-admin@@')[1] : error_message;
  
  let match = splitString.match(pattern);
  
  if(isValidJSON(splitString)){
    if(Object.prototype.hasOwnProperty.call(JSON.parse(splitString), 'message')) {
      return JSON.parse(splitString).message;
    }
  }
  if (match) {
    return match[1];
  } else {
    return null;
  }
}

export function extractDatesFromString(str) {
  // let regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/g;
  const regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/g;
  let dates = str?.match(regex);
  
  if(dates) return dates;
  return false;
}

export function loopObjErrors(obj, depth = 0, maxDepth = 100) {
  if(typeof obj === 'string') return obj;
  if (depth >= maxDepth) {
    throw new Error('Maximum recursion depth exceeded');
  }

  if (obj.message) return obj.message;
  
  const nextObj = Object.values(obj)[0];
  if (!nextObj || typeof nextObj !== 'object') {
    return '';
  }

  return loopObjErrors(nextObj, depth + 1, maxDepth);
}