import React from 'react';
import { Create, useRemoveFromStore, useResetStore, useStore } from 'react-admin';
import FormEvents from './FormEvents';
import { CreateCustomComponent } from '../../../Components/CreateCustomComponent/CreateCustomComponent';

const CreateEvents = () => {
  const remove = useRemoveFromStore();
  return (
    <CreateCustomComponent
      mutationOptions={{onSuccess: remove('locations')}} 
      redirect='list' 
      mutationMode={'pessimistic'}
    >
      <FormEvents isEdit={false} from='events'/>
    </CreateCustomComponent>
  );
};

export default CreateEvents;