
export function talentModel (record) {
  return {
    id: record?.id,
    name: record?.submission?.name ?? record?.name,
    category: record?.submission?.category ?? record?.category,
    country: record?.submission?.country ?? record?.country,
    picture: {
      original: record?.submission?.picture?.original ?? record?.picture?.original
    },
    tags: record?.submission?.tags ?? record?.tags,
    media: {
      instagram: {
        url: record?.submission?.media?.instagram?.url ?? record?.media?.instagram?.url,
        description: record?.submission?.media?.instagram?.description ?? record?.media?.instagram?.description,
        embed: record?.submission?.media?.instagram?.embed ?? record?.media?.instagram?.embed
      },
      soundcloud: {
        url: record?.submission?.media?.soundcloud?.url ?? record?.media?.soundcloud?.url,
        description: record?.submission?.media?.soundcloud?.description ?? record?.media?.soundcloud?.description,
        embed: record?.submission?.media?.soundcloud?.embed ?? record?.media?.soundcloud?.embed
      },
      spotify: {
        url: record?.submission?.media?.spotify?.url ?? record?.media?.spotify?.url,
        description: record?.submission?.media?.spotify?.description ?? record?.media?.spotify?.description,
        embed: record?.submission?.media?.spotify?.embed ?? record?.media?.spotify?.embed
      },
      youtube: {
        url: record?.submission?.media?.youtube?.url ?? record?.media?.youtube?.url,
        description: record?.submission?.media?.youtube?.description ?? record?.media?.youtube?.description,
        embed: record?.submission?.media?.youtube?.embed ?? record?.media?.youtube?.embed
      },
    },
    gallery: record?.submission?.gallery ?? record?.gallery,
    message: record?.submission?.message ?? record?.message,
    short_bio: record?.submission?.short_bio ?? record?.short_bio,
    tagline: record?.submission?.tagline ?? record?.tagline,
    long_bio: record?.submission?.long_bio ?? record?.long_bio,
  };
}

export function eventModel (record) {
  return {
    id: record?.id,
    name: record?.submission?.name ?? record?.name,
    start_date: record?.submission?.start_date ?? record?.start_date,
    duration: record?.submission?.duration ?? record?.duration,
    type: record?.submission?.type ?? record?.type,
    category: record?.submission?.category ?? record?.category,
    description: record?.submission?.description ?? record?.description,
    what_to_expect: record?.submission?.what_to_expect ?? record?.what_to_expect,
    good_to_know: record?.submission?.good_to_know ?? record?.good_to_know,
    tags:  record?.submission?.tags ?? record?.tags,
    picture: {
      original: record?.submission?.picture?.original ?? record?.picture?.original,
    },
    options: {
      name: record?.submission?.options?.name ?? record?.options?.name
    },
      
  };
}