import { Box, Switch, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useResourceContext, useUpdate } from 'react-admin';
import { switchStyleIos } from '../../Components/StyledComponents/Inputs';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { InputSubtitle } from '../../Components/StyledComponents/InputSubtitle';
import { InputTitle } from '../../Components/StyledComponents/InputTitle';
import { strCapitalize } from '../../helpers/strCapitilize';
import AdaptableFormRedesign from '../../Components/AdaptableForm/AdaptableFormRedesign';
import EditModalButton from '../Onboarding/Components/EditModalButton';

export const FormComponents = (props) => {
  const { info, data, resourceProp, onSubmit, xs = 9, mt = 2 } = props;
  const resource = useResourceContext();
  let resourcePick = resource === 'homeComponents' ? 'home' : resource;

  return (
    <Box>
      <FormToolbar
        {...props}
        info={info?.[resourcePick]}
        infoId={info?.id}
        resource={resourcePick}
      />
      <AdaptableFormRedesign
        simpleform={{ record: info, onSubmit: onSubmit }}
        data={data}
        isEdit
        resourceProp={resourceProp}
        xs={xs}
        sx={{ padding: 0 }}
        schemaSx={{ marginTop: mt }}
      />
    </Box>
  );
};

export const FormToolbar = (props) => {
  const { info, section, sx, infoId, resource, noShow, EditInputs } = props;

  const [update, { isLoading }] = useUpdate();
  const customToast = useCustomToasty();

  const handleShow = (e) => {
    let value = e.target.checked;
    let data = {
      [resource]: {
        ...info,
        [section.key]: {
          ...info[section.key],
          show: value,
        },
      },
    };

    return update(
      'app_config',
      { id: infoId, data: data, previousData: info },
      {
        onSuccess: (data) =>
          customToast(
            'success',
            value
              ? `The ${section.key} is now showing in the app`
              : `The ${section.key} is now hidden.`,
          ),
        onError: (err) =>
          customToast('error', 'There was an error, please try again later.'),
      },
    );
  };

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start',
          marginTop: '40px',
          width: 'auto',
          position: 'relative',
          maxWidth: '40%',
        }}
      >
        <Typography
          sx={{
            fontWeight: '900',
            fontSize: '1.3em',
          }}
        >
          {strCapitalize(section?.title)}
        </Typography>
        {EditInputs && (
          <EditModalButton
            {...props}
            EditInputs={EditInputs}
            sx={{ top: '50%', right: '-50px' }}
          />
        )}
      </Box>
      {section?.subtitle && (
        <InputSubtitle sx={{ width: '70%', ...sx }}>
          {section?.subtitle}
        </InputSubtitle>
      )}
      <Tooltip
        arrow
        placement="top"
        title={`Switching this input will show the ${section?.title.toLowerCase()} in the app.`}
      >
        {!noShow && (
          <Box
            sx={{
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
            }}
          >
            {section?.key !== 'featured_categories' && (
              <>
                <InputTitle sx={{ fontSize: '.9em' }}>
                  {info[section.key]?.show ? 'Hide' : 'Show'}
                </InputTitle>
                <Switch
                  disabled={isLoading}
                  checked={info?.[section.key]?.show}
                  sx={switchStyleIos}
                  onChange={handleShow}
                />
              </>
            )}
          </Box>
        )}
      </Tooltip>
    </Box>
  );
};
