
import { BackgroundImageRepeat } from './BackgroundImageRepeat';
import { IconImage } from './IconImage';
import { OnBoardForm } from './OnBoardForm';
import { StepSlider } from './StepSlider';
import TextBox from './TextBox';

export const OnBoardProvider = (props) => {

  const { source, data } = props;

  return (
    <OnBoardForm record={data} {...props}>
      <BackgroundImageRepeat source={source}/>
      <IconImage source={source} />
      <TextBox source={source}  />
    </OnBoardForm>
  );
};