import React, { useCallback, useEffect, useState } from 'react';
import { AutocompleteArrayInput, AutocompleteInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// Hooks & Utils
import { INPUTS_VARIANT } from '../../assets/constants/Form';
// Components
import { InputTitle } from '../StyledComponents/InputTitle';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
// Material & Icons
import { TextField } from '@mui/material';
import { useRequiredSchema } from '../../Hooks/useRequiredSchema';

const InputAutocomplete = ({placeholder,required, title, titleTransform, subtitle, limit, isArray, width,...rest }) => {
  const { setValue } = useFormContext();
  const palette = useSelector((state) => state?.palette?.palette);
  const { isRequiredSchema } = useRequiredSchema();
  const [limitReached, setLimitReached] = useState(false);
  const [values, setValues] = useState([]);
  const onSelect = useCallback((newValues) => {
    setValues(newValues);
    setLimitReached(newValues.length >= limit);
  }, [limit]);

  const checkDisable = useCallback(option => limitReached && !values.includes(option), [limitReached, values]);
  const paramss = useParams();
  const urlparams = paramss['*']?.split('/')[0];
  const fromTalentId = urlparams?.split('=')[1];
  useEffect(() => {
    if (fromTalentId) {
      setValue('talents_ids', [fromTalentId]);
    }
  }, [fromTalentId]);

  if (limit) {
    return (
      <AutocompleteArrayInput 
        onChange={onSelect} 
        getOptionDisabled={checkDisable} 
        variant={INPUTS_VARIANT}
        sx={{'& .MuiAutocomplete-inputRoot': {
          borderRadius: '10px'
        }}} 
        {...rest} />
    );
  }

  if (isArray) {
    return (
      <div>
        {title &&  
      <InputTitle required={required} textTransform={titleTransform} value={title} />
        }
        {subtitle &&  
        <InputSubtitle>{subtitle}</InputSubtitle>
        }
        <AutocompleteArrayInput
          variant={INPUTS_VARIANT} 
          {...rest}
          defaultValue={fromTalentId? [fromTalentId] : null}
          sx={{'& .MuiAutocomplete-inputRoot': {
            borderRadius: '10px',
            width:width ? width : null,
            '& .MuiChip-root':{
              backgroundColor:palette?.primary,
              color:'white',
              padding:'5px'
            },
            '& .MuiChip-deleteIcon':{
              color:'white',
            }
          }}}
        />
      </div>
    );
  }

  if(placeholder) {
    return (
      <>
        {title &&  
        <InputTitle textTransform={titleTransform} value={title} />
        }
        {subtitle &&  
        <InputSubtitle >{subtitle}</InputSubtitle>
        }
        <AutocompleteInput variant={INPUTS_VARIANT} {...rest} renderInput={(params) => <TextField {...params} {...rest} variant="outlined" placeholder={placeholder} />} sx={{'& .MuiAutocomplete-inputRoot': {
          borderRadius: '10px'
        }}}/>
      </>
    );
  }

  return (
    <>
      {title &&  
      <InputTitle required={required} textTransform={titleTransform} value={title} />
      }
      {subtitle &&  
      <InputSubtitle>{subtitle}</InputSubtitle>
      }
      <AutocompleteInput variant={INPUTS_VARIANT} sx={{'& .MuiAutocomplete-inputRoot': {
        borderRadius: '10px'
      }}} {...rest}/>
    </>
  );
};

export default InputAutocomplete;