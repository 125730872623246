import { useFormContext } from 'react-hook-form';
import Store from '../../../Store';
import { useCallback } from 'react';
import { Box, Tooltip } from '@mui/material';
import { DEFAULT_BOARD } from '../boards';
import { useCustomToasty } from '../../../Hooks/useCustomToasty';
import { Button } from '../../../Components/Generics';

export const SliderButtons = ({source, onChange }) => {
  const colorPalette = Store.getState()?.palette.palette;
  const { getValues, setValue } = useFormContext();
  const customToast = useCustomToasty();
  const maxStepsLength = 5;
  const handleAddStep = useCallback(() => {
  
    const field = getValues()?.onboarding;
    const newStep = DEFAULT_BOARD;
      
    setValue('onboarding', [...field, newStep]);
  
  },[getValues()?.onboarding]);
  
  const handleDelete = useCallback(() => {
    let onboardingData = getValues()?.onboarding; 
    
    if(onboardingData?.length <= 2) return customToast('error', 'There must be at least 2 steps containing actions notifications and location.');
    if(source !== 0){
      onChange(source - 1);  
    } else {
      onChange(source + 1);
    }
    return setValue('onboarding', onboardingData.filter((_, i) => i !== source));
  },[source]);
  
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '10px'
      }}
    >
      <Tooltip title="Delete this step">
        <Button
          onClick={handleDelete}
          style={{
            backgroundColor:colorPalette.error, 
            borderColor: colorPalette.error, 
            display: 'flex',
            alignItems: 'center',
            fontWeight: '600',
            padding:'10px',
            color:'#ffffff'
          }}
        >
          Remove Screen
        </Button>
      </Tooltip>
      <Tooltip title="Add a step">
        <Button
          onClick={handleAddStep}
          disabled={getValues()?.onboarding?.length >= maxStepsLength}
          style={{
            backgroundColor:colorPalette.primary,
            borderColor: colorPalette.primary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: '600',
            padding:'10px',
            minWidth: '125px',
            color:'#ffffff'
          }}
        >
          Add Screen
        </Button>
      </Tooltip>
      
      
    </Box>
  );
};