import { Box, TextField } from '@mui/material';
import { InputRequestField } from '../../../../Components/InputsForm/InputRequestField';
import { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

export const EmailComponent = ({control, checkVal, deleteHandle, children, componentKey, newValues, handleChange}) => {
  const [email, setEmail] = useState(null);
  const regexEmail = /^[\w.+@]+([a-zA-Z\d.-]+\.[a-zA-Z]{2,}|(\d{1,3}\.){3}\d{1,3})$/;


  const handlehandleChange = (e) => {
    setEmail({...email, email: e});
  };

  useEffect(() => {
    let valueUpdated = false;
    const updatedValues = newValues.map((item) => {
      if (item.key === componentKey) {
        valueUpdated = true;
        return { ...item, value: email };
      }
      return item;
    });
    if (!valueUpdated) {
      updatedValues.push({ key: componentKey, value: email });
    }
  
    handleChange(updatedValues);
    return() => {
      valueUpdated = false;
    };
  },[email]);

  const validateTm = (data) => {
    if(data?.length > 0) {
      if(!data?.find((item, i) => item.role === 'talent_manager')) return 'This email is in use by another role. It can’t be set as Talent Manager';
      return undefined;
    }
    return undefined;
  };


  return (
    <>
      <Box key={componentKey} style={{display: 'flex', flexDirection: 'row', gap: '20px'}}>
        <Controller 
          control={control}
          rules={{
            required: 'This input is required'
          }}
          name={`adminName.${componentKey}`}
          render={({field,
            fieldState:  { invalid, isTouched, isDirty, error },
            formState,}) => (
            <TextField {...field} helperText={error?.message} {...formState} error={error} isTouched={isTouched} onChange={(e) => {field.onChange(e);setEmail({...email, name: e.target.value});}} label="Name" variant="outlined" sx={{width:'15.5em'}}/>
          )} 
        />
        <InputRequestField
          inputChange={handlehandleChange}
          control={control} 
          resource="admins"
          fieldName="email" 
          pattern={regexEmail} 
          variant="outlined" 
          sx={{
            width: '15.5em'
          }} 
          name={`email.${componentKey}`} 
          label="Email" 
          validate={validateTm}
        />
        {children}
      </Box>
    </>
  );
};