import { Box, FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { usePermissions, useRecordContext, useStore } from 'react-admin';
import { mainColor } from '../../../../Theme';


export const SelectField = ({
  value, 
  sx, 
  field,
  label
}) => {
  const [selected, setSelected] = useStore('approveData', null);

  const handleChange = (item) => {
    setSelected({'options':{[field]: item}});
  };
  
  return (
    <Box 
      mt="2px" 
      sx={{
        width: '100%',
        ...sx,
        gridColumnStart: 1,
        gridColumnEnd: 3
      }}>
      <FormLabel
        sx={{
          textTransform: 'uppercase',
          fontSize: '.8em',
          fontWeight: '600',
          color: 'rgba(0, 0, 0, 0.38)',
          
        }}
      >{label}</FormLabel>
      <FormControl
        sx={{
          width: '100%',
        }}
      >
        <RadioGroup>
          {
            value && field && value[field]?.map((item, index) => (
              <FormControlLabel
               
                
                labelPlacement='start'
                label={item}
                control={
                  <Radio 
                    onChange={() => handleChange(item)}
                    value={item}
                    label={item}
                  />} />
            ))
          }
        </RadioGroup>
      </FormControl>
    </Box>
  );
};


export const SelectFieldNames = ({
  value, 
  sx, 
  field,
  label,
  defaultValue
}) => {
  const [selected, setSelected] = useStore('approveData', null);
  const [radioValue, setRadioValue] = useState(`${defaultValue}-${0}`);
  const handleChange = (item, index) => {
    console.log(item);
    setRadioValue(`${item}-${index}`);
    setSelected({'options':{[field]: item}});
  };
  const { permissions: permis } = usePermissions();
  return (
    <Box 
      mt="2px" 
      sx={{
        ...sx,
        gridColumnStart: 1,
        gridColumnEnd: 3
      }}>
      {/* <FormLabel
        sx={{
          textTransform: 'uppercase',
          fontSize: '.8em',
          fontWeight: '600',
          color: 'rgba(0, 0, 0, 0.38)',
          
        }}
      >{label}</FormLabel> */}
      <FormControl
        sx={{
          width: 'auto',
        }}
      >
        <RadioGroup
          value={radioValue}
        >
          {
            value && field && [defaultValue].concat(value[field])?.map((item, index) => (
              <FormControlLabel
                sx={{
                  justifyContent: 'space-between',
                }}
                name={`${item}-${index}`}
                disabled={permis === 'talent_manager'}
                labelPlacement='end'
                control={
                  <Radio 
                    name={`${item}-${index}`}
                    defaultChecked={index === 0}
                    defaultValue={index === 0}
                    onChange={() => handleChange(item, index)}
                    value={`${item}-${index}`}
                    label={item}
                  />} 
                label={
                  <Typography 
                    style={{
                      fontWeight: '600',
                      color:'#3395ff',
                    }}>{item}</Typography>
                }
              />
            ))
          }
        </RadioGroup>
      </FormControl>
    </Box>
  );
};
