export const actionBarFunctions = (props)=>{
  const { setOpenBox, setEventsTotal, openBox, setValue, setFilterEventsTotal, totalEvents} = props;
  const moveToLeft = () => {
    const eventsElement = document.getElementById(
      'datagrid-events-scrolleable',
    );
    const headerElement = document.getElementById(
      'calendar-venues-scrolleable',
    );
    if (eventsElement && headerElement) {
      eventsElement.scrollLeft -= 250;
      headerElement.scrollLeft -= 250;
    }
  };
  const moveToRight = () => {
    const eventsElement = document.getElementById(
      'datagrid-events-scrolleable',
    );
    const headerElement = document.getElementById(
      'calendar-venues-scrolleable',
    );
    if (eventsElement && headerElement) {
      eventsElement.scrollLeft += 250;
      headerElement.scrollLeft += 250;
    }
  };
  const handleOpenUnscheduled = () => {
    setOpenBox(!openBox);
    setEventsTotal(false);
  };
  const handleCleanInput = () => {
    setValue('');
    setFilterEventsTotal(totalEvents);
  };
  const handleSearchInputChange = (event) => {
    let name = event.target.value;
    setValue(name);
    handlerFilterEvents(name);
    setOpenBox(true);
    setEventsTotal(true);
  };
  const handlerFilterEvents = (name) => {
    let format = name.toLowerCase();
    setFilterEventsTotal(
      totalEvents.filter((event) => event.name.toLowerCase().includes(format)),
    );
  };
  return {
    moveToLeft,
    moveToRight,
    handleOpenUnscheduled,
    handleCleanInput,
    handlerFilterEvents,
    handleSearchInputChange
  };
};