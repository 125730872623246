import {
  Box,
  CircularProgress,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  SimpleForm,
  useEditContext,
  useGetOne,
} from 'react-admin';

import { AdaptableToolbarRedesign } from './AdaptableToolbarRedesign';
import { LineCompleteSticky } from '../RedesignComponents/LineCompleteSticky';
import { SchemaGridSection } from './SchemaGridSection';

const AdaptableFormRedesign = (props) => {
  const {
    simpleform,
    index,
    idToaction,
    showProgress,
    sx,
    schemaSx
  } = props;
  const { isLoading } = useEditContext();
  
  if (isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // TODO: Sacar este get de aca.
  const defaultValor = useGetOne('events', {id: idToaction}, { enabled: Boolean(idToaction)});
  const dataEditar= defaultValor?.data;
  

  return (
    <>
      <SimpleForm
        id={`${index}'adaptable-simple-form'`}
        sx={{ position: 'relative', ...sx }}
        defaultValues={dataEditar}
        toolbar={
          <AdaptableToolbarRedesign {...props} />
        }
        sanitizeEmptyValues
        disableInvalidFormNotification
        // mode='onSubmit'
        {...simpleform}
      >
        {showProgress && (
          <LineCompleteSticky
            data={props.data}
            elementId={`${index}'adaptable-simple-form'`}
          />
        )}
        <Box
          id={index}
          sx={{
            // maxWidth: 1400,
            width: '100%',
            marginTop:5,
            ...schemaSx
          }}
        >
          <SchemaGridSection {...props} />
        </Box>
      </SimpleForm>
    </>
  );
};

export default AdaptableFormRedesign;
