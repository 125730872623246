import React from 'react';
import { InputGalleryAws } from '../../../../Components/RedesignComponents/Inputs/index.js';
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign.jsx';

const Sponsors = () => {
  const data = [
    {
      inputs: [
        {
          field: <InputGalleryAws size={true} urlInput={true} picture limit={15} source={'more.sponsors.items'} draggable={true}/>,
          breakpoints: { xs: 12}
        }
      ],
    },
  ];
    
  return {
    data: data
  };
};

export default Sponsors;