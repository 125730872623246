import {  useState } from 'react';
import { InputTitle } from './InputTitle';
import { Checkbox, Typography } from '@mui/material';
import { EditCategoryModal } from '../../Resources/Info/Form/FormComponents/EditCategoryComponent';
import { apiUrl } from '../../dataProvider';
import axios from 'axios';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { useDispatch, useSelector } from 'react-redux';
import { storeShemas } from '../../Store/Reducers/infoReducer';
import '../../assets/styles/schemaTable.styles.css';
import { MdEdit } from 'react-icons/md';
import { green } from '@mui/material/colors';
import {Button} from '../Generics/index';

export const DnDTables = (props) => {

  const {
    choices,
    resource
  } = props;

  const palette = useSelector(state => state?.palette?.palette);
  const [rows, setRows] = useState(choices);
  const [isDragging, setIsDragging] = useState(false);
  const [openItem, setOpenItem] = useState(null);
  const customToast = useCustomToasty();
  const dispatch = useDispatch();
  
  const onDrag = (e, id) => {
    e.dataTransfer.setData('text/plain', id);
  };
  
  const onDragOver = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };
  
  const drop = (e, boolean) => {
    setIsDragging(false);
    e.preventDefault();
    const data = e.dataTransfer.getData('text');
    setRows((f) => f?.map((item => {
      let obj = {...item};
      if(item.key === data){
        return {...obj, selected: boolean};
      }
      return item;
    })));
  };

  const onClickRequired = (e,v) => {
    setRows((f) => f?.map((item => {
      let obj = {...item};
      if(item.key === v.key){
        return {...obj, required: e};
      }
      return item;
    })));
  };

  const rowProps = {
    setIsDragging: setIsDragging,
    onDrag: onDrag, 
    setOpenItem: setOpenItem, 
    onDragOver: onDragOver, 
    rows: rows,
    onClickRequired: onClickRequired,
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios({
        url: `${apiUrl}/schemas/${resource}`,
        method: 'PUT',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'content-range',
          'Authorization': 'Bearer ' + localStorage.getItem('auth'),
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
        data: {
          additional: rows?.filter(item => !item.unmovable && item?.selected).map(item => item.name),
          required: rows?.filter(item => !item.unmovable && item?.selected && item.required).map(item => item.name),
        }
      });
      if(response.data){
        dispatch && dispatch(storeShemas({value: response.data, key: resource}));
        customToast('success', `The ${resource} schema has been updated.`);
      }
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  };
    
  
  return (
    <div 
      id="DnDTable-outerDiv"
      style={{
        'display': 'flex', 
        flexDirection: 'column',
        'width': '100%'
      }}
    >
      <div
        id="DnDTable-innerDiv"

        onDragOver={onDragOver} 
        style={{
          'display': 'flex',
          flexDirection:'row',
          alignItems: 'flex-start',
          'width': '100%',
          'gap':'20px'
        }}
      >
        <div style={{'width': '100%'}}>
          <div 
            onDragOver={onDragOver} 
            onDrop={(e) => drop(e, true)} 
            style={{ display: 'flex', flexDirection:'column', gap: '20px'}}
          >
            <InputTitle>Fields in use</InputTitle>
            <table 
              onDragOver={onDragOver} 
              onDrop={(e) => drop(e, true)} 
              id="fields-in-use" 
              className='dnd-table'
            >
              <Thead />
              <tbody key={`${resource}-0`} className={isDragging ? 'selected-shadow' : ''}>
                {
                  rows?.map((item, index) => {
                    if(item.selected || item.unmovable){
                      return (
                        <DraggableRow {...rowProps} item={item} key={item.key} id={item.key} />
                      );
                    } 
                  })
                }
                { 
                  !rows?.find(item => item.selected || item.unmovable) && <EmptyMessage />}
              </tbody>
            </table>
          </div>
        </div>
        <div style={{width: '100%', display: 'flex', flexDirection:'column', gap: '20px'}}>
          <InputTitle>Available Fields</InputTitle>
          <table 
            onDragOver={onDragOver} 
            onDrop={(e) => drop(e, false)} 
            id="available-fields"
            className='dnd-table'>
            <Thead />
            <tbody key={`${resource}-1`} className={isDragging ? 'selected-shadow' : ''}>
              {
                rows?.map((item, index) => {
                  if(!item.selected && !item.unmovable){
                    return (
                      <DraggableRow {...rowProps} item={item} key={item.key} id={item.key} disabled />
  
                    );
                  }
                })
              }
              { 
                !rows?.find(item => !item.selected && !item.unmovable) && <EmptyMessage />}
            </tbody>
          </table>
        </div>
        <EditCategoryModal open={!!openItem} setOpen={setOpenItem} item={openItem} {...props} />
      </div>
      <div style={{ display: 'flex', width: '100%', margin: '20px', justifyContent: 'flex-end'}}>
        <Button data-testid="test-onSubmit" onClick={onSubmit} style={{backgroundColor:palette?.primary,color:'#ffffff',textTransform:'uppercase',fontWeight:500}} hoverStyle={{backgroundColor: palette?.primary}}>Save</Button>
      </div>
    </div>
  );
};
  

const DraggableRow = ({ item, onClickRequired, id, children, setIsDragging, onDrag, setOpenItem, onDragOver, rows, reqDisabled,  ...rest }) => {
  const palette = useSelector(state => state.palette.palette);

  return <tr 

    id={`${id}-tr`}
    onDragEnd={() => {
      setIsDragging(false);
    }} 
    onDragStart={(e) => {
      onDrag(e,id);
    }} 
    onDragLeave={() => setIsDragging(true)}
    onDragOver={onDragOver} 
    draggable={item?.unmovable ? 'false' : 'true'}
    className={!item.unmovable ? 'dnd-tbody-selectable dnd-tbody-tr': 'dnd-tbody-tr'}
    style={{
      backgroundColor: item.unmovable && '#f2f2f2',
      cursor: item.unmovable ? 'default' : 'pointer'
    }}
  >
    <td key={`${id}-td-1`} className='dnd-td'>{item.name?.replace('_', ' ')}</td>
    {/* <td className='dnd-td'>{item.type}</td> */}
    <td key={`${id}-td-2`}  className='dnd-td'>{
      item.name === 'category' ? (
        <Button 
          onClick={() => setOpenItem(item)} 
          style={{
            gap: '10px',
            textTransform:'uppercase',
            fontWeight:500
          }}
        >
          
          <Typography sx={{
            color: palette.primary,
            fontSize: '.9em',
            fontWeight: '800',
            
          }}>Edit</Typography>
          <MdEdit
            // onClick={() => setOpenItem(item)} 
            style={{
              cursor: 'pointer', 
            }} 
            color={palette.primary}
            size="20px" 
          />
        </Button>
      ) : ''}</td>
    <td key={`${id}-td-3`}  className='dnd-td'>
      <Checkbox
        data-testid="checkbox-test"
        {...rest} 
        onClick={(e) => onClickRequired(e.target.checked, item)}
        disableRipple
        sx={{
          width: '20px',
          height: '20px',
          zIndex: 999,
          color: `!important ${green[600]}`,
          borderColor: `!important ${green[800]}`,
          '&.Mui-checked': {
            color: `!important ${green[600]}`,
            borderColor: `!important ${green[800]}`,
          },
          '&.MuiCheckbox-root': {
            color: `!important ${green[600]}`,
            borderColor: `!important ${green[800]}`,
          },
          '&:hover': {
            borderColor: `!important ${green[800]}`, 
            color: green[500], 
            '&.MuiCheckbox-root': {
              color: `!important ${green[600]}`,
              borderColor: `!important ${green[800]}`,
            },
          },
        }}
        checked={rows?.find(item => item.key === id)?.required}
        disabled={rest.disabled || item.unmovable}
      />
    </td>
  </tr>;
};

const Thead = () => (
  <thead key={'thead'}  className='dnd-thead'>
    <tr key={'tr'} className='dnd-tr'>
      <th key={'th-1'} className='dnd-th'>Field name</th>
      {/* <th className='dnd-th'>Type</th> */}
      <th key={'th-2'}  className='dnd-th'></th>
      <th key={'th-3'}  className='dnd-th'>Required</th>
    </tr>
  </thead>
);

const EmptyMessage = () => (
  <tr key={'tr-empty'}  className="empty-list">
    <td  key={'td-empty'} colSpan={5} style={{textAlign: 'center'}}>
      <Typography 
        sx={{
          width: '100%',
          fontSize: '1em',
          fontWeight: '500', 
          color: '#606060'
        }}>
          No items on this list
      </Typography>
    </td> 
  </tr>
);
