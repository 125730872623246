import {
  DeleteButton,
  Toolbar,
  usePermissions,
  useRecordContext,
  useResourceContext,
} from 'react-admin';
import { useSelector } from 'react-redux';
import React, { useMemo, useState } from 'react';
// Hooks & Utils
import { COLORS } from '../../assets/constants/theme';
import { submitFunctions } from './functions';
// Components
import { Button } from '../Generics';
// Material & Icons
import { Box, Tooltip } from '@mui/material';
import ActionButtons from '../StyledComponents/ActionButtons';
import { FaSave } from 'react-icons/fa';

export const AdaptableToolbarRedesign = (props) => {
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        padding: 0,
        margin: 0,
      }}
    >
      <FormButtons {...props} />
    </Box>
  );
};

export const FormButtons = (props) => {
  const { showDelete, saveBackground, floating } = props;
  const resource = useResourceContext();

  const palette = useSelector((state) => state?.palette?.palette);
  const styledFloating = floating
    ? {
        position: 'absolute',
        bottom: 0,
        right: 0,
        transition: 'width 500ms',
        animation: '1s ease-in',
        backgroundColor: saveBackground
          ? COLORS.forms.header.background
          : 'transparent',
      }
    : {};

  return (
    <Toolbar
      sx={{
        display: 'flex',
        flexDirection: showDelete ? 'row-reverse' : 'row',
        height: '70px',
        justifyContent: showDelete ? 'space-between' : 'flex-end',
        gap: '20px',
        backgroundColor: saveBackground
          ? COLORS.forms.header.background
          : 'transparent',
        ...styledFloating,
      }}
    >
      <SaveButtonComponent {...props} resource={resource} palette={palette} />
      <SaveAsDraftComponent {...props} resource={resource} palette={palette} />
      <DeleteComponent {...props} resource={resource} palette={palette} />
    </Toolbar>
  );
};

export const SaveButtonComponent = (props) => {
  const { noTooltip, permis, isEdit, resource } = props;
  const [loading, setLoading] = useState(false);
  const { handleData } = submitFunctions(props);

  const updatedProps = {
    ...props,
    loading,
    setLoading,
    resource,
  };

  const MemoSave = useMemo(() => {
    return (
      <ActionButtons
        label={
          permis === 'talent_manager' ? 'Save' : isEdit ? 'Save' : 'Create'
        }
        onClick={() => handleData(updatedProps)}
        {...updatedProps}
        sx={{ height: '40px' }}
      />
    );
  }, [loading]);

  return (
    <Tooltip
      slotProps={{ tooltip: { sx: { fontSize: '.9em' } } }}
      placement='top'
      title={
        noTooltip
          ? ''
          : permis === 'talent_manager' &&
            'This information will be reviewed by the staff. You will be notified when this process is done or if any change is needed.'
      }
    >
      {React.cloneElement(MemoSave)}
    </Tooltip>
  );
};
export const SaveAsDraftComponent = (props) => {
  const { draft, palette } = props;
  const record = useRecordContext();
  const { handleData } = submitFunctions({ ...props, isDraft: true });
  const { permissions: permis } = usePermissions();
  const [loading, setLoading] = useState(false);

  const updatedProps = {
    ...props,
    isDraft: true,
    loading,
    setLoading,
  };

  if (
    draft &&
    permis === 'talent_manager' &&
    (['created', 'draft', null].includes(record?.submission) ||
      ['created', 'draft', null, 'not_submitted'].includes(
        record?.submission?.status
      ))
  ) {
    return (
      <Tooltip
        slotProps={{ tooltip: { sx: { fontSize: '.9em' } } }}
        placement='top'
        title={
          'Save the current changes in case you are you lack required information to submit the document.'
        }
      >
        <div>
          <Button
            style={{
              backgroundColor: palette?.primary,
              textTransform: 'none',
              fontSize: '0.875rem',
              fontWeight: '500',
              height: '44px',
              minWidth: '135px',
              borderRadius: '10px',
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              gap: '0.5em',
            }}
            hoverStyle={{ backgroundColor: palette?.secondary }}
            onClick={() => handleData(updatedProps)}
          >
            <FaSave size='15px' />
            <p>SAVE AS DRAFT</p>
          </Button>
        </div>
      </Tooltip>
    );
  }
};

export const DeleteComponent = (props) => {
  const { showDelete, isEdit, deleteResource, resource } = props;
  const { permissions: permis } = usePermissions();
  const { onError } = submitFunctions(props);

  if (
    permis !== 'talent_manager' &&
    permis !== 'admin' &&
    showDelete &&
    isEdit
  ) {
    return (
      <Box>
        <DeleteButton
          confirmContent={
            deleteResource
              ? 'Are you sure you want to delete this event'
              : `Are you sure you want to delete this ${resource === 'events' ? 'event' : resource}?`
          }
          confirmTitle='Delete'
          mutationOptions={{ onError }}
          mutationMode='pessimistic'
          // variant="contained"
          // Si se le pasa la prop deleteResource, que en el unico lugar es talents events
          // se toma events para eliminar, pero si no es el caso, usa el default
          resource={deleteResource ? deleteResource : resource}
          redirect={deleteResource && '/talents'}
          sx={{ paddingX: '15px', paddingY: '10px', borderRadius: '10px' }}
        />
      </Box>
    );
  }
};
