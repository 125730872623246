import React, { useMemo } from 'react';
import { useRecordContext, useRedirect, useResourceContext } from 'react-admin';
import { editedFields } from '../../helpers/editedFields';

export const ShowProvider = ({
  switchBody, 
  children,
  body
}) => {
      
  const record = useRecordContext();
  const eFields = useMemo(() => {
    if (!switchBody) {
      if (body) {
        return editedFields(body);
      }
    }
    return [];
  }, [switchBody]);

  return (
    <>
      { 
        eFields && 
            React.cloneElement(children, {
              editedFields: eFields, 
              switchBody: switchBody
            })
            
      }
    </>
  );
};