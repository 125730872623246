import { useMemo, useState } from 'react';
import { Box, Skeleton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeLineDays } from './TimeLineDays';
import { useSelector } from 'react-redux';
import StartDates from '../../StartDates/StartDates';

export const InputSelectTimes = ({ source }) => {
  const [minTime, setMinTime] = useState([]);

  const memoedTime = useMemo(() => {
    return minTime;
  }, [minTime]);

  const days = useSelector((state) => state.data.info.days);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {days ? (
        <Box
          sx={{
            width: '100%',
            height: 'auto',
          }}
        >
          {source !== 'start_dates' ? (
            <TimeLineDays
              setMinTime={setMinTime}
              minTime={memoedTime}
              source={source}
              days={days}
            />
          ) : (
            <StartDates source={source} days={days} />
          )}
        </Box>
      ) : (
        <>
          <Skeleton sx={{ width: '100%', height: '50px' }} />
          <Skeleton sx={{ width: '100%', height: '50px' }} />
          <Skeleton sx={{ width: '100%', height: '50px' }} />
        </>
      )}
    </LocalizationProvider>
  );
};
