

export const transform = (data) => {
  const { send_now, ...rest } = data;

  console.log('notif', data);
  
  const dataSend = {
    ...rest,
    send_at: send_now ? null : rest.send_at
  };
  
  return dataSend;
};