import { useInput } from 'react-admin';
import { InputAddTextInputs } from '../../../Talents/Form/TalentsListComponents/InputAddTextInputs';
import { LinksItems } from './LinksItems';
import { useFormContext } from 'react-hook-form';

export const InputAddLinkItems = (props) => {
  const { source } = props;
  const {
    field: { value, onChange },
  } = useInput({ source, defaultValue: [] });
  const { getValues } = useFormContext();
  const handleDelete = (index) => {
    let newArr = value?.filter((_, i) => i !== index);
    onChange(newArr);
  };
  return (
    <InputAddTextInputs
      direction="row"
      addBelow
      onDelete={handleDelete}
      element={<LinksItems {...props} />}
      initialNumber={getValues()['more']['external_links']['items'].length}
      limit={10}
      {...props}
    />
  );
};
