import { Box, Modal } from '@mui/material';
import { useState } from 'react';
import { ShowProvider } from '../../../Components/ShowComponents/ShowProvider';
import { ProfileCard } from '../../Talents/Form/TalentsShowComponents/ProfileCard';
import { Button } from '../../../Components/Generics';

export const ShowProfileModal = (props) => {
  const { body } = props;
  const [open, setOpen] = useState(false);
  
  return (
    <>
      <Button 
        style={{
          position: 'absolute',
          top: '30%',
          right: '0%',
        }}  
        onClick={() => setOpen(true)}
      >
        View Last Submission
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            width: '70vw',
            padding: '20px',
            height: 'auto',
            maxHeight: '90vh',
            overflowX: 'hidden',
            overflowY: 'scroll',
            borderRadius: '12px',
            backgroundColor: 'white',
            '&::-webkit-scrollbar': {
              width: '12px',
              backgroundColor: 'white',
            },
          }}
        >
          <ShowProvider body={body} >
            <ProfileCard showButtons={false} />
          </ShowProvider>
        </Box>
      </Modal>
    </>
  );
};