import React from 'react';
import { InputAddLinkItems } from '../../../HomeComponents/Sections/Components/InputAddLinkItems';

const ExternalLinks = () => {
  const data = [
    {
      inputs: [
        {
          field: (
            <InputAddLinkItems
              source="more.external_links.items"
              noDisableRemove={true}
            />
          ),
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];

  return {
    data: data,
  };
};

export default ExternalLinks;
