import React, { useState } from 'react';
import {
  MenuItemLink,
  useGetList,
  usePermissions,
  useResourceDefinitions,
  useSidebarState,
  useStore,
} from 'react-admin';
import { Collapse, Typography, keyframes, useTheme } from '@mui/material';
import { Box } from '@mui/material';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { COLORS } from '../../../assets/constants/theme';
import { TbUsers } from 'react-icons/tb';
import { BsCalendarEvent, BsInfoCircle } from 'react-icons/bs';
import { pendingSubmissions } from '../../../dataProvider';
import { MdFolderOpen } from 'react-icons/md';
import { LuHelpingHand } from 'react-icons/lu';
import { BsLayoutTextSidebarReverse } from 'react-icons/bs';
import { MdOutlinePendingActions } from 'react-icons/md';
import { TbMicrophone2 } from 'react-icons/tb';
import { MdInterpreterMode } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { IoMdPerson } from 'react-icons/io';
import { useParams } from 'react-router-dom';
export const TitleSection = ({ resource, resources }) => {
  const sidebarOpen = useSidebarState()[0];
  return (
    <Box style={{}}>
      <Box
        sx={{
          cursor: 'default',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '5px 12px',
          padding: '6px 9px 6px 15px',
          borderRadius: '12px',
        }}
      >
        {sidebarOpen && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="subtitle2"
                style={{
                  color: '#515151',
                  fontWeight: '600',
                  borderRadius: '12px',
                  fontSize: '14px',
                  lineHeight: '1.75',
                  letterSpacing: '0.01em',
                }}
              >
                {resource.options.label ?? resource.name}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Collapse in={!sidebarOpen} unmountOnExit>
        {resources.map((item, index) => {
          if (
            item.options?.menuParent &&
            item.options?.menuParent === resource.name
          ) {
            return (
              <Box
                sx={{
                  position: 'relative',
                }}
              >
                <MenuItemLink
                  key={`${item.name}-${index}`}
                  to={
                    item.options.customPath
                      ? `/${item.options.customPath}`
                      : `/${item.name}`
                  }
                  primaryText={
                    (item.options && item.options.label) || item.name
                  }
                  leftIcon={item.icon ? <item.icon /> : null}
                  sidebarIsOpen={open}
                  sx={{
                    margin: sidebarOpen[0] ? '0px 12px 0 24px' : '0px 3px',
                    color: '#757575',
                    padding: '6px 16px',
                    fontWeight: '600',
                    borderRadius: '12px',
                    fontSize: '0.85rem',
                    lineHeight: '1.75',
                    letterSpacing: '0.01em',
                    '&.RaMenuItemLink-active': {
                      backgroundColor: '#eeeeee',
                      color: COLORS.light.primary.main,
                      '& .MuiListItemIcon-root': {
                        color: COLORS.light.primary.main,
                      },
                    },
                  }}
                />
                {item.options.notifications &&
                pendingSubmissions[item.name] &&
                pendingSubmissions[item.name] > 0 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        position: 'absolute',
                        top: 5,
                        right: 10,
                        backgroundColor: 'red',
                        color: 'white',
                        fontSize: '.7em',
                        width: '15px',
                        height: '15px',
                        fontWeight: '600',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {pendingSubmissions[item.name]}
                    </Box>
                  ) : null}
              </Box>
            );
          }
        })}
      </Collapse>
    </Box>
  );
};

export const SectionParent = ({ resource, resources }) => {
  const [open, setOpen] = useState(false);
  const palette = useSelector((state) => state?.palette?.palette);
  const params = useParams();
  const urlparams = params['*']?.split('/')[0];
  const formatUrlParams = () => {
    switch (urlparams) {
    case 'event_submissions':
      return 'events';
    case 'talent_submissions':
      return 'talents';
    case 'events':
      return 'events';
    case 'talents':
      return 'talents';
    default:
      break;
    }
  };
  const resourceplural = `${resource.name.toLowerCase()}s`;
  const sidebarOpen = useSidebarState();
  const pickIconForResource = (resourceName) => {
    if (resourceName === 'articles') {
      return <BsLayoutTextSidebarReverse />;
    }
    if (resourceName === 'talents') {
      return (
        <IoMdPerson
          color={resourceplural === formatUrlParams() ? '#ffffff' : '#000000'}
        />
      );
    }
    if (resourceName === 'talent_submissions') {
      return <MdOutlinePendingActions />;
    }
    if (resourceName === 'events') {
      return (
        <MdOutlinePendingActions
          color={resourceplural === formatUrlParams() ? '#ffffff' : '#000000'}
        />
      );
    }
    if (resourceName === 'event_submissions') {
      return <MdOutlinePendingActions />;
    }
  };
  const itemsNoShow = (name) => {
    if (sidebarOpen[0] === false) {
      if (
        name === 'talent_submissions' ||
        name === 'event_submissions' ||
        name === 'categories'
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const openStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: '12px',
  };
  const closeStyle = {
    cursor: 'pointer',
  };
  return (
    <Box
      id={resource.name}
      sx={{
        marginBottom: open && '6px',
        '&:hover': {
          backgroundColor:
            formatUrlParams() === resourceplural
              ? null
              : !sidebarOpen[0]
                ? 'transparent'
                : '#f5f5f5',
        },
        backgroundColor: !sidebarOpen[0]
          ? formatUrlParams() === resourceplural
            ? palette?.primary
            : 'transparent'
          : null,
      }}
    >
      <div
        style={{
          backgroundColor: sidebarOpen[0]
            ? formatUrlParams() === resourceplural
              ? palette?.primary
              : 'transparent'
            : null,
          padding: sidebarOpen[0] ? '6px 16px' : null,
        }}
      >
        <Box
          sx={sidebarOpen[0] ? openStyle : closeStyle}
          onClick={sidebarOpen[0] ? () => setOpen(!open) : null}
        >
          {sidebarOpen[0] && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  padding: 0,
                  margin: 0,
                }}
              >
                <div
                  style={{
                    minWidth: '30px',
                    height: '28px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <resource.icon
                    color={
                      resourceplural === formatUrlParams()
                        ? '#ffffff'
                        : '#000000'
                    }
                  />
                </div>
                <Typography
                  variant="subtitle2"
                  style={{
                    color:
                      resourceplural === formatUrlParams()
                        ? '#ffffff'
                        : '#000000',
                    fontWeight: '600',
                    borderRadius: '12px',
                    fontSize: '16px',
                    lineHeight: '1.75',
                    letterSpacing: '0.01em',
                  }}
                >
                  {resource.options.label ?? resource.name}
                </Typography>
                {pendingSubmissions[
                  resource.name === 'Talent'
                    ? 'talent_submissions'
                    : 'event_submissions'
                ] &&
                pendingSubmissions[
                  resource.name === 'Talent'
                    ? 'talent_submissions'
                    : 'event_submissions'
                ] > 0 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        position: 'relative',
                        backgroundColor:
                        formatUrlParams() === resourceplural
                          ? '#ffffff'
                          : palette?.primary,
                        color:
                        formatUrlParams() === resourceplural
                          ? '#000000'
                          : '#ffffff',
                        fontSize: '.6em',
                        width: '19px',
                        height: '16px',
                        fontWeight: '600',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginLeft: '50px',
                        pointerEvents: 'none',
                      }}
                    >
                      <span>
                        {
                          pendingSubmissions[
                            resource.name === 'Talent'
                              ? 'talent_submissions'
                              : 'event_submissions'
                          ]
                        }
                      </span>
                    </Box>
                  ) : null}
              </Box>
              {open ? (
                <MdExpandLess
                  style={{
                    color:
                      resourceplural === formatUrlParams()
                        ? '#ffffff'
                        : '#000000',
                  }}
                />
              ) : (
                <MdExpandMore
                  style={{
                    color:
                      resourceplural === formatUrlParams()
                        ? '#ffffff'
                        : '#000000',
                  }}
                />
              )}
            </>
          )}
        </Box>
      </div>
      <Collapse in={!sidebarOpen[0] || open} unmountOnExit>
        {resources.map((item, index) => {
          if (
            item.options?.menuParent &&
            item.options?.menuParent === resource.name &&
            itemsNoShow(item.name)
          ) {
            return (
              <Box
                sx={{
                  position: 'relative',
                  '& .MuiListItemIcon-root': {
                    marginLeft: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    minWidth: '36px',
                  },
                }}
              >
                <MenuItemLink
                  key={`${item.name}${index}`}
                  to={
                    item.options.customPath
                      ? `/${item.options.customPath}`
                      : `/${item.name}`
                  }
                  primaryText={
                    sidebarOpen[0]
                      ? (item.options && item.options.label) || item.name
                      : item.name === 'events'
                        ? 'Events'
                        : item.name === 'talents'
                          ? 'Talents'
                          : 'Content'
                  }
                  leftIcon={
                    sidebarOpen[0] ? (
                      <span
                        style={{
                          width: '6px',
                          height: '6px',
                          borderRadius: '100%',
                          backgroundColor:
                            item.name === urlparams
                              ? palette?.primary
                              : '#7C7C7C',
                        }}
                      ></span>
                    ) : (
                      pickIconForResource(item.name)
                    )
                  }
                  sidebarIsOpen={open}
                  sx={{
                    color: '#7C7C7C',
                    fontWeight: '600',
                    borderRadius: '12px',
                    fontSize: '0.85rem',
                    lineHeight: '1.75',
                    letterSpacing: '0.01em',
                    '&.RaMenuItemLink-active': {
                      color: palette?.primary,
                    },
                  }}
                />
                {item.options.notifications &&
                pendingSubmissions[item.name] &&
                pendingSubmissions[item.name] > 0 ? (
                    <Box
                      sx={{
                        display: 'flex',
                        position: 'absolute',
                        top: 10,
                        right: 60,
                        backgroundColor: palette?.primary,
                        color: 'white',
                        fontSize: '.6em',
                        width: '19px',
                        height: '16px',
                        fontWeight: '600',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        pointerEvents: 'none',
                      }}
                    >
                      <span>{pendingSubmissions[item.name]}</span>
                    </Box>
                  ) : null}
              </Box>
            );
          }
        })}
      </Collapse>
    </Box>
  );
};

export const GuideButton = () => {
  const sidebarOpen = useSidebarState();

  return (
    <Box
      style={{
        marginBottom: open && '6px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '6px 16px',
          margin: sidebarOpen[0] ? '0 12px' : '3px',
          cursor: 'pointer',
          borderRadius: '12px',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#757575',
              marginLeft: '0px',
              marginRight: '20px',
              letterSpacing: '0.05em',
              fontWeight: '600',
              fontSize: '0.8rem',
            }}
          >
            <LuHelpingHand size={20} />
          </div>
          <Typography
            variant="subtitle2"
            style={{
              color: '#000000',
              fontWeight: '600',
              borderRadius: '12px',
              fontSize: '16px',
              lineHeight: '1.75',
              letterSpacing: '0.01em',
            }}
          >
            Guide
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const TalentManagerForm = ({ talent_id }) => {
  const [selectedTalent] = useStore('selectedTalent', 'selectedTalent', {
    selected: false,
    talent: '',
  });
  const [open, setOpen] = useState(true);
  const sidebarOpen = useSidebarState();
  const permis = usePermissions()?.permissions !== 'talent_manager';
  const resorce = useGetList('resources', {
    filter: {},
    pagination: { perPage: 9999, page: 1 },
  });
  const { data: dataTalentos } = useGetList('talents', {
    filter: {},
    pagination: { perPage: 9999, page: 1 },
  });
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(
    (name) => resourcesDefinitions[name],
  );
  var FORM_TALENT_SIDEBAR = [
    {
      name: permis ? 'Events' : 'My Events',
      path: 'event',
      icon: BsCalendarEvent,
    },
  ];

  if (resorce?.data) {
    FORM_TALENT_SIDEBAR = [
      ...FORM_TALENT_SIDEBAR,
      {
        name: permis ? 'Resources' : 'Mktg Assets Library',
        path: 'resources',
        icon: MdFolderOpen,
        target: true,
        link: resorce?.data?.[0]?.link,
      },
    ];
  }

  const myEffect = keyframes`
    0%{
      transform: translateX(100%);
     
    }
    100%{
      transform: translateX(-100%);
      
    }
  `;

  const myEffect2 = keyframes`
  0%{
    transform: translateX(0%);
   
  }
  100%{
    transform: translateX(-100%);
  
  }
  `;

  const classAnimation = {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    animation: `${myEffect2} 2s linear 1, ${myEffect} 4s 2s linear infinite`,
  };

  const styledMenuItem = {
    margin: sidebarOpen[0] ? '0px 12px 0 24px' : '0px 3px',
    color: '#757575',
    padding: '6px 16px',
    fontWeight: '600',
    borderRadius: '12px',
    fontSize: '0.85rem',
    lineHeight: '1.75',
    letterSpacing: '0.01em',
    boxSizing: 'border-box',
    '&.RaMenuItemLink-active': {
      backgroundColor: '#eeeeee',
      color: COLORS.light.primary.main,
      '& .MuiListItemIcon-root': {
        color: COLORS.light.primary.main,
      },
    },
  };
  return (
    <>
      <Box style={{ marginBottom: open && '6px' }}>
        {/* <ModalSelectTalent list={dataTalentos} /> */}
        {resources.map((resource, index) => {
          const firstIndex = index === 0 ? true : false;
          return (
            <>
              {/* PARA EL DASHBOARD */}
              {firstIndex && (
                <Box
                  sx={{
                    height: sidebarOpen[0] ? '80px' : 'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid',
                    borderColor: '#808080',
                    marginBottom: '10px',
                  }}
                >
                  <MenuItemLink
                    key={`${resource.name}-${index}`}
                    to={
                      resource.options.customPath
                        ? `/${resource.options.customPath}`
                        : `/${resource.name}`
                    }
                    primaryText={
                      (resource.options && resource.options.label) ||
                      resource.name
                    }
                    leftIcon={resource.icon ? <resource.icon /> : null}
                    sidebarIsOpen={sidebarOpen[0]}
                    sx={{
                      padding: sidebarOpen[0] ? '6px 29px' : null,
                      color: '#000000',
                      fontWeight: '600',
                      width: '100%',
                      height: '100%',
                      margin: sidebarOpen[0] ? '0 0px' : '0px',
                      '&.RaMenuItemLink-active': {
                        backgroundColor: '#eeeeee',
                        color: COLORS.light.primary.main,
                        '& .MuiListItemIcon-root': {
                          color: COLORS.light.primary.main,
                        },
                      },
                    }}
                  />
                </Box>
              )}
              {!firstIndex &&
              resource.options.isTitle !== true &&
              resource.name !== 'event_submissions' ? (
                  <MenuItemLink
                    key={resource.name}
                    to={
                      resource.options.customPath
                        ? `/${resource.options.customPath}`
                        : `/${resource.name}`
                    }
                    primaryText={
                      resource.name === 'talents' &&
                    resource.options.label === 'Active'
                        ? 'Talent'
                        : resource.name === 'events' &&
                          resource.options.label === 'Active'
                          ? 'Events'
                          : (resource.options && resource.options.label) ||
                          resource.name
                    }
                    leftIcon={
                      resource.icon ? (
                        <resource.icon />
                      ) : resource.name === 'talents' ? (
                        <MdInterpreterMode />
                      ) : resource.name === 'events' ? (
                        <TbMicrophone2 />
                      ) : null
                    }
                    sidebarIsOpen={sidebarOpen[0]}
                    sx={{
                      color: '#000000',
                      padding: '6px 16px',
                      margin: sidebarOpen[0] ? '0 12px' : '3px',
                      fontWeight: '600',
                      borderRadius: '12px',
                      lineHeight: '1.75',
                      letterSpacing: '0.01em',
                      '&.RaMenuItemLink-active': {
                        backgroundColor: '#eeeeee',
                        color: COLORS.light.primary.main,
                        '& .MuiListItemIcon-root': {
                          color: COLORS.light.primary.main,
                        },
                      },
                    }}
                  />
                ) : null}
            </>
          );
        })}
      </Box>
    </>
  );
};

export const FestivalForm = ({ talent_id }) => {
  const sidebarOpen = useSidebarState();
  const [open, setOpen] = useState(true);
  const FORM_FESTIVAL_SIDEBAR = [
    {
      name: 'Festival Information',
      path: 'festivals',
      icon: BsInfoCircle,
    },
  ];
  return (
    <>
      <Box
        style={{
          marginBottom: open && '6px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0px 12px',
            padding: '6px 9px 6px 15px',
            cursor: 'pointer',
            borderRadius: '12px',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
          }}
          onClick={() => setOpen(!open)}
        >
          {sidebarOpen[0] && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TbUsers
                  style={{
                    color: '#757575',
                    marginLeft: '3px',
                    marginRight: '25px',
                    letterSpacing: '0.05em',
                    fontWeight: '600',
                    fontSize: '0.8rem',
                  }}
                />
                <Typography
                  variant="subtitle2"
                  style={{
                    color: '#757575',
                    fontWeight: '600',
                    borderRadius: '12px',
                    fontSize: '.9rem',
                    lineHeight: '1.75',
                    letterSpacing: '0.01em',
                  }}
                >
                  {'Festival'}
                </Typography>
              </Box>
              {open ? (
                <MdExpandLess style={{ color: '#757575' }} />
              ) : (
                <MdExpandMore style={{ color: '#757575' }} />
              )}
            </>
          )}
        </Box>
        <Collapse in={!sidebarOpen[0] || open} unmountOnExit>
          {FORM_FESTIVAL_SIDEBAR?.map((item, i) => (
            <MenuItemLink
              key={`${item.name}-${i}`}
              to={`${item.path}/64de33473c70cacd8a1fdbde/edit`}
              primaryText={item.name}
              leftIcon={<item.icon />}
              sidebarIsOpen={open}
              sx={{
                margin: sidebarOpen[0] ? '0px 12px 0 24px' : '0px 3px',
                color: '#757575',
                padding: '6px 16px',
                fontWeight: '600',
                borderRadius: '12px',
                fontSize: '0.85rem',
                lineHeight: '1.75',
                letterSpacing: '0.01em',
                '&.RaMenuItemLink-active': {
                  backgroundColor: '#eeeeee',
                  color: COLORS.light.primary.main,
                  '& .MuiListItemIcon-root': {
                    color: COLORS.light.primary.main,
                  },
                },
              }}
            />
          ))}
        </Collapse>
      </Box>
    </>
  );
};
