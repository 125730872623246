import { memo } from 'react';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import { ColorPickerTextField } from '../../../Components/InputsForm/InputColorPicker';
import { SelectInput } from 'react-admin';
import { useDispatch } from 'react-redux';
import { setPalette } from '../../../Store/Reducers/paletteReducer';

export const FormPalette = () => {
  const dispatch = useDispatch();
  const data = [
    {
      title: 'Palette',
      subtitle: 'This colors will be present in your app and backoffice',
      inputs: [
        {
          field: (
            <ColorPickerTextField
              hexFormat
              label=""
              title={'Primary'}
              source={'palette.primary'}
              sx={{ borderRadius: '5px' }}
              sizeBox={{ width: '130px' }}
            />
          ),
        },
        {
          field: (
            <ColorPickerTextField
              hexFormat
              label=""
              title={'Secondary'}
              source={'palette.secondary'}
              sx={{ borderRadius: '5px' }}
              sizeBox={{ width: '130px' }}
            />
          ),
        },
        {
          field: (
            <ColorPickerTextField
              hexFormat
              label=""
              title={'Tertiary'}
              source={'palette.tertiary'}
              sx={{ borderRadius: '5px' }}
              sizeBox={{ width: '130px' }}
            />
          ),
        },
        {
          field: (
            <ColorPickerTextField
              hexFormat
              label=""
              title={'Background'}
              source={'palette.background'}
              sx={{ borderRadius: '5px' }}
              sizeBox={{ width: '130px' }}
            />
          ),
        },
        {
          field: (
            <ColorPickerTextField
              hexFormat
              label=""
              title={'Input Background'}
              source={'palette.input_background'}
              sx={{ borderRadius: '5px' }}
              sizeBox={{ width: '130px' }}
            />
          ),
        },
        {
          field: (
            <ColorPickerTextField
              hexFormat
              label=""
              title={'Gray'}
              source={'palette.gray'}
              sx={{ borderRadius: '5px' }}
              sizeBox={{ width: '130px' }}
            />
          ),
        },
        {
          field: (
            <ColorPickerTextField
              hexFormat
              label=""
              title={'Info'}
              source={'palette.info'}
              sx={{ borderRadius: '5px' }}
              sizeBox={{ width: '130px' }}
            />
          ),
        },
      ],
    },
    {
      title: 'fonts',
      subtitle: 'Select the fonts you want in your app',
      inputs: [
        {
          field: (
            <SelectInput
              variant="outlined"
              fullWidth
              source="font"
              choices={['Helvetica Neue']}
            />
          ),
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];

  const onSuccess = (e) => dispatch(setPalette(e.palette));

  const MemoComponent = memo(AdaptableFormRedesign);

  return (
    <MemoComponent onSuccess={onSuccess} data={data} isEdit resource={'info'} />
  );
};
