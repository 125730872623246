
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import { generalInformation } from './FormInfoSections/generalInformation';
import { memo } from 'react';
import { locationInformation } from './FormInfoSections/locationInformation';
import { festivalFeatures } from './FormInfoSections/festivalFeatures';
import { useDispatch } from 'react-redux';
import { storeInfo } from '../../../Store/Reducers/infoReducer';


export const FormInfo = () => {
  const dispatch = useDispatch();
  const data = [
    generalInformation(),
    locationInformation(),
  ];

  const MemoedForm = memo(AdaptableFormRedesign);

  const onSuccess = (e) => dispatch(storeInfo(e));


  return (
    <MemoedForm onSuccess={onSuccess} data={data} isEdit />
  );
};