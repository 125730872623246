import { useFormContext } from 'react-hook-form';
import { InputArrayTextInput } from '../../../Components/InputsForm/InputArrayTextInput';
import { InputSubtitle } from '../../../Components/StyledComponents/InputSubtitle';
import { InputTitle } from '../../../Components/StyledComponents/InputTitle';
import { required, useRecordContext } from 'react-admin';
import InputText from '../../../Components/InputsForm/InputText';
import React, { useMemo } from 'react';
import { Spinner } from '../../../Components/Spinner';
import InputTextSetEventName from '../../../Components/InputsForm/InputTextSetEventName';
import { EVENTS_TOOLTIPS } from '../Tooltips';
export const EventsNameComponent = ({isLoading}) => {
  const record = useRecordContext();
  const { getValues } = useFormContext();
  const category = getValues()?.['category'];
  const initialValue = record?.submission?.options?.name ? record?.submission?.options?.name : null;
  const OptionsArrayInput = useMemo(() => {
    if(isLoading) return <div />;
    if(!isLoading) return (
      <>
        <InputSubtitle>Please provide 2 additional name options for this event.</InputSubtitle>
        <InputArrayTextInput 
          hideAdd
          hideRemove
          label=""
          initialNumber={2}
          source={'options.name'}
          addText="Add a must have item"
          limit={5}
          initialValue={initialValue}
          direction="column" 
          title={'Event Option Name'}
          enumerate
          gap={'0px'}
          requiredFields
        />
      </>
    );
  },[isLoading]);

  if(category === 'Music' || !category) {
    return (
      <InputText
        subtitle={EVENTS_TOOLTIPS['eventName']}
        title="Event name"
        fullWidth
        label=""
        source="name"
        validate={[required('This field is required')]}
      />
    );
  } else {
    return(
      <>
        <InputText
          subtitle={EVENTS_TOOLTIPS['eventName']}
          title="Event name"
          fullWidth
          label=""
          source="name"
          validate={[required('This field is required')]}
        />
        <br />
        {React.cloneElement(OptionsArrayInput)}
      </>
    );
  }
};
