import React, { memo, useMemo, useState } from 'react';
import { eventsSchema } from './FormInfoSections/eventsSchema';
import { experiencesSchema } from './FormInfoSections/experiencesSchema';
import { faqsSchema } from './FormInfoSections/faqsSchema';
import { locationsSchema } from './FormInfoSections/locationsSchema';
import { postsSchema } from './FormInfoSections/postsSchema';
import { talentsSchema } from './FormInfoSections/talentsSchema';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import { TfiHandDrag } from 'react-icons/tfi';
import { SchemasSection } from './FormInfoSections/SchemaSection.jsx';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
export const FormSchema = () => {

  const schemas = useSelector((state) => state.data.schemas);
  const schemaChoices = Object.keys(schemas);
  const [selectedTab, setSelectedTab] = useState(schemaChoices[0]);
  
  const Memo = useMemo(() => { return SchemasSection;},[schemas]);
  const MemoComponent = memo(Memo);

  return (
    <>
      <Box sx={{margin: '30px 10px',paddingX:4,paddingY:2,backgroundColor:'#ECECEC',fontSize:'15px',borderRadius:'10px'}}>
        <span>Welcome to your backoffice&apos;s form configuration section! Here, you can tailor the talent, event, and post submission forms to fit your festival&apos;s needs.</span>
        <li>
          <span style={{fontWeight: '500'}}>Customize Freely:</span> Decide what information you must gather by marking fields as required, either for saving the form in the backoffice or for submission approval.
        </li>
        <li>
          <span style={{fontWeight: '500'}}>Quick Setup:</span> Easily adjust your forms with just a few clicks, ensuring you collect all the necessary details efficiently.
        </li>
        <div style={{'height': '2em', 'display': 'flex', 'flex-direction': 'row', 'align-items': 'center', 'gap': '20px','marginTop': '20px'}}>
          <TfiHandDrag size={24} /> <p style={{'font-size': '16px', 'font-weight': '700'}}>Drag and drop the fields you want present in your schemas</p>
        </div>
      </Box>
      <MemoComponent schemas={schemas} schemaChoices={schemaChoices} setSelectedTab={setSelectedTab} selectedTab={selectedTab} />
    </>
  );
};