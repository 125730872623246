import React, { useEffect } from 'react';
import { useRedirect } from 'react-admin';
import { getToken } from '../../helpers/getToken';
import { Spinner } from '../../Components/Spinner';


export const InfoList = () => {

  const redirect = useRedirect();
  const token = getToken();
  useEffect(() => {
    redirect('edit', 'festivals',  token.festival_id);
  },[]);


  return <Spinner />;
};