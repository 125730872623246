import { useState } from 'react';
import { InputAddSets } from './InputAddSets';
import { useInput } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { EditInterestTitle } from './EditInterestTitle';


export const InputAddSubsets = (props) => {

  const {
    setIndex,
    index,
    source
  } = props;

  const { field } = useInput({ source: source});
  
  const handleEditTitle = (e, index) => {
    const value = e.target.value;
    let newArr = field.value?.map((item, i) => {

      if(i === index){
        let obj = {...item, title: value};

        return obj;
      }
      return item;
    });
    
    field.onChange(newArr);
  };

  const reorderSets = (arr) => {
    field.onChange(arr);
  };

  const addInterest = () => {
    let values = field.value;
    field.onChange([...values, {
      multiselect: false,
      options: [],
      title: 'Title',
    }]);
    setIndex(values?.length);
  };
  console.log(field.value);
  const onDelete = (e) => {
    let arr = field.value.filter(it => it._id !== e._id);
    console.log(arr);
    return field.onChange(arr);
  };

  return (
    <InputAddSets 
      sx={{
        marginTop: '0px',
        alignItems: 'flex-start',
        justifyContent:'flex-start',
        maxWidth: '500px' 
      }} 
      sets={field.value} 
      onEditChange={handleEditTitle}
      title="interests" 
      onDelete={onDelete}
      selectedSet={index}
      showDelete
      onClick={setIndex}
      EditInputs={EditInterestTitle}
      setSets={reorderSets}
      maxLength={140}
      addSets={addInterest}
    />
  );
};