import { NumberInput } from 'react-admin';


export const InputNumber = ({...rest}) => {

  return <NumberInput 
    InputProps={{
      sx: {
        borderRadius: '10px'
      }
    }}
    {...rest} 
  />;
};