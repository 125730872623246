import CreateNotifications from './Form/CreateNotifications.jsx';
import EditNotifications from './Form/EditNotifications.jsx';
import ShowNotifications from './Form/ShowNotifications.jsx';
import NotificationsList from './NotificationsList.jsx';
import { MdNotificationsNone } from 'react-icons/md';

export default {
  list: NotificationsList,
  create: CreateNotifications,
  edit: EditNotifications,
  icon: MdNotificationsNone,
  show: ShowNotifications
};