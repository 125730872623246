import { Box } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';
import { DnDTables } from '../../../../Components/StyledComponents/DnDTable';
import { useSelector } from 'react-redux';
import { FaCircle, FaRegCircle } from 'react-icons/fa';
import { baseInputsNotRequired } from './schemaExceptions';
import { Button } from '../../../../Components/Generics';

export const SchemasSection = (props) => {
  const {
    schemas,
    selectedTab,
    setSelectedTab,
    schemaChoices
  } = props;
  const palette = useSelector((state) => state?.palette?.palette);
  const filteredKeys = ['_archived', '_draft', 'event_ready'];
  const choices = useMemo(() => { 
    let base = schemas[selectedTab]?.base.map((item) => { 
      return {
        name: item,
        key: item,
        required: baseInputsNotRequired.includes(item) ? false : true, 
        selected: true, 
        unmovable: true
      };});
    let additional = schemas[selectedTab].additional?.map(item => {
      let isRequired = schemas[selectedTab].required?.find(req => req === item);
      return {
        name: item, 
        key: item, 
        required: !!isRequired ?? false, 
        selected: true, 
        unmovable: false
      };
     
    });
    let all = schemas[selectedTab].all_additional?.filter(item => {
      if(!additional?.find(name => name?.name === item)) {
        return item;
      }
    }).map(item => { 
      return {
        name: item,
        key: item, 
        required: false,
        selected: false, 
        unmovable: false
      };
    });

    let arr = [ ...base, ...additional, ...all].filter(item => !filteredKeys.includes(item.name));
    return arr;
    
  }, [selectedTab]);

  const Memo = useMemo(() => { 
    return DnDTables;
  }, [choices, selectedTab]);
  const MemoComponent = memo(Memo);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '80%',
        height: 'auto',
        margin: 2
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        gap: '50px'
      }}>
        <Box style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          {
            schemaChoices?.map((item, index) => {
              return (
                <Button
                  key={index}
                  onClick={() => setSelectedTab(item)}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems:'center',
                    gap: '20px',
                    cursor: 'pointer',
                    padding: '10px',
                    borderRadius: '5px',
                    color: selectedTab === item ? palette?.primary: 'black',
                  }}
                  hoverStyle={{backgroundColor: '#f0f0f0'}}
                >
                  {item === selectedTab ? <FaCircle /> : <FaRegCircle />}
                  <InputTitle 
                    sx={{color: selectedTab === item ? palette?.primary: 'black'}}
                  >{item}</InputTitle>
                </Button>
              );
            })
          }
        </Box>
        {/* {React.cloneElement(DnDTables, {choices: choices, selectedTab: selectedTab, ...props})} */}
        <MemoComponent resource={selectedTab} choices={choices} />
      </Box>
    </Box>

  );
};
