import { useState, useEffect } from 'react';
import { useSidebarState } from 'react-admin';
import styles from './Dashboard.module.css';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Card } from '../../Components/Generics';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);
const bgColors = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
];
const borderColors = [
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
];
const dataOptions = {
  backgroundColor: bgColors,
  borderColor: borderColors,
  borderWidth: 2,
};
export const PopularSection = ({ posts, events, talents }) => {
  const [open] = useSidebarState();
  const isNetbook = useMediaQuery('(max-width:1300px)');
  const isNotebook = useMediaQuery('(max-width:1600px)');
  const isMonitor = useMediaQuery('(min-width:1601px)');
  const [talentsMetrics, setTalentsMetrics] = useState({
    labels: [],
    datasets: [
      {
        label: 'Popularity',
        data: [],
        ...dataOptions,
      },
    ],
  });
  const [eventsMetrics, setEventsMetrics] = useState({
    labels: [],
    datasets: [
      {
        label: 'Popularity',
        data: [],
        ...dataOptions,
      },
    ],
  });
  const [postsMetrics, setPostsMetrics] = useState({
    labels: [],
    datasets: [
      {
        label: 'Popularity',
        data: [],
        ...dataOptions,
      },
    ],
  });
  useEffect(() => {
    if (talents) {
      const labels = talents?.favs?.map((metric) => metric.name);
      const dataValues = talents?.favs?.map((metric) => metric.count);

      setTalentsMetrics((prevData) => ({
        ...prevData,
        labels,
        datasets: [
          {
            ...prevData.datasets[0],
            data: dataValues,
          },
        ],
      }));
    }
  }, [talents]);

  useEffect(() => {
    if (events) {
      const labels = events?.favs?.map((metric) => metric.name);
      const dataValues = events?.favs?.map((metric) => metric.count);

      setEventsMetrics((prevData) => ({
        ...prevData,
        labels,
        datasets: [
          {
            ...prevData.datasets[0],
            data: dataValues,
          },
        ],
      }));
    }
  }, [events]);

  useEffect(() => {
    if (posts) {
      const labels = posts?.favs?.map((metric) => metric.name);
      const dataValues = posts?.favs?.map((metric) => metric.count);

      setPostsMetrics((prevData) => ({
        ...prevData,
        labels,
        datasets: [
          {
            ...prevData.datasets[0],
            data: dataValues,
          },
        ],
      }));
    }
  }, [posts]);
  const handleWidth = () => {
    if (open) {
      if (isNetbook) {
        return '600px';
      } else if (isNotebook) {
        return '480px';
      } else if (isMonitor) {
        return '700px';
      } else {
        return '600px';
      }
    } else {
      if (isNetbook) {
        return '500px';
      } else if (isNotebook) {
        return '550px';
      } else if (isMonitor) {
        return '700px';
      } else {
        return '600px';
      }
    }
  };
  const optionEvents = {
    responsive: true,
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#000000',
          callback: function (value, index) {
            if (eventsMetrics.labels[index].length > 30) {
              return eventsMetrics.labels[index].substr(0, 30) + '...';
            } else {
              return eventsMetrics.labels[index];
            }
          },
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        color: 'black',
        align: 'end',
        font: {
          weight: 'bold',
        },
      },
    },
  };
  const optionTalents = {
    responsive: true,
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#000000',
          callback: function (value, index) {
            if (talentsMetrics.labels[index].length > 30) {
              return talentsMetrics.labels[index].substr(0, 30) + '...';
            } else {
              return talentsMetrics.labels[index];
            }
          },
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        color: 'black',
        align: 'end',
        font: {
          weight: 'bold',
        },
      },
    },
  };
  const optionArticles = {
    responsive: true,
    indexAxis: 'y',
    maintainAspectRatio: false,
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          color: '#000000',
          callback: function (value, index) {
            if (postsMetrics.labels[index].length > 30) {
              return postsMetrics.labels[index].substr(0, 30) + '...';
            } else {
              return postsMetrics.labels[index];
            }
          },
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      datalabels: {
        color: 'black',
        align: 'end',
        font: {
          weight: 'bold',
        },
      },
    },
  };
  const handlerScrollHeight = (resource) => {
    const metrics = {
      events: eventsMetrics,
      talents: talentsMetrics,
      articles: postsMetrics,
    };
    const currentMetrics = metrics[resource];
    const length = currentMetrics?.labels?.length;
    switch (resource) {
    case 'events':
      if (length <= 15) {
        return '350px';
      } else if (length <= 30) {
        return '700px';
      } else if (length <= 50) {
        return '1000px';
      } else if (length <= 100) {
        return '2500px';
      } else if (length <= 170) {
        return '4500px';
      } else if (length > 170 && length < 400) {
        return '10000px';
      } else {
        return '12000px';
      }
    case 'talents':
      if (length <= 15) {
        return '350px';
      } else if (length <= 30) {
        return '700px';
      } else if (length < 50) {
        return '1000px';
      } else if (length < 100) {
        return '2500px';
      } else if (length <= 170) {
        return '4500px';
      } else if (length > 170 && length < 400) {
        return '10000px';
      } else {
        return '12000px';
      }
    case 'articles':
      if (length <= 15) {
        return '350px';
      } else if (length <= 30) {
        return '700px';
      } else if (length < 50) {
        return '1000px';
      } else if (length < 100) {
        return '2500px';
      } else if (length <= 170) {
        return '4500px';
      } else if (length > 170 && length < 400) {
        return '10000px';
      } else {
        return '12000px';
      }
    default:
    }
  };
  return (
    <div className={styles.popularContainer}>
      <Card
        style={{
          padding: '20px 25px',
          height: '500px',
          maxHeight: '450px',
          width: handleWidth(),
        }}
      >
        <div style={{ height: '70px' }}>
          <p className={styles.popularTitle}>Most popular Events</p>
          <span className={styles.popularTotal}>Total: {events?.total}</span>
        </div>
        <div
          style={{
            height: eventsMetrics?.labels?.length > 5 ? '400px' : 'auto',
            maxHeight: '350px',
            overflowY: eventsMetrics?.labels?.length > 5 ? 'scroll' : 'auto',
            width: '100%',
          }}
        >
          <div
            style={{
              height:
                eventsMetrics?.labels?.length > 5
                  ? handlerScrollHeight('events')
                  : '200px',
            }}
          >
            {eventsMetrics?.labels?.length >= 1 ? (
              <Bar options={optionEvents} data={eventsMetrics} />
            ) : (
              <EmptyCombo />
            )}
          </div>
        </div>
      </Card>
      <Card
        style={{
          padding: '20px 25px',
          height: '500px',
          maxHeight: '450px',
          width: handleWidth(),
        }}
      >
        <div style={{ height: '70px' }}>
          <p className={styles.popularTitle}>Most popular Artists</p>
          <span className={styles.popularTotal}>Total: {talents?.total}</span>
        </div>
        <div
          style={{
            height: talentsMetrics?.labels?.length > 5 ? '400px' : 'auto',
            maxHeight: '350px',
            overflowY: talentsMetrics?.labels?.length > 5 ? 'scroll' : 'auto',
            width: '100%',
          }}
        >
          <div
            style={{
              height:
                talentsMetrics?.labels?.length > 5
                  ? handlerScrollHeight('talents')
                  : '200px',
            }}
          >
            {talentsMetrics?.labels?.length >= 1 ? (
              <Bar options={optionTalents} data={talentsMetrics} />
            ) : (
              <EmptyCombo />
            )}
          </div>
        </div>
      </Card>
      <Card
        style={{
          padding: '20px 25px',
          height: '500px',
          maxHeight: '450px',
          width: handleWidth(),
        }}
      >
        <div style={{ height: '70px' }}>
          <p className={styles.popularTitle}>Most popular Articles</p>
          <span className={styles.popularTotal}>Total: {posts?.total}</span>
        </div>
        <div
          style={{
            height: postsMetrics?.labels?.length > 5 ? '400px' : 'auto',
            maxHeight: '350px',
            overflowY: postsMetrics?.labels?.length > 5 ? 'scroll' : 'auto',
            width: '100%',
          }}
        >
          <div
            style={{
              height:
                postsMetrics?.labels?.length > 5
                  ? handlerScrollHeight('articles')
                  : '200px',
            }}
          >
            {postsMetrics?.labels?.length >= 1 ? (
              <Bar options={optionArticles} data={postsMetrics} />
            ) : (
              <EmptyCombo />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};
const EmptyCombo = () => {
  const palette = useSelector((state) => state?.palette?.palette);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100px',
      }}
    >
      <p
        style={{
          fontWeight: 'bold',
          fontSize: '16px',
          color: '#888',
          marginRight: '8px',
        }}
      >
        Nothing to show
      </p>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width='24'
        height='24'
        fill={palette?.primary}
      >
        <path d='M0 0h24v24H0z' fill='none' />
        <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4 13h-8v-2h8v2z' />
      </svg>
    </div>
  );
};
