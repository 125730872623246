import React, { useState } from 'react';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';
import { ContactInformation } from '../../TalentForm/ContactInformation';
import { ProfileForm } from '../../TalentForm/ProfileForm';
import TabsEventList from './TabsEventList';
import { CgProfile } from 'react-icons/cg';
import { BsCalendarEvent, BsInfoCircle } from 'react-icons/bs';

const EditTalents = () => {
  const [form, setForm] = useState('profile');
  const tabs = [
    {
      title: 'Profile',
      redirectUrl: 'profile',
      icon: CgProfile,
    },
    {
      title: 'Events',
      redirectUrl: 'event',
      icon: BsCalendarEvent,
    },
    {
      title: 'Contact Information',
      redirectUrl: 'info',
      icon: BsInfoCircle,
    },
  ];

  return (
    <div>
      <EditFormCustom tabs={tabs} handleForm={setForm}>
        {form === 'info' ? (
          <ContactInformation />
        ) : form === 'event' ? (
          <TabsEventList />
        ) : (
          <ProfileForm isEdit />
        )}
      </EditFormCustom>
    </div>
  );
};

export default EditTalents;
