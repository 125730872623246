import { Box,Typography,LinearProgress } from '@mui/material';
import React from 'react';
import { Create, usePermissions, useResourceContext } from 'react-admin';
import { NavbarTitle } from '../RedesignComponents/NavbarTitle';
export const CreateCustomComponent = (props) => {
  const {
    children,
    actionText
  } = props;
  const resource = useResourceContext();
  const { permissions: permis } = usePermissions();
  
  return (
    <Create actions={false} title={<TitleEdit />} redirect='list' mutationMode={'pessimistic'}>
      <Box style={{elevation: 0,boxShadow:'none',padding:'0px 16px',marginTop:20}}>
        {permis !== 'talent_manager' && (
        // <Box fullWidth mt="5" sx={{display: 'flex', justifyContent: 'flex-end',elevation: 0,boxShadow:'none'}} >
          <div style={{display:'flex', flexDirection: 'row'}}>
            <NavbarTitle actionText={actionText} isEdit={false}/>
          </div>
        )}
        {children}
      </Box>
    </Create>
  );
};

const TitleEdit = () => {
  const resource = useResourceContext();
  console.log('ASd;kasjdklasjd');

  return (
    <span>{`Create "${resource}"`}</span>
  );
};