import dayjs from 'dayjs';
import { extractDatesFromString } from '../../../helpers/inputErrorMessage';
import PropTypes from 'prop-types';


// Validar si input es array y si contiene keys "value" y "key"
export const validateArray = (arr, incldueKeys) => {
  if(!(arr instanceof Array)) throw Error('not an Array');
  if(incldueKeys && !arr.every(obj => Object.prototype.hasOwnProperty.call(obj, 'value'))) throw Error('not an obj array with key "value"');
  if(incldueKeys && !arr.every(obj => Object.prototype.hasOwnProperty.call(obj, 'key'))) throw Error('not an obj array with key "key"');
  return undefined;
};
// Funcion para agregar periodos de open y close dentro de un mismo dia
export const addTime = (values, dayPicked, _callback) => {
  let filteredDates = values.filter(item => { 
    if(dayjs(item.value.close).isSame(dayPicked, 'day')) {
      return item;
    }
  });
  let formattedDates = filteredDates.map(date => new Date(date.value.close));
  
  //Ultimo horario calculado en base al close del día seleccionado
  let lastDate = Math.max.apply(null, formattedDates);
  let latestDate = dayjs(lastDate).format('YYYY-MM-DDTHH:mm:ss');
  let formatedDateOpen = dayjs(latestDate).add(1, 'hours').format('YYYY-MM-DDTHH:mm:ss');
  let formatedDateClose = dayjs(latestDate).add(2, 'hours').format('YYYY-MM-DDTHH:mm:ss');
  let lastPosibleHour = dayjs(dayPicked).add(23, 'hours').format('YYYY-MM-DDTHH:mm:ss');
  
  if(dayjs(formatedDateOpen).isAfter(lastPosibleHour)){
    _callback('error', 'You cant add times after the 23:00hs');
    return undefined;
  } else {
    return {key: values.length, value: {open: formatedDateOpen, close: formatedDateClose}};
  }
};
  
  
// Checkear si el día esta abierto o cerrado viendo si existen valores para el día en base al open
export const initialCheckOpen = (values, dayPicked) => {
  let dayClosed = values?.find((item, index) => {
    if(dayjs(item.value?.open).isSame(dayPicked, 'day')) return item;
  });
  return !!dayClosed;
};
  
//Cambio de state para open24 en base al booleano
export const handleHours = (e, dayPicked, record) => {
  let firstHour = dayjs(dayPicked).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
  let lastHour = dayjs(dayPicked).endOf('day').format('YYYY-MM-DDTHH:mm:ss');

  let defaultValues = {
    open: record?.['open'] ?? firstHour, 
    close: (record?.['close'] && record?.['close'] !== lastHour) ? record?.['close'] : firstHour
  };
  
  if(e) {
    return {open: firstHour, close: lastHour};
  } else {
    return defaultValues;
  }
};

export const handleDayError = (error, day) => {
  let date = extractDatesFromString(error)[0];
  if(error && dayjs(date).isSame(day, 'day')) return true;
  return false;
};

export const sortDates = (a,b) => {
  let date = dayjs(a.close).valueOf();
  let prevDate = dayjs(b.open).valueOf();
  
  return date - prevDate;
  
};
  
// Validation para el día seleccionado
export const validateDay = (e) => {
  let dates = e.sort(sortDates);
  for (let i = 0; i < dates.length; i++){
    if(dayjs(dates[i].close).diff(dates[i].open, 'minutes', true) <= 15) {
      
      return 'Open Times cant be lower than 15 minutes.';
    }
    let before = dates[i - 1]?.close;
    let timeNow = dates[i].open;
  
    if(before && dayjs(timeNow).isBefore(before)) {
      return `open: ${timeNow} - close: ${before}`;
    }
    continue;
  }
  return undefined;
};

// Default value o record value en base a los open times que existan para el día seleccionado
export const createObj = (arr, dayPicked) => {
  if(arr) {
    return arr?.map((item, i) => {
      return {key: i, value: item};
    });
  } else {
    return [{ key: 0, value: {open: dayjs(dayPicked).format('YYYY-MM-DDTHH:mm:ss'), close: dayjs(dayPicked).format('YYYY-MM-DDTHH:mm:ss')}}];
  }
};
  
// Format el objeto para pasar al input
export const formatObj = (arr) => {
  const validate = validateArray(arr, true);
  if(validate) return validate;
  return arr?.map(item => item.value);
};
  
//Comprarar si los días son los mismos en el input de source
export const dayValues = (values, day) => {
  // if(!values.some(obj => dayjs(obj?.open).isSame(day, 'day'))) throw Error('No value is same date as "day"');
  return values?.filter((item, index) => {
    let date = dayjs(item?.open).format('YYYY-MM-DD');
    if (date === day) {
      return item;
    }
  });
};
  
// Encuentra el día seleccionado y se fija si tiene un valor de 24 horas
export const allDay = (values, dayPicked) => {
  const validate = validateArray(values, true);
  if(validate) return validate;

  let found = values?.filter(item => dayjs(item.value.open).isSame(dayPicked, 'day'));
  // Si la cantidad de periodos es mayor a uno, podemos entender que el día no estara abierto las 24 horas.
  if(found?.length > 1)return false;

  let start = `${dayjs(found[0]?.value?.open).get('hour')}:${dayjs(found[0]?.value?.open).get('minute')}`; // Found siempre tendra una sola instancia, ya que es el único caso posible donde haya open24
  let end = `${dayjs(found[0]?.value?.close).get('hour')}:${dayjs(found[0]?.value?.close).get('minute')}`;
  return (start === '0:0' && end === '23:59');

};
  
export const restOfValues = (values, day) => {
  if(validateArray(values)) return validateArray(values);

  return values?.filter((item, index) => {
    let date = dayjs(item?.open).format('YYYY-MM-DD');
    if(date !== day) {
      return item;
    }
  });
};
  
export const defaultValues = (days) => {
  if(validateArray(days)) return validateArray(days);
  return days?.reduce((acc, item, index) => {
    let openTime = dayjs(item).format('YYYY-MM-DDTHH:mm:ss');
    let closeTime = dayjs(item).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
    return [...acc, {open: openTime, close: closeTime}];
  },[]);
};

//Verifica que el close sea al dia siguiente del día seleccionado
export const checkCloseDate = (value, dayPicked) => {
  return dayjs(value?.close, 'YYYY-MM-DD').isAfter(dayPicked);
};

export const handleCloseDay = (boolean, values, day, _callback, record) => {

  switch(true) {
  case validateArray(values):
    return validateArray(values);

  case !_callback: 
    throw Error('No callback assigned');

  case !!boolean && typeof day !== 'string':
    throw Error('Day not correct format string');
  case !!boolean: 
    if(!validateArray(values) && typeof day === 'string') {
      let poppedValues = values?.filter(item  => !dayjs(item.open).isSame(day, 'day'));
      _callback(poppedValues); 
    }
    break;

  case !boolean:
    if(record) return _callback([...values, ...record]);
    if(!record) return _callback([...values]);
    break;
    
  default:
    throw Error('Error in args missing / not correct');
  }
};

//Funcion para el formateo y parseo de todas las fechas de open_times
export const handleFormatParse = (e, _callback) => {
  if(typeof _callback !== 'function') throw Error('No callback passed');
  return e?.map((item, index) => {
    let open = _callback(item.open);
    let close = _callback(item.close);
    let obj = {open: open, close: close};
    return obj;
  });
};