import { TextField, Typography } from '@mui/material';
import { LocationPin } from '../../Resources/Venues/Form/Map/VenueMap';
import { Map, Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { COLORS } from '../../assets/constants/theme';
import { useInput } from 'react-admin';
import { InputTitle } from '../StyledComponents/InputTitle';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import {Button} from '../Generics/index';
export const defualtGeoJson = {
  'type': 'Feature',
  'geometry': {
    'type': 'Point',
    'coordinates': [null, null],
  },
  'properties': {
    'name': ''
  }
};


export const LocationMapbox = ({source, validate, required, subtitle, title}) => {

  const {
    field,
    formState: { errors }
  } = useInput({source: source, validate, defaultValue: defualtGeoJson});
  const customToast = useCustomToasty();

  const handleCoordinates = (e, key) => {
    if(key === 'lat' && Math.abs(e) > 90) return customToast('error', 'Lattitude coordinates cant be greater than 90 or lower than -90.');
    if(key === 'lng' && Math.abs(e) > 180) return customToast('error', 'Longitude coordinates cant be greater than 180 or lower than -180.');
    let value = e;
    let current = field.value.geometry?.coordinates;
    let position = key === 'lat' ? [current?.[0] ?? 0, value] : [value, current?.[1] ?? 0];
    
    field.onChange({...field?.value, geometry: {...field?.value?.geometry, coordinates: position}});
  };
 
  function handleDeleteIcon() {
    field.onChange(null);
  };
  
  function addMarker(event) {
    const { lng, lat } = event.lngLat;
    field.onChange({...field?.value, geometry: {...field?.value?.geometry, coordinates: [lng, lat]}});
  }

  return (
    <>
      <InputTitle required={required}>{title}</InputTitle>
      <InputSubtitle>{subtitle}</InputSubtitle>
      <div style={{display:'flex',flexDirection:'row',gap:20, marginTop: '10px'}}>
        <div style={{display:'flex', width: '100%', flexDirection:'row',alignItems:'center',gap:15}}>
          <Typography fontWeight="600" fontSize={'1em'} color={'rgba(0, 0, 0, 0.92)'}>Latitude</Typography>
          <TextField 
            type="number"
            onChange={(e) => handleCoordinates(Number(e.target.value), 'lat')}
            error={errors[source] && !field.value.geometry?.coordinates[1]}
            helperText={(errors[source] && !field.value.geometry?.coordinates[1]) && 'Required'} 
            sx={{width: '50%'}}
            variant="standard"
            label="" 
            value={field.value.geometry?.coordinates[1]}
          />
        </div>
        <div style={{display:'flex', width: '100%', flexDirection:'row',alignItems:'center',gap:15}}>
          <Typography fontWeight="600" fontSize={'1em'}  color={'rgba(0, 0, 0, 0.92)'}>Longitude</Typography>
          <TextField 
            type="number"
            onChange={(e) => handleCoordinates(Number(e.target.value), 'lng')}
            error={errors[source] && !field.value.geometry.coordinates[0]} 
            helperText={(errors[source] && !field.value.geometry?.coordinates[0]) && 'Required'} 
            sx={{width: '50%'}} 
            variant="standard" 
            label="" 
            value={field.value.geometry?.coordinates[0]}
          />

        </div>
      </div>
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapLib={import('mapbox-gl')}
        initialViewState={{
          longitude:25,
          latitude: 25,
          zoom: 0,
        }}
        style={{ height: '25vh', width: '100%',marginTop:'10px' }}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        onClick={addMarker}
        // Atribuciones de Mapbox
        attributionControl={false}
      >
        <div style={{position:'absolute',bottom:'0px',right:'0px',zIndex:100}}>
          <Button variant='contained' onClick={handleDeleteIcon}
            style={{
              backgroundColor:COLORS.table.indicators.fontColor.rejected,
              color: 'white',
              '&.Mui-disabled': {
                backgroundColor:COLORS.table.indicators.fontColor.rejected, 
                opacity: 0.5,
                color: 'white',
              },}}
            hoverStyle={{backgroundColor:COLORS.table.indicators.fontColor.rejected, opacity: 0.8}}
          >
          Remove Pin
          </Button>
        </div>
        { (field.value.geometry?.coordinates.length > 1) && <Marker
          anchor="bottom"
          longitude={field.value.geometry?.coordinates[0]}
          latitude={field.value.geometry?.coordinates[1]}
        >
          <LocationPin />
        </Marker>}
      </Map>
    </>
  );
};