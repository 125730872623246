import { Box, TextField, Tooltip, Typography, Alert } from '@mui/material';
import InputText from '../../Components/InputsForm/InputText';
import { DateInput, NumberInput, RadioButtonGroupInput, ReferenceArrayInput, ReferenceInput, SelectInput, TextInput, required, useGetOne, usePermissions } from 'react-admin';
import { InputTitle } from '../../Components/StyledComponents/InputTitle';
import { InputAddTextInputs } from '../Talents/Form/TalentsListComponents/InputAddTextInputs';
import { InputArrayTextInput } from '../../Components/InputsForm/InputArrayTextInput';
import { travelForms } from './HospitalityTravelForms/TravelForms';
import InputDate from '../../Components/InputsForm/InputDate';
import { useUtcFormat } from '../../helpers/date';
import InputButtonAws from '../../Components/InputsForm/InputButtonAws';

import { useParams } from 'react-router-dom';
import { InputNumber } from '../../Components/InputsForm/InputNumber';
import { InputSubtitle } from '../../Components/StyledComponents/InputSubtitle';
import { LinkedForm } from './LinkedForm';

export const HospitalityForm = () => {
  const permisos = usePermissions();

  const params = useParams();
  const talent_id = params['*']?.split('/')[1] ?? params?.id;
  const { data: talentData, loading, error } = useGetOne('talents', { id: talent_id });
  const { formatFestivalTime, parseFestivalTime } = useUtcFormat();


  const data = [
    {
      title: '',
      inputs: [
        {
          field: <InputSubtitle>Please take the time to fill out this advance form with pertinent information so we can ensure your smooth arrival and time on site. Your complete information is due by no later than January 20th, 2024.</InputSubtitle>,
          breakpoints: { xs: 12 }
        }
      ]
    },
    {
      title: 'AR Contact Information',
      inputs: [
        {
          field: <InputSubtitle>Please enter the mobile number or WhatsApp numbers of the following contacts, inclusive of the country code (e.g. +1) This information is mandatory. We need to be able to communicate with you when traveling to/from site so we can assist should any delays be encountered on your journey.</InputSubtitle>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle>Tour Managers</InputTitle>
            <InputArrayTextInput
              source="ar_and_hospitality.contact_information.tour_managers"
              direction="column"
              obj
              addText="Add tour manager"
              requiredFields={['name', 'phone']}
              elements={[
                <TextField
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: '10px'
                    }
                  }}
                  fieldName="name"
                  label="Name"
                  variant="outlined"
                />,
                <TextField
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: '10px'
                    }
                  }}
                  type="number"
                  fieldName="phone"
                  label="Phone"
                  variant="outlined"
                />
              ]} />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle>Technical Advance</InputTitle>
            <InputArrayTextInput
              source="ar_and_hospitality.contact_information.technical_advance"
              direction="column"
              obj
              requiredFields={['name', 'phone']}
              addText={'Add technical advance'}
              elements={[

                <TextField
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: '10px'
                    }
                  }}
                  fieldName="name"
                  label="Name"
                  variant="outlined"
                />,
                <TextField
                  InputProps={{
                    sx: {
                      borderRadius: '10px'
                    }
                  }}
                  fullWidth
                  fieldName="email"
                  label="Email"
                  variant="outlined"
                />,
                <TextField
                  InputProps={{
                    sx: {
                      borderRadius: '10px'
                    }
                  }}
                  fullWidth
                  type="number"
                  fieldName="phone"
                  label="Phone"
                  variant="outlined"
                />
              ]} />
          </>,
          breakpoints: { xs: 12 }
        },
      ]
    },
    {
      title: 'Personnel Names and Travel Information',
      inputs: [
        {
          field: <InputSubtitle> <span>Please check your contract before listing the names here below. ONLY the contracted number of talent/crew will be allocated credentials. <br /> It&rsquo;s important that you field this information for each one of the personnel artists and crew.</span></InputSubtitle>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle><span>Personnel Artist information <br /></span></InputTitle>
            <InputSubtitle>
              Please list the names of all performing talent and their coinciding roles. I.E: ‘Jane Doe - guitarist&rsquo;. Please list the name of all talent crew and their coinciding roles. I.E: &rsquo;John Smith - Lighting Designer’.
            </InputSubtitle>
            <InputArrayTextInput
              source="ar_and_hospitality.personnel.performers"
              obj
              requiredFields={['name', 'arriving_to', 'inbound_airline']}
              enumerate
              limit={10}
              addText="Add a performing talent"
              title="Personnel performer"
              elements={travelForms()}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexFlow: 'row wrap',
                flexGrow: 1,
              }}
            />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle><span>Personnel Crew information<br /></span></InputTitle>
            <InputSubtitle>Please list the name of all talent crew and their coinciding roles. I.e. &apos;John Smith - Lighting Designer&apos; </InputSubtitle>
            <InputArrayTextInput
              source="ar_and_hospitality.personnel.crew"
              obj
              enumerate
              limit={10}
              requiredFields={['name', 'inbound_date', 'arriving_to', 'inbound_airline']}

              title="Personnel crew"
              addText="Add a crew member"
              elements={travelForms()}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexFlow: 'row wrap',
                flexGrow: 1,
              }}
            />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle>Gear Luggage Carried</InputTitle>
            <InputSubtitle>Please provide details of the quantity of baggage and equipment you or your group is traveling with. This includes any performance props.</InputSubtitle>
            <InputText multiline minRows={3} label="" fullWidth source="ar_and_hospitality.travel.gear_luggage_carried" />

          </>,
          placement: 'right',
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle>If some members of your group are traveling at different times, please list them below</InputTitle>
            <InputText multiline minRows={3} fullWidth source="ar_and_hospitality.travel.different_times" />

          </>,
          tooltip: 'Format response as such: Name, Role, Arriving From, Arrival Airport, Arrival Time, Arrival Date, Arrival Airline, Arrival Flight Number / Departing to, Departure Airport, Departure Time, Departure Date, Departure Airline, Departure Flight Number',
          placement: 'right',
          breakpoints: { xs: 12 }
        },
      ]

    },
    {
      title: 'Transportation and Accomodation',
      inputs: [
        {
          field: <InputSubtitle><span>
            Please let us know if you are arranging your own transport to / from the festival site as well as your own accommodation or camping.<br /> <br /> PLEASE NOTE: If you were contracted for ground transportation and/or accommodation provided by the Festival and have since changed your plans, please let us know. This way we are able to free up and reallocate your accommodation or transport to others who may need it. Remember that for bookings made independently of the festival, you will be responsible for your own transportation between the airport and the hotel as well as from the hotel to the festival site.
          </span></InputSubtitle>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle>Total No. in arriving group<br /><br /></InputTitle>
            <InputNumber variant="outlined" fullWidth label="" source="ar_and_hospitality.transportation_and_accommodation.total_number_in_arriving_group" />
          </>,
          breakpoints: { xs: 5 }
        },
        {
          field: <>
            <InputTitle>Total No. in departing group<br /><br /></InputTitle>
            <InputNumber variant="outlined" fullWidth label="" source="ar_and_hospitality.transportation_and_accommodation.total_number_in_departing_group" />
          </>,
          breakpoints: { xs: 5 }
        },
        {
          field: <>
            <InputTitle>If camping on site, how many tents will you be bringing?</InputTitle>
            <SelectInput
              optionText={'label'}
              optionValue='key'
              InputProps={{
                sx: {
                  borderRadius: '10px'
                }
              }}
              choices={[
                { key: '1', label: '1' },
                { key: '2', label: '2' },
                { key: '3', label: '3' },
                { key: '4', label: '4' },
                { key: '5', label: '5' },
              ]}
              variant="outlined"
              fullWidth
              label=""
              source="ar_and_hospitality.transportation_and_accommodation.number_of_tents" />
          </>,
          breakpoints: { xs: 6 }
        },
        {
          field: <>
            <InputTitle textTransform={null}>Are you making your own accommodation arrangements? If so, at what hotel or nearby accommodations will you be staying? If any members of your group intend to camp on site, please also indicate that here.</InputTitle>
            <InputText multiline minRows={3} fullWidth label="" source="ar_and_hospitality.transportation_and_accommodation.own_accommodation_arrangements" />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle textTransform={null}>Are you making your own ground transportation arrangements? If so, please list the total number and type of vehicles you will be arriving to site in.</InputTitle>
            <InputText multiline minRows={3} fullWidth label="" source="ar_and_hospitality.transportation_and_accommodation.own_ground_transportation_arrangements" />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle>Accommodation Check-in date</InputTitle>
            <InputDate
              fullWidth
              InputLabelProps={{ sx: { display: 'none' } }}
              inputTime
              source='ar_and_hospitality.transportation_and_accommodation.accommodation_check_in_date'
              defaultValue={null}
              label=""
              format={(value) => formatFestivalTime(value)}
              parse={(value) => parseFestivalTime(value)}
            />
          </>,
          breakpoints: { xs: 5 }
        },
        {
          field: <>
            <InputTitle>Accommodation Check-out date</InputTitle>
            <InputDate
              fullWidth
              InputLabelProps={{ sx: { display: 'none' } }}
              label=""
              inputTime
              source='ar_and_hospitality.transportation_and_accommodation.accommodation_check_out_date'
              defaultValue={null}
              format={(value) => formatFestivalTime(value)}
              parse={(value) => parseFestivalTime(value)}
            />
          </>,
          breakpoints: { xs: 5 }
        },
      ],
    },
    {
      title: 'Hospitality',
      inputs: [
        {
          field: <InputSubtitle><span>Please let us know if you are arranging your own transport to / from the festival site as well as your own accommodation or camping.<br /> PLEASE NOTE: If you were contracted for ground transportation and/or accommodation provided by the Festival and have since changed your plans, please let us know. This way we are able to free up and reallocate your accommodation or transport to others who may need it. Remember that for bookings made independently of the festival, you will be responsible for your own transportation between the airport and the hotel as well as from the hotel to the festival site.</span></InputSubtitle>
        },
        {
          field: <>
            <InputTitle textTransform={null}>Any dietary restrictions we should be aware of?</InputTitle>
            <InputText multiline minRows={3} fullWidth label="" source="ar_and_hospitality.hospitality.dietary_restrictions" />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle sx={{ marginBottom: '20px' }}>Upload your hospitality rider here</InputTitle>
            <InputButtonAws actionText="Upload rider" source={'ar_and_hospitality.hospitality.hospitality_rider'} />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field:
            <>
              <InputTitle>Please indicate up to 5 of your must-have items from your rider</InputTitle>
              <InputArrayTextInput
                label={'Must-have item'}
                addText="Add a must have item"
                limit={5}
                source="ar_and_hospitality.hospitality.must_have_items"
                direction="column" />
            </>,
          breakpoints: { xs: 12 }
        }
      ],
    },
    {
      title: 'Technical',
      inputs: [
        {
          field: <>
            <InputTitle textTransform={null}><span>Please attach your technical rider with a current stage plot and input list<br /><br /></span></InputTitle>
            <InputButtonAws actionText="Upload rider" source={'ar_and_hospitality.technical.technical_rider'} />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle textTransform={null}>Do you need any locally-provided backline equipment for your performance? If so, please outline your backline needs</InputTitle>
            <InputText multiline minRows={3} fullWidth label="" source="ar_and_hospitality.technical.backline_equipment" />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field:
            <>
              <InputTitle textTransform={null}>Are you comfortable with performers on stage?</InputTitle>
              <RadioButtonGroupInput
                variant="outlined"
                source={'ar_and_hospitality.technical.performers_on_stage'}
                choices={[
                  { id: 'yes', name: 'Yes!' },
                  { id: 'maybe', name: 'Maybe, can I please have some more information?' },
                  { id: 'no', name: 'No, thank you' },
                ]}
                sx={{ width: '60%' }}

                label=''
                fullWidth
              />
            </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle textTransform={null}>Other technical needs we should make note of?</InputTitle>
            <InputSubtitle><span>Please be very specific about your technical needs, regardless of how small they might seem (e.g.: mics, headsets, phone adaptors, risers, etc.) What are you planning on bringing and what would you like the festival to provide?</span></InputSubtitle>
            <InputText multiline minRows={3} fullWidth label="" source="ar_and_hospitality.technical.other_technical_needs" />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field:
            <>
              <InputTitle textTransform={null}>Do you consent to an audio recording of your set? For a selection of artists by our marketing team, we are offering an opportunity to have their set recorded at no cost. These would be periodically released as exclusive mixes in official Raveapp Festival promotions and we would work with you to place them in high profile publishers throughout 2024 and 2025.</InputTitle>
              <RadioButtonGroupInput
                variant="outlined"
                source={'ar_and_hospitality.technical.audio_recording_consent'}
                choices={[
                  { id: 'yes', name: 'Sounds good!' },
                  { id: 'maybe', name: 'I would like more information' },
                  { id: 'no', name: 'No, thank you' },
                ]}
                label=''
                sx={{ width: '60%' }}
              />
            </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle textTransform={null}>If applicable, please outline your artist&rsquo;s photo policy.</InputTitle>
            <InputText multiline minRows={3} fullWidth label="" source="ar_and_hospitality.technical.photo_policy" />
          </>,
          breakpoints: { xs: 12 }
        },
      ],
    }
  ];

  // return (
  //   <TabbedSimpleForm floating validateCustomErrors form="hospitality" noTooltip rowSpacing={3} record={talentData} data={data} isEdit />
  // );
  return (
    <>
      {permisos.permissions === 'talent_manager' && (
        <div style={{ marginTop: '15px' }}>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight={400}
            alignSelf={'flex-start'}
          >
            🛎️ AR & Hospitality
          </Typography>
        </div>
      )}
      <LinkedForm
        link="https://form.jotform.com/232927444514155"
        from="hospitality"
      />
    </>
  );

};