import axios from 'axios';
import { inputErrorMessage, loopObjErrors } from '../../../helpers/inputErrorMessage';
import { responseError, validationErrors } from '.';

const headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Expose-Headers': 'content-range',
};
  
export const putRequest = async (draftData, options, customToast, refresh) => {
  const {resourceProp, onSuccess, resource, setLoading} = options;

  setLoading(true);
  const token = localStorage.getItem('auth');
  console.log(token);
  try {
    const url = `${process.env.REACT_APP_BASE_PATH}/${resourceProp ? resourceProp :resource}/${
      draftData.id}`;
    const response = await axios({
      url:url,
      method: 'PUT',
      headers: {
        ...headers,
        'Authorization': 'Bearer ' + token,
      },
      data: { ...draftData},
    });
    if (response.data) {
      customToast('success', 'Changes were saved.');
      onSuccess && onSuccess(response.data);
      refresh();
    }
  } catch (error) {
    responseError(error, customToast); 
    throw new Error(error);

  } finally {
    setLoading(false);
  }
};
  
export const talentRequests = async (draftData, method, handlers, clearErrorsProps = {}) => {

  const {
    isTalentDraft,
    customToast,
    clearErrors,
    refresh,
    setLoading,
    redirect,
    onSuccess,
  } = handlers;
  
  const { isDraft: isDraftSubmission } = clearErrorsProps;
  const data = isDraftSubmission ? {...draftData, sketch: true} : {...draftData};
  const token = localStorage.getItem('auth');
  const isEdit = method === 'PUT' ? true : false;
 

  const url = method === 'PUT' ? `${process.env.REACT_APP_BASE_PATH}/talents/${draftData.id}` : `${process.env.REACT_APP_BASE_PATH}/talents`;

  setLoading(true);
  // TODO: Revisar que la lógica de Save as Draft para Talent managers se haga mandando el sketch: true dentro del body del put.
  try {
    const response = await axios({
      url:url,
      method: method,
      headers: {
        ...headers,
        'Authorization': 'Bearer ' + token,
      },
      data: data,
    });
    if(response.data) {
      clearErrors();
      customToast('success', method === 'POST' ? 'Talent succesfuly created' : 'Changes were saved.');
      
      onSuccess && onSuccess(response?.data);
      refresh();
      if(method === 'POST') return redirect('list', 'talents');
      return;
    }
  } catch (error) {
    if(data['_draft']){
      return validationErrors(error, customToast,isEdit, clearErrorsProps);
    }
    responseError(error, customToast);
    throw new Error(error);
  } finally {
    setLoading(false);
  }
};
  
  
