import { Box, Grid, Modal, Skeleton, Typography } from '@mui/material';
import { mainColor } from '../../Theme';
import { Button } from 'react-admin';


export const styledBox = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  height: 'auto',
  maxHeight: '800px',
  maxWidth: '700px',
  width: '100%',
  padding: '10px 20px',
  backgroundColor: '#fff',
  position: 'absolute',
  top: '50%',
  borderRadius: '10px',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};
  

export default function ModalReportComponent ({onSubmit, data, open, setOpen, ref}) {
  return(
    <Modal 
      onClose={() => setOpen(false)}
      open={open} ref={ref}>
      <Box
        sx={styledBox}
      >
        <Typography 
          sx={{
            width: '30%', 
            borderBottom: `1px ${mainColor} solid`, 
            padding: '5px', 
            margin: '20px', 
            textTransform: 'uppercase', 
            fontSize: '18px', 
            fontWeight: '600',
            color: mainColor,
          }}>Talents report</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            gap: '20px'
          }}
        >
          <Grid 
            rowSpacing={2}
            container>
            {
              data?.map((item, i) => {
                if(item?.isLoading) {return (
                  <Grid item xs={12} sx={{
                    display: 'flex',
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center'
                  }}>
                    <Skeleton sx={{height: '50px', width:' 80%'}} />
                  </Grid>
                );} else {
                  return (
                    (
                      <Grid item sx={{
                        display: 'flex',
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        justifyContent: 'center'
                      }} xs={item.xs}>{item.input}
                      </Grid>
                    )
                  );
                }
              })
            }
          </Grid>
          
          <Button variant="outlined" sx={{marginBottom: '20px'}} onClick={onSubmit}>Accept</Button>
        </Box>
      </Box>
    </Modal>
  );
}