import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
  name: 'data',
  initialState: {
    info: null,
    schemas: {
      talents: null,
      locations: null,
      events: null,
      faqs: null,
      articles: null,
      experiences:null,
    }
  },
  reducers: {
    storeInfo: (state, action) => {
      state.info = action.payload;
    },
    storeShemas: (state, action) => {
      switch(true){
      case action.payload.key === 'talents':
        state.schemas.talents = action.payload.value;
        return;
      case action.payload.key === 'locations':
        state.schemas.locations = action.payload.value;
        return;
      case action.payload.key === 'events':
        state.schemas.events = action.payload.value;
        return;
      case action.payload.key === 'faqs':
        state.schemas.faqs = action.payload.value;
        return;
      case action.payload.key === 'articles':
        state.schemas.articles = action.payload.value;
        return;
      case action.payload.key === 'experiences':
        state.schemas.experiences = action.payload.value;
        return;
      }
    }
  },
});

export const { 
  storeInfo, 
  storeShemas
} = dataSlice.actions;

export default dataSlice.reducer;
