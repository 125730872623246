import { appCard, appCard2 } from './BlogStyles/styledBlogs';
import { TextSelection, Transaction } from 'prosemirror-state';


export const recursiveCard = (str) => {
  if(str?.includes('[card2]')) {
    let start = str.indexOf('[card2]') + 7;
    let end = str.indexOf('[/card2]');
    let extractedString = str.substring(start, end);
    let result = str.replace(/\[card2\]([\s\S]*?)\[\/card2\]/, function(match) {
      let div = document.createElement('div');
      div.style = appCard;
      div.innerHTML = extractedString;
      return div.outerHTML;
    });
    return recursiveCard(result);
  }

  if(str?.includes('[card]')) {
    let start = str.indexOf('[card]') + 6;
    let end = str.indexOf('[/card]');
    let extractedString = str.substring(start, end);
    let result = str.replace(/\[card\]([\s\S]*?)\[\/card\]/, function(match) {
      let div = document.createElement('div');
      div.style = appCard2;
      let children = div.children;
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        child.style.color = 'white !important';
        child.style = {'& *': '{color:white!important}'};
      }
      div.innerHTML = extractedString;
      return div.outerHTML;
    });
    return recursiveCard(result);
  }

  if(str?.includes('[img]')) {
    let start = str.indexOf('[img]') + 5;
    let end = str.indexOf('[/img]');
    let extractedString = str.substring(start, end);
    let endRef = extractedString.indexOf('">');
    let startRef = extractedString.indexOf('href="') + 6;
    let href = extractedString.substring(startRef, endRef);
    let result = str.replace(/\[img\]([\s\S]*?)\[\/img\]/, function(match) {
      let img = document.createElement('img');
      img.style = 'width:300px';
      img.src = href;
      let fileExtensionIndex = href.lastIndexOf('.');
      let fileExtension = href.substring(fileExtensionIndex, href.length);
      encodeImageBase64(`<a href="${href}" />`, `image/${fileExtension}`, res => {
        if (res) {
          img.alt = res;
        } else {
          console.log('Failed to encode image.');
        }
      });
      return img.outerHTML;
    });
    console.log(result);
    return recursiveCard(result);
  }
  return str;
};

export const handleSelectionChange = (openingStr, closingStr, editor) => {
  const rich = document.getElementById('blog-rich-text-input-ref');
  rich.focus();
  if (editor.view) {
    const startIndex = rich.innerText.lastIndexOf(openingStr) + 1;
    const endIndex = rich.innerText.lastIndexOf(closingStr) + 1;
    if (startIndex !== -1 && endIndex !== -1 && endIndex > startIndex) {
      const newStart = startIndex + openingStr.length;
      const newEnd = endIndex;
      const tr = editor.view.state.tr
        .setSelection(TextSelection.create(editor.view.state.doc, newStart, newEnd));
      
      editor.view.dispatch(tr);
    }
  }
};

export const encodeImageBase64 = (url, fileExtension, callback)  => {
  console.log(fileExtension);
  fetch(url, {
    'Content-type': fileExtension
  })
    .then(response => {
      console.log(response);
      return response.blob();
    })
    .then(blob => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        callback(base64data);
      };
      reader.readAsDataURL(blob);
    })
    .catch(error => {
      console.error('Error encoding image:', error);
      callback(null);
    });
};