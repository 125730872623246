import React from 'react';
import { Create,useNotify } from 'react-admin';
import FormAdmins from './FormAdmins';
import { transform } from './transform';
import { useNavigate } from 'react-router';
import { CreateCustomComponent } from '../../../Components/CreateCustomComponent/CreateCustomComponent';
const CreateAdmins = () => {
  const notify = useNotify();
  const navigate = useNavigate();  
  const goBack = () => {
    navigate(-1);
  };

  return (
    <CreateCustomComponent redirect={goBack} mutationMode={'pessimistic'} mutationOptions={{onError: (data) => {
      if(data.startsWith('Duplicated key')) {
        return notify('There where some errors. A user with the email already exists in the Backoffice.', {type: 'error'});
      }
    }}}>
      <FormAdmins from='users'/>
    </CreateCustomComponent>
  );
};

export default CreateAdmins;