import {
  RichTextInput,
  RichTextInputToolbar,
  FormatButtons,
} from 'ra-input-rich-text';
import { useInput } from 'react-admin';
import { useRequiredSchema } from '../../Hooks/useRequiredSchema';


export const InputRichTextCountdown = ({source, validate = [], ...rest}) => {
  const { isRequiredSchema } = useRequiredSchema();
  
  return(
    <>
      <RichTextInput
        source={source}
        sx={{'& .RaRichTextInput-editorContent .ProseMirror': {
          borderRadius:'10px'
        }}}
        toolbar={
          <RichTextInputToolbar >
            {/* <LevelSelect />  */}
            <FormatButtons /> 
            {/* <ColorButtons /> */}
          </RichTextInputToolbar>
        }
        validate={[...isRequiredSchema(source), ...validate]}
        {...rest}
      />
    </>
  );
};