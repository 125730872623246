import { Box, Typography, LinearProgress } from '@mui/material';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAbsolute } from '../../Hooks/usePosition';
import { useSelector } from 'react-redux';
import { resolveTimeFormat } from '@mui/x-date-pickers/internals/utils/time-utils';
import { useResourceContext } from 'react-admin';

function checkValue(key, _callback) {
  let result = _callback(key);

  if (!result || result === '' || result?.length === 0 || result === undefined || key === 'logo') {
    return false;
  } else {
    return true;
  }
}


function objString(obj, key, _callback) {
  let nestedKey = key.includes('.') ? key.split('.') : [key];
  if (Object.keys(obj).find((item) => item === key || item === nestedKey[1]))
    return checkValue(key, _callback);
  if (!key || !obj || !obj[nestedKey[0]]) return false;
  if (!Object.keys(obj[nestedKey[0]]).find((item) => item === nestedKey[1])) {
    return false;
  } else {
    return objString(obj[nestedKey[0]], key, _callback);
  }
};

export const LineCompleteSticky = ({ data }) => {


  const palette = useSelector((state) => state?.palette?.palette);
  const resource = useResourceContext();
  const { getValues } = useFormContext();

  const progressBarFields = useSelector(state => {
    const schemas = state.data.schemas[resource];
    return [...schemas.base, ...schemas.additional];
  });

  const percentage = useMemo(() => {

    let total = progressBarFields?.length;
    for (let i = 0; i < progressBarFields?.length; i++){
      const key = progressBarFields[i];
      let found = progressBarFields?.find((item, index) => {
        let result = objString(
          Object.fromEntries([[key, getValues(key)]]),
          item,
          getValues,
        );
        return result; 
      });

      if (!found) {
        total = total - 1;
        continue;
      }
      continue;
    }
    return Math.floor((total / progressBarFields?.length) * 100);
  }, [resource, data, progressBarFields]);



  return (
    <>
      {
        !!progressBarFields && (
          <Box
            sx={{
              position: 'sticky',
              zIndex: 999,
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <Box sx={{ width: '100%', mr: 1 }}>
              {
                (!!percentage || percentage === 0) && (
                  <LinearProgress
                    sx={{
                      marginTop: '20px',
                      borderRadius: '10px',
                      height: '15px',
                      backgroundColor:'#ECECEC',
                      '.MuiLinearProgress-bar1Determinate': {
                        backgroundColor: palette?.primary,
                      }
                    }}
                    value={percentage}
                    variant="determinate"
                  />
                )
              }
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography sx={{ fontSize: '1.5em', fontWeigh: 600 }}>
                {percentage}
                <span style={{ fontSize: '1vw' }}>%</span>
              </Typography>
            </Box>
          </Box>
        )
      }
    </>
  );
};
