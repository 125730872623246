import React from 'react';
import FormEvents from './FormEvents';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';
import { transform } from './Transform';
import { Edit, useRemoveFromStore} from 'react-admin';

const EditEvents = () => {
  const remove = useRemoveFromStore();

  return (
    <EditFormCustom  
      mutationOptions={{onSuccess: remove('locations')}} 
      actions={null}
    >
      <FormEvents isEdit from={'events'}/>
    </EditFormCustom>
  );
};

export default EditEvents;