
import { Fragment, useState } from 'react';
import { StepSlider } from './Components/StepSlider';
import { BOARDS } from './boards';
import { OnBoardProvider } from './Components/OnBoardProvider';
import { useGetList, useListContext } from 'react-admin';
import { Spinner } from '../../Components/Spinner';

export const OnBoardingTab = () => {

  const [selectedStep, setSelectedStep] = useState(0);
  const { data, isFetching } = useGetList('app_config');
  const stepProps = {
    steps: BOARDS.length,
    value: selectedStep,
    onChange: setSelectedStep,
    source: selectedStep
  };
  
  return (
    <Fragment key="slider-tab">
      {
        isFetching ? (
          <Spinner />
        ) : (
          <Fragment>
            <OnBoardProvider data={data[0]} source={selectedStep} stepProps={stepProps} />
          </Fragment>
        )
      }
    </Fragment>
  );
};
