import { useEffect, useMemo,useState,memo } from 'react';
import { useInput, useRecordContext } from 'react-admin';

import dayjs from 'dayjs';
import { useFormState } from 'react-hook-form';
import { inputErrorMessage } from '../../../helpers/inputErrorMessage';

import InputAddTimelines from './InputAddTimelines';
import { defaultValues, formatObj, handleFormatParse, restOfValues, validateDay } from './functions';
import { useUtcFormat } from '../../../helpers/date';


export const TimeLineDays = (props) => {
  const record = useRecordContext();
  const {
    days,
    source
  } = props;
  
  const{ errors, isSubmitting } = useFormState();
  const { formatFestivalTime, parseFestivalTime } = useUtcFormat();
  const [dayPicked, setDayPicked] = useState(days[0]);
  const [addHours, setAddHours] = useState(record?.[source] ? handleFormatParse(record?.[source], formatFestivalTime) : defaultValues(days));
  
  const { 
    findDays, 
  } = useMemo(() => {
    return addHours.reduce((acc, item) => {
      if (dayjs(item?.open, 'YYYY-MM-DD').isSame(dayPicked)) {
        acc.findDays.push(item);
      } else {
        acc.restOfDays.push(item);
      }
      return acc;
    }, { 
      findDays: [], 
      restOfDays: [] 
    });
  },[dayPicked]);

  const {
    field,
  } = useInput({
    source,
    validate: validateDay,
    format: (e) => handleFormatParse(e, formatFestivalTime),
    parse: (e) => handleFormatParse(e, parseFestivalTime),
    defaultValue: defaultValues(days)
  });
  
  useEffect(() => field.onChange(addHours), [addHours]);
  
  const handleChange = (e) => {
    let currentDayValues = formatObj(e);
    let restOfDays = restOfValues(addHours, dayPicked);
    let concat = currentDayValues.concat(restOfDays);
    return setAddHours(concat);
  };
  const getError = (source, errors) => {
    if(errors[source]) return inputErrorMessage(errors[source].message);
    return undefined;
  };

  const handleError = useMemo(() => {
    return getError(source, errors);
  }, [errors, isSubmitting]);

  const InputProps = {
    source: source,
    handleChange: handleChange,
    setAddHours: setAddHours,
    findDays: findDays, 
    value: addHours, 
    dayPicked: dayPicked,
    days: days,
    setDayPicked: setDayPicked,
    error: handleError,
    ...props
  };


  const MemoComponent = useMemo(() => memo(InputAddTimelines), [dayPicked, handleError]);

  return (
    <MemoComponent {...InputProps} />
  );
};
