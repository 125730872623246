import { Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useGetList } from 'react-admin';
import { FormComponents } from '../HomeComponents/FormComponents';
import { Spinner } from '../../Components/Spinner';
import ExternalLinks from './Sections/ExternalLinks/ExternalLinks';
import Sponsors from './Sections/Sponsors/Sponsors';
import { InputAddSets } from '../Interests/Form/Components/InputAddSets';
const tabs = [
  {
    key: 'external_links',
    title: 'External Links',
    subtitle: 'Add up to 4 external links in your more page',
  },
  {
    key: 'sponsors',
    title: 'Sponsors',
    subtitle: (
      <p>
        Add sponsors to the more page in your app. You can select the size your
        sponsor icon occupy’s in the app:<br></br>
        <strong>1</strong> - Will ocuppy the full width<br></br>
        <strong>1/2</strong> - Will occupy 50% of it and other items with 1/2
        size will be placed beside<br></br>
        <strong>1/3</strong> - Will occupy 33% of the width<br></br>
        <br></br>
        Default size will be full width for all.
      </p>
    ),
  },
];
const MoreForm = () => {
  const [selectedForm, setSelectedForm] = useState(0);

  const { data: info, isFetching } = useGetList('app_config');

  const form = useMemo(() => {
    switch (!!info) {
    case tabs[selectedForm].key === 'external_links':
      return ExternalLinks();
    case tabs[selectedForm].key === 'sponsors':
      return Sponsors();
    }
  }, [info, selectedForm]);

  const props = useMemo(() => {
    return {
      ...form,
      section: tabs[selectedForm],
      selectedSet: selectedForm,
      info: info?.[0],
      sets: tabs,
      sx: { minHeight: 'auto', maxHeight: 'auto' },
      resourceProp: 'app_config',
      onClick: setSelectedForm,
      title: 'More',
      draggable: false,
    };
  }, [info, selectedForm]);

  return (
    <>
      <Grid container direction={'row'}>
        <Grid item xs={3} align="center">
          <InputAddSets {...props} />
        </Grid>
        <Grid item xs={9}>
          {!isFetching ? <FormComponents {...props} /> : <Spinner />}
        </Grid>
      </Grid>
    </>
  );
};

export default MoreForm;
