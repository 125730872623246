import Store from '../../Store';

export const BOARDS =  ['welcome','notifications', 'location', 'schedule', 'profile'];

export const DEFAULT_BOARD = {
  background_color: '#fff',
  background_image: {
    original: null
  },
  background_image_resize: 'mosaic',
  image: {
    original: null
  },
  title: 'Title',
  description: 'description',
  font_color: '#fff',
  box_background_color: Store.getState()?.palette.palette.primary,
  cta_text: 'Continue',
  cta_background_color: '#fff',
  cta_color: '#131313',
  action: 'welcome'
};
