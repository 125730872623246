import axios from 'axios';
import styles from './OrderModal.module.css';
export const orderFunctions = (props) => {
  const {
    cards,
    customToast,
    setCards,
    resource,
    setHoverCard,
    setDraggedCard,
    draggedCard,
  } = props;

  const handleDragStart = (e, index) => {
    const clone = e.currentTarget.cloneNode(true);
    setDraggedCard(index);
    clone.classList.add(styles.cardClone);
    clone.style.top = `${e.clientY}px`;
    clone.style.left = `${e.clientX}px`;
    document.body.appendChild(clone);

    const handleDrag = (e) => {
      clone.style.top = `${e.clientY}px`;
      clone.style.left = `${e.clientX}px`;
    };
    const handleDragEnd = () => {
      clone.remove();
      setDraggedCard(null);
      document.removeEventListener('drag', handleDrag);
      document.removeEventListener('dragend', handleDragEnd);
    };
    document.addEventListener('drag', handleDrag);
    document.addEventListener('dragend', handleDragEnd);

    const ghost = document.createElement('div');
    ghost.style.opacity = '0';
    ghost.style.visibility = 'hidden';
    e.dataTransfer.setDragImage(ghost, 0, 0);
  };
  const handleDragOver = (e, index) => {
    e.preventDefault();
    setHoverCard(index);
  };
  const handleDrop = async (e, newIndex) => {
    e.preventDefault();
    setHoverCard(null);
    setDraggedCard(null);
    console.log(draggedCard);
    //Elemento original que arrastramos, ubicado en cards
    const draggedIndex = draggedCard;
    const draggedCardVariable = cards[draggedIndex];
    //
    const updatedCards = [...cards];
    updatedCards.splice(draggedIndex, 1);
    updatedCards.splice(newIndex, 0, draggedCardVariable);

    updatedCards.forEach((card, index) => {
      card.rank = index;
    });

    const locationsData = updatedCards.map((item) => ({
      id: item.id,
      rank: item.rank,
    }));
    axios({
      url: `${process.env.REACT_APP_BASE_PATH}/${resource}`,
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      data: {
        [resource]: [...locationsData],
      },
    })
      .then((res) => {
        customToast('success', 'Updated successfully');
        setCards([...updatedCards]);
        console.log(updatedCards, 'CARDS POST UPDATE');
      })
      .catch((err) => {
        customToast('error', err.message);
      });
  };

  return {
    handleDragStart,
    handleDragOver,
    handleDrop,
  };
};
