import { Show, useGetList, useGetOne, useListContext, usePermissions, useRecordContext, useRedirect, useResourceContext, useShowController } from 'react-admin';
import { useMediaQuery,Box } from '@mui/material';
import ShowTemplate from '../../../Components/ShowComponents/ShowTemplate';
import { useMemo, useState } from 'react';
import { LastSubmitter } from './TalentsShowComponents/LastSubmitter';
import { ProfileCard } from './TalentsShowComponents/ProfileCard';
import { AsideActions } from '../../../Components/ShowComponents/AsideActions/AsideActions';
import { ShowProvider } from '../../../Components/ShowComponents/ShowProvider';
import { ReviewActions } from '../../../Components/RedesignComponents/ReviewActions';

const PostTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>Talent - {record.name}</span>;
};

const ShowTalents = () => {
  const isDesktop = useMediaQuery(
    theme => theme.breakpoints.down('xl'),
    { noSsr: true }
  );

  const [switchBody, setSwitchBody] = useState(false);
  return (
    <Show
      // aside={<AsideActions switchBody={switchBody} setSwitchBody={setSwitchBody} draft/>}
      actions={null}
      title={<PostTitle />}
    >
      <ShowTemplate
        from='talents'
        switchBody={switchBody}
        recordTitle={'name'}
        AsideActions={<ReviewActions switchBody={switchBody} setSwitchBody={setSwitchBody} draft/>}
        setSwitchBody={setSwitchBody}
        Submitter={LastSubmitter}
      >
        <ShowProvider switchBody={switchBody}>
          <ProfileCard />
        </ShowProvider>
      </ShowTemplate>
    </Show>
  );
};

export default ShowTalents;

