import React from 'react';
import { useGetList, required } from 'react-admin';
// Components
import InputText from '../../../Components/InputsForm/InputText';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import InputSelect from '../../../Components/InputsForm/InputSelect';
import { InputPillsSelect } from '../../../Components/RedesignComponents/Inputs';
// Material & Icons
import { EXCURSIONS_TOOLTIPS } from '../../TalentForm/Tooltips';
import { InputImage } from '../../../Components/InputsForm/InputImage';
import { InputDurationMinutes } from '../../../Components/InputsForm/InputDuration';
import { InputSelectTimes } from '../../../Components/InputsForm/OpenTimesComponents/InputSelectTimes';
const FormActivities = ({ isEdit, from }) => {
  const { data: locations, isFetching } = useGetList('locations', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
  });

  const { data: experiencesTypes, isFetching: isFetchingExperiences } =
    useGetList('experiences/categories', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
    });
  const data = [
    {
      title: 'LOCATION DETAILS',
      inputs: [
        {
          field: (
            <InputText
              required={true}
              fullWidth
              source="name"
              title={'Name'}
              label=""
            />
          ),
          breakpoints: { xs: 8.5 },
        },
        {
          field: (
            <InputPillsSelect
              choices={experiencesTypes && experiencesTypes}
              withText={true}
              source="category"
              label=""
              title={'Category'}
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputText
              required={true}
              fullWidth
              key={Math.random()}
              source="description"
              title={'DESCRIPTION'}
              subtitle={'Describe thoroughly your location'}
              multiline={true}
              label={''}
            />
          ),
          breakpoints: { xs: 8.5 },
        },
        {
          field: <></>,
          breakpoints: { xs: 3 },
        },
        {
          field: (
            <InputImage
              title={'Image'}
              tooltip={EXCURSIONS_TOOLTIPS['image']}
              source="picture"
            />
          ),
          breakpoints: { xs: 4 },
        },
        {
          field: (
            <InputSelect
              choices={isFetching ? [] : locations}
              optionValue={'id'}
              optionText={'name'}
              fullWidth
              source="location_id"
              title={'Meeting Point'}
              subtitle={
                'If your location has a meeting point or a location where it takes place, select from festival’s available locations'
              }
              label={''}
            />
          ),
          breakpoints: { xs: 4 },
        },
      ],
    },
    {
      title: 'BOOKING DETAILS',
      inputs: [
        {
          field: (
            <InputText
              source="book_url"
              title={'URL'}
              subtitle={EXCURSIONS_TOOLTIPS['book_url']}
              label={''}
            />
          ),
          breakpoints: { xs: 8.5 },
        },
      ],
    },
    {
      title: 'TIMES',
      inputs: [
        {
          field: (
            <InputDurationMinutes
              source={'duration'}
              title="Duration (IN MINUTES)"
              noStage={true}
              InputProps={{
                sx: {
                  borderRadius: '10px',
                  height: '40px',
                  padding: 0,
                  marginTop: '10px',
                },
              }}
            />
          ),
          breakpoints: { xs: 8.5 },
        },
        {
          field: <InputSelectTimes source={'start_dates'} />,
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];
  return (
    <AdaptableFormRedesign
      isEdit={isEdit}
      data={data}
      from={from}
      icon={false}
      saveBackground={true}
      showDelete
    />
  );
};

export default FormActivities;
