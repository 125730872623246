import React from 'react';
import FormActivities from './FormActivities';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';
import { transform } from './transform';
const EditActivities = () => {
  return (
    <EditFormCustom mode="onChange" redirect='list' transform={transform} >
      <FormActivities from={'activities'} isEdit={true} />
    </EditFormCustom>
  );
};

export default EditActivities;