import { Autocomplete, Box, Checkbox, FormControlLabel, FormGroup, TextField, Typography,Chip } from '@mui/material';
import { InputTitle } from '../StyledComponents/InputTitle';
import { uppercaseString } from './ModalReporte';
import React, { useMemo } from 'react';
import { COLORS } from '../../assets/constants/theme';


export default function AllAutocomplete ({
  multiple = true,
  title,
  disableOptions, 
  arr, 
  width = '80%',
  value = [], 
  onChange, 
  allTitle = {id: '', name: 'All'},
  element = <Checkbox />,
  getOptionLabel = (option) => option.name,
  tag,
  inputLabelProps,
  label='',
  ...rest}) {

  const updatedArr = useMemo(() => {
    if(arr) {
      if(allTitle) return [allTitle, ...arr];
      return arr;
    }
    return [];
  }, [arr]);
  return (
    <Box sx={{width: width, alignItems: 'center','& .MuiInputBase-sizeSmall':{
      maxHeight:'45px',
      overflow: 'hidden'
    },'& .css-214hmp-MuiAutocomplete-root .MuiAutocomplete-inputRoot':{
      flexWrap: 'nowrap',
    }}}>
      {title && <InputTitle>{title}</InputTitle>}
      <Autocomplete
        multiple={multiple}
        fullWidth
        options={updatedArr}
        sx={{
          flexWrap: 'wrap',
          '& .MuiChip-root':{
            backgroundColor:'black',
            height: '50%',
          }
        }}
        label=""
        value={value}
        disablePortal
        getOptionDisabled={(option) => disableOptions}
        disableClearable
        disableListWrap
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        renderOption={(params, v) => {
          return (
            <Box 
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                '* &': {
                  ...inputLabelProps
                }
              }}
              {...params}
            >
              <FormGroup >
                <FormControlLabel onClick={(e) => e.preventDefault()} control={React.cloneElement(element, { checked: params?.['aria-selected']})} label={<Typography sx={{fontSize: '0.8em'}}>{params.key}</Typography>} />
              </FormGroup>
            </Box>
          );
        }}
        renderInput={(params) => <TextField label={label} sx={{width: '100%'}} variant="outlined" {...params} />}
        // ChipProps={{deleteIcon: false,onDelete: null,style:{backgroundColor: 'transparent'}}}
        renderTags={(params) =>{
          let limites = rest.limitTags;
          return (
            <Box sx={{display: 'flex', flexDirection: 'row', width:'100%',gap:0,margin:0,padding:0,borderRadius:'50px'}}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems:'center',gap: '5px', flexWrap: 'nowrap', width:'auto', overflowX: 'hidden'}}>
                {
                  params?.slice(0, limites)?.map((e,index) => <Chip label={e.name || e.text || e} sx={{backgroundColor:COLORS.light.secondary.light,fontSize:'14px', color: 'black',overflowX: index === limites - 1 ? 'hidden' : 'visible'}}/>)
                }
              </div>
              <div style={{width:'auto'}}>
                {params?.length > limites && <Chip label={`+${params?.length - limites}`} sx={{ backgroundColor: 'transparent', color: 'black',fontSize:'14px' }} />}
              </div>
            </Box>
          );
        }
        }
        {...rest}
      />
    </Box>
  );
}