import React from 'react';
import { InputTitle } from '../../StyledComponents/InputTitle';
import { useGetList } from 'react-admin';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
import { DnDArticles } from '../../OrderArticles/DnDArticles';

const InputDndArticles = ({
  approvedFields,
  textTransform,
  title,
  subtitle,
  watchField,
  optionText = 'text',
  optionValue = 'text',
  source,
  featured,
  info,
  isModal,
  ...rest
}) => {
  const { data: posts, isFetched } = useGetList('articles', {
    pagination: {
      perPage: 100,
      page: 1,
    },
  });

  return (
    <>
      {title && (
        <InputTitle
          textTransform={textTransform}
          sx={{
            marginTop: '25px',
          }}
        >
          {title}
        </InputTitle>
      )}
      {subtitle && <InputSubtitle>{subtitle}</InputSubtitle>}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            border: '1px solid #D7D7D7',
            borderRadius: '10px',
            maxWidth: '60vw',
            marginTop: 10,
          }}
        >
          {posts && isFetched && (
            <DnDArticles
              key={JSON.stringify(posts)}
              options={posts.filter(
                (post) =>
                  post.rank !== null &&
                  post.rank !== undefined &&
                  post.rank >= 0 &&
                  post.rank <= 9
              )}
              isModal={isModal}
              info={info}
              source={source}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default InputDndArticles;
