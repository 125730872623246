import {
  Box,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useInput } from 'react-admin';
import { RgbaColorPicker } from 'react-colorful';
import { FaSquareFull } from 'react-icons/fa';
import { InputTitle } from '../StyledComponents/InputTitle';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
import { useOnClickOutside } from '../../Hooks/useOnClickOutside';

export function hexToRgb(hexColor) {
  if(typeof hexColor !== 'string') return {r: null, g: null, b: null, a: 0};
  // Remove '#' if present
  hexColor = hexColor.replace('#', '');

  // Split the hex color into red, green, and blue components
  var redHex = hexColor.substring(0, 2);
  var greenHex = hexColor.substring(2, 4);
  var blueHex = hexColor.substring(4, 6);

  // Convert hex components to decimal
  var red = parseInt(redHex, 16);
  var green = parseInt(greenHex, 16);
  var blue = parseInt(blueHex, 16);
  var alphaHex = hexColor.length === 8 ? hexColor.substring(6, 8) : 'ff';
  var alpha = parseInt(alphaHex, 16) / 255;

  // Return an object containing the RGB components
  return {
    r: red,
    g: green,
    b: blue,
    a: alpha
  };
}

export function rgbToHex(red, green, blue, alpha) {
  var redHex = red.toString(16).padStart(2, '0');
  var greenHex = green.toString(16).padStart(2, '0');
  var blueHex = blue.toString(16).padStart(2, '0');
  var alphaHex = (alpha !== undefined ? Math.round(alpha * 255) : 255).toString(16).padStart(2, '0');
  
  return '#' + redHex + greenHex + blueHex + alphaHex;
}

export function generateColorVariants(hexColor) {
  if (!hexColor || hexColor.length <= 6 || !hexColor?.includes('#')) return '';
  // Convert hex color to RGB components
  var red = parseInt(hexColor.substring(1, 3), 16);
  var green = parseInt(hexColor.substring(3, 5), 16);
  var blue = parseInt(hexColor.substring(5, 7), 16);
  var alpha = hexColor.length === 9 ? parseInt(hexColor.substring(7, 9), 16) / 255 : 1;
  // Calculate lighter variant (increase each component by 20%)
  var lighterRed = Math.min(Math.round(red * 1.5), 255);
  var lighterGreen = Math.min(Math.round(green * 1.5), 255);
  var lighterBlue = Math.min(Math.round(blue * 1.5), 255);
  var lighterHex = rgbToHex(lighterRed, lighterGreen, lighterBlue, alpha);

  // Calculate medium variant (decrease each component by 20%)
  var mediumRed = Math.max(Math.round(red * 0.6), 0);
  var mediumGreen = Math.max(Math.round(green * 0.6), 0);
  var mediumBlue = Math.max(Math.round(blue * 0.6), 0);
  var mediumHex = rgbToHex(mediumRed, mediumGreen, mediumBlue, alpha);

  return {
    light: lighterHex,
    medium: mediumHex
  };
}


export const ColorPickerTextField = ({ placement, title, subtitle, label, source, validate, secondary, tertiary, sx, typography,hexFormat,sizeBox,value , onChange}) => {

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const ref = useRef(false);
  const pickerRef = useRef(false);
  const {
    field,
  } = useInput({source, validate});
  const [color,setColor] = useState(field?.value);

  const handleChangeText = (e, key) => {
    setError(false);
    let obj = field?.value;
    let value = e.target.value;
    if(!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) setError(true);
    if(hexFormat){
      field.onChange(value);
      setColor(value);
      onChange && onChange(value);
    }else{
      field.onChange({...obj, [key]: value});
      setColor({...obj, [key]: value});
    }
  };
  const handleChange = (e) => {
    if(open) {
      if(hexFormat){
        if (!e.r || !e.g || !e.b) return undefined;
        let hexColor = rgbToHex(e.r, e.g, e.b, e.a);
        field.onChange(hexColor);
        setColor(hexColor);
        onChange && onChange(hexColor);
      }else{
        if(!e.r || !e.g || !e.b) return undefined;
        let hexColor = rgbToHex(e.r, e.g, e.b, e.a);

        let newObj = {
          ...field?.value,
          [open[1]]: hexColor
        };
        
        field.onChange(newObj);
        setColor(newObj);
        onChange && onChange(newObj);
      }
    }
  };
  useOnClickOutside([ref, pickerRef],() => setOpen(false));


  const placementProps = useMemo(() => {
    switch(true){
    case placement === 'right-bottom':
      return {
        top: 50,
        left: 100,
        transform: 'translate(0%, 0%)'
      };
    case placement === 'bottom':
      return {
        top: 50,
        left: '50%',
        transform: 'translate(-50%, 0%)'
      };
    case placement === 'top':
      return {
        bottom: 50,
        left: '50%',
        transform: 'translate(-50%, 0%)'
      };
    case placement === 'left':
      return {
        top: '50%',
        right: 50,
        transform: 'translate(0%, -50%)'
      };
    case placement === 'right':
      return {
        top: '50%',
        left: 0,
        transform: 'translate(25%, -50%)'
      };
    }
  },[]);
  useEffect(() => {
    if(value){
      setColor(value);
    }else if (field?.value === null || field?.value === '' || field?.value === undefined) {
      setColor('');
    }
  }, [value]);
  return (
    <Box  sx={{
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      // maxWidth: hexFormat ? '200px' : '100%',
      zIndex: '99999',
    }}>
      <Box sx={{display: 'flex', flexDirection: 'column',  flex: '0 0 33.33%'}}>
        {title && <InputTitle sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          marginBottom:'8px'
        }}>{title}</InputTitle>}
        {subtitle && <InputSubtitle>{subtitle}</InputSubtitle>}
        <TextField 
          error={error}
          helperText={(!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(field?.value['solid']) && field?.value['solid']) && 'Not a hex color string'}
          InputProps={{
            onClick: () => setOpen([true, 'solid']),
            ref: ref,
            sx: {
              width: 'inherit',
              height: '40px',
              maxWidth:hexFormat ? '200px': '100px',
              zIndex: 0,
              ...sx,
            },
            startAdornment:(
              <InputAdornment position="start">
                <FaSquareFull size="25px" color={hexFormat ? color : color.solid} />
              </InputAdornment>
            )
          }}
          sx={{
            width:hexFormat ?'120px' : '300px',
            marginBottom:'8px',
            ...sizeBox
          }}
          value={hexFormat ? color : color.solid}
          onChange={(e) => handleChangeText(e, 'solid')}
          
          InputLabelProps={{shrink: true}} 
          variant="outlined" 
          label={label ?? ''}
        />
      </Box>
      {
        secondary && (
          <Box sx={{display: 'flex', flexDirection: 'column', flex: '0 0 33.33%'}}>
            {title && <InputTitle>Light</InputTitle>}
            <TextField 
              InputProps={{
                sx: {
                  width: 'inherit',
                  height: '40px',
                  maxWidth: '200px'

                },
                startAdornment:(
                  <InputAdornment position="start">
                    <FaSquareFull size="25px" color={color.light} />
                  </InputAdornment>
                )
              }}
              sx={{
                width: 'inherit'
              }}

              value={color.light}
              InputLabelProps={{shrink: true}} 
              variant="outlined" 
              label=""
            />
          </Box>

        )
      }
      {
        tertiary && (
          <Box sx={{display: 'flex', flexDirection: 'column',  flex: '0 0 33.33%'}}>
            {title && <InputTitle>Medium</InputTitle>}
            <TextField 
              InputProps={{
                sx: {
                  width: 'inherit',
                  height: '40px',
                  maxWidth: '200px'

                },
                startAdornment:(
                  <InputAdornment position="start">
                    <FaSquareFull size="25px" color={color.medium} />
                  </InputAdornment>
                )
              }}
              sx={{
                width: 'inherit'
              }}
              
              value={color.medium}
              InputLabelProps={{shrink: true}} 
              variant="outlined" 
              label=""
            />
          </Box>

        )
      }
      {
        typography && (
          <Box sx={{display: 'flex', flexDirection: 'column',  width: '100%'}}>
            {title && <InputTitle>Typography</InputTitle>}
            <TextField 
              InputProps={{
                onClick: () => setOpen([true, 'font_color']),
                sx: {
                  width: 'inherit',
                  height: '40px',
                  maxWidth: '200px'

                },
                startAdornment:(
                  <InputAdornment position="start">
                    <FaSquareFull size="25px" color={color.font_color} />
                  </InputAdornment>
                )
              }}
              sx={{
                width: 'inherit'
              }}

              onChange={(e) => handleChangeText(e, 'font_color') }
              value={color.font_color}
              InputLabelProps={{shrink: true}} 
              variant="outlined" 
              label=""
            />
          </Box>

        )
      }
      {
        open[0] && (
          <Box 
            ref={pickerRef}
            sx={{
              position: 'absolute', 
              bottom: '60%',
              left: 0,
              height: '200px',
              maxWidth: '200px'
            }}>
            <RgbaColorPicker
              color={hexFormat ? hexToRgb(color):hexToRgb(color.solid)}
              onChange={handleChange}
            />
          </Box>
        )
      }
    </Box>
  );
};

// export const ColorPickerInput = ({
//   title,
//   subtitle,
//   label = 'Color',
//   sx,
//   onChange,
//   value,
// }) => {
//   const [open, setOpen] = useState(false);
//   const [error, setError] = useState(false);
//   const ref = useRef(false);
//   const pickerRef = useRef(false);

//   const handleChangeText = (e) => {
//     setError(false);
//     let value = e.target.value;
//     if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) setError(true);
//     onChange && onChange(value);
//   };
//   const handleChange = (e) => {
//     if (!e.r || !e.g || !e.b) return undefined;
//     let hexColor = rgbToHex(e.r, e.g, e.b);
//     onChange && onChange(hexColor);
//   };

//   useOnClickOutside([ref, pickerRef], () => setOpen(false));

//   return (
//     <Box
//       sx={{
//         position: 'relative',
//         width: '100%',
//         gap: '10px',
//         maxWidth: '450px',
//         display: 'flex',
//         flexDirection: 'column',
//       }}
//     >
//       {title && <InputTitle>{title}</InputTitle>}
//       {subtitle && <InputSubtitle>{subtitle}</InputSubtitle>}
//       <TextField
//         InputProps={{
//           ref: ref,
//           sx: {
//             width: 'inherit',
//             height: '40px',
//             maxWidth: '200px',
//             ...sx,
//           },
//           error: error,
//           startAdornment: (
//             <InputAdornment position="start">
//               <FaSquareFull size="25px" color={value} />
//             </InputAdornment>
//           ),
//         }}
//         sx={{
//           width: 'inherit',
//         }}
//         value={value}
//         onChange={handleChangeText}
//         onClick={() => setOpen(true)}
//         InputLabelProps={{ shrink: true }}
//         variant="outlined"
//         label={label}
//       />
//       {open && (
//         <Box
//           ref={pickerRef}
//           sx={{
//             position: 'absolute',
//             bottom: 0,
//             right: 0,
//             height: '200px',
//             maxWidth: '200px',
//           }}
//         >
//           <RgbaColorPicker color={hexToRgb(value)} onChange={handleChange} />
//         </Box>
//       )}
//     </Box>
//   );
// };

//
// export const FormColorPickerInput = ({
//   title,
//   subtitle,
//   label = 'Color',
//   sx,
//   source,
// }) => {
//   const [open, setOpen] = useState(false);
//   const [error, setError] = useState(false);
//   const ref = useRef(false);
//   const pickerRef = useRef(false);
//   const { field } = useInput({ source, defaultValue: '' });
//   const handleChangeText = (e) => {
//     setError(false);
//     let value = e.target.value;
//     if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)) setError(true);
//     field.onChange(value);
//   };
//   const handleChange = (e) => {
//     if (!e.r || !e.g || !e.b) return undefined;
//     let hexColor = rgbToHex(e.r, e.g, e.b);
//     field.onChange(hexColor);
//   };

//   useOnClickOutside([ref, pickerRef], () => setOpen(false));

//   return (
//     <Box
//       sx={{
//         position: 'relative',
//         width: '100%',
//         gap: '10px',
//         maxWidth: '450px',
//         display: 'flex',
//         flexDirection: 'column',
//       }}
//     >
//       {title && <InputTitle>{title}</InputTitle>}
//       {subtitle && <InputSubtitle>{subtitle}</InputSubtitle>}
//       <TextField
//         InputProps={{
//           ref: ref,
//           sx: {
//             width: 'inherit',
//             height: '40px',
//             maxWidth: '200px',
//             ...sx,
//           },
//           error: error,
//           startAdornment: (
//             <InputAdornment position="start">
//               <FaSquareFull size="25px" color={field?.value} />
//             </InputAdornment>
//           ),
//         }}
//         sx={{
//           width: 'inherit',
//         }}
//         value={field?.value}
//         onChange={handleChangeText}
//         onClick={() => setOpen(true)}
//         InputLabelProps={{ shrink: true }}
//         variant="outlined"
//         label={label}
//       />
//       {open && (
//         <Box
//           ref={pickerRef}
//           sx={{
//             position: 'absolute',
//             bottom: 0,
//             right: 0,
//             height: '200px',
//             maxWidth: '200px',
//           }}
//         >
//           <RgbaColorPicker color={hexToRgb(field.value)} onChange={handleChange} />
//         </Box>
//       )}
//     </Box>
//   );
// };