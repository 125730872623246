import { useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { useGetList, useSidebarState } from 'react-admin';
import Calendar from 'muvi-calendar/dist/App';
// Hooks & Utils
import dataProvider from '../../dataProvider';
import { scheduleFunctions } from './Components/calendarFunctions';
import { useUtcFormat } from '../../helpers/date';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
// Components
import Header from './Components/Header';
import { Spinner } from '../../Components/Spinner';
import { EventActions } from './Components/EventActions';
import { ActionBar } from './Components/ActionBar/ActionBar';
// Material & Icons
import { useMediaQuery } from '@mui/material';

const Schedule = () => {
  //Fetch
  const { data, isLoading, refetch, isFetching, isRefetching } = useGetList(
    'events',
    {
      pagination: { perPage: 9999, page: 1 },
      filter: { type: 'show' },
      sort: { field: 'date_created' },
    },
  );
  const { data: venues, isLoading: loadingVenues } = useGetList('locations', {
    filter: { event_ready: true, sub_locations: true },
    pagination: { perPage: 9999, page: 1 },
    sort: { field: 'rank', order: 'DESC' },
  });

  //Hooks & States
  const [open, setOpen] = useSidebarState();
  const customToast = useCustomToasty();
  const { parseFestivalTime } = useUtcFormat();
  const festivalDays = useSelector((state) => state?.data?.info?.days);
  const palette = useSelector((state) => state?.palette?.palette);
  const festivalTimeZone = useSelector(
    (state) => state?.data?.info?.timezone?.offset,
  );
  const [filterState, setFilterState] = useState({
    filterDate: null,
    filterVenues: null,
    filterTalents: null,
    filterCategorys: null,
    filterName: null,
  });

  //HandlerClick
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [eventSelected, setEventSelected] = useState(null);
  const openModal = Boolean(anchorEl);
  const handleItemClick = (id, itemId, event) => {
    const selectedItem = updatedEvents.find((item) => item.id === id);
    if (selectedItem) {
      setEventSelected(event);
      setSelectedItemId(itemId);
      setAnchorEl(document.getElementById(itemId));
    }
  };
  const handlePopoverClose = () => {
    setSelectedItemId(null);
    setAnchorEl(null);
  };
  //HandlerDrag
  // const [errorDrag, setErrorDrag] = useState(null);
  // const [elementErrorDrag, setElementErrorDrag] = useState(null);
  // const openErrorDrag = Boolean(errorDrag);
  // const onDragError = (item, itemCellId, itemCell) => {
  //   setErrorDrag(itemCellId);
  //   setElementErrorDrag(itemCell);
  // };
  // const handleTooltipClose = () => {
  //   setErrorDrag(null);
  // };

  //Memos Data
  const updatedVenues = useMemo(() => {
    let arr = [];
    if (venues) {
      if (filterState?.filterVenues?.length > 0) {
        for (let i = 0; i < venues?.length; i++) {
          for (let j = 0; j < filterState?.filterVenues?.length; j++) {
            if (
              venues[i].id === filterState?.filterVenues[j] ||
              venues[i].main_location === filterState?.filterVenues[j]
            ) {
              arr.push(venues[i]);
              continue;
            }
          }
        }
        if (filterState?.filterVenues?.length > 0) return arr;
      }
      return venues;
    }
  }, [venues, filterState.filterVenues]);
  const updatedEvents = useMemo(() => {
    let arr = [];
    if (isRefetching) {
      return [];
    }
    if (data) {
      if (filterState?.filterTalents?.length > 0) {
        for (let i = 0; i < data.length; i++) {
          for (let z = 0; z < filterState?.filterTalents?.length; z++) {
            if (
              data[i]?.['talents_ids']?.find(
                (item) => item === filterState?.filterTalents[z],
              )
            ) {
              arr.push(data[i]);
              continue;
            }
          }
        }
        if (filterState?.filterTalents?.length > 0) return arr;
      }
      if (filterState?.filterCategorys?.length > 0) {
        for (let i = 0; i < data?.length; i++) {
          for (let z = 0; z < filterState?.filterCategorys?.length; z++) {
            if (data[i]?.['category'] === filterState?.filterCategorys[z]) {
              arr.push(data[i]);
              continue;
            }
          }
        }
        if (filterState?.filterCategorys?.length > 0) return arr;
      }

      if (filterState?.filterName?.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let value = data?.[i]?.['name'];

          if (
            value.toLowerCase().includes(filterState?.filterName) ||
            value.toUpperCase().includes(filterState?.filterName)
          ) {
            arr.push(data[i]);
            continue;
          }
        }
        if (filterState?.filterName?.length > 0) return arr;
      }
      if (filterState?.filterCategorys?.length > 0) {
        for (let i = 0; i < data?.length; i++) {
          for (let z = 0; z < filterState?.filterCategorys?.length; z++) {
            if (data[i]?.['category'] === filterState?.filterCategorys[z]) {
              arr.push(data[i]);
              continue;
            }
          }
        }
        if (filterState?.filterCategorys?.length > 0) return arr;
      }
      return data;
    }
  }, [data, filterState, isFetching, isLoading, isRefetching]);

  //Controllers Schedule
  const { handleMove, onResize, scheduleEvent, onClick } = scheduleFunctions({
    customToast,
    dataProvider,
    refetch,
    updatedEvents,
    parseFestivalTime,
    handleItemClick,
  });

  //MediaQuerys & Styles
  const isNetbook = useMediaQuery('(max-height:700px)');
  const isNotebook = useMediaQuery('(min-height:701px) and (max-height:900px)');
  const isMonitor = useMediaQuery('(min-height:901px) and (max-height:1600px)');
  const isLargeMonitor = useMediaQuery('(min-height:1601px)');
  const handleHeight = () => {
    if (isNetbook) {
      return '80vh';
    } else if (isNotebook) {
      return '86vh';
    } else if (isMonitor) {
      return '92vh';
    } else if (isLargeMonitor) {
      return '95vh';
    } else {
      return '80vh';
    }
  };
  // const tooltipStyle={
  //   color: 'black',
  //   backgroundColor: 'white',
  //   borderRadius: '4px',
  //   padding: '10px',
  //   fontSize:'15px'
  // };
  // const handlerDownload = () => {
  //   downloadSchedule('datagrid-events-scrolleable');
  // };
  if (
    !loadingVenues &&
    !isLoading &&
    festivalDays?.length > 0 &&
    !isNaN(festivalTimeZone)
  ) {
    return (
      <div
        style={{
          height: '100%',
          width: open ? 'calc(100vw - 274px)' : 'calc(100vw - 83px)',
        }}
      >
        {data && (
          <div
            style={{
              marginTop: '20px',
              height: handleHeight(),
              width: '100%',
            }}
          >
            <Calendar
              venues={updatedVenues}
              onItemMove={handleMove}
              onClick={onClick}
              onResize={onResize}
              scheduleEvent={scheduleEvent}
              timeZone={festivalTimeZone}
              days={festivalDays}
              loader={
                <div className="progress-container">
                  <div className="progress"></div>
                </div>
              }
              header={<Header />}
              actionBar={
                <ActionBar
                  totalEvents={updatedEvents}
                  palette={palette}
                  setFilterState={setFilterState}
                  filterState={filterState}
                />
              }
              items={updatedEvents?.filter(
                (item) => item.start_date !== null && item.end_date !== null,
              )}
              venueConfig={{
                titleStyle: {
                  fontSize: isMonitor ? '16px' : '14px',
                  fontWeight: '400',
                  color: 'black',
                },
              }}
              itemConfig={{
                containerStyle: {
                  borderRadius: '10px',
                },
                eventStyle: {
                  padding: '5px 10px',
                },
                showTalents: true,
              }}
              styleProps={{
                tableStyle: {
                  text: {
                    fontSize: '1em',
                    fontWeight: '600',
                    color: 'black',
                  },
                },
                header: {
                  daysButton: {
                    style: {
                      cursor: 'pointer',
                      minWidth: '6.5em',
                      borderRadius: '.5vw',
                      border: '1px solid black',
                      height: isMonitor ? '50px' : '40px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      alignContent: 'center',
                      placeItems: 'center',
                      backgroundColor: 'transparent',
                      fontSize: isMonitor ? '0.9em' : '0.8em',
                      color: 'black',
                      fontWeight: '400',
                    },
                    dayActiveBackground: palette?.primary,
                    fontColorActive: 'white',
                  },
                  periods: {
                    color: palette?.primary,
                  },
                },
                daysContainer: {
                  marginTop: '0px',
                  marginBottom: isMonitor ? '0px' : '15px',
                },
                colorOutOfHours: {
                  show: true,
                  dropeable: false,
                  color: '#D9D9D97A',
                },
                colorSubLocation: {
                  show: true,
                  color: 'grey',
                  opacity: 0.3,
                },
                timeZone: {
                  showTimeZone: true,
                },
              }}
              // onDragError={onDragError}
            />
          </div>
        )}
        <EventActions
          openModal={openModal}
          closeModal={handlePopoverClose}
          anchorEl={anchorEl}
          selectedItemId={selectedItemId}
          eventSelected={eventSelected}
          refetch={refetch}
        />
        {/* <Popover
          id={errorDrag}
          open={openErrorDrag}
          anchorEl={elementErrorDrag}
          onClose={handleTooltipClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        >
          <div style={tooltipStyle}>
          You can not edit this event through the calendar, please edit it through the form.
          </div>
        </Popover> */}
      </div>
    );
  } else {
    return (
      <div style={{ height: '800px' }}>
        <Spinner />
      </div>
    );
  }
};

export default Schedule;
