import { AiFillDelete } from 'react-icons/ai';
import {Button} from '../../Generics/index';

export const asideActionButton = {
  'font-size': '.8rem',
  'font-weight': '600',
  'text-transform': 'uppercase',
  'place-self': 'center',
  'align-items': 'center',
};

export const AsideDelete = ({color = '#f99190', children}) => (
  <Button
    style={{...asideActionButton, color: color}}
  >
    <AiFillDelete sx={{color: '#f99190', marginRight:'5px'}} fontSize="20px"/>
    {children}
  </Button>
);