import { mainColor } from '../../Theme';
import { styled } from 'styled-components';

export const styledShowTemplate = {
  'position': 'relative',
  'display': 'flex',
  'justify-content': 'flex-start',
  'align-items': 'start',
  'width' : 'auto',
  'height' : 'auto',
  'min-height': '100vh',
  'margin': '40px'
};

export const BackArrow = styled.div`
position: absolute;
top: 0px;
display: inline-block;
left: 0px;
display: flex;
align-items: center;
justify-content: center;
width: 40px;
border-radius: 50%;
height: 40px;
cursor: pointer;
border: none;
&:hover {
  background-color: #fff;
}`;

export const styledCard = {
  'display' : 'flex',
  'align-items': 'center',
  'justify-content': 'center',
  'width': '100%',
  'height': '100%',
  'border': '1px solid #d4d4e8',
  'border-radius': '12px',
  'padding' : '20px',
  'position': 'relative',
  'background-color': '#fff',
  'margin-bottom': '20px',
  'box-shadow': 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px', 
 
  
};
  
export const styledTitle = {
  'display': 'flex',
  'font-size': '1em',
  'color': mainColor,
  'text-transform': 'uppercase',
  'margin-left': '20px',
  'margin-top': '30px',
  'margin-bottom': '10px',
  'font-weight': '600'
};

export const styledArrow = {
  position: 'absolute',
  top: -30,
  left: -80,
  // border: '1px solid black',
  // borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: 'red'
  }
};

export const styledRow = {
  'display': 'flex',
  'flex-direction': 'row',
  'width': '100%',
  'justify-content': 'space-between'
};
  
export const styledValue = {
  'font-size': '.8em',
  'font-weight': '500',
  'color': mainColor,
};
export const styledKey =  {
  'font-size': '.8em',
  'text-transform': 'uppercase',
  'font-weight': '500',
  'color': '#606060',
    
};

export const SmallCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 10%;
    flex-grow: 1;
    height: auto;
    border: 1px solid #f0f0f0;
    border-radius: 15px;
    padding: 25px;
    min-width: 200px;
    max-width: 300px;
    background-color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
`;

export const SmallCardHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    align-items: flex-start;
`;

export const SmallCardTitle = styled.h4`
    width: 50%;
    font-size: 1em;
    margin: 0;
    padding: 0;
    color: ${mainColor};
    font-weight: 600;
    text-align: start;
    text-transform: uppercase;
    color: #00000;
`;

export const SmallCardIcon = styled.div`
    width: 10%;
    text-align: start;
    color: ${props => props.color ?? mainColor}
`;

export const SmallCardBody = styled.div`
    width: 100%;
    font-size: 12px;
    margin-top: 20px;
    text-transform: uppercase;
`;

export const asideActionsStyles = {
  position: 'fixed',
  width: 'auto',
  height: '100%',
  top: 100,
  padding: '60px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 600,
  justifyContent: 'flex-start'
};

