import { useRef, useState } from 'react';
import { usePermissions, useRedirect } from 'react-admin';
import { useSelector } from 'react-redux';
// Hooks & Utils
import { easeInLeft, opacityIn } from '../../../../assets/animations';
// Components
import { Button } from '../../../../Components/Generics';
import { SimpleTalentForm } from './SimpleTalentForm';
// Material & Icons
import { Box, Modal,Popover } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

export const modalBoxStyle = {
  width: 'auto',
  height: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  animation: `${opacityIn} 500ms ease`,
  overflow: 'auto',
  borderRadius:'10px'
};

export const AddTalentModal = () => {
  const palette = useSelector((state) => state?.palette?.palette);
  const[open, setOpen] = useState(false);
  const [transition, setTransition] = useState(false);
  const buttonRef = useRef(null);
  const { permissions } = usePermissions();
  const redirect = useRedirect();

  const buttonClass = {
    width:'100%',
    fontSize: '1em',
    textTransform: 'none',
    padding:'10px 25px',
    color:'white',
    fontWeight: '600',
    justifyContent: 'flex-start',
    alignItems: 'center',
    right: 0,
    position: 'relative',
    animation: transition && `${easeInLeft} 500ms ease`,
    top: 0,
    border:'none',
    '&:hover': {backgroundColor:palette?.secondary,border:'none',}
  };

  const openInviteTalentModal= () => {
    setTransition(true);
    setOpen(false);
  };

  return(
    <>
      {permissions !== 'talent_manager' && (
        <div ref={buttonRef} > 
          <Button onClick={() => setOpen(true)} 
            style={{
              padding: '10px 15px',
              borderRadius: '10px',
              color: '#fff',
              backgroundColor: palette?.primary,
              fontWeight: 500,
              lineHeight: '1.75',
              width: '100px',
              textTransform: 'capitalize',
              fontSize: '16px',
            }} 
            hoverStyle={{backgroundColor:palette?.secondary}}
          >
            <span>+ Add</span>
          </Button>
          <Popover
            sx={{
              '& .MuiPaper-root': {
                right: '40px',
                width: '18em',
              },
              '& .MuiPopover-paper': {
                borderRadius: '10px',
                marginTop: '10px',
              }
            }}
            open={open}
            anchorEl={buttonRef.current}
            onClose={() => setOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}

          >
            <div style={{display:'flex',flexDirection:'column',backgroundColor:palette?.primary,borderRadius:'10px'}}>
              <Button 
                onClick={() => redirect('create', 'talents')} 
                style={buttonClass}
                hoverStyle={{backgroundColor:palette?.secondary,border:'none'}}
              >
                <CreateIcon sx={{height: '20px', width: '20px',marginRight:'8px'}} />
                Create talent from scratch
              </Button>
              <Button 
                onClick={openInviteTalentModal} 
                style={buttonClass} 
                hoverStyle={{backgroundColor:palette?.secondary,border:'none'}}>
                <PersonAddAlt1Icon sx={{height: '20px', width: '20px',marginRight:'8px'}} />
                Invite a talent 
              </Button>
            </div>
          </Popover>
          <Modal
            open={transition}
            onClose={() => setTransition(false)}
          >
            <Box
              sx={modalBoxStyle}
            >
              <SimpleTalentForm setOpen={setOpen} setTransition={setTransition} transition={transition} />
            </Box>
          </Modal>
        </div>
      )}
    </>
  );


};