import { Show, useRecordContext } from 'react-admin';
import ShowTemplate from '../../../Components/ShowComponents/ShowTemplate';
import SmallCardComponents from '../../../Components/ShowComponents/SmallCardComponent';
import { dateFormat } from '../../../helpers/date';
import { AsideActions } from '../../../Components/ShowComponents/AsideActions/AsideActions';
import { BsBodyText } from 'react-icons/bs';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AlarmIcon from '@mui/icons-material/Alarm';

const ShowNotifications = () => {
  const record = useRecordContext();
  return(
    <>
      <Show
        title={'Notifications'} 
        aside={null} 
        actions={null}
      >
        <ShowTemplate
          recordTitle="title"
        >
          <NotificationInfo />
        </ShowTemplate>
      </Show>
    </>
  );
};

export const NotificationInfo = () => {
  const record = useRecordContext();

  const data = [
    {key: 'Body', value : (record?.body), icon: <BsBodyText />},
    {key: 'Status', value : (record?.status), icon: record?.status === 'sent' ? <CheckCircleOutlineIcon /> : <ReportGmailerrorredIcon />},
    {key: 'Date', value : dateFormat(record?.date_created), icon: <CalendarMonthIcon />},
    {key: 'Sent Notifications', value : record?.notifications_sent, icon: <AlarmIcon />},
  ];

  return(
    <SmallCardComponents cards={data}/>
  );
};

export default ShowNotifications;

