import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';


export const FormTitle = ({sx, children})=> {
  const palette = useSelector((state) => state?.palette?.palette);

  return (
    <Typography 
      sx={{
        fontSize: '16px',
        // letterSpacing: '2px',
        color: palette?.primary,
        fontWeight: '600',
        ...sx
      }} 
      variant='subtitle2'
    >
      {children}
    </Typography>
  );
};