import {
  useDataProvider,
  useRefresh,
  usePermissions,
} from 'react-admin';

import AdaptableFormRedesign from '../../Components/AdaptableForm/AdaptableFormRedesign';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { usePosition } from '../../Hooks/usePosition';
import { progressBarFieldsEvents } from '../../assets/constants/RequiredFields';

export const SingleEventForm = ({ item, data, index = 0, isEdit, editSub,deleteResource, ...rest }) => {

  const permis = usePermissions().permissions;
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const customToast = useCustomToasty();

  const isTbEditSubbmission = permis !== 'talent_manager' && editSub && isEdit;
  const isTmEditMain = permis === 'talent_manager' && ['rejected', 'draft', 'pending'].includes(item?.submission?.status) && !editSub;
 
  const handleUpdateEvent = async (newData) => {
    const newDataForTM= {
      ...newData,
      talents_ids: item?.talent_id,
      collaborators_ids: item?.collaborators_ids,
    };
    try {
      let request = await dataProvider.update('events', { id: item.id, data:permis === 'talent_manager'? newDataForTM : newData });
      if (request.data) {
        rest?.onSave && rest?.onSave();
        customToast('success', 'Event updated succesfuly');
        refresh();
      }
    } catch (error) {
      customToast('error', error);

      throw error;
    }
  };

  return (
    <AdaptableFormRedesign
      resetStore  
      index={index}
      draft="events"
      record={
        isTbEditSubbmission || isTmEditMain ? {...item, ...item?.submission} : item
      }
      simpleform={
        isEdit ? 
          { 
            record: 
            (isTbEditSubbmission || isTmEditMain) ? {...item, ...item?.submission} : item,
            onSubmit: handleUpdateEvent
          }
          :undefined}
      data={data}
      from={'talents'}
      isEdit={isEdit}
      showProgress
      saveBackground={true}
      showDelete
      deleteResource={deleteResource}
      idToaction={rest.idToaction}
    />
  );
};
