import { Box,Typography,LinearProgress } from '@mui/material';
import React from 'react';
import { Edit, usePermissions, useRecordContext, useResourceContext } from 'react-admin';
import { ListTabs } from '../../Resources/Talents/Form/TalentsListTabs/ListTabs';
import { NavbarTitle } from '../RedesignComponents/NavbarTitle';
const EditFormCustom = ({handleForm, children, tabs, actionText ,defaultTab = 'profile', ...rest }) => {
  const resource = useResourceContext();  
  const { permissions: permis } = usePermissions();
  return (
    <Edit actions={false} redirect='list' mutationMode={'pessimistic'} {...rest} sx={{'& .RaEdit-noActions':{
      margin:0
    }, '& .MuiPaper-root':{boxShadow:'none'}}}>
      <div style={{elevation: 0,boxShadow:'none',padding:'0px 16px',marginTop:20}}>
        {permis !== 'talent_manager' && (
          <div style={{display:'flex', flexDirection: 'row'}}>
            <NavbarTitle actionText={actionText} isEdit={true}/>
          </div>
        )}
        {
          tabs && (
            <ListTabs 
              defaultTab={defaultTab} 
              handleForm={handleForm} 
              tabs={tabs} />
          )
        }
        {children}
      </div>
    </Edit>
  );
};

export default EditFormCustom;