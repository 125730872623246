import React, { useState } from 'react';
import { required, useGetList } from 'react-admin';
// Components
import VenueMap from './Map/VenueMap';
import { InputSubtitle } from '../../../Components/StyledComponents/InputSubtitle';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import { InputTitle } from '../../../Components/StyledComponents/InputTitle';
import InputText from '../../../Components/InputsForm/InputText';
import { InputSwitch } from './Map/SwitchInput';
import { InputRichTextCountdown } from '../../../Components/InputsForm/InputRIchTextCountdown';
import InputTags from '../../../Components/RedesignComponents/Inputs/InputTags.jsx';
// Material & Icons
import { Box, Typography } from '@mui/material';
import { LOCATIONS_TOOLTIPS } from '../../TalentForm/Tooltips';
import { InputPillsSelect } from '../../../Components/RedesignComponents/Inputs';
import { InputSelectTimes } from '../../../Components/InputsForm/OpenTimesComponents/InputSelectTimes';
import { ColorPickerTextField } from '../../../Components/InputsForm/InputColorPicker';
import InputBoolean from '../../../Components/InputsForm/InputBoolean';
import { useFormContext } from 'react-hook-form';
import { InputImage } from '../../../Components/InputsForm/InputImage.jsx';

const FormVenues = ({ isEdit, from }) => {
  const [mapOn, setMapOn] = useState(true);
  const { data: typesData } = useGetList('locations/categories', {
    pagination: { page: 1, perPage: 100 },
  });
  const data = [
    {
      title: 'LOCATION DETAILS',
      inputs: [
        {
          field: (
            <InputText
              required={true}
              fullWidth
              key={Math.random()}
              source="name"
              title={'Name'}
              label={''}
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <Box key="description">
              <InputTitle
                fontWeight={600}
                fontSize={'16px'}
                color={'#000'}
                required={true}
              >
                DESCRIPTION
              </InputTitle>
              <InputSubtitle sx={{ minHeight: '0px' }}>
                Describe thoroughly your location
              </InputSubtitle>
              <InputRichTextCountdown
                label=" "
                fullWidth
                key={Math.random()}
                source="description"
                rows={10}
                multiline
              />
            </Box>
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputPillsSelect
              choices={typesData && typesData}
              withText={true}
              source="category"
              label=""
              title={'category'}
              subtitle={'Select your location category'}
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputTags
              title="Tags"
              subtitle={LOCATIONS_TOOLTIPS['tags']}
              source="tags"
              label=""
              reference={'locations/tags'}
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputBoolean
              title="Event Ready"
              subtitle="If the location is event ready, it will appear in the calendar, available for event selection."
              label=""
              isColumn
              source="event_ready"
              sx={{
                alignItems: 'flex-start',
                '& .MuiFormControlLabel-root': {
                  marginLeft: '0px',
                },
              }}
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputImage
              title={'Image'}
              tooltip={LOCATIONS_TOOLTIPS['image']}
              source="picture"
            />
          ),
          breakpoints: { xs: 6 },
        },
      ],
    },
    {
      title: 'Map',
      inputs: [
        {
          field: (
            <>
              <InputTitle value={'Map Location'} />
              <VenueMap source={'location.geometry.coordinates'} />
            </>
          ),
          source: ['location', 'brief_description', 'icon'],
          breakpoints: { xs: 12 },
        },
      ],
    },

    {
      title: 'Open times',
      inputs: [
        {
          field: <InputSelectTimes source={'open_times'} />,
          breakpoints: { xs: 12 },
        },
      ],
    },
    {
      title: 'Palette',
      subtitle:
        'Choose the background and font colors that will be visible in your calendar view',
      inputs: [
        {
          field: <StageReadyPalette />,
          source: 'palette',
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];
  return (
    <AdaptableFormRedesign
      isEdit={isEdit}
      from={from}
      data={data}
      mapOn={mapOn}
      saveBackground={true}
      showDelete
    />
  );
};

export default FormVenues;

export const StageReadyPalette = () => {
  const methods = useFormContext();

  return (
    <>
      {methods.watch('event_ready') ? (
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
          <ColorPickerTextField
            title="Background Color"
            source="palette.solid"
            hexFormat
          />
          <ColorPickerTextField
            title="Light"
            source="palette.light"
            hexFormat
          />
          <ColorPickerTextField
            title="Medium"
            source="palette.medium"
            hexFormat
          />
          <ColorPickerTextField
            title="Font color"
            source="palette.font_color"
            hexFormat
          />
        </div>
      ) : (
        <Typography
          sx={{
            fontSize: '.9em',
            fontStyle: 'italic',
          }}
        >
          Palette is only available for event ready locations
        </Typography>
      )}
    </>
  );
};
