import { InputAddSets } from './Form/Components/InputAddSets';
import { useDataProvider, useDelete, useGetList } from 'react-admin';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { FormComponents } from '../HomeComponents/FormComponents';
import { Box, Grid, TextField } from '@mui/material';
import { Spinner } from '../../Components/Spinner';
import { interestsForm } from './Form/InterestsForm';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { reorderRank } from './Form/Components/functions';
import axios from 'axios';
import { EditInterestTitle } from './Form/Components/EditInterestTitle';

export const DEFAULT_VALUES = {
  key: 'default',
  title: 'Title',
  subtitle: 'Subtitle'
};

const handleInitialInterests = (data) => {
  let value = reorderRank(data.data);
  if (value) return value;
  return [DEFAULT_VALUES];
};

export const InterestList = () => {
  const customToast = useCustomToasty();
  const dataProvider = useDataProvider();
  const formData = interestsForm();
  const [selectedForm, setSelectedForm] = useState(0);
  const [interests, setInterests] = useState([]);
  const resourceProp = 'interests';
  const [deleteOne, { isPending }] = useDelete();

  const { data: info, isFetching, refetch } = useGetList('interests', {}, {
    onSuccess: (res) => {
      const arr = handleInitialInterests(res);
      setInterests(arr);
    },
    onError: (err) => console.log('Error fetching interests:', err)
  });

  const updateManyRank = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_BASE_PATH}/interests/rank`,
        data: {
          interests: interests?.map(item => { return {id: item.id, rank: item.rank};})
        },
        method: 'PUT',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'content-range',
          'Authorization': 'Bearer ' + localStorage.getItem('auth'),
        }
      });
      if(response.data) {
        customToast('success', 'Sets ranked saved.');
      }
    } catch (error) {
      customToast('error', 'there was an error');
    }
  };

  const addInterest = () => {
    setInterests((f) => [...f, {
      title: `${DEFAULT_VALUES.title}${f?.length}`,
      interests: [
        {
          title: 'New Interests',
          options: [],
          multiselect: false
        }
      ],

    }]);
    setSelectedForm(interests?.length);
  };

  const handleOrder = (arr) => {
    const orderedArr = arr?.map((item, index) => {
      item.rank = index;
      return item;
    });
    setInterests(orderedArr);
  };

  const handleEditTitle = (e, index) => {
    const value = e.target.value;
    setInterests((f) => {
      return f?.map((item, i) => {
        if (i === index) {
          return { ...item, title: value };
        }
        return item;
      });
    });
  };

  const onSubmit = async (data) => {
    if(!data.id) {
      try {
        const response = await  dataProvider.create(resourceProp, { data: data});
        if(response.data) {
          customToast('success', 'Successfuly created new interest');
          refetch();
          return;
        }
      } catch (error) {
        customToast('error', 'error');
      }
    } else{

      try {
        const response = await  dataProvider.update(resourceProp, { id: data.id,  data: data});
        if(response.data) {
          customToast('success', 'Changes saved.');
          refetch();
          return;
        }
      } catch (error) {
        customToast('error', 'error');
      }
    }
  };

  // Por alguna razón si queres poner el setSelectedForm adentro del onDelete, no se ejecuta.
  // Hay que realizar un rerendering del componente al cambiar un estado dentro del onDelete, si se encuentra una mejor manera obvio refaccionar.
  // Ya se intento poniendo el cambio de form dentro del onDelete del DraggableSet, sin exito.

  
  const [itDeletes, setItDeletes] = useState(false);
  useEffect(() => {
    if(itDeletes) {
      setSelectedForm(prevState => prevState - 1);
    }
    return () => {
      setItDeletes(false);
    };
  }, [itDeletes]);

  const onDelete = async (item, index) => {

    if(!item?.id){
      let arr = interests.filter((item) => Object.prototype.hasOwnProperty.call(item, 'id'));
      setInterests(arr);
      setItDeletes(true);
      return;
    } else {
      try {
        const response = await deleteOne('interests',{id:item.id, previousData: item});
        // Corregir response del back
        if(response.data){
          customToast('success', 'Interest Deleted Succesfuly');
          refetch();
        }
      } catch (error) {
        customToast('error', 'Error, please try again later.');
      
      }
    }
  };

  const props = useMemo(() => ({
    section: interests?.[selectedForm],
    info: interests?.[selectedForm],
    sets: interests,
    onClick: setSelectedForm,
    setSets: handleOrder,
    noShow: true,
    selectedSet: selectedForm,
    addSets: addInterest,
    resourceProp,
    objKey: resourceProp,
    onDelete: onDelete,
    onReorder: updateManyRank,
    isDeleting: isPending
  }), [interests, selectedForm]);

  return (
    <Grid mx={2} container direction="row">
      {!isFetching ? (
        <Box sx={{display: 'flex', width: '100%', alignItems: 'flex-start', gap: '20px', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Grid item xs={3} align="center">
            <InputAddSets 
              {...props} 
            />
          </Grid>
          <Grid item xs={9}>
            {
              !isNaN(selectedForm) && (
                <FormComponents 
                  {...props} 
                  data={formData}
                  onSubmit={onSubmit}
                  xs={12} 
                  placement="left-bottom"
                  titleValue={info?.[selectedForm]?.title} 
                  EditInputs={EditInterestTitle}
                  onEditChange={handleEditTitle} 
                  index={selectedForm}
                />
              )
            }
          </Grid>
        </Box>
      ) : (
        <Spinner />
      )}
    </Grid>
  );
};

