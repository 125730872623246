import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { CollapseGridSection, GridSection } from './GridComponents/GridComponents';
import { useResourceContext } from 'react-admin';
import { useSelector } from 'react-redux';

function filterArray(arr1, arr2) {
  return arr1.filter(item => arr2.includes(item));
}

function findInputsInSchema(data, schema, arr) {
 
  let inputs = data;
  let correctInputs = [];
  for (let i = 0; i < schema.length; i++) {
  
    const foundItems = data.inputs?.filter(item => {

      //Modular esto para no ir leyendo tantas opciones dentro de un input de react admin.
      let firstSource = item.field.props.source;
      let secondSource = item.field.props.children?.props?.source;
      let thirdSource = item.field.props.children?.props?.key;
      let fourthSource = item.field.props.key;
      let fifthSource = item.field.key;
      let sixthSource = item.source; // Esta es la mejor opción

      let arr = [firstSource, secondSource, thirdSource, fourthSource, fifthSource];
      if(
        arr?.includes(schema[i]) || (
          Array.isArray(sixthSource) ? 
            !!sixthSource?.find(source => source.includes(schema[i])) : 
            sixthSource?.includes(schema[i])
        )
      ) return item;
    });
    if(foundItems.length > 0) {
      foundItems.map(item => correctInputs.push(item));
    }
  }
  inputs = {
    ...inputs,
    inputs: filterArray(data.inputs, correctInputs)
  };

  if(inputs.inputs.length > 0) {
    return inputs;
  } else {
    return undefined;
  }
}

export const EXCLUDED_SCHEMAS = ['festivals', 'homeComponents', 'notifications','popups', 'schedule', 'admins','more', 'on_boarding', 'interests'];

export const SchemaGridSection = (props) => {

  const {
    data,
    indexFormCount,
    formCount,
  } = props;
  const resource = useResourceContext();

  const schema = useSelector((state) => {
    if(EXCLUDED_SCHEMAS.includes(resource)) return false;
    return [...state.data.schemas[resource].base, ...state.data.schemas[resource].additional];
  });


  const schemaResults = useMemo(() => {
    let arr = [];
    if(!schema || EXCLUDED_SCHEMAS.includes(resource)) return data;
    
    for (let j = 0; j < data.length; j++) {
      if(!data[j].inputs){
        arr.push(data[j].inputs);
        continue;
      } else {
        let foundInputs = findInputsInSchema(data[j], schema, arr);
        if(foundInputs) arr.push(foundInputs);
      }
    }
    return arr;
    
  }, [schema, data]);

  return (
    <>
      {schemaResults?.map((item, index) => {
        if(item.custom) {
          return React.cloneElement(item.component);
        }
        if(item?.card) return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: '#d8d8d8',
              width: '100%',
              padding: 2,
              height: 'auto',
              borderRadius: 1
            }}>
            <Typography sx={{fontWeight: '300', fontSize: '14px', lineHeight: '23.8px'}}>{item.text}</Typography>
          </Box>
        );
        if (item?.noShow) return <div></div>;
        if (item?.title === 'submission') return <div style={{height:'4rem',display:'flex',justifyContent:'flex-end',flexWrap:'wrap',alignContent:'flex-start'}}>{item?.inputs[0]?.field}</div>;
        if (item.collapse) return <CollapseGridSection item={item} data={schemaResults} index={index} indexFormCount={indexFormCount} formCount={formCount} />;
        return <GridSection item={item} data={schemaResults} index={index} indexFormCount={indexFormCount} formCount={formCount} {...props} />;
      })}
    </>
  );
};
