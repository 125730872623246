export const TYPES_ACTIVITIES = [
  { id: 1, text: 'tour'},
  { id: 2 , text: 'food & drinks'},
  { id : 3, text: 'adventures'},
  { id : 4, text: 'wellness'},
  { id: 5, text: 'nature'}
];
//Hasta que el backend devuelva los TYPES, luego borrar
export const TYPES = [
  'Adventure',
  'Food & drinks',
  'Nature',
  'Tour',
  'Wellness'
];
//Notifications deep linking options
export const DEEP_LINKING_OPTIONS = [
  { id: 0, text: 'Events'},
  { id: 1, text: 'Talents'},
  { id: 2, text: 'Articles'},
  { id: 3, text: 'Locations'},
  { id: 4, text: 'More'},
  { id: 5, text: 'Excursions'},
  { id: 6, text: 'FAQs'},
];