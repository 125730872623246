import { Typography } from '@mui/material';
export const stepsJoy = [
  {
    content: (
      <>
        <Typography variant="body1" fontWeight={400} gutterBottom>
        This tool will help you and your team organize and manage your multi-day-event by providing you with tools to schedule your events, manage your talent, and create content for your app.
        </Typography>
        <Typography variant="body1" fontWeight={400} >
        Press <span style={{fontWeight: 'bold'}}>next</span> to start the tour of your backoffice.
        </Typography>
      </>
    ),
    title: (
      <Typography fontWeight={500} sx={{ pt: 2, fontSize:'1.25rem' }}>
        Welcome to your RaveUp backoffice!
      </Typography>
    ),
    placement: 'center',
    target: 'body',
  },
  {
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
        This is your sidebar. Use it to navigate the backoffice, you will find everything you need here. 
      </Typography>
    ),
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
        Sidebar
      </Typography>
    ),
    local: { skip: <strong>SKIP</strong> },
    placement: 'right',
    target: '#sidebarBox',
  },
  {
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
          Talent
      </Typography>
    ),
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
       This section will help you manage all your talent, and content related to them. You can approve/reject talent submissions made by talent managers, create talents and edit their information
      </Typography>
    ),
  
    local: { skip: <strong>SKIP</strong> },
    placement: 'bottom',
    target: '#Talent',
  },
  {
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
        Events
      </Typography>
    ),
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
        The event section works for planning your multi-day-event. Use our calendar to drag already created events and organize your schedule, or schedule events as you create them. 
      </Typography>
    ),
    local: { skip: <strong>SKIP</strong> },
    placement: 'bottom',
    target: '#Event',
  },
  {
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
        Locations
      </Typography>
    ),
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
        Create the locations of your event, place them on the map and establish their open/close times. 
      </Typography>
    ),
    local: { skip: <strong>SKIP</strong> },
    placement: 'bottom',
    target: '#locations',
  },
  {
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
        Articles
      </Typography>
    ),
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
        Upload rich-text articles to your app to inform users about relevant issues
      </Typography>
    ),
    local: { skip: <strong>SKIP</strong> },
    placement: 'bottom',
    target: '#articles',
  },
  {
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
        FAQ’s
      </Typography>
    ),
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
        Upload relevant FAQ’s for your event and solve all your attendee’s doubts.
      </Typography>
    ),
    local: { skip: <strong>SKIP</strong> },
    placement: 'bottom',
    target: '#faqs',
  },
  {
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
       App Home Screen
      </Typography>
    ),
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
        Customize the homepage of your app, select different home components and edit them. Choose what you want your users to see when they land in your app.
      </Typography>
    ),
    local: { skip: <strong>SKIP</strong> },
    placement: 'bottom',
    target: '#homeComponents',
  },
  {
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
       App More Screen
      </Typography>
    ),
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
        Select what you want your users to access from your app’s more page
      </Typography>
    ),
    local: { skip: <strong>SKIP</strong> },
    placement: 'bottom',
    target: '#more',
  },
  {
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
         App Onboarding
      </Typography>
    ),
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
          Customize the onboarding of your app, select the titles, the actions and the images a user will see the first time they open your app. 
      </Typography>
    ),
    local: { skip: <strong>SKIP</strong> },
    placement: 'bottom',
    target: '#on_boarding',
  },
  {
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
       Push Notifications
      </Typography>
    ),
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
        Send notifications to your app users to alert them about important announcements.
      </Typography>
    ),
    local: { skip: <strong>SKIP</strong> },
    placement: 'bottom',
    target: '#notifications',
  },
  {
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
       Users
      </Typography>
    ),
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
        Create roles and assign them to new users. Manage who accesses your backoffice. 
      </Typography>
    ),
    local: { skip: <strong>SKIP</strong> },
    placement: 'bottom',
    target: '#admins',
  },
  {
    title: (
      <Typography
        variant="subtitle1"
        gutterBottom
        fontWeight={500}
        alignSelf={'flex-start'}
      >
       Festival Settings
      </Typography>
    ),
    content: (
      <Typography
        variant="body1"
        fontWeight={400}
        alignSelf={'flex-start'}
        gutterBottom
      >
        Fully control your festival. Make important configurations that will define the structure of your app.
      </Typography>
    ),
    local: { skip: <strong>SKIP</strong> },
    placement: 'bottom',
    target: '#festivals',
  },
];