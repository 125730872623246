import React, { useEffect } from 'react';
import { DateInput, DateTimeInput } from 'react-admin';
import { INPUTS_VARIANT } from '../../assets/constants/Form';
import { InputTitle } from '../StyledComponents/InputTitle';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { useUtcFormat } from '../../helpers/date';

const InputDate = ({ title, inputTime, ...rest }) => {
  const { getValues, setValue} = useFormContext();
  let startDate = getValues()?.['start_date'];
  let duration = getValues()?.duration;
  const { formatFestivalTime } = useUtcFormat();
  useEffect(() => {
    if(startDate && duration) {
      setValue('end_date', formatFestivalTime(dayjs(startDate).add(duration, 'minutes')));
    }
  },[startDate,duration]);

  if (inputTime) {
    return (
      <>
        {title && <InputTitle>{title}</InputTitle>}
        <DateTimeInput 
          variant={INPUTS_VARIANT}
          {...rest} 
          InputProps={{
            sx: {
              borderRadius: '10px'
            }
          }}
        />
      </>
    );
  }
  else {
    return (
      <>
        {title && <InputTitle>{title}</InputTitle>}

        <DateInput variant={INPUTS_VARIANT} {...rest} />
      </>
    );
  }
};

export default InputDate;