import React, { useState } from 'react';
import styles from './IconButton.module.css';

const IconButton = ({children, icon, onClick, tooltip, disabled, style={},hoverStyle={},...rest}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const mergedStyle = {
    ...style,
    ...(isHovered ? hoverStyle : {}),
  };
  return (
    <button
      className={styles.iconButton}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      aria-label={tooltip}
      style={mergedStyle}
      disabled={disabled}
      type='button'
      {...rest}
    >
      {icon}
      {children}
    </button>
  );
};

export default IconButton;