import { useEffect, useState } from 'react';
import { Show, useRecordContext } from 'react-admin';
import { LastSubmitter } from '../Talents/Form/TalentsShowComponents/LastSubmitter';
import ShowTemplate from '../../Components/ShowComponents/ShowTemplate';
import { EventsCard } from '../Talents/Form/TalentsShowComponents/EventosCard';
import { ShowProvider } from '../../Components/ShowComponents/ShowProvider';
import { ReviewActions } from '../../Components/RedesignComponents/ReviewActions';
const PostTitle = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <span>Talent - {record.name}</span>;
};

const EventsShow = () => {
  useEffect(() => {
    localStorage.setItem('events', 'pending');

  }, []);
  const [switchBody, setSwitchBody] = useState(false);

  return (
    <div >
      <Show
        actions={null}
        title={<PostTitle />}
      >
        <ShowTemplate
          from='events'
          switchBody={switchBody}
          recordTitle={'name'}
          AsideActions={<ReviewActions switchBody={switchBody} setSwitchBody={setSwitchBody} draft/>}
          setSwitchBody={setSwitchBody}
          Submitter={LastSubmitter}
        >
          <ShowProvider switchBody={switchBody}>
            <EventsCard />
          </ShowProvider>
        </ShowTemplate>
      </Show>
    </div>
  );
};

export default EventsShow;
