import { defaultTheme } from 'react-admin';
import { COLORS } from '../assets/constants/theme';
import '../assets/fonts/fonts.css';
import { createTheme } from '@mui/material';

export const mainColor = '#2E7477';
export const appColor = '#F7E4C2';
export const errorColor = '#f99190';

export const breakpoints = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 1800,
    },
  },
});

export const theme = {
  typography: {
    // ...defaultTheme.typography,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      'Moret-Book',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    // ...defaultTheme.components,
    RaShow: {
      styleOverrides: {
        root: {
          backgroundColor: '#f0f0f0'
        }
      }
    },
    // MuiFormControl: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#f5f5f5'
    //     }
    //   }
    // },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          backgroundColor: '#fff',
          borderRadius: '10px'
        },
        root: {
          borderRadius: '10px'
        },
        // root: {
        //   padding: 0,
        // }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderRadius: '10px'
        }
      }
    },
    // MuiFormHelperText: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#f5f5f5'
    //     },
    //   }
    // },
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: '#f5f5f5',
    //     },
    //   }
    // },
    // MuiInputBase: {
    //   styleOverrides: {
    //     root: {
    //       padding: 0,
          
    //     }
    //   }
    // },
    // RaLayout: {
    //   defaultProps: {
    //     backgroundColor: '#f5f5f5'
    //   }
    // },
    // MuiPaper: {
    //   styleOverrides: {
    //     root: {
    //       boxShadow:'none'
    //     }
    //   }
    // },
    
    RaListToolbar: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontSize: '.9rem',
          borderTopLeftRadius: '0px',
          borderTopRightRadius: '0px'
        },
        input: {
          paddingTop: '18px',
          paddingRight: '3px',
          paddingBottom: '3px',
        }
      }
    },
    RaAutocompleteInput: {
      styleOverrides: {
        root: {
          '& .MuiFilledInput-root': {
            paddingTop: '15px'
          }
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '.9rem',
        }
      }
    },

  }
};

export const lightTheme = {
  ...defaultTheme,
  palette:{
    mode: 'light',
    ...COLORS.light
  },
  ...theme
};

export const darkTheme = {
  ...defaultTheme,
  palette:{
    mode: 'dark',
    ...COLORS.dark
  },
  ...theme
};

// Para los modales
export const styleAbsolute = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
export const styleFlex = {
  display:'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p:'10px 16px'
};