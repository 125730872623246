import {
  useCreate,
  usePermissions,
  useRedirect,
  useRefresh,
  useResourceContext,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useCustomToasty } from '../../../Hooks/useCustomToasty';
import { EXCLUDED_SCHEMAS } from '../SchemaGridSection';
import { putRequest, talentRequests } from './requests';
import {
  inputErrorMessage,
  loopObjErrors,
} from '../../../helpers/inputErrorMessage';

export const submitFunctions = (props) => {
  const { permissions: permis } = usePermissions();
  const resource = useResourceContext();
  const refresh = useRefresh();
  const customToast = useCustomToasty();
  const { getValues, clearErrors, handleSubmit } = useFormContext();
  const redirect = useRedirect();
  const [create] = useCreate();
  const schema = useSelector((state) => {
    if (EXCLUDED_SCHEMAS.includes(resource)) return false;
    return [
      ...state.data.schemas[resource].base,
      ...state.data.schemas[resource].additional,
    ]; // camibar por required
  });

  const handleData = async (props) => {
    const { simpleform: { onSubmit } = {}, isEdit } = props;
    let draftData = getValues();
    const isTalent = resource === 'talents';
    const isTalentDraft = Boolean(
      isTalent &&
        !!draftData?.['_draft'] &&
        !!draftData.name &&
        !!draftData.category
    );
    const handlers = {
      customToast,
      refresh,
      clearErrors,
      setLoading: props.setLoading,
      onSuccess: props.onSuccess,
      redirect,
    };
    const method = isEdit ? 'PUT' : 'POST';

    const clearErrorsProps = {
      isTalentDraft: draftData?.['_draft'] ?? false,
      permis,
      resource,
      clearErrors,
      schema,
      isDraft: props?.isDraft,
    };
    // Aca le agregue esta conversion a Booleano ya que de por si pasandole onSubmit
    // al switch nunca lo detectaba como true, a pesar de que si existia como prop.
    const onSubmitActive = Boolean(onSubmit);
    switch (true) {
    //En case que se haga un put/post de talentos donde draft sea false (que se publique)
    case isTalent && !isTalentDraft:
      return await handleSubmit(
        (data) =>
          talentRequests(
            data,
            method,
            handlers,
            isTalentDraft,
            clearErrorsProps
          ),
        (err) => validationErrors(err, customToast, isEdit, clearErrorsProps)
      )();

      // Siempre y cuando sea un put a talentos con el fin de editarlo SIN publicarlo, se realiza una lógica adicional eliminando las validaciones.
    case isTalent && isTalentDraft:
      return await talentRequests(
        draftData,
        method,
        handlers,
        isTalentDraft,
        clearErrorsProps
      );

      // Create común para todos los resource
    case !isEdit:
      return await handleSubmit(
        (data) =>
          create(
            resource,
            { data: data },
            {
              onSuccess: (res) => {
                customToast(
                  'success',
                  `The ${resource} has been succesfuly created.`
                );
                redirect('list', resource);
              },
              onError: (err) => {
                responseError(err, customToast);
              },
            }
          ),
        (err) => validationErrors(err, customToast, isEdit, clearErrorsProps)
      )();

      // Onsubmit custom pasado por props
    case onSubmitActive:
      return await handleSubmit(
        (data) => onSubmit(data),
        (err) => validationErrors(err, customToast, isEdit)
      )();

      // Put a cualquier resource por react admin
    default:
      return await handleSubmit(
        (data) => putRequest(data, { ...props }, customToast, refresh),
        (err) => validationErrors(err, customToast, isEdit, clearErrorsProps)
      )();
    }
  };

  const onError = ({ ...rest }) => {
    customToast('error', rest?.body?.detail);
  };

  return {
    handleData,
    onError,
  };
};

export const validationErrors = (
  err,
  customToast,
  isEdit,
  clearErrorsProps = {}
) => {
  let message;

  const { isTalentDraft, resource, clearErrors, schema } = clearErrorsProps;

  switch (true) {
  case resource === 'talents':
    message = inputErrorMessage(Object.values(err)[0].message);
    if (isTalentDraft) {
      const restErrors = schema.filter((item) => {
        if (!['name', 'category'].includes(item)) {
          return item;
        }
      });
      clearErrors(restErrors);
    }
    return customToast(
      'error',
      message ?? 'Some fields are missing, please check the form.'
    );
  case !isEdit:
    message = inputErrorMessage(Object.values(err)[0].message);
    return customToast(
      'error',
      message ?? 'Some fields are missing, please check the form.'
    );

  default:
    if (Object.values(err).length > 1)
      return customToast(
        'error',
        'Some fields may be missing, please check and try again.'
      );
    return customToast(
      'error',
      inputErrorMessage(loopObjErrors(Object.values(err)[0])) ??
          'Theres an error in the form, please check again.'
    );
  }
};

export const responseError = (obj, customToast) => {
  
  if (typeof obj === 'string') return customToast('error', obj);
  if (obj?.response?.data?.detail)
    return customToast('error', obj?.response?.data?.detail);
  return customToast('error', 'There was an error, please try again.');
};
