import { Box, ToggleButton, Typography } from '@mui/material';
import { useTiptapEditor } from 'ra-input-rich-text';
import Remove from '@mui/icons-material/Remove';
import { useFormContext } from 'react-hook-form';
import { handleSelectionChange } from '../BlogRenderer/helpers';
import ImageIcon from '@mui/icons-material/Image';
import { mainColor } from '../../Theme';
import BlogRenderer from '../BlogRenderer';


const InputCustomToolbar = ({inputRef, source, ...rest}) => {
  const editor = useTiptapEditor();
  const {getValues, setValue} = useFormContext();
  const cards ='[card][/card]';
  const imgs = '[img][/img]';

  function splitString(string, index) {
    var part1 = string.substring(0, index);
    var part2 = string.substring(index);
    return [part1, part2];
  }

  const handleCard = (tags) => {
    let value = getValues(source);
    let newValue = `${value ?? ''}<br />${tags}`;
    setValue(source, newValue);
    let index = tags.indexOf(']') + 1;
    let result = splitString(tags, index);
    setTimeout(() => handleSelectionChange(result[0], result[1], editor), 100);
  };
  return (
    <>
      <br />
      <Box style={{padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        <Typography style={{fontSize: '.8em', color: mainColor, textTransform: 'uppercase', fontWeight: '600'}}>Custom</Typography>
        <Box style={{display: 'flex', flexDirection: 'row'}}>
          <ToggleButton aria-label="Add an horizontal rule"
            title="Add a card tag"
            value="left"
            onClick={() => handleCard(cards)}
            selected={editor && editor.isActive('horizontalRule')}>
            <Remove fontSize="20px"/>
          </ToggleButton>
          <ToggleButton aria-label="Add an horizontal rule"
            title="Add a Image Tag"
            value="left"
            onClick={() => handleCard(imgs)}
            selected={editor && editor.isActive('horizontalRule')}>
            <ImageIcon fontSize="20px"/>
          </ToggleButton>
          <BlogRenderer />
        </Box>
      </Box>
    </>
  );
};

export default InputCustomToolbar;