import { Box, Typography } from '@mui/material';
import PropType from 'prop-types';
import EditModalButton from './EditModalButton';
import { ColorPickerTextField } from '../../../Components/InputsForm/InputColorPicker';
import { BOARDS } from '../boards';
import { GoDotFill } from 'react-icons/go';
import { useFormContext,} from 'react-hook-form';
import InputText from '../../../Components/InputsForm/InputText';
import { memo } from 'react';
import { useInput, useRecordContext } from 'react-admin';
import InputAutocomplete from '../../../Components/InputsForm/InputAutocomplete';



const TextBox = (props) => {
  const { source } = props;
  const { getValues, setValue } = useFormContext();
  const { onboarding } = getValues();

  const handleChangeAutocomplete = (value,record) => {
    if(value === ''){
      setValue(`onboarding.${source}.action`, '');
    }
  };

  return (
    <Box
      key="onboard_provider_text"
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: onboarding?.[source]?.box_background_color,
        borderRadius: '12px 12px 0px 0px',
        width: '100%',
        height: '270px',
        justifyContent: 'space-between'
      }}
    >
      <Box 
        mb={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: '15px',
          height: '100%',
          width: '100%'
        }}>
        <Box 
          mt={2}
          sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '15px'}}
        >
          <Box>
            {
              BOARDS.map((item) => 
                <GoDotFill color={onboarding?.[source]?.cta_background_color} />)
            }
          </Box>
          <Typography
            sx={{
              textAlign: 'center', 
              lineHeight: '25px', 
              fontWeight: '700', 
              fontSize: '24px', 
              color: onboarding?.[source]?.font_color,
              fontFamily: 'Open-sans'
            }}
          >
            { onboarding?.[source].title }
          </Typography>
        </Box>
        <Typography
          sx={{ 
            lineHeight: '15px', 
            color: onboarding?.[source].font_color,
            fontSize: '.85em',
            textAlign: 'center', 
            padding: '0 10px', 
            fontFamily: 'Open-sans'
          }}
        >
          { onboarding?.[source].description }
        </Typography>
        <Box sx={{
          position: 'relative',
          width: '80%', 
          height: '50px', 
          borderRadius: '5px', 
          display: 'flex', 
          flexDirection:'column', 
          alignItems: 'center', 
          minWidth: '200px',
          justifyContent: 'center',
          backgroundColor: onboarding?.[source]?.cta_background_color
        }}>
          <Typography 
            sx={{
              color: onboarding?.[source]?.cta_color,
              fontSize: '1.1em',
              fontWeight: '700',
              fontFamily: 'Open-sans'
            }
            }>
            { onboarding?.[source]?.cta_text }
          </Typography>
          <EditModalButton 
            sx={{
              top: 20,
              right: 0
            }} 
            placement="top-right"
            EditInputs={EditButtonInputs}
            handleChangeAutocomplete={handleChangeAutocomplete}
            {...props} 
          />
        </Box>
      </Box>
      <EditModalButton
        sx={{
          top: 25, 
          right: 10
        }}
        placement="right" 
        EditInputs={EditInputs}
        {...props} />
    </Box>
  );
};

export default memo(TextBox);

TextBox.proptype = {
  source: PropType.string.isRequired
};


const EditInputs = ({source}) => (
  <Box>
    <ColorPickerTextField 
      sizeBox={{
        width: '200px'
      }} 
      hexFormat
      source={`onboarding.${source}.box_background_color`}
      title="Background Color" 
      label="" 
    />
    <ColorPickerTextField
      sizeBox={{
        width: '200px'
      }}
      hexFormat
      source={`onboarding.${source}.font_color`} 
      title="Font Color" 
      label=""  
    />
    <InputText
      fullWidth
      title="Title text"
      label="" 
      source={`onboarding.${source}.title`}
      counter={30}
    />
    <InputText 
      fullWidth 
      title="Description text" 
      multiline
      rows={3} 
      label=""
      source={`onboarding.${source}.description`}
      inputProps={{
        defaultValue: ''
      }}
      counter={160}
    />
  </Box>
);
  
const EditButtonInputs = ({ source, handleChangeAutocomplete }) => (
  <Box>
    <ColorPickerTextField
      hexFormat
      sx={{maxWidth: '100%'}} 
      source={`onboarding.${source}.cta_background_color`}
      title="Background Color" 
      label="" 
          
    />
    <ColorPickerTextField 
      hexFormat
      sx={{maxWidth: '100%'}} 
      source={`onboarding.${source}.cta_color`}
      title="Font color"
      label="" 
           
    />
    <InputText 
      fullWidth 
      title="Title text" 
      label="" 
      source={`onboarding.${source}.cta_text`}
      counter={15}
    />
    <InputAutocomplete
      label=''
      title='Action'
      sx={{'& .MuiInputBase-input': {
        height: '10px'
      }}}
      source={`onboarding.${source}.action`} 
      choices={[
        { id: 'next', name: 'Next' },
        { id: 'notifications', name: 'Notifications' },
        { id: 'locations', name: 'Locations' },
      ]} 
      onChange={handleChangeAutocomplete}
    />
  </Box>
);
