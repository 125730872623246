import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Create,SimpleForm, Toolbar, useDataProvider, useNotify, SaveButton, useRefresh } from 'react-admin';
import { useSelector } from 'react-redux';
// Hooks & Utils
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';
import { transformSlug } from '../../../../helpers/transforms';
import { apiUrl } from '../../../../dataProvider';
// Components
import { IconButton } from '../../../../Components/Generics';
import { SelectCategory } from './SelectCategory';
import { EventsComponent } from './EventsComponent';
import { EmailComponent } from './EmailComponent';
import { InputRequestField } from '../../../../Components/InputsForm/InputRequestField';
import { InputAddTextInputs } from './InputAddTextInputs';
// Material & Icons
import { Box, Tooltip, Typography } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { mainColor } from '../../../../Theme';


export const SimpleTalentForm = ({setOpen, setTransition, transition}) => {
  const [emails, setEmails] = useState([]);
  const [events, setEvents] = useState([]);
  const [checkVal, setCheckVal] = useState(false);
  const [created, setCreated] = useState(false);
  const [admins, setAdmins] = useState(false);
  const dataProvider = useDataProvider();
  const { handleSubmit, control, ...rest } = useForm({ mode: 'all'});
  const palette = useSelector(state => state?.palette?.palette);
  const notify = useNotify();
  const customToast = useCustomToasty();
  const refresh = useRefresh();
  const MyToolbar = () => (
    <Toolbar 
      sx={{
        backgroundColor:'transparent',
        display: 'flex',
        justifyContent: 'flex-end', 
      }}
    >
      <SaveButton
        alwaysEnable
        sx={{
          paddingX: '15px',
          paddingY: '10px', 
          borderRadius: '10px',
          color: '#fff',
          backgroundColor: palette?.primary,
          '&:hover':{
            backgroundColor: palette?.primary},
        }} 
        mutationOptions={{
          onSuccess: () => notify('Talent and emails created succesfuly', {type: 'success'}),
          onError: () => {
            notify('There where some errors', {type: 'error'});
          }
        }}
        label='Invite'
        icon={null}
      />
    </Toolbar>
  );

  const validations = () => {
    // logica adicional para validaciones
    return true;
  };

  const axiosFunction = async (route, body) => {
    const newBody= {
      ...body,
      talent_id: [body?.talent_id]
    };
    try {
      const response = await fetch(`${apiUrl}/${route}`, {
        method: 'POST',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'content-range',
          'Authorization': 'Bearer ' + localStorage.getItem('auth'),
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newBody)
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if(data.data) {
        customToast('success', `${route} was created succesfuly!`);

      }
    } catch (error) {
      customToast('error', `There was an error trying to create the ${route}, please try again.`);
      console.error('Error:', error);
      throw error;
    }
  };

  //Axios requests
  const requestMails = (name, email, id) => axiosFunction('admins',  {name: name, email: email, role: 'talent_manager', talent_id: id});
  const requestEvents = (name, duration, id) => axiosFunction('events',  {name: name, duration: duration, talents_ids: [id], collaborators_ids: [], tags: []});

  const onSubmit = async (data) => {
    if(validations()) {
      try {
        let updatedData = {
          name: data.name,
          category: data.category
        };
        if(!created) {
          try {
            const result = await dataProvider.create('talents', {data: {...updatedData, slug: transformSlug(data?.name)}});
            if(result.data){
              customToast('success', 'Talent was created succesfuly!');
              setTransition(false);
              setCreated(result.data);
              refresh();
              try {
                console.log('PRIMERA DE EMAILS');
                const emailRequest = await Promise.all(emails?.reduce((a, item, index) => {
                  return [...a, requestMails(item.value.name, item.value.email, result?.data?.id)];
                },[]));
                if(emailRequest) {
                  console.log('entro?');
                  setAdmins(true);
                  try {
                    const eventRequests = await Promise.all(events?.map(item => {
                      return requestEvents(item.value.name, item.value.duration, result?.data?.id);
                    }));
                    if(eventRequests){
                      setOpen(false);
                    }
                  } catch (error) {
                    throw new Error(error);
                  }
                }
              } catch (error) {
                throw new Error(error);
              }
            }
          } catch (error) {
            throw new Error(error);
          }
        } else {
          if(!admins) {
            try {
              console.log('SEGUNDA DE EMAILS');

              const emailRequest = await Promise.all(emails?.map(item => {
                return requestMails(item.value.name, item.value.email, created?.id);
              }));
              if(emailRequest) {
                setAdmins(true);

                try {
                  const eventRequests = await Promise.all(events?.map(item => {
                    return requestEvents(item.value.name, item.value.duration, created?.id);
                  }));
                  if(eventRequests.data){
                    setOpen(false);
                  }
                } catch (error) {
                  throw new Error(error);
                }
              }
            } catch (error) {
              throw new Error(error);
            }
          } else {
            try {
              const eventRequests = await Promise.all(events?.map(item => {
                return requestEvents(item.value.name, item.value.duration, created?.id);
              }));
              if(eventRequests) {
                setOpen(false);
              }
            } catch (error) {
              throw new Error(error);
            }
          }
        }


      } catch (error) {
        console.log('err');
        throw new Error(error);
      }
    } else {
      customToast('error', 'Some fields contain some errors, please check them.');
    }

  };
  
  const onError = (data,e) => {
    data && customToast('error', 'Some fields contain errors, please check and try again.');
    return;
  };


  return (
    <FormProvider {...rest}>
      <Create>
        <SimpleForm 
          onSubmit={handleSubmit(onSubmit, onError)}
          mode="onBlur" 
          toolbar={<MyToolbar />}
          sx={{width:'40em',padding:'2vw',display:'flex',flexDirection:'column','& .MuiStack-root':{
            gap:'20px'
          }}} 
        >
          <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between', width:'100%'}}>
            <Typography fontSize={'2em'}fontWeight={600}>Invite new talent</Typography>
            <IconButton onClick={() => setTransition(false)}><span style={{color:'black'}}>x</span></IconButton>
          </div>
          {/* NAME SECTION */}
          <div>
            <Typography sx={{fontSize: '1em', textTransform: 'uppercase', fontWeight: '600'}}>Talent Name</Typography>
            <Typography variant='subtitle2' fontWeight={300}>Enter the exact name you want to use on all our material</Typography>
            <InputRequestField control={control} resource="talents" fieldName="q" variant="outlined" sx={{width: '100%','& .MuiInputBase-root':{borderRadius:'10px'}}} name="name" label="Artists Name" />
          </div>
          {/* CATEGORY SECTION */}
          <div>
            <Typography sx={{fontSize: '1em', textTransform: 'uppercase', fontWeight: '600'}}>Category</Typography>
            <Typography variant='subtitle2' fontWeight={300}>Select the category for your talent</Typography>
            <SelectCategory events={events} setEvents={setEvents} control={control} name="category" />
          </div>
          {/* TALENT MANAGERS SECTION */}
          <div>
            <TooltipTitle 
              title="Talent Managers" 
              tooltip="Users that have permission to complete the talent’s profile, submit changes to the information and receive notifications regarding the talent information. When adding a Talent Manager, a user will be created for them and and they’ll receive an email to authenticate and access the platform." 
            />
            <InputAddTextInputs
              tooltip={ 'Please include an email that we can use to contact you for any extra information we may need. This will not be public. Remember that you will be able to update your information using this exact same email address'}
              element={<EmailComponent control={control} />} 
              checkVal={checkVal} 
              values={emails}
              setValues={setEmails}
              modify={'talent_managers'}
            />
          </div>
          {/*EVENTS SECTION  */}
          <div>
            <TooltipTitle title="Events" tooltip="Create the events name and its duration for the talents to edit later." />
            <InputAddTextInputs
              element={<EventsComponent control={control} />}
              checkVal={checkVal} 
              values={events} 
              setValues={setEvents}
            />
          </div>
        </SimpleForm>
      </Create>
    </FormProvider>
  );};
  
export const TooltipTitle = ({tooltip, title}) => {
  return (
    <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '2px'}}>
      <Typography sx={{fontSize: '1em', textTransform: 'uppercase', fontWeight: '600'}}>{title}</Typography>
      <Tooltip placement="top-start" arrow slotProps={{tooltip:{sx: {fontSize: '.9em', }}}} title={tooltip} >
        <Box sx={{display: 'flex', cursor: 'pointer', color: mainColor, borderRadius:'50%', padding: '5px', transition: 'background-color 500ms, color 250ms', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', alignItems: 'center', '&:hover' : {
          color: 'white', backgroundColor: 'rgba(0, 0, 0, 0.2)'
        }}}>
          <HelpOutlineIcon sx={{ height: '20px', width: '20px'}} />
        </Box>
      </Tooltip>
    </Box>
  );
};