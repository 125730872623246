import React, { useState } from 'react';
import styles from './Button.module.css';

const Button = ({
  children,
  onClick,
  variant = 'text',
  size = 'medium',
  color = 'default',
  disabled = false,
  style = {},
  hoverStyle = {},
  referencia,
  svgAction,
  svgOnClick,
  ...rest
}) => {
  const classNames = [
    styles.button,
    styles[variant],
    styles[size],
    styles[color],
    disabled ? styles.disabled : '',
  ].join(' ');
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const mergedStyle = {
    ...style,
    ...(isHovered ? hoverStyle : {}),
  };
  const handleSvgClick = (event) => {
    event.stopPropagation();
    if (svgOnClick) {
      svgOnClick();
    }
  };
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      ref={referencia}
      type="button"
      {...rest}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames}
      style={mergedStyle}
    >
      {children}
      {svgAction && (
        <>
          <div onClick={handleSvgClick}>
            <SvgX />
          </div>
        </>
      )}
    </button>
  );
};
const SvgX = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2524 17.1384L11.9207 13.4102L8.19245 16.7419C7.99469 16.9186 7.73483 17.0095 7.47003 16.9946C7.20523 16.9798 6.95719 16.8603 6.78046 16.6625C6.60374 16.4648 6.51281 16.2049 6.52769 15.9401C6.54256 15.6753 6.66201 15.4273 6.85977 15.2506L10.588 11.9189L7.25632 8.19062C7.0796 7.99286 6.98867 7.733 7.00355 7.4682C7.01842 7.2034 7.13787 6.95535 7.33563 6.77863C7.53339 6.60191 7.79325 6.51098 8.05805 6.52585C8.32285 6.54073 8.5709 6.66018 8.74762 6.85794L12.0793 10.5862L15.8076 7.25449C16.0053 7.07777 16.2652 6.98684 16.53 7.00171C16.7948 7.01659 17.0428 7.13604 17.2195 7.3338C17.3963 7.53156 17.4872 7.79142 17.4723 8.05622C17.4575 8.32102 17.338 8.56906 17.1402 8.74579L13.412 12.0775L16.7437 15.8057C16.9204 16.0035 17.0113 16.2633 16.9965 16.5281C16.9816 16.7929 16.8621 17.041 16.6644 17.2177C16.4666 17.3944 16.2068 17.4854 15.942 17.4705C15.6772 17.4556 15.4291 17.3362 15.2524 17.1384Z"
        fill="#D1D1D1"
      />
    </svg>
  );
};

export default Button;
