// Components
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
// Material & Icons
import { BannerItems } from './BannerItems.jsx';

export const BannerForm = () => {
  const data = [
    {
      inputs: [
        {
          field: <BannerItems 
            source="home.banner.items"
            limit={10}
          />,
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];

  return {
    data: data
  };
};