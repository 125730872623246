import React, { useState } from 'react';
import {
  regex,
  required,
  useRecordContext,
  ReferenceArrayInput,
} from 'react-admin';
// Hooks & Utils
import { useNavigate } from 'react-router';
import { EVENTS_TOOLTIPS } from '../../TalentForm/Tooltips';
// Components
import InputAutocomplete from '../../../Components/InputsForm/InputAutocomplete';
import InputText from '../../../Components/InputsForm/InputText';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import { InputTabSingleSelector } from '../../../Components/RedesignComponents/Inputs/InputSelectorRedesign';
// Material & Icons
import { Typography } from '@mui/material';

const FormAdmins = ({ isEdit, from }) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const [showTalentId, setShowTalentId] = useState(
    record?.role == 'talent_manager',
  );
  const emailRegex =
    /^(?:(?:[^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(?:"[^"]+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // const handleChange = (e) => {
  //   setShowTalentId(e.target.value == 'talent_manager');
  // };
  const data = [
    {
      title: 'INFORMATION',
      inputs: [
        {
          field: (
            <InputText
              required={true}
              fullWidth
              source="name"
              validate={[required('The name is required.')]}
              title={'Name'}
              label=""
            />
          ),
          breakpoints: { xs: 6 },
        },
        {
          field: (
            <InputText
              required={true}
              fullWidth
              source="email"
              validate={[
                required('The Email is required.'),
                regex(emailRegex, 'The email inserted is not a valid format.'),
              ]}
              title={'Email'}
              label=""
            />
          ),
          breakpoints: { xs: 6 },
        },
        {
          field: (
            <InputTabSingleSelector
              label=""
              title="Role"
              fullWidth
              source="role"
              resource="admins/roles"
              validate={[required('The role is required.')]}
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <>
              <ReferenceArrayInput source="talent_id" reference="talents">
                <InputAutocomplete
                  title={
                    <Typography
                      fontWeight={600}
                      fontSize={'16px'}
                      color={'#000'}
                      sx={{ marginTop: '25px' }}
                    >
                      Talent
                    </Typography>
                  }
                  subtitle={EVENTS_TOOLTIPS['talentNameCreate']}
                  freeSolo={false}
                  optionText="name"
                  isArray
                  label=""
                  fullWidth
                />
              </ReferenceArrayInput>
            </>
          ),
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];

  return (
    <AdaptableFormRedesign
      isEdit={isEdit}
      data={data}
      from={from}
      icon={false}
      saveBackground={true}
      showDelete
    />
  );
};

export default FormAdmins;
