import { strCapitalize } from '../../../../helpers/strCapitilize';
import { hexToRgb } from '../../../../Components/InputsForm/InputColorPicker';
import { useSelector } from 'react-redux';
import { RxDragHandleDots2 } from 'react-icons/rx';
import styles from './sets.module.css';
import { useState } from 'react';
import { onDrag } from './functions';
import EditModalButton from '../../../Onboarding/Components/EditModalButton';
import { Box, Button, CircularProgress, IconButton, Modal } from '@mui/material';
import { MdDelete, MdDeleteForever } from 'react-icons/md';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';
import { InputSubtitle } from '../../../../Components/StyledComponents/InputSubtitle';

export const DraggableSet = (props) => {
  
  const {
    setIsDragging,
    selectedSet,
    index,
    item,
    isDragging,
    isDraggingOver,
    setIsDraggingOver,
    onClick,
    key = 'title',
    EditInputs,
    onDelete,
    resourceProp,
    isDeleting,
    draggable = true,
    deleteInterests = false,
    sets
  } = props;

  const palette = useSelector((state) => state?.palette?.palette); 
  const backgroundColor = hexToRgb(palette.tertiary);
  const [selected, setSelected] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
   
  const id = `draggable-set-${index}`;
  
  const handleSelected = () => {
    setSelected(true);
  };
  
  const handleOnDragEnd = () => {
    setIsDragging(false);
    setSelected(false);
    setIsDraggingOver(null);
  };
  
  const handleOnDragStart = (e) => {
    onDrag(e,index);
    
  };

  const handleDelete = (item, index) => {
    onClick(index - 1);
    onDelete(item, index);
    setOpenDeleteModal(false);
  };

  return (
    <div 
      id={id}
       
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px',
        padding: '5px 0',
        maxWidth: '400px',
        height:'auto'
      }}>
      { 
        index === 0 && draggable &&
          <div 
            className={styles['dnd-divider']}
            style={{ 
              '--divider-color': palette.tertiary,
              width: '100%',
              borderBottom: `2px solid ${palette.tertiary}`,
              display: 'block',
              opacity: isDraggingOver === 'draggable-set--1' ? 0.5 : 0,
            }} />
      }
      <div 
        draggable={draggable && selected}
        id={id}
        onDragEnd={handleOnDragEnd} 
        onDragStart={handleOnDragStart} 
        onMouseOver={() => setShowDelete(true)}
        onMouseOut={() => setShowDelete(false)}
        className={
          isDragging ?
            styles['dnd-sets-buttons', 'dnd-sets-buttons-selected-shadow'] :
            styles['dnd-sets-buttons'] }
        style={{
          boxShadow: isDraggingOver && isDraggingOver === id ?
            `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, 0.1) 0px 15px 20px` 
            : isDraggingOver === `draggable-set-${index-1}` ?
              `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, 0.1) 0px -10px 20px` :
              null,
        }}
      >
        <div
          onMouseDown={handleSelected}
          id={id}
          style={{ 
            backgroundColor: index === selectedSet ?
              palette.tertiary :
              '#DDDDDD',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '4px 0px 0px 4px',
            height: 'inherit'
          }}>
          <RxDragHandleDots2 id={id}  />
        
        </div>
        <div
          id={id}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection:'row',
            alignItems:'center',
            backgroundColor: index === selectedSet ? `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, 0.1)` : '#F5F5F5',
            justifyContent: 'space-between',
            color: 'black',
            paddingLeft: '10px',
            borderRadius: '0px 4px 4px 0px',
            position:'relative'
          }}
          onClick={(e) => {
            onClick && onClick(index);
          }}
        
        >
          {strCapitalize(item?.[key])}
          <div style={{
            display: 'flex',
            flexDirection:'row',
            alignItems:'center',
          }}>

            {
              onDelete && showDelete && index === selectedSet && (
                <div className={styles['dnd-on-delete']}>
                  <IconButton disabled={sets.length === 1} onClick={() => setOpenDeleteModal(true)}>
                    <MdDelete />
                  </IconButton>
                  <Modal 
                    open={openDeleteModal}
                    onClose={() => setOpenDeleteModal(false)}>
                    <Box sx={{
                      backgroundColor: '#fff',
                      position:'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      height: 'auto',
                      width: 'auto'
                    }}>
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                        padding:'20px',
                        gap: '10px'
                      }}>
                        <InputTitle>Delete {resourceProp}</InputTitle>
                        <InputSubtitle>Are you sure you want to delete this {resourceProp}?</InputSubtitle>
                        <Button
                          disabled={isDeleting }
                          sx={{ alignSelf: 'flex-end', backgroundColor: palette.error, color: '#fff', }} 
                          variant="outlined" 
                          onClick={() => handleDelete(item, index)}
                        >
                          {!isDeleting ? 'Delete' : <CircularProgress />}
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                </div>
              )
            }
            {EditInputs && <div style={{width: '50px', position:'relative'}}> <EditModalButton {...props} titleValue={item.title} EditInputs={EditInputs} sx={{top: '50%', right: '0%'}}  /> </div>}
          
          </div>
        </div>
      </div>
      <div 
        className={styles['dnd-divider']}
        style={{ 
          '--divider-color': palette.tertiary,
          width: '100%',
          borderBottom: `2px solid ${palette.tertiary}`,
          display: 'block', 
          opacity: isDraggingOver === id ? 0.5 : 0
        }} />
    
    </div>
  );
};