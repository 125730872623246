import React, { useEffect, useRef, useState } from 'react';
// Hooks & Utils
import { useListContext, useGetList, useResourceContext } from 'react-admin';
import { useSelector } from 'react-redux';
import { dynamicColor } from '../../helpers/dynamicColor';
import { responsiveFilter } from '../../Hooks/useMediaQuery';
import { roleConvert } from '../../helpers/roleConvert';
// Material & Icons
import {
  Autocomplete,
  Checkbox,
  Chip,
  Skeleton,
  TextField as MuiTextField,
  Popover,
} from '@mui/material';
import { IoMdSearch } from 'react-icons/io';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import { Button, TextField } from '../Generics';
import dayjs from 'dayjs';

export const SearchComponent = ({
  sx,
  filterState,
  setFilterState,
  placeholder,
}) => {
  const [value, setValue] = React.useState('');
  const { filterValues, setFilters } = useListContext();
  const handleFilter = (e) => {
    setFilterState &&
      setFilterState({ ...filterState, filterName: e.target.value });
    setFilters && setFilters({ ...filterValues, q: e.target.value });
    setValue(e.target.value);
  };
  return (
    <TextField
      sx={{ width: '100%', backgroundColor: 'white', ...sx }}
      value={value}
      onChange={handleFilter}
      placeholder={placeholder}
      icon={<IoMdSearch size={24} />}
    />
  );
};
export const CategoryFilter = ({
  _callback,
  sx,
  filter,
  from,
  responsive,
  forceResource,
  ...rest
}) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const [category, setCategory] = useState([]);
  const resource = useResourceContext();
  const { setFilters, filterValues } = useListContext();
  const { data, isFetching } = useGetList(
    `${forceResource ? forceResource : resource}/categories`,
    {
      pagination: { perPage: 9999, page: 1 },
      sort: { field: 'rank', order: 'DESC' },
    },
  );
  const handleFilter = (e, v) => {
    if (_callback) {
      _callback(e, v);
    } else {
      setFilters({
        ...filterValues,
        category: v?.map((item) => encodeURIComponent(item.text)),
      });
    }
  };

  return isFetching ? (
    <Skeleton height="50px" width="200px" sx={sx} />
  ) : (
    <Autocomplete
      isOptionEqualToValue={(e, v) => {
        return e === v;
      }}
      multiple
      options={data}
      limitTags={1}
      disableCloseOnSelect
      disableClearable
      ChipProps={{
        onDelete: null,
        sx: { fontWeight: 600 },
      }}
      getOptionLabel={(option) => {
        if (option.text !== undefined) return option.text;
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <li {...props} key={option.text}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              sx={{
                padding: 0,
                marginRight: 1,
                color: palette?.primary,
                '&.Mui-checked': {
                  color: palette?.primary,
                },
              }}
              checked={selected}
            />
            <span
              style={{
                color: category.includes(option.text)
                  ? palette?.primary
                  : 'black',
                fontWeight: 600,
              }}
            >
              {option.text}
            </span>
          </li>
        );
      }}
      onChange={(event, newValue) => {
        if (newValue) {
          const excludeUndefined = newValue?.filter(
            (tag) => tag.text !== undefined,
          );
          const filteredNewValue = excludeUndefined?.map((tag) => tag);
          const uniqueTags = [...new Set([...filteredNewValue])];
          if (category.length > 0) {
            setCategory(uniqueTags);
            handleFilter(event, uniqueTags);
          } else {
            if (filteredNewValue && filteredNewValue?.length > 0) {
              setCategory(uniqueTags);
              handleFilter(event, uniqueTags);
            }
          }
        }
      }}
      value={category}
      sx={{
        width: responsive,
        zIndex: 999,
        '& .MuiFilledInput-root:before': {
          visibility: 'hidden',
        },
        '& .MuiFilledInput-root': {
          paddingX: '20px',
          paddingY: '6px',
        },
        '& .MuiFilledInput-root:after': {
          border: 'none',
        },
        '& .MuiAutocomplete-inputRoot': {
          borderRadius: '50px',
          backgroundColor: 'transparent',
          '& .MuiChip-root': {
            backgroundColor: palette?.primary,
            color: 'white',
          },
          '& .MuiInputLabel-root': {
            paddingX: '5px',
          },
        },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          paddingLeft: '15px',
        },
      }}
      renderInput={(params) => (
        <MuiTextField
          label={'Category'}
          variant="outlined"
          {...params}
          sx={{
            '& .MuiInputLabel-root': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '80%',
              color: 'black',
              top: '-4px !important',
            },
          }}
        />
      )}
    />
  );
};
export const CompletedFilter = ({ width, responsive }) => {
  const { filterValues, setFilters } = useListContext();
  const completeValues = ['Complete', 'Incomplete'];
  const handleFilter = (e, v) => {
    const target = v;
    if (target === 'Complete') {
      return setFilters({ ...filterValues, completed: true });
    } else if (target === 'Incomplete') {
      return setFilters({ ...filterValues, completed: false });
    } else {
      return setFilters({ ...filterValues, completed: null });
    }
  };
  return (
    <Autocomplete
      onChange={handleFilter}
      sx={{
        marginBottom: '0px',
        height: '45px',
        '& .MuiInputBase-sizeSmall': {
          borderRadius: '50px',
          color: 'black',
        },
        '& .MuiInputLabel-root': {
          color: 'black',
          fontWeight: 400,
          top: '-4px !important',
        },
        '& .MuiInputBase-root': {
          height: '100%',
          padding: '0px 0px',
          borderRadius: '50px',
        },
      }}
      getOptionLabel={(option) => option}
      options={completeValues}
      renderInput={(params) => (
        <MuiTextField
          label="Completed?"
          variant="outlined"
          sx={{
            width: responsive,
            height: '100%',
            zIndex: 9999,
            color: 'black',
          }}
          InputProps={{
            ...params.InputProps,
            sx: {
              overflow: 'auto',
              margin: '0px',
            },
            startAdornment: (
              <div
                style={{
                  overflow: 'auto',
                  margin: '0px',
                }}
              >
                {params.InputProps.startAdornment}
              </div>
            ),
          }}
          {...params}
        />
      )}
    />
  );
};
export const MultiSelectFilter = ({
  _callback,
  sx,
  from,
  responsive,
  fetchUrl,
  options,
  noObj,
  label,
  textFieldVariant = 'outlined',
  scheduleFilter,
  scheduleOptionBase,
  filterSchedule,
}) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const [category, setCategory] = useState([]);
  const resource = useResourceContext();
  const { setFilters, filterValues } = useListContext();
  const { data, isFetching } = useGetList(`${fetchUrl ? fetchUrl : resource}`, {
    pagination: { perPage: 9999, page: 1 },
    sort: { field: 'rank', order: 'DESC' },
  });
  const handleFilter = (e, v) => {
    if (_callback) {
      _callback(e, v);
    } else if (label === 'Tags') {
      setFilters({
        ...filterValues,
        tags: v?.map((item) => encodeURIComponent(item.text)),
      });
    } else if (from === 'articles' || from === 'faqs') {
      setFilters({
        ...filterValues,
        categories: v?.map((item) => encodeURIComponent(item.text)),
      });
    } else {
      setFilters({
        ...filterValues,
        category: v?.map((item) => encodeURIComponent(item.text)),
      });
    }
  };
  useEffect(() => {
    if (scheduleFilter) {
      let arr = [];
      if (
        scheduleFilter?.filterVenues?.length > 0 &&
        filterSchedule === 'location'
      ) {
        for (let i = 0; i < data?.length; i++) {
          for (let j = 0; j < scheduleFilter?.filterVenues?.length; j++) {
            if (
              data[i].id === scheduleFilter?.filterVenues[j] ||
              data[i].main_location === scheduleFilter?.filterVenues[j]
            ) {
              arr.push(data[i]);
              continue;
            }
          }
        }
        if (scheduleFilter?.filterVenues?.length > 0) setCategory(arr);
      }
      if (
        scheduleFilter?.filterCategorys?.length > 0 &&
        filterSchedule === 'category'
      ) {
        console.log('category');
        for (let i = 0; i < data?.length; i++) {
          for (let z = 0; z < scheduleFilter?.filterCategorys?.length; z++) {
            if (data[i]?.text === scheduleFilter?.filterCategorys[z]) {
              arr.push(data[i]);
              continue;
            }
          }
        }
        if (scheduleFilter?.filterCategorys?.length > 0) setCategory(arr);
      }
    }
  }, []);
  const bgColor = dynamicColor(palette?.primary, 0.2);
  return isFetching ? (
    <Skeleton height="50px" width="200px" sx={sx} />
  ) : (
    <Autocomplete
      isOptionEqualToValue={(e, v) => {
        return e === v;
      }}
      multiple
      options={options ? options : data ? data : []}
      limitTags={1}
      disableCloseOnSelect
      disableClearable
      getOptionLabel={(option) => {
        if (noObj) {
          if (option !== undefined) return option;
        } else if (scheduleOptionBase === false) {
          if (option.name !== undefined) return option.name;
        } else {
          if (option.text !== undefined) return option.text;
        }
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <li
            {...props}
            key={
              scheduleOptionBase === true
                ? noObj
                  ? option
                  : option.name
                : noObj
                  ? option
                  : option.text
            }
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              sx={{
                padding: 0,
                marginRight: 1,
                color: palette?.primary,
                '&.Mui-checked': {
                  color: palette?.primary,
                },
              }}
              checked={selected}
            />
            <span
              style={{
                color: noObj
                  ? category.includes(option)
                  : category.includes(option.text)
                    ? palette?.primary
                    : 'black',
                fontWeight: 600,
              }}
            >
              {scheduleOptionBase === true
                ? noObj
                  ? option
                  : option.name
                : noObj
                  ? label === 'Days'
                    ? dayjs(option).format('MM-DD')
                    : option
                  : option.text}
            </span>
          </li>
        );
      }}
      onChange={(event, newValue) => {
        if (newValue) {
          const excludeUndefined = newValue?.filter((tag) =>
            scheduleOptionBase === true
              ? noObj
                ? tag !== undefined
                : tag.name !== undefined
              : noObj
                ? tag !== undefined
                : tag.text !== undefined,
          );
          const filteredNewValue = excludeUndefined?.map((tag) => tag);
          const uniqueTags = [...new Set([...filteredNewValue])];
          if (category.length > 0) {
            setCategory(uniqueTags);
            handleFilter(event, uniqueTags);
          } else {
            if (filteredNewValue && filteredNewValue?.length > 0) {
              setCategory(uniqueTags);
              handleFilter(event, uniqueTags);
            }
          }
        }
      }}
      renderTags={(params) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '70%',
              gap: 0,
              margin: 0,
              padding: 0,
              borderRadius: '5px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '5px',
                flexWrap: 'nowrap',
                width: 'auto',
                overflowX: 'hidden',
                paddingLeft: '5px',
              }}
            >
              {params?.slice(0, 1)?.map((e, index) => (
                <Chip
                  label={e.name || e.text || e}
                  sx={{
                    backgroundColor: bgColor.rgba,
                    fontSize: '14px',
                    color: palette?.primary,
                    overflowX: index === 1 - 1 ? 'hidden' : 'visible',
                    padding: 0,
                    margin: 0,
                    height: '24px',
                    fontWeight: 600,
                  }}
                />
              ))}
            </div>
            <div style={{ width: 'auto' }}>
              {params?.length > 1 && (
                <Chip
                  label={`+${params?.length - 1}`}
                  sx={{
                    backgroundColor: 'transparent',
                    color: 'black',
                    fontSize: '14px',
                    height: '24px',
                  }}
                />
              )}
            </div>
          </div>
        );
      }}
      value={category}
      sx={{
        width: responsive,
        zIndex: 999,
        display: 'flex',
        flexDirection: 'row',
        height: '45px',
        backgroundColor: '#E8E8E8',
        '& .MuiInputBase-input': {
          backgroundColor: '#E8E8E8',
        },
        '& .MuiAutocomplete-root': {
          padding: '0px 0px',
          height: '100%',
        },
        '& .MuiInputBase-root': {
          height: '100%',
          padding: '0px 0px',
          borderRadius: '5px',
        },
        '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          padding: '0px 0px',
        },
      }}
      renderInput={(params) => (
        <MuiTextField
          label={label}
          variant={textFieldVariant}
          {...params}
          sx={{
            '& .MuiInputLabel-root': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '80%',
              color: 'black',
              padding: '0px',
              top: '-4px !important',
            },
          }}
        />
      )}
    />
  );
};
//Event Filter
export const ScheduleFilter = ({ isLargeScreen }) => {
  const { setFilters, filterValues } = useListContext();
  const [bool, setBool] = useState(null);
  const handleChange = (e, v) => {
    let newFilters = { ...filterValues };
    const value = v;
    setBool(value);
    if (!value) {
      delete newFilters['scheduled'];
      return setFilters(newFilters);
    }
    setFilters({ ...newFilters, scheduled: value === 'Scheduled' });
  };

  return (
    <Autocomplete
      onChange={handleChange}
      sx={{
        marginBottom: '0px',
        height: '45px',
        '& .MuiInputBase-sizeSmall': {
          borderRadius: '50px',
          color: 'black',
        },
        '& .MuiInputLabel-root': {
          color: 'black',
          fontWeight: 400,
          top: '-4px !important',
        },
        '& .MuiInputBase-root': {
          height: '100%',
          padding: '0px 0px',
          borderRadius: '50px',
        },
      }}
      getOptionLabel={(option) => option}
      options={['Scheduled', 'Not Scheduled']}
      renderInput={(params) => (
        <MuiTextField
          label="Scheduled"
          variant="outlined"
          sx={{
            width: isLargeScreen,
            height: '100%',
            zIndex: 9999,
            color: 'black',
          }}
          InputProps={{
            ...params.InputProps,
            sx: {
              overflow: 'auto',
              margin: '0px',
            },
            startAdornment: (
              <div
                style={{
                  overflow: 'auto',
                  margin: '0px',
                }}
              >
                {params.InputProps.startAdornment}
              </div>
            ),
          }}
          {...params}
        />
      )}
    />
  );
};
//Talent Filter
export const VisiblityFilter = ({ responsive }) => {
  const { filterValues, setFilters } = useListContext();
  const visiblityValues = ['Visible', 'Hidden'];
  const handleFilter = (e, v) => {
    const target = v;
    if (target === 'Visible') {
      return setFilters({ ...filterValues, draft: false });
    } else if (target === 'Hidden') {
      return setFilters({ ...filterValues, draft: true });
    } else {
      return setFilters({ ...filterValues, draft: null });
    }
  };
  return (
    <Autocomplete
      onChange={handleFilter}
      sx={{
        marginBottom: '0px',
        height: '45px',
        '& .MuiInputLabel-root': {
          color: 'black',
          fontWeight: 400,
          top: '-4px !important',
        },
        '& .MuiInputBase-sizeSmall': {
          borderRadius: '50px',
        },
        '& .MuiInputBase-root': {
          height: '100%',
          padding: '0px 0px',
          borderRadius: '50px',
        },
      }}
      getOptionLabel={(option) => option}
      options={visiblityValues}
      renderInput={(params) => (
        <MuiTextField
          label="Visibility"
          variant="outlined"
          sx={{
            width: responsive,
            height: '100%',
            zIndex: 9999,
            color: 'black',
          }}
          InputProps={{
            ...params.InputProps,
            sx: {
              overflow: 'auto',
              margin: '0px',
            },
            startAdornment: (
              <div
                style={{
                  overflow: 'auto',
                  margin: '0px',
                }}
              >
                {params.InputProps.startAdornment}
              </div>
            ),
          }}
          {...params}
        />
      )}
    />
  );
};
// Location filter
export const GeoTaggedFilter = ({ responsive }) => {
  const { setFilters, filterValues } = useListContext();
  const [boolGeo, setBoolGeo] = useState(null);
  const handleChangeGeo = (e, v, from) => {
    let newFilters = { ...filterValues };
    const value = v;
    setBoolGeo(value);
    if (!value) {
      delete newFilters['geo_tagged'];
      return setFilters(newFilters);
    }
    setFilters({
      ...newFilters,
      geo_tagged: value?.toLowerCase() === 'yes' ? true : false,
    });
  };

  const geoArr = ['Yes', 'No'];
  return (
    <div>
      <Autocomplete
        onChange={handleChangeGeo}
        InputProps={{
          fontSize: '.9em',
          borderRadius: '50px',
        }}
        sx={{
          marginBottom: '0px',
          height: '45px',
          '& .MuiInputBase-sizeSmall': {
            borderRadius: '50px',
            color: 'black',
            maxHeight: '45px',
          },
          '& .MuiInputLabel-root': {
            color: 'black',
            fontWeight: 400,
            top: '-4px !important',
          },
          '& .MuiInputBase-root': {
            height: '100%',
            padding: '0px 0px',
            borderRadius: '50px',
          },
        }}
        getOptionLabel={(option) => option}
        options={geoArr}
        renderInput={(params) => (
          <MuiTextField
            label="Geo-tagged"
            variant="outlined"
            sx={{
              width: responsive,
              height: '100%',
              zIndex: 9999,
              color: 'black',
            }}
            InputProps={{
              ...params.InputProps,
              sx: {
                overflow: 'auto',
                margin: '0px',
              },
              startAdornment: (
                <div
                  style={{
                    overflow: 'auto',
                    margin: '0px',
                  }}
                >
                  {params.InputProps.startAdornment}
                </div>
              ),
            }}
            {...params}
          />
        )}
      />
    </div>
  );
};
// Users filter
export const RoleFilter = ({
  setFilter,
  resource = 'manager_roles',
  capitalize,
}) => {
  const { filterValues, setFilters } = useListContext();
  const [value, setValue] = useState('');
  const { data, isFetching } = useGetList(`admins/${resource}`, {
    pagination: { perPage: 9999, page: 1 },
    sort: { field: 'rank', order: 'DESC' },
  });
  const responsive = responsiveFilter();
  const handleFilter = (e, v) => {
    if (resource === 'manager_roles') {
      setFilters({ ...filterValues, manager_role: v?.text });
      setFilter({ ...filterValues, manager_role: v?.text });
      setValue(e?.target?.value);
    } else {
      setFilters({ ...filterValues, role: v?.text });
      setValue(e?.target?.value);
    }
  };
  return (
    <div style={{ width: '100%' }}>
      <Autocomplete
        fullWidth
        limitTags={2}
        onChange={handleFilter}
        sx={{
          marginBottom: '0px',
          height: '45px',
          '& .MuiInputBase-sizeSmall': {
            borderRadius: '50px',
            color: 'black',
          },
          '& .MuiInputLabel-root': {
            color: 'black',
            fontWeight: 400,
            top: '-4px !important',
          },
          '& .MuiInputBase-root': {
            height: '100%',
            padding: '0px 0px',
            borderRadius: '50px',
          },
        }}
        getOptionLabel={(option) =>
          capitalize ? roleConvert(option?.text) : option?.text
        }
        options={!data ? [{ label: 'Loading...', id: 0 }] : data}
        renderInput={(params) => (
          <MuiTextField
            label="Role"
            variant="outlined"
            sx={{
              width: responsive,
              height: '100%',
              zIndex: 9999,
              color: 'black',
            }}
            InputProps={{
              ...params.InputProps,
              sx: {
                overflow: 'auto',
                margin: '0px',
              },
              startAdornment: (
                <div
                  style={{
                    overflow: 'auto',
                    margin: '0px',
                  }}
                >
                  {params.InputProps.startAdornment}
                </div>
              ),
            }}
            {...params}
            {...params}
            {...params}
          />
        )}
      />
    </div>
  );
};
const newFilterStyleBt = {
  backgroundColor: '#E8E8E8',
  padding: '0px 10px',
  height: '40px',
  display: 'flex',
  flexDirection: 'row',
  gap: '5px',
  textTransform: 'none',
  fontSize: '14px', 
  lineHeight:'19px',
};
export const NewFilter = ({
  _callback,
  from,
  fetchUrl,
  noObj,
  label,
  options,
  scheduleOptionBase,
  setShowFilter,
  showFilter,
  singleSelect,
  deleteSlug,
}) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const [open, setOpen] = useState(false);
  const [filterState, setFilterState] = useState([]);
  const resource = useResourceContext();
  const refb = useRef();
  const { setFilters, filterValues } = useListContext();
  const { data, isFetching } = useGetList(`${fetchUrl ? fetchUrl : resource}`, {
    pagination: { perPage: 9999, page: 1 },
    sort: { field: 'rank', order: 'DESC' },
  });
  const handlerFilterState = (e, v) => {
    if (!singleSelect) {
      if (!filterState.includes(v)) {
        setFilterState([...filterState, v]);
      } else {
        setFilterState(filterState.filter((item) => item !== v));
      }
    } else {
      if (!filterState.includes(v)) {
        setFilterState([v]);
      } else {
        setFilterState([]);
      }
    }
  };
  useEffect(() => {
    handleFilter(null);
  }, [filterState]);

  const handleFilter = (e) => {
    if (_callback) {
      _callback(e, filterState);
    } else if (label === 'Tags') {
      setFilters({
        ...filterValues,
        tags: filterState?.map((item) => encodeURIComponent(item.text)),
      });
    } else if (from === 'articles' || from === 'faqs') {
      setFilters({
        ...filterValues,
        categories: filterState?.map((item) => encodeURIComponent(item.text)),
      });
    } else {
      setFilters({
        ...filterValues,
        category: filterState?.map((item) => encodeURIComponent(item.text)),
      });
    }
  };
  const deleteFilter = (label) => {
    setShowFilter(
      showFilter?.filter((item) => item.toLowerCase() !== label.toLowerCase()),
    );
    setFilters({
      ...filterValues,
      [deleteSlug]: null,
    });
  };
  return (
    <div ref={refb}>
      <Button
        style={newFilterStyleBt}
        onClick={() => setOpen(!open)}
        svgAction={true}
        svgOnClick={() => deleteFilter(label)}
      >
        <p style={{ color: '#000', fontWeight: 600 }}>{label}</p>
        <span style={{ color: 'grey'}}>is:</span>
        {filterState?.slice(0, 2).map((item, index) => (
          <p key={index} style={{color: '#000', fontWeight: 700 }}>
            {noObj
              ? label === 'Days'
                ? dayjs(item).format('MM-DD')
                : item
              : item?.text}
            {index === 0 && ','}
          </p>
        ))}
        {filterState?.length > 2 && (
          <div
            style={{
              display: 'inline-block',
              marginLeft: '8px',
              padding: '2px 8px',
              backgroundColor: palette.primary,
              borderRadius: '50%',
              fontSize: '12px',
              fontWeight: 'bold',
              color: '#fff',
            }}
          >
            {filterState?.length - 2}
          </div>
        )}
      </Button>
      <Popover
        anchorEl={refb.current}
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '10px',
            marginTop: '5px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ul
          style={{
            listStyle: 'none',
            margin: 0,
            padding: '10px 15px',
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
          }}
        >
          {options ? (
            <>
              {options?.map((option, index) => (
                <li
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    onClick={(e) => handlerFilterState(e, option)}
                    sx={{
                      padding: 0,
                      marginRight: 1,
                      color: palette?.primary,
                      '&.Mui-checked': {
                        color: palette?.primary,
                      },
                    }}
                    checked={filterState?.includes(option)}
                  />
                  <span
                    style={{
                      color: 'black',
                      fontWeight: 600,
                    }}
                  >
                    {scheduleOptionBase === true
                      ? noObj
                        ? option
                        : option.name
                      : noObj
                        ? label === 'Days'
                          ? dayjs(option).format('MM-DD')
                          : option
                        : option.text}
                  </span>
                </li>
              ))}
            </>
          ) : (
            <>
              {data?.map((option, index) => (
                <li
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    onClick={(e) => handlerFilterState(e, option)}
                    sx={{
                      padding: 0,
                      marginRight: 1,
                      color: palette?.primary,
                      '&.Mui-checked': {
                        color: palette?.primary,
                      },
                    }}
                    checked={filterState?.includes(option)}
                  />
                  <span
                    style={{
                      color: 'black',
                      fontWeight: 600,
                    }}
                  >
                    {label === 'Role' ?<>{roleConvert(option?.text)} </> 
                      : 
                      <>{scheduleOptionBase === true
                        ? noObj
                          ? option
                          : option.name
                        : noObj
                          ? label === 'Days'
                            ? dayjs(option).format('MM-DD')
                            : option
                          : option.text}</>}
                  </span>
                </li>
              ))}
            </>
          )}
        </ul>
      </Popover>
    </div>
  );
};
