import React from 'react';
import { Create } from 'react-admin';
import FormBlogs from './FormBlogs';
import { transform } from './Transform';
import { CreateCustomComponent } from '../../../../Components/CreateCustomComponent/CreateCustomComponent';

const CreateBlogs = () => {
  return (
    <CreateCustomComponent transform={transform} redirect='list' mutationMode={'pessimistic'} sx={{'& .RaCreate-noActions': { marginTop: '0px'},'& .MuiCardContent-root':{paddingX: '50px'}}}>
      <FormBlogs from={'content'}/>
    </CreateCustomComponent>
  );
};

export default CreateBlogs;