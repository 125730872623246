import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, Radio, TextField } from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { regex, useInput, useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { InputTitle } from '../StyledComponents/InputTitle';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
import { InputCategoryPills } from '../RedesignComponents/Inputs';
import { EVENTS_TOOLTIPS } from '../../Resources/TalentForm/Tooltips';
import { InputDateTime } from '../RedesignComponents/Inputs/InputDateTime';
import { inputErrorMessage } from '../../helpers/inputErrorMessage';
import { useRequiredSchema } from '../../Hooks/useRequiredSchema';
import { TooltipIcon } from '../StyledComponents/TooltipIcon';

export const InputDurationMinutes = ({ title, subtitle, source, validate=[], ...rest }) => {
  const record = useRecordContext();
  const { getValues, setValue } = useFormContext();
  const { isRequiredSchema } = useRequiredSchema();
  const [dayEvent, setDayEvent] = useState(false);
  
  const regexNumber = /^(?!-)[0-9]+$/;
  const { field, fieldState } = useInput({
    source,
    validate: [
      ...isRequiredSchema(source), ...validate,
      
      (e) => {
        if (e !== null && e < 5) {
          return 'Event Duration must be equal or greater than 5';
        }
        return undefined;
      },
      regex(regexNumber, 'The duration must be a number'),
    ],
  });
  const stageIdState = getValues('location_id');
  const [duration, setDuration] = useState(
    record?.duration ? record?.duration : getValues()?.duration
  );
  useEffect(() => {
    field.onChange(duration);

    let startDate = getValues('start_date');
    let newEndDate = dayjs(startDate).add(duration, 'minutes');
    setValue('end_date', newEndDate);
  }, [duration]);
  const stepsMemo = useMemo(() => {
    let steps;
    if(Number(field.value) <= 0 && stageIdState === null || stageIdState === undefined ) {
      setValue('start_date', null);
    }
    if (field.value === '') {
      steps = 0;
    } else if (Number(field.value) > 0 && stageIdState === null || stageIdState === undefined) {
      steps = 1;
      setValue('start_date', null);
    } else if (stageIdState != null || stageIdState === undefined) {
      steps = 2;
    } else {
      steps = 0; 
    }
    return steps;
  }, [field.value, stageIdState]);

  const handleDayEvent = (e) => {
    let bool = e.target.checked;
    setDayEvent(bool);
    if(bool) {
      setDuration(1439);
    } else {
      setDuration(record?.duration ? record?.duration : '');
    }
  };
  
  return (
    <>
      <Box sx={{ width: '100%', margin: '0px 10px 0px 0px' }}>
        {title && <InputTitle>{title}</InputTitle>}
        {subtitle && <InputSubtitle>{subtitle}</InputSubtitle>}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '120px',
            marginBottom: '20px',
          }}
        >
          
          {
            dayEvent ? (
              <TextField
                data-testid="hs-textfield"

                sx={{ width: '100%', ...rest.sx }}
                label=""
                variant="outlined"
                value="24:00hs"
                disabled
                {...rest}
              />
            ) : (
              <TextField
                data-testid="duration-textfield"
                type="tel"
                onChange={(e) => setDuration(e.target.value)}
                sx={{ width: '100%', ...rest.sx }}
                label=""
                variant="outlined"
                disabled={dayEvent}
                helperText={inputErrorMessage(fieldState?.error?.message)}
                {...field}
                {...fieldState}
                {...rest}
              />
            )
          }
         
          <FormGroup>
            <FormControlLabel sx={{'& .MuiFormControlLabel-label' :{display:'flex',flexDirection:'row',alignItems:'center'}}} label={
              <Fragment>
                24 hour event
                <TooltipIcon message="You can choose to " />
              </Fragment>
            } control={<Checkbox data-testid="checkbox" onClick={handleDayEvent} />} />
          </FormGroup>
        </Box>
      </Box>
      {stepsMemo >= 1  && !rest.noStage && <InputCategoryPills
        title="Select Stage"
        subtitle={EVENTS_TOOLTIPS['selectStage']}
        source="location_id"
        isLocation
        reference="locations"
        label=""
        filter={{ event_ready: true }}
        // validate={[required('The location is required.')]}
        // required={true}
        optionText="name"
        optionValue="name"
      />}
      {stepsMemo >= 2  && !rest.noStage && <InputDateTime
        source={'start_date'}
        subtitle={EVENTS_TOOLTIPS['selectDay']}
        title="Select Date / Time"
        checkStageTime
        shouldDisableTime={dayEvent ? (e) => {
          if(dayjs(e).format('HH:mm') === '00:00') return false; 
          return true;
        } : undefined}
      />}
    </>
  );
};
