import { Box, Modal, TextField, Typography } from '@mui/material';
import { asideActionButton } from './DeleteButton';
import { useDataProvider, useRecordContext, useRefresh,useRedirect } from 'react-admin';
import { useRef, useState } from 'react';
import { useOnClickOutside } from '../../../Hooks/useOnClickOutside';
import axios from 'axios';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import { apiUrl } from '../../../dataProvider';
import { useCustomToasty } from '../../../Hooks/useCustomToasty';
import { errorColor } from '../../../Theme';
import { COLORS } from '../../../assets/constants/theme';
import {Button} from '../../Generics/index';

export const RejectButton = ({id, route, setSwitchBody, color, children}) => {
  const redirect = useRedirect();
  const [open, setOpen] = useState(false);
  const [motive, setMotive] = useState('');
  const record = useRecordContext();
  const refresh = useRefresh();

  const dataProvider = useDataProvider();
  const customToast = useCustomToasty();
  const ref  = useRef(false);
  const handleClick = () => {
    setOpen(true);
  };

  useOnClickOutside(ref, () => { setOpen(false);});

  const handleReject = async () => {
    try {
      const request = axios({
        method: 'PUT',
        url: `${apiUrl}/${route}/${id}/reject`,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('auth')
        },
        data: {message: motive}
      });
      const response = await request;
      if (response.data) {
        setSwitchBody && setSwitchBody(false);
        refresh();
        setOpen(false);
        customToast('success', 'Talent changes have been rejected');
        redirect(`/${route}`);
      }
    } catch (error) {
      customToast('error', 'There was an error, please try again later.');
    }
  };

  return (
    <>
      <Button
        style={{
          fontSize: '1em',
          fontWeight:'600',
          'text-transform': 'uppercase',
          'place-self': 'center',
          'align-items': 'center',
          backgroundColor:COLORS.table.indicators.fontColor.rejected,
          color:'white',
          height:'70%',
          borderRadius:'10px'
        }}
        onClick={() => handleClick()}
      >
        <DoDisturbAltIcon fontSize="20px" sx={{marginRight:'5px'}}/>
        {children}
      </Button>
      <Modal
        open={open}
        ref={ref}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            width: '700px',
            height: '300px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '12px',
            padding: '20px'
          }}>
          <Button onClick={() => setOpen(false)} style={{position:'absolute',top:5, right:0}}><span style={{fontSize: '15px',color:'black'}}>X</span></Button>
          <Typography color={COLORS?.table?.indicators?.fontColor?.rejected} sx={{fontSize: '20px', alignSelf: 'start', marginLeft: '20px', fontWeight: '600', textTransform: 'uppercase'}}>Reject Changes</Typography   >
          <Typography sx={{marginLeft: '20px', alignSelf: 'flex-start'}}>Write a reason as to why these changes are being rejected.</Typography>
          <TextField sx={{width: '100%'}} InputProps={{sx: {margin: '20px'}}} variant="outlined" multiline minRows={3} maxRows={4} value={motive} onChange={(e) => setMotive(e.target.value)} />
          <Button style={{...asideActionButton,width:'200px',padding:1,borderRadius:'10px', backgroundColor: COLORS?.table?.indicators?.fontColor?.rejected, color: '#fff', borderColor: '#f99190', '&:hover': {color: errorColor}}} onClick={handleReject}>Reject</Button>
        </Box>
      </Modal>
    </>
  );
};