import {  useEffect, useState } from 'react';
import { useListContext } from 'react-admin';
import { Typography, Tooltip } from '@mui/material';
import { useResourceContext } from 'react-admin';
import { useSelector } from 'react-redux';
import {Button} from '../../../../Components/Generics';
const navBarStyle = {
  backgroundColor: '#fff',
  width: '100%', 
  display: 'flex', 
  height: '40px', 
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const tabStyle = {
  'width': '100%',
  'height': '100%',
  'background-color': '#fff',
  'align-items': 'center',
  'justify-content': 'flex-start',
  'text-transform': 'uppercase',
  'display': 'flex',
  'flex-direction': 'row',
  'max-height': '60px',
  'border-radius': '5px 5px 0 0',
  'cursor':'pointer'
};

const typoStyle = {
  fontWeight: '600',
  fontSize: '1em',
  'width': 'auto',
  'maxWidth': '170px',
  'textOverflow': 'ellipsis',
  'overflow': 'hidden',
  'whiteSpace': 'nowrap'
};


export const ListTabs = ({defaultTab, tabs, setFilter, handleForm}) => {
  const palette = useSelector((state) => state?.palette?.palette);

  const data = useResourceContext();
  useEffect(() => {
    if(tabs.length > 2){
      // setSelected('profile');
    }else{
      let localTalents = localStorage.getItem('talents');
      let localEvents = localStorage.getItem('events');
  
      if(data ==='talents' ){
        if(localTalents ){ 
          setSelected(localTalents);
          return;
        }
      }
      else if (data === 'events'){
        if(localEvents){
          setSelected(localEvents);
          return;
        } 
      }
      setSelected(defaultTab);
    }
   
  },[]);

  const [selected, setSelected] = useState(defaultTab);

  const {isLoading} = useListContext();

  useEffect(() => {
    if(selected === 'main') {
      setFilter && setFilter({});
    } else {
      setFilter && setFilter({pending: true});
    }

    handleForm && handleForm(selected);
    
  },[selected]);
  
  return(
   
    !isLoading && (
      <div style={{...navBarStyle}}>
        {
          tabs?.map((tab, i) => (
            <Tooltip title={tab.title} placement="bottom">
              <Button
                style={{
                  ...tabStyle,
                  borderBottom: `3px solid ${selected === tab.redirectUrl ? palette?.primary : '#c4c1c1'}`, 
                  '&:hover': {
                    backgroundColor: 'transparent',
                  }
                }} onClick={() => {setSelected(tab.redirectUrl);}}
              >
                <Typography 
                  sx={{...typoStyle,
                    color:selected === tab.redirectUrl ? palette?.primary : 'grey',
                    marginLeft: i === 1 ? '10px': 0,

                  }}>
                  {tab.title}</Typography>
              </Button>
            </Tooltip>

          ))
        }
      </div>
    )
   
  );
};