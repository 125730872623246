import { Modal, Box } from '@mui/material';
import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import {Button} from '../Generics/index';
import { useSelector } from 'react-redux';

const UploadModal = ({ isOpen, onClose, image, onCancel, setIsModalOpen, fieldOnChange, fileUpload }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [y, setY] = useState(null);
    const palette = useSelector((state) => state?.palette?.palette);

    const onCropComplete = (croppedArea, croppedAreaPixels) => {
      // console.log('height de la foto', image?.dimensions?.height)
      // console.log('croppedArea', croppedArea, 'cropedAreaPixels', croppedAreaPixels);
      setY(croppedArea.y)
      // console.log('crop', crop);
    }

    const onCropChange = (crop) => {
      // Log the position of the cropper in pixels relative to the total height
      const cropperPosition = {
        x: Math.round(crop.x),
        y: Math.round(crop.y),
      };
      // Update the crop state
      setCrop(crop);
    };

    const onSave = () => {
      setIsModalOpen(false);
      console.log('se guardo esto', y)
      fieldOnChange({ 'original': fileUpload.location, 'y_center': Math.floor(y)})
    }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '45%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          // height: '400px',
          // width: '600px',
          height: '80%',
          width: '50%'
        }}
      >
        {/* <img
            src={image?.location}
            style={{ width: '100%', height: '100%', objectFit: 'contain', zIndex: 0, overflow: 'scroll' }}
        /> */}
        <Cropper
          style={{ objectFit: 'contain', zIndex: 0, overflow: 'scroll' }}
          image={image?.location}
          crop={crop}
          // zoom={zoom}
          aspect={1.5}
          // cropSize={width: number, height: number}
          objectFit={'horizontal-cover'}
          // onCropChange={setCrop}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
        <Button 
          style={{ position: 'relative', top: '108%', left: '73%', backgroundColor: palette?.primary, color: 'white'}}
          hoverStyle={{ backgroundColor: palette?.secondary}}
          onClick={onCancel}
        >Cancel</Button>
        <Button 
          style={{ position: 'relative', top: '108%', left: '76%', backgroundColor: palette?.primary, color: 'white'}}
          hoverStyle={{ backgroundColor: palette?.secondary}}
          onClick={onSave}
        >Save</Button>
      </Box>
    </Modal>
  );
};

export default UploadModal;