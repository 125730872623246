import React, { useEffect, useMemo } from 'react';
import {
  useStore,
  usePermissions,
  useGetList,
  useRecordContext,
} from 'react-admin';
import { Box, Button } from '@mui/material';
import { InputSubtitle } from '../../../Components/StyledComponents/InputSubtitle';
import { useParams } from 'react-router-dom';
import { SingleEventForm } from '../../TalentForm/TalentsEventForm';
import { Spinner } from '../../../Components/Spinner';
import {
  getFullData
} from '../../../assets/constants/dataFields';
// La prop EventFromTalent, es para poder acceder a la edicion del evento desde las TABS de talents.
const FormEvents = ({
  isEdit,
  closeModalEdit,
  resetFilterCategory,
  from,
  noTooltip,
  noValidate,
  idToaction,
  eventFromTalent,
  setViewTable,
  deleteResource,
  eventFromUnscheduled,
  noBack,
  onSave
}) => {
  // START : El antiguo TalentsForm >
  const [selectedTalent] = useStore('selectedTalent', 'selectedTalent', {
    selected: false,
    talent: '',
  });
  const [editSubmission, setEditSubmission] = useStore('edit_submission', null);

  const params = useParams();
  const record = useRecordContext();
  const { permissions: permis } = usePermissions();
  const talent_id = params['*']?.split('/')[1] ?? params.id;
  const newTalent_id = selectedTalent?.talent;
  const {
    data: events,
    isFetching,
  } = useGetList('events', {
    filter: {
      talent_id: permis !== 'talent_manager' ? [talent_id] : [newTalent_id],
    },
  },{
    enabled: isEdit
  });
  // Lógica para la edición de una submission y put a documento main.
  
  const editSub = useMemo(() => {
    if(permis === 'talent_manager') return false;
    let bool = editSubmission;
    return bool;
  },[]);

  useEffect(() => {
    return setEditSubmission(false);
  },[editSub]);
  const talentName =
    events &&
    events[0]?.talents?.map(
      (item, index) => item.id === talent_id && item?.name
    );
  const talentNameTM =
    events &&
    events[0]?.talents?.map(
      (item, index) => item.id === newTalent_id && item?.name
    );
  // END : El antiguo TalentsForm
  const editedFields = useMemo(() => {
    if (record?.submission && !['approved', 'rejected']?.includes(record?.submission?.status)) {
      console.log('aver');
      let arr = Object.keys(record?.submission).reduce((a, b) => {
        return [...a, b];

      }, []);
      return arr;
    }
    return null;
  }, [record]);
  const data = getFullData({from,
    permis,
    talentName: from === 'talents' ?talentName[0] : record?.talents ? record?.talents[0]?.name  : talentName,
    talentNameTM,
    isFetching,
    noValidate,
    isEdit,
    editedFields,
    events
  });
  
  return (
    <>
      {from !== 'talents' ? (
        <SingleEventForm
          resetFilterCategory={resetFilterCategory}
          closeModalEdit={closeModalEdit}
          // recordFromModal={recordFromModal}
          isEdit={isEdit}
          data={data}
          from={from}
          noTooltip={noTooltip}
          item={record ?? eventFromUnscheduled}
          idToaction={idToaction}
          editSub={editSub}
          noBack={noBack}
          onSave={onSave}
        />
      ) : (
        <>
          {permis === 'superadmin' || permis === 'admin' ? (
            events === undefined ? (
              <Spinner />
            ) :
              <SingleEventForm
                resetFilterCategory={resetFilterCategory}
                closeModalEdit={closeModalEdit}
                // recordFromModal={recordFromModal}
                isEdit={isEdit}
                data={data}
                from={from}
                noTooltip={noTooltip}
                idToaction={idToaction}
                editSub={editSub}
                item={eventFromTalent}
                setViewTable={setViewTable}
                deleteResource={deleteResource}
                
              />
          ) : permis === 'talent_manager' ? events?.length > 0  ?(
            <>
              <SingleEventForm
                resetFilterCategory={resetFilterCategory}
                closeModalEdit={closeModalEdit}
                // recordFromModal={recordFromModal}
                isEdit={isEdit}
                data={data}
                from={from}
                noTooltip={noTooltip}
                idToaction={idToaction}
                editSub={editSub}
                item={eventFromTalent}
                setViewTable={setViewTable}
              />
            </>
          ): <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingY: '100px',
              gap: '20px',
            }}
          >
            <InputSubtitle sx={{fontWeight:'600',fontSize:'14px'}}>
            This talent has no events assigned yet, click here to create
            one.
              <br />
            </InputSubtitle>
          </Box> : (
            <Spinner />
          )}
        </>
      )}
    </>
  );
};

export default FormEvents;