import React from 'react';
import { Create } from 'react-admin';
import FormFaqs from './FormFaqs';
import { transform } from './Transform';
import { CreateCustomComponent } from '../../../Components/CreateCustomComponent/CreateCustomComponent';

const CreateFaqs = () => {
  return (
    <CreateCustomComponent redirect='list' transform={transform} mutationMode={'pessimistic'}>
      <FormFaqs from={'faqs'}/>
    </CreateCustomComponent>
  );
};

export default CreateFaqs;