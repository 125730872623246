import dayjs from 'dayjs';

export const findTimes = (date, times) => {
  // Recibo una fecha , Ej 2022-11-15 y horarios
  // Si el horario es un string, retorno un array vacio (caso de CREATE)
  if(typeof times === 'string') return [];
  // Busco en el array de horarios cuales corresponden a esa fecha y los retorno
  return times?.filter((item) => dayjs(item).isSame(date, 'day')) || [];
};
export const handlerFormatTimes = (times, functionFormat) => {
  return times?.map(functionFormat);
};
export const handlerParseTimes = (times, functionParse) => {
  return times?.map(functionParse);
};
export const validateTimes = (times) => {
};