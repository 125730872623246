import React, { useEffect, useRef, useState } from 'react';
import { useInput, useRecordContext } from 'react-admin';
import { useSelector } from 'react-redux';
// Hooks & Utils
import useUploadAws from '../../../../Hooks/useUploadAws';
import { arraySource } from '../../../../helpers/arraySource';
// Components
import {IconButton} from '../../../Generics/index';
// Material & Icons
import {
  FormHelperText,
  Typography,
  ImageList,
  ImageListItem,
  TextField,
  Popover,
  useMediaQuery,
} from '@mui/material';
import { GrGallery } from 'react-icons/gr';
import { AiFillDelete, AiOutlineLink } from 'react-icons/ai';
import styles from './InputGalleryAws.module.css';

const iconBtStyle = {
  width: '25px',
  height: '25px',
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  outline: 'none',
  padding: 0.5,
  backgroundColor: '#00000082',
};
const InputGalleryAws = ({
  helperText,
  validate,
  label,
  source = 'picture',
  limit,
  picture,
  urlInput,
  size,
  draggable,
  sx,
  sxImg,
}) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const record = useRecordContext();
  const isMonitor = useMediaQuery('(min-width: 1400px)');
  const { field, fieldState } = useInput({ source, validate });
  const [uploadedImages, setUploadedImages] = useState(
    arraySource(record, source) ?? []
  );
  const inputRef = useRef(null);

  // Function to handle IconButton click
  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.click(); // Trigger the input click programmatically
    }
  };
  const [openPopoverIndex, setOpenPopoverIndex] = useState(null);
  const [hoverCard, setHoverCard] = useState(null);
  const [urlField, setUrlField] = useState('');
  const { uploadAWS } = useUploadAws();
  const popoverRefs = useRef([]);

  const handleClick = async (e) => {
    const fileUpload = await uploadAWS(e);
    let value = field?.value;
    if (fileUpload.success) {
      if (picture) {
        setUploadedImages([
          ...uploadedImages,
          { picture: { original: fileUpload.location } },
        ]);
        field.onChange([
          ...value,
          { picture: { original: fileUpload.location }, url: '' },
        ]);
        return;
      }
      setUploadedImages([...uploadedImages, { original: fileUpload.location }]);
      field.onChange([...value, { original: fileUpload.location }]);
    }
  };
  const handleRemoveImage = (index) => {
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);
    setUploadedImages(updatedImages);
    field.onChange(updatedImages);
  };
  //SECTION URL > START
  const handleOpenPopover = (props) => {
    const { index } = props;
    setOpenPopoverIndex(index);
  };
  const handleClosePopover = () => {
    setOpenPopoverIndex(null);
  };
  const handleChangeUrl = (value, index) => {
    let updatedValue = [...field.value];
    let item = updatedValue[openPopoverIndex];
    if (item) {
      updatedValue[openPopoverIndex] = {
        ...item,
        url: value,
      };
      field.onChange(updatedValue);
    }
    setUrlField(updatedValue);
  };
  //SECTION URL > END

  //SECTION SIZE > START
  const handleChangeSize = (e, index) => {
    let value = e.target.value;
    let updatedValue = [...field.value];
    let item = updatedValue[index];
    if (item) {
      updatedValue[index] = {
        ...item,
        size: value,
      };
      field.onChange(updatedValue);
    }
  };
  //SECTION SIZE > END
  const handleDnd = (itemsUpdated) => {
    field.onChange(itemsUpdated);
    setUploadedImages(itemsUpdated);
  };
  useEffect(() => {
    const listUrls = field?.value?.map((item) => {
      return {
        url: item?.url,
      };
    });
    setUrlField(listUrls);
  }, [uploadedImages]);
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'start',
        gap: 2,
        marginBottom: 3,
      }}
    >
      <div style={{ width: '100%', ...sx }}>
        {label && (
          <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
            {label}
          </Typography>
        )}
        <div
          className={styles.containerInputs}
          style={{
            color: fieldState.invalid && 'red',
            padding: uploadedImages?.length > 0 ? '6px' : '10px 20px',
            justifyContent:
              uploadedImages?.length === 0 ? 'center' : 'space-between',
            width: isMonitor ? '900px' : '700px',
          }}
        >
          {uploadedImages && uploadedImages?.length > 0 && (
            <>
              <ImageList
                sx={{
                  maxWidth: uploadedImages?.length === limit ? '100%' : '80%',
                  margin: '0px',
                  height: '100%',
                  gridAutoFlow: 'column',
                  padding:'5px 2px',
                  '&::-webkit-scrollbar': {
                    height: '10px',
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                    borderRadius: '20px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: palette?.primary,
                    borderRadius: '20px',
                  },
                }}
              >
                {uploadedImages?.map((image, index) => (
                  <ImageDnDGallery
                    urlField={urlField}
                    size={size}
                    image={image}
                    index={index}
                    urlInput={urlInput}
                    popoverRefs={popoverRefs}
                    handleOpenPopover={handleOpenPopover}
                    handleRemoveImage={handleRemoveImage}
                    handleChangeUrl={handleChangeUrl}
                    handleChangeSize={handleChangeSize}
                    handleClosePopover={handleClosePopover}
                    openPopoverIndex={openPopoverIndex}
                    uploadedImages={uploadedImages}
                    onChangeField={handleDnd}
                    draggable={draggable}
                    sxImg={sxImg}
                    setHoverCard={setHoverCard}
                    hoverCard={hoverCard}
                  />
                ))}
              </ImageList>
            </>
          )}
          {uploadedImages && uploadedImages?.length < limit && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '20%',
              }}
            >
              <label htmlFor="upload-button">
                <input
                  hidden
                  accept="image/*"
                  id="upload-button"
                  type="file"
                  onChange={handleClick}
                  multiple
                  ref={inputRef}

                />
                <IconButton
                  component="span"
                  style={{ height: '35px', width: '35px' }}
                  onClick={handleIconClick}
                >
                  <GrGallery size={24} />
                </IconButton>
              </label>
              <Typography fontWeight={700} fontSize={'14px'} color={'#515151'}>
                Add images here
              </Typography>
            </div>
          )}
          {uploadedImages == undefined || uploadedImages === null ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <label htmlFor="upload-button">
                <input
                  hidden
                  accept="image/*"
                  id="upload-button"
                  type="file"
                  onClick={handleClick}
                  multiple
                  ref={inputRef}
                />
                <IconButton
                  component="span"
                  style={{ height: '35px', width: '35px' }}
                >
                  <GrGallery size={24} />
                </IconButton>
              </label>
              <Typography fontWeight={700} fontSize={'15px'} color={'#515151'}>
                Add images here
              </Typography>
              <Typography fontWeight={400} fontSize={'13px'} color={'#515151'}>
                Drag images here or click to browse
              </Typography>
            </div>
          ) : null}
        </div>
        {helperText && (
          <FormHelperText sx={{ marginLeft: '10px' }}>
            {helperText}
          </FormHelperText>
        )}
        {fieldState.invalid && (
          <FormHelperText sx={{ color: 'red' }}>
            The Image is required
          </FormHelperText>
        )}
      </div>
    </div>
  );
};
const SelectSizeGallery = ({ handler, index, value }) => {
  return (
    <>
      <select
        name='Size'
        id='sponsorsSelect'
        onChange={(e) => handler(e, index)}
        value={value}
        className={styles.selectStyle}
      >
        <option value=''>Size</option>
        <option value='1'>1</option>
        <option value='1/2'>1/2</option>
        <option value='1/3'>1/3</option>
      </select>
    </>
  );
};
const ImageDnDGallery = ({
  urlField,
  size,
  image,
  index,
  urlInput,
  popoverRefs,
  handleOpenPopover,
  handleRemoveImage,
  handleChangeUrl,
  handleChangeSize,
  handleClosePopover,
  openPopoverIndex,
  uploadedImages,
  onChangeField,
  draggable = 'false',
  sxImg,
  setHoverCard,
  hoverCard
}) => {
  const palette = useSelector((state) => state?.palette?.palette);

  //DND Functions
  const handleDragStart = (e, index) => {
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('cardIndex', index.toString());
  };

  const handleDragOver = (e,index) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = 'move';
    if (e.currentTarget) {
      setHoverCard(index);
    }
  };

  const handleLeave = () => {
    setHoverCard(null);
  };

  const handleDrop = (e, indexDrop) => {
    const draggedIndex = parseInt(e.dataTransfer.getData('cardIndex'), 10);
    const draggedCard = uploadedImages[draggedIndex];
    const updatedCards = uploadedImages.filter(
      (card, index) => index !== draggedIndex
    );
    updatedCards.splice(indexDrop, 0, draggedCard);
    onChangeField(updatedCards);
    e.preventDefault();
  };
  const dragProps =
    draggable === true
      ? {
        draggable: true,
        onDragStart: (e) => handleDragStart(e, index),
        onDragOver: (e)=> handleDragOver(e,index),
        onDragLeave: handleLeave,
        onDrop: (e) => handleDrop(e, index),
      }
      : { draggable: false };
  return (
    <ImageListItem
      ref={(el) => (popoverRefs.current[index] = el)}
      key={index}
      sx={{
        height: '100%',
        borderRadius: '10px',
        width: '200px',
      }}
      {...dragProps}
    >
      {size && (
        <SelectSizeGallery
          handler={handleChangeSize}
          index={index}
          value={image?.size}
        />
      )}
      {urlInput && (
        <Popover
          id={index}
          open={openPopoverIndex === index}
          anchorEl={popoverRefs?.current[index]}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              sx: {
                marginTop: '-30px',
                borderRadius: '10px',
              },
            },
          }}
        >
          <TextField
            value={urlField[index]?.url}
            onChange={(event) => handleChangeUrl(event.target.value, index)}
            placeholder={'URL of your image'}
            sx={{
              width: '100%',
              height: '100%',
              padding: 0,
              margin: 0,
              backgroundColor: 'transparent',
            }}
            inputProps={{ style: { padding: 2, margin: 0 } }}
          />
        </Popover>
      )}
      <img
        src={image?.picture?.original ?? image.original}
        alt={`Uploaded Image ${index + 1}`}
        loading='lazy'
        style={{
          height: '160px',
          width: '100%',
          borderRadius: '10px',
          objectFit: 'contain',
          cursor: 'grab',
          boxShadow: hoverCard === index
            ? `0px 0px 2px 2px ${palette.primary}`
            : 'none',
          ...sxImg,
        }}
      />
      {urlInput && image?.url?.length > 1 && (
        <div
          style={{
            display: 'flex',
            maxWidth: '100%',
            position: 'absolute',
            bottom: 0,
            padding: '2px',
          }}
        >
          <span className={styles.urlSpanStyle} >{image.url}</span>
        </div>
      )}
      {urlInput && (
        <IconButton
          icon={<AiOutlineLink size={17} color='white' />}
          onClick={() => handleOpenPopover({ index: index })}
          style={{
            ...iconBtStyle,
            position: 'absolute',
            top: 4,
            right: 35,
            backgroundColor: image?.url ? palette?.primary : '#00000082',
          }}
        />
      )}
      <IconButton
        icon={ <AiFillDelete size={17} color='white' />}
        onClick={() => handleRemoveImage(index)}
        style={{
          ...iconBtStyle,
          position: 'absolute',
          top: 4,
          right: 5,
        }}
      />
    </ImageListItem>
  );
};

export default InputGalleryAws;
