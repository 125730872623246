import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { styleAbsolute } from '../../Theme';
import {Button} from '../Generics/index';
import { useSelector } from 'react-redux';

const DeleteModal = ({
  preventRowClick,
  openModal,
  setOpenModal,
  confirmDelete,
  idToAction,
  textAction,
}) => {
  const palette = useSelector((state) => state?.palette?.palette);
  const handleDeleteModal = (e)=>{
    e.stopPropagation();
    setOpenModal(false);
  };
  const handleConfirmDelete = (e)=>{
    e.stopPropagation();
    if(idToAction !== null){
      confirmDelete(idToAction);
    }else{
      confirmDelete();
    }
  };
  return (
    <Modal
      open={openModal}
      sx={{ zIndex: 9999 }}
      onClose={handleDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleAbsolute}>
        <Typography id="modal-modal-title" component="h4">
          Are you sure you want to delete this {textAction}
        </Typography>
        <Box
          id="modal-modal-description"
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            mt: 6,
          }}
        >
          {idToAction !== null ? (
            <Button
              style={{
                backgroundColor: palette?.primary,
                color: '#fff',
              }}
              hoverStyle={{ backgroundColor: palette?.secondary }}
              onClick={handleConfirmDelete}
            >
              Confirm
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: palette?.primary,
                color: '#fff',
              }}
              hoverStyle={{ backgroundColor: palette?.secondary }}
              onClick={handleConfirmDelete}
            >
              Confirm
            </Button>
          )}
          <Button
            sx={{
              backgroundColor: palette?.primary,
              color: '#fff',
            }}
            hoverStyle={{ backgroundColor: palette?.secondary,color: '#fff' }}

            variant="filled"
            onClick={handleDeleteModal}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
