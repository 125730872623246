import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { required } from 'react-admin';
// Hooks & Utils
import { VISIBILITY_TYPES } from '../../../../assets/constants/BlogsUtils';
// Components
import {
  InputPillsSelect,
  InputTabSingleSelector,
} from '../../../../Components/RedesignComponents/Inputs/index.js';
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
import InputRichText from '../../../../Components/InputsForm/InputRichText';
import InputText from '../../../../Components/InputsForm/InputText';
import InputTags from '../../../../Components/RedesignComponents/Inputs/InputTags.jsx';
// Material & Icons
import { Typography } from '@mui/material';
import { InputImage } from '../../../../Components/InputsForm/InputImage.jsx';

const FormBlogs = ({ isEdit, from }) => {
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_BASE_PATH}/articles/categories`,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((err) => {
        throw err;
      });
  }, []);
  const data = [
    {
      title: 'CONTENT',
      inputs: [
        {
          field: (
            <InputText
              title={'Title'}
              label=""
              subtitle=""
              source="title"
              fullWidth
              required={true}
              validate={[required('The title is required.')]}
            />
          ),
          breakpoints: { xs: 6 },
        },
        {
          field: (
            <InputText
              title={'Author'}
              label=""
              subtitle=""
              source="author"
              fullWidth
              // required={true}
              // validate={[required()]}
            />
          ),
          breakpoints: { xs: 6 },
        },
        {
          field: (
            <InputText
              title={'Subtitle'}
              label=""
              subtitle=""
              source="subtitle"
              fullWidth
              // validate={[required()]}
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: <InputImage title={'MAIN IMAGE'} source="picture" />,
          source: 'picture',
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputRichText
              label=""
              source="body"
              title="Body"
              validate={[required('The body is required.')]}
            />
          ),
          // source: 'body',
          breakpoints: { xs: 12 },
        },
      ],
    },
    {
      title: 'POST DETAILS',
      inputs: [
        {
          field: (
            <InputTabSingleSelector
              label=""
              title={
                <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                  VISIBILITY
                </Typography>
              }
              options={VISIBILITY_TYPES}
              fullWidth
              source="visibility"
              resource={'articles'}
              validate={[required('The visibility is required.')]}
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputPillsSelect
              choices={categories && categories}
              source="category"
              reference="articles/categories"
              label=""
              title={
                <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                  CATEGORY
                </Typography>
              }
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputTags
              title="Tags"
              subtitle=""
              source="tags"
              label=""
              reference={'articles/tags'}
            />
          ),
          source: 'tags',
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];
  return (
    <AdaptableFormRedesign
      isEdit={isEdit}
      data={data}
      from={from}
      icon={false}
      saveBackground={true}
      showDelete
      noName
    />
  );
};

export default FormBlogs;
