import { regex } from 'react-admin';
import InputText from '../../../../Components/InputsForm/InputText';
import { Box } from '@mui/material';
import { InputImage } from '../../../../Components/InputsForm/InputImage';
import { linkRegex } from '../../../TalentForm/regex';

export const LinksItems = (props) => {
  const { index, source, noIcon } = props;

  const sourceIndex = `${source}[${index}]`;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '20px',
      }}
    >
      {!noIcon && (
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100px' }}
        >
          <InputImage
            title={index === 0 ? 'Icon' : null}
            source={`${sourceIndex}`}
            action="icon"
            sxContainer={{ display: 'flex', height: '100%', margin: '0px' }}
          />
        </div>
      )}
      <InputText
        title={index === 0 ? 'Name' : null}
        direction="column"
        label=""
        source={`${sourceIndex}.text`}
        containerSx={{ height: '120px' }}
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
      <InputText
        validate={[
          regex(
            linkRegex,
            ' "Oops, looks like your link does not match the required structure. Have you tried https://[your_link].com?"',
          ),
        ]}
        title={index === 0 ? 'Link' : null}
        direction="column"
        label=""
        source={`${sourceIndex}.link`}
        containerSx={{ height: '120px' }}
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </Box>
  );
};
