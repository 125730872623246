
//EmbedRegex
export const soundcloudEmbedRegex =
    /https:\/\/w\.soundcloud\.com\/player\/\?url=https%3A\/\/api\.soundcloud\.com\/(tracks|playlists)\/(\d+)&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true/; //eslint-disable-line
export const youtubeEmbedRegex =
    /^https:\/\/(www\.)?youtube\.com\/embed\/([^\"&?\/\s]{11})/; //eslint-disable-line
export const spotifyEmbedRegex =
    /^https:\/\/open\.spotify\.com\/embed\/(track|playlist|album|episode|show|artist)\/[a-zA-Z0-9?=_%&-]+(?:\/\w+)?(?:\?.*)?$/;

//Link regex
  export const linkRegex = /^https:\/\/(?:www\.)?[^\s]*\.[a-zA-Z]{2,}[^\s]*$/; //eslint-disable-line
export const spotifyRegex =
    /^https:\/\/open\.spotify\.com\/(artist)\/[a-zA-Z0-9]{22}(\?[^\s]*)?$/; //eslint-disable-line
export const instagramRegex =
    /^https:\/\/(www\.)?instagram\.com\/(?:[a-zA-Z0-9_\.]{2,})\/?(?:\?[^\/\s]*)?$/; //eslint-disable-line
export const soundcloudRegex =
    /^https:\/\/(?:www\.|on\.)?soundcloud\.com\/([a-zA-Z0-9_-]+)\/?(?:\?[^\/\s]*)?$/; //eslint-disable-line
export const youtubeRegex =
    /^https:\/\/(?:www\.)?youtube\.com\/(?:c\/|channel\/|user\/)?([^\/\?]+)(?:\/videos)?(?:\?[^\/\s]*)?$/; //eslint-disable-line
