import React, { useEffect } from 'react';
import dayjs from 'dayjs';
const Header = React.memo(function Header({
  styleProps,
  setActualDay,
  actualDay,
  days,
  loadingCalendar,
  actualPeriod,
  setActualPeriod,
  setPeriods,
}) {
  const formatDays = days?.map((item) =>
    dayjs(item).format('YYYY-MM-DDT00:00:00'),
  );
  const sortedDays = formatDays.sort((a, b) => {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
  const containerPeriods = [];
  let currentPeriod = [];
  sortedDays.forEach((date, index) => {
    if (
      currentPeriod.length === 0 ||
      dayjs(date).diff(
        dayjs(currentPeriod[currentPeriod.length - 1]),
        'day',
      ) === 1
    ) {
      currentPeriod.push(date);
    } else {
      containerPeriods.push(currentPeriod);
      currentPeriod = [date];
    }
    if (index === sortedDays.length - 1) {
      containerPeriods.push(currentPeriod);
    }
  });
  const scrollInTable = async (item) => {
    if (!actualPeriod.includes(dayjs(item).format('YYYY-MM-DDT00:00:00'))) {
      loadingCalendar(true);
      setActualDay(item);
      setTimeout(() => {
        loadingCalendar(false);
      }, 2000);
      moveIntoDay(item);
    } else {
      loadingCalendar(false);
      const targetElement = document.getElementById(`${item}T00:00:00`);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };
  const moveIntoDay = (item) => {
    setTimeout(() => {
      const targetElement = document.getElementById(`${item}T00:00:00`);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 2000);
  };
  const dynamicStyle = (isActive, styleProps) => {
    const baseStyle = styleProps?.style;
    const activeColor = styleProps?.dayActiveBackground;

    return {
      ...baseStyle,
      backgroundColor: isActive ? activeColor : baseStyle?.backgroundColor,
    };
  };
  const periodActive = (period) => {
    return period.every((date) => actualPeriod.includes(date))
      ? 'active'
      : 'inactive';
  };
  useEffect(() => {
    if (actualPeriod?.length === 0) setActualPeriod(containerPeriods[0]);
  }, []);
  useEffect(() => {
    const dayPeriod = containerPeriods.find((period) =>
      period.includes(dayjs(actualDay).format('YYYY-MM-DDT00:00:00')),
    );
    if (
      dayPeriod &&
      actualPeriod.length >= 1 &&
      JSON.stringify(actualPeriod) !== JSON.stringify(dayPeriod)
    ) {
      setActualPeriod(dayPeriod);
    }
  }, [actualDay]);
  useEffect(() => {
    setPeriods(containerPeriods);
  }, []);
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', marginBottom: '1em' }}
    >
      <div
        className="calendar-header"
        data-test="calendar-header"
        style={{
          width: '100%',
          overflowX: 'auto',
          height: '100%',
          scrollbarWidth: 'thin',
          ...styleProps?.daysContainer,
        }}
      >
        {containerPeriods?.map((period, index) => (
          <div
            className="calendar-header-period-container"
            key={index}
            data-test={`calendar-header-period-container-${periodActive(period)}`}
          >
            {containerPeriods.length > 1 && (
              <p
                style={{
                  color: 'black',
                  fontWeight: '600',
                  marginBottom: '5px',
                  fontSize: '16px',
                  ...styleProps?.header?.periods,
                }}
              >
                Period {index + 1}
              </p>
            )}
            <div className="calendar-header-period" key={index}>
              {period.map((item, index) => (
                <button
                  data-test="calendar-header-button"
                  onClick={() =>
                    scrollInTable(dayjs(item).format('YYYY-MM-DD'))
                  }
                  key={index}
                  style={dynamicStyle(
                    actualDay === dayjs(item).format('YYYY-MM-DD'),
                    styleProps?.header?.daysButton,
                  )}
                >
                  <p
                    style={{
                      color:
                        actualDay === dayjs(item).format('YYYY-MM-DD')
                          ? styleProps?.header?.daysButton?.fontColorActive
                          : styleProps?.header?.daysButton?.style?.color,
                    }}
                  >
                    {dayjs(item).format('ddd')}
                  </p>
                  <p
                    style={{
                      color:
                        actualDay === dayjs(item).format('YYYY-MM-DD')
                          ? styleProps?.header?.daysButton?.fontColorActive
                          : styleProps?.header?.daysButton?.style?.color,
                    }}
                  >
                    {dayjs(item).format('DD/MM')}
                  </p>
                </button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
export default Header;