import { Box, TextField, Typography } from '@mui/material';
import { mainColor } from '../../../../Theme';
import { RxCross2 } from 'react-icons/rx';
import { regex, useInput } from 'react-admin';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useController, useForm, useFormContext, useFormState, useWatch } from 'react-hook-form';


export const EventsComponent = ({events, control, newValues, handleChange, deleteHandle, componentKey, children}) => {
  const [event, setEvent] = useState({name: `Event ${newValues.length + 1}`, duration: 60});
  const regexNumber = /^(?!-)[0-9]+$/;
  const { getValues } = useFormContext();
  const handleHandleChange = (e) => {
    let value = e.target.value;
    if(regexNumber.test(value) || value === '') {
      setEvent({...event, duration: value });
    } else {
      return;
    }
  };

  useEffect(() => {
    if(
      control._formValues['category'] === 'Music' && 
      control._formValues['name']?.length > 0
    ) {
      return setEvent({name: control._formValues['name'], duration: event.duration});
    } else {
      return setEvent({name: `Event ${newValues.length + 1}`, duration: event.duration});
    }
  },[control._formValues['category']]);

  useEffect(() => {
    let valueUpdated = false;
    const updatedValues = newValues.map((item) => {
      if (item.key === componentKey) {
        valueUpdated = true;
        return { 
          ...item, 
          value: event 
        };
      }
      return item;
    });
    if (!valueUpdated) {
      updatedValues.push({ key: componentKey, value: event });
    }
    handleChange(updatedValues);
    return() => {
      valueUpdated = false;
    };
  },[event]);
  
  return (
    <>
      <Box 
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '20px'
        }}
      >
        <Controller 
          control={control}
          value={event.name}
          name={`eventName.${componentKey}`} 
          render={({field,
            fieldState:  { invalid, isTouched, isDirty, error },
            formState}) => {

            return (
              <TextField
                sx={{width:'15.5em'}}
                {...field} 
                value={event.name}
                helperText={error?.message}
                {...formState} 
                error={error}
                isTouched={isTouched} 
                variant="outlined" 
                label="Event Name"
                onChange={(e) => {field.onChange(e) && setEvent({...event, name: e.target.value});}}
              />
            );
          }} />
        <Controller 
          control={control}
          value={event.duration}
          rules={{
            min: {
              value: 5,
              message: 'Duration cant be less that 5 minutes.'
            },
            pattern: {
              value: regexNumber,
              message: 'The duration can only contain positive numbers'
            }
          }}
          name={`eventDuration.${componentKey}`}
          render={({field,
            fieldState:  { invalid, isTouched, isDirty, error },
            formState,}) => (
            <TextField
              {...field} 
              helperText={error?.message}
              {...formState} 
              error={error}
              isTouched={isTouched} 
              sx={{
                width: '15.5em',
              }}
              value={event.duration}
              onChange={(e) => {field.onChange(e); handleHandleChange(e);}}
              variant="outlined"
              label="Duration (Minutes)"
            />
          )} />
        {children}
      </Box>
    </>
  );
};