import { transformSlug } from '../../../../helpers/transforms';

export const transform = (data) => {
  const slug = transformSlug(data.title);
  return(
    {
      ...data,
      slug
    }
  );
};